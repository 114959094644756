import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { SprintsTable } from './components/SprintsTable'
import { Container, Content, SubHeader, Button } from './styles'
import { CreateSprintModal } from './components/CreateSprintModal'
import { EditSprintModal } from './components/EditSprintModal'
import { IStoreProps } from 'store/modules/store/types'
import { storeRequest } from 'store/modules/store/actions'
import { ISprint, ISprintProps } from 'store/modules/sprints/types'
import {
  getActiveSprintsRequest,
  getFinishedSprintsRequest
} from 'store/modules/sprints/actions'

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}
interface SelectorSprints {
  state: DefaultRootState
  sprints: ISprintProps
}

export const Sprints = () => {
  const [isCreateSprintModalOpen, setIsCreateSprintModalOpen] = useState(false)
  const [isEditSprintModalOpen, setIsEditSprintModalOpen] = useState(false)
  const [selectedSprint, setSelectedSprint] = useState<ISprint>({} as ISprint)

  const dispatch = useDispatch()

  const { storeId } = useParams<{ storeId: string }>()

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { activeSprints, finishedSprints, hasRequested, metadata, loading } =
    useSelector<SelectorSprints, ISprintProps>(state => state.sprints)
  const token = window.localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (!store) {
      dispatch(storeRequest(storeId, token))
    }
  }, [store])

  useEffect(() => {
    if (!hasRequested && store) {
      dispatch(getActiveSprintsRequest(store._id, token))
      dispatch(getFinishedSprintsRequest(store._id, token))
    }
  }, [hasRequested, store])

  const handleOpenEditSprintModal = (sprint: ISprint) => {
    setSelectedSprint(sprint)
    setIsEditSprintModalOpen(true)
  }

  return (
    <Container>
      <SubHeader>
        <Button onClick={() => setIsCreateSprintModalOpen(true)}>
          ADICIONAR CORRIDINHA
        </Button>
      </SubHeader>

      <Content>
        <SprintsTable
          sprints={activeSprints}
          tableTitle='Corridinhas Ativas'
          metadata={metadata}
          isLoading={loading}
          openEditModal={handleOpenEditSprintModal}
        />
      </Content>
      <Content>
        <SprintsTable
          sprints={finishedSprints}
          tableTitle='Encerradas'
          storeId={store?._id}
          metadata={metadata}
          isLoading={loading}
        />
      </Content>

      {store && (
        <CreateSprintModal
          isOpen={isCreateSprintModalOpen}
          closeModal={() => setIsCreateSprintModalOpen(false)}
          storeId={store._id}
          isLoading={loading}
        />
      )}

      {isEditSprintModalOpen && (
        <EditSprintModal
          isOpen={isEditSprintModalOpen}
          closeModal={() => setIsEditSprintModalOpen(false)}
          initialSprintState={selectedSprint}
        />
      )}
    </Container>
  )
}
