import styled from 'styled-components'

export const Line = styled.div`
  height: 320px;
  width: 1px;
  background-color: #dddddd;
  position: relative;
  margin: 0 36px;

  div:first-child {
    font-family: 'Roboto', sans-serif;
    color: #c4c4c4;
    padding: 10px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
`
