import { TextField } from '@material-ui/core'
import styled, { css } from 'styled-components'
interface StoryPagesNumberProps {
  storiesLength: number
}
interface FooterSaveButtonProps {
  variant?: 'contained'
  disabled?: boolean
}

export const Container = styled.div`
  padding: 2rem;
  overflow-y: scroll;
  background-color: #fafafa;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  width: 100%;
`

export const Label = styled.h6`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  color: #5b5b76;

  svg {
    margin-right: 4px;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e8;
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;
`

const DefaultButton = styled.button`
  border-radius: 50px;
  padding: 11px 0;
  width: 100%;

  cursor: pointer;
  background-color: transparent;

  font-weight: 700;
  font-size: 0.875rem;
  font-family: 'Commissioner', sans-serif;
`
export const FooterSaveButton = styled(DefaultButton)<FooterSaveButtonProps>`
  border: 2px solid ${({ disabled }) => (disabled ? '#9A9AB1' : '#2a3ecb')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ variant, disabled }) =>
    variant === 'contained'
      ? css`
          background-color: ${disabled ? '#9A9AB1' : '#2a3ecb'};
          color: ${disabled ? '#EEEEF1' : '#fff'};
        `
      : css`
          color: #2a3ecb;
        `};
`

export const TextInput = styled(TextField)`
  font-family: 'Roboto', sans-serif;
  label {
    color: #949494;
    font-size: 0.875rem;
  }
  fieldset {
    border-color: #c4c4c4;
  }

  input {
    color: #263238;
  }
`

export const StoryPagesNumber = styled.p<StoryPagesNumberProps>`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: ${({ storiesLength }) =>
    storiesLength === 10 ? '#F34456' : '#9A9AB1'};
`
