import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { formatPrice } from '../../../utils/format'
import { IMonthReport } from '../../../store/modules/report/types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core'
import palette from 'theme/palette'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const Performance = ({ className = '', data, monthReport, ...rest }) => {
  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title='Performance'
        subheader={
          monthReport?.otherInputs &&
          `Todas as lojas: ${formatPrice(monthReport?.store?.total.sold)}`
        }
      />
      <Divider />
      <CardContent>
        {data && data.goals ? (
          <div className={classes.chartContainer}>
            <Bar
              data={{
                labels: data.labels,
                datasets: [
                  {
                    label: 'Faturado: R$',
                    backgroundColor: palette.primary.dark,
                    data: data.sold,
                    borderRadius: 4
                  },
                  {
                    label: 'Meta: R$',
                    backgroundColor: palette.black + '40',
                    data: data.goals,
                    borderRadius: 4
                  },
                  {
                    label: 'Meta recalculada: R$',
                    backgroundColor: palette.secondary.light,
                    data: data.future,
                    borderRadius: 4
                  }
                ]
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    enabled: true,
                    mode: 'index',
                    intersect: false,
                    borderWidth: 1,
                    borderColor: palette.divider,
                    backgroundColor: palette.white,
                    titleColor: palette.text.primary,
                    bodyColor: palette.text.secondary,
                    footerColor: palette.text.secondary,
                    filter: item => item.raw > 0,
                    itemSort: (a, b) => b.datasetIndex - a.datasetIndex
                  }
                },
                scales: {
                  x: {
                    stacked: true,
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    grid: {
                      borderDash: [3],
                      drawBorder: false
                    },
                    ticks: {
                      callback: (value, index, ticks) => 'R$ ' + value
                    }
                  }
                },
                maintainAspectRatio: false,
                interaction: {
                  intersect: false
                },
                layout: { padding: 0 }
              }}
            />
          </div>
        ) : (
          <Typography variant='subtitle2'>
            Não há meta para esse mês.
          </Typography>
        )}
      </CardContent>
      {/* <Divider />
      <CardActions className={classes.actions}>
        <Button
          color='primary'
          size='small'
          variant='text'
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  )
}

Performance.propTypes = {
  className: PropTypes.string
}

export default Performance
