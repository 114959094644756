import { IGoal, IUpdateUsers } from './types'

export function goalRequest(goalId: string, token: string | null) {
  return {
    type: '@goal/GOAL_REQUEST',
    payload: { goalId }
  }
}

export function goalListRequest(
  storeId: string,
  token: string | null,
  page = 1,
  limit = 10
) {
  return {
    type: '@goal/LIST_REQUEST',
    payload: { storeId, token, page, limit }
  }
}

export function goalListSuccess(goalsList: IGoal[]) {
  return {
    type: '@goal/LIST_SUCCESS',
    payload: { goalsList }
  }
}

export function clearGoalSave() {
  return {
    type: '@goal/CLEAR_SAVE'
  }
}

export function goalAddRequest() {
  return {
    type: '@goal/ADD_REQUEST'
  }
}

export function goalAddSuccess(goal: IGoal) {
  return {
    type: '@goal/ADD_SUCCESS',
    payload: { goal }
  }
}

export function goalFailed(error: any) {
  return {
    type: '@goal/FAILED',
    payload: { error }
  }
}

export function handleOpenModal() {
  return {
    type: '@modal/GOAL_OPEN'
  }
}

export function handleCloseModal() {
  return {
    type: '@modal/GOAL_CLOSE'
  }
}

export function currentGoalRequest(storeId: string, token: string | null) {
  return {
    type: '@goal/CURRENT_REQUEST',
    payload: { storeId, token }
  }
}

export function currentGoalSuccess(currentGoal: IGoal) {
  return {
    type: '@goal/CURRENT_SUCCESS',
    payload: { currentGoal }
  }
}

export function currentGoalFailed() {
  return {
    type: '@goal/CURRENT_FAILED'
  }
}

export function monthGoalRequest(
  storeId: string,
  token: string | null,
  date: string
) {
  return {
    type: '@goal/MONTH_REQUEST',
    payload: { storeId, token, date }
  }
}

export function monthGoalSuccess(goal: IGoal) {
  return {
    type: '@goal/GOAL_SUCCESS',
    payload: { goal }
  }
}

export function goalUpdateUsers(
  users: IUpdateUsers[],
  goalId: string,
  token: string | null
) {
  return {
    type: '@goal/UPDATE_USERS',
    payload: { users, goalId, token }
  }
}

export function goalDeleteRequest(goalId: string, token: string | null) {
  return {
    type: '@goal/DELETE_REQUEST',
    payload: { goalId, token }
  }
}

export function goalEditMainGoalsRequest(
  goalId: string,
  data: any,
  token: string | null
) {
  return {
    type: '@goal/EDIT_MAINGOALS_REQUEST',
    payload: { goalId, data, token }
  }
}
