import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MUISelect
} from '@mui/material'
import { FormControl } from './styles'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
}

interface SelectProps {
  label: string
  value: string[]
  options: string[]
  error: boolean | undefined
  handleChange?: (newValues: string[]) => void
}

export const Select = ({
  label,
  value,
  options,
  error,
  handleChange
}: SelectProps) => {
  return (
    <FormControl error={error}>
      <InputLabel>{label}</InputLabel>
      <MUISelect
        labelId={`multiple-checkbox-label-${label}`}
        multiple
        value={value}
        onChange={event => {
          handleChange && handleChange(event.target.value as string[])
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected: any) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  )
}
