import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// Utils
import { cepMask, cnpjMask, phoneMask } from '../../../../utils/inputMasks'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Material UI
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import AvatarInput from '../AvatarInput'
import ReactSwitch from 'react-switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

//Icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { LockerIcon } from '../../../../assets/Icons/LockerIcon'

//Images
import ChaveMestraImage from '../../../../assets/chave-mestra.png'
import RemuneracaoImage from '../../../../assets/remuneracao.png'
import RankingImage from '../../../../assets/ranking.png'
import { RankingCardExample } from './components/RankingCardExample'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { listGroupRequest } from 'store/modules/group/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {},
  cardHeaderRoot: {
    padding: '0 16px 16px'
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DCEDC8',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    padding: '0 8px'
  },
  switchDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E2E4E9',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    padding: '0 8px'
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  sectionsContainer: {
    height: '100px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0
  },
  masterKey: {
    margin: '0 16px 24px',
    width: '160px'
  },
  adornment: {
    cursor: 'pointer'
  },
  masterKeyInput: {
    height: 35
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  iconCardHeader: {
    color: '#C4C4C4',
    width: '15px'
  },
  formControl: {
    width: '100%'
  },
  config: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1.5)
  }
}))

const StoreSettings = ({
  className,
  loading,
  store,
  handleSubmitData,
  setHasEditedFormInfo,
  handleUpdateStoreAvatar,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const { groups, hasRequested } = useSelector(state => state.group)
  const { user } = useSelector(state => state.user)

  const defaultValues = {
    avatar: store.photo.url,
    name: store.name,
    phone: store.phone,
    cnpj: store.cnpj,
    city: store.city,
    address: store.address,
    cep: store.cep,
    masterCode: store.masterCode,
    remunerationVisible: store.remunerationVisible,
    rankingEnabled: store.rankingEnabled,
    config: {
      storeType: store.config?.storeType,
      timeZone: store.config?.timeZone,
      modalityStore: store.config?.modalityStore,
      businessModel: store.config?.businessModel,
      sellersPerformance: store.config?.sellersPerformance,
      sellersSortRanking: store.config?.sellersSortRanking
    }
  }

  const [data, setData] = useState(defaultValues)
  const [selectedGroups, setSelectedGroup] = useState([])
  const [visibility, setVisibility] = useState(false)

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const config = {
    type: [
      'Loja de Rua',
      'Loja de Shopping / Galeria',
      'Quiosque de Rua',
      'Quiosque de Shopping / Galeria',
      'Megastore de Rua',
      'Megastore de Shopping / Galeria'
      // Caso adicionar uma modalidade nova, adicionar sempre no final da lista
    ],
    timeZone: {
      0: 'America/Rio_Branco',
      1: 'America/Manaus',
      2: 'America/Sao_Paulo',
      3: 'America/Noronha'
    },
    sellersConfig: {
      performance: {
        0: 'Não mostrar desempenhos dos outros vendedores',
        1: 'Desempenhos em relação ao primeiro lugar',
        2: 'Desempenho em relação à posição acima',
        3: 'Mostrar desempenhos sem comparação',
        4: 'Desempenhos em relação à própria posição'
      },
      sortRanking: {
        0: 'Faturamento',
        1: 'Porcentagem do Faturamento em relação à Meta Individual'
      }
    },
    modalityStore: [
      'Outros',
      'Moda Infantil',
      'Moda Feminina',
      'Moda Masculina',
      'Moda Gestante',
      'Calçados',
      'Moda Íntima',
      'Fitness',
      'Boutiques Pet',
      'Moda de Praia',
      'Brinquedos',
      'Óticas'
      // Caso adicionar uma modalidade nova, adicionar sempre no final da lista
    ],
    businessModel: ['Marca própria', 'Franquia', 'Multimarcas']
  }

  const timeZoneDictionary = {
    'America/Rio_Branco': '(UTC -05:00) Acre',
    'America/Manaus': '(UTC -04:00) Amazonas',
    'America/Sao_Paulo': '(UTC -03:00) São Paulo - Horário de Brasília',
    'America/Noronha': '(UTC -02:00) Ilha Fernando de Noronha'
  }

  // Checked Menu Style
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  }

  useEffect(() => {
    setSelectedGroup(groups.filter(group => group.stores.includes(store._id)))
  }, [groups])

  const token = localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (!hasRequested && user.type === 'director') {
      dispatch(listGroupRequest(token))
    }
  }, [hasRequested, dispatch, token, listGroupRequest])

  // Quando o usuário selecionar o valor renderizado no select, é salvo em Data a key (index) para ser enviada ao back
  const handleChangeTimeZone = event => {
    setData({
      ...data,
      config: {
        ...data.config,
        timeZone: Object.values(config.timeZone).findIndex(
          value => value === event.target.value
        )
      }
    })
    setHasEditedFormInfo(
      JSON.stringify(defaultValues) !==
        JSON.stringify({
          ...data,
          config: {
            ...data.config,
            timeZone: Object.values(config.timeZone).findIndex(
              value => value === event.target.value
            )
          }
        })
    )
  }

  // Função para popular o estado de data
  const handleChangeData = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
    setHasEditedFormInfo(
      JSON.stringify(defaultValues) !==
        JSON.stringify({
          ...data,
          [event.target.name]: event.target.value
        })
    )
  }

  // Função para popular o estado de data
  const handleChangeGroupData = event => {
    if (selectedGroups.some(el => el === event.target.value)) {
      setSelectedGroup(
        selectedGroups.filter(group => group._id !== event.target.value._id)
      )
    } else {
      setSelectedGroup([...selectedGroups, event.target.value])
    }
  }

  // Função para popular a configuração do estado de data
  const handleChangeConfig = event => {
    setData({
      ...data,
      config: {
        ...data.config,
        [event.target.name]: event.target.value
      }
    })
    setHasEditedFormInfo(
      JSON.stringify(defaultValues) !==
        JSON.stringify({
          ...data,
          config: {
            ...data.config,
            [event.target.name]: event.target.value
          }
        })
    )
  }

  // Função para enviar a requisição
  const submitData = () => {
    if (
      data.name &&
      data.cnpj &&
      data.city &&
      data.address &&
      data.cep &&
      typeof data.config.storeType === 'number' &&
      typeof data.config.timeZone === 'number' &&
      data.config.modalityStore.length > 0 &&
      typeof data.config.businessModel === 'number' &&
      typeof data.config.sellersPerformance === 'number' &&
      typeof data.config.sellersSortRanking === 'number'
    ) {
      setHasEditedFormInfo(false)
      handleSubmitData({
        ...data,
        phone: data.phone.replace(/[^a-zA-Z0-9]/g, ''),
        cep: data.cep.replace(/[^a-zA-Z0-9]/g, ''),
        cnpj: data.cnpj.replace(/[^a-zA-Z0-9]/g, '')
      })
    } else {
      toast.error('Preencha todas as informações da sua loja')
    }
  }

  return (
    <Grid spacing={2} container className={classes.container}>
      <Grid item xs={12} md={3}>
        <AvatarInput
          image={store && store.photo.url}
          title={store && store.name}
          subTitle={store && store.email}
          handleSubmitImage={dataRequest =>
            handleUpdateStoreAvatar(dataRequest)
          }
        />
      </Grid>
      <Grid item xs={12} md={8} className={classes.cAvatarInputardsContainer}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <form autoComplete='off' noValidate>
            <CardHeader
              title='Minha loja'
              subheader='Edite aqui as informações da sua loja'
            />
            <Divider />
            <CardContent>
              <Grid container spacing={1} data-clarity-unmask='true'>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Nome'
                    margin='dense'
                    name='name'
                    onChange={handleChangeData}
                    required
                    value={data.name}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Telefone'
                    margin='dense'
                    name='phone'
                    onChange={handleChangeData}
                    onFocus={event => event.target.select()}
                    value={data.phone}
                    InputProps={{
                      inputComponent: phoneMask
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cep'
                    margin='dense'
                    name='cep'
                    onChange={handleChangeData}
                    required
                    value={data.cep}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cepMask
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cidade'
                    margin='dense'
                    name='city'
                    onChange={handleChangeData}
                    required
                    value={data.city}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Endereço'
                    margin='dense'
                    name='address'
                    onChange={handleChangeData}
                    required
                    value={data.address}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='CNPJ'
                    margin='dense'
                    name='cnpj'
                    onChange={handleChangeData}
                    required
                    value={data.cnpj}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cnpjMask
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} className={classes.config}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    required
                  >
                    <InputLabel id='storeType'>Tipo de Loja</InputLabel>
                    <Select
                      labelId='storeType'
                      value={`${data.config.storeType}` || ''}
                      name='storeType'
                      onChange={handleChangeConfig}
                      label='Tipo de Loja'
                      MenuProps={MenuProps}
                    >
                      {config.type.map((type, index) => (
                        <MenuItem key={index} value={index}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={classes.config}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    required
                  >
                    <InputLabel id='timeZone'>Fuso Horário</InputLabel>
                    <Select
                      labelId='timeZone'
                      name='timeZone'
                      value={`${config.timeZone[data.config.timeZone]}` || ''}
                      onChange={handleChangeTimeZone}
                      label='Fuso Horário'
                      MenuProps={MenuProps}
                    >
                      {Object.entries(timeZoneDictionary).map(
                        ([zone, time]) => (
                          <MenuItem key={time} value={zone}>
                            {time}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={classes.config}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    required
                  >
                    <InputLabel id='modalityStore'>Setores da Loja</InputLabel>
                    <Select
                      name='modalityStore'
                      labelId='modalityStore'
                      value={data.config.modalityStore || []}
                      onChange={handleChangeConfig}
                      multiple
                      label='Setores da Loja'
                      renderValue={selected =>
                        config.modalityStore
                          .filter((_, index) => selected.includes(index))
                          .join(', ')
                      }
                      MenuProps={MenuProps}
                    >
                      {config.modalityStore
                        .slice(1)
                        // .sort()
                        .map((value, index) => (
                          <MenuItem value={index + 1} key={index + 1}>
                            <Checkbox
                              checked={
                                data.config.modalityStore?.indexOf(index + 1) >
                                -1
                              }
                              style={{
                                color: '#2A3ECB'
                              }}
                            />
                            <ListItemText primary={value} />
                          </MenuItem>
                        ))}
                      <MenuItem value={0}>
                        <Checkbox
                          checked={data.config.modalityStore?.indexOf(0) > -1}
                          style={{
                            color: '#2A3ECB'
                          }}
                        />
                        <ListItemText primary='Outros' />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={classes.config}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    required
                  >
                    <InputLabel id='businessModel'>
                      Modelo de Negócio
                    </InputLabel>
                    <Select
                      labelId='businessModel'
                      name='businessModel'
                      value={`${data.config.businessModel}` || ''}
                      onChange={handleChangeConfig}
                      label='Modelo de Negócio'
                      MenuProps={MenuProps}
                    >
                      {config.businessModel.map((businessModel, index) => (
                        <MenuItem key={index} value={index}>
                          {businessModel}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <CardContent hidden={user.type !== 'director'}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} className={classes.config}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    required
                  >
                    <InputLabel id='storeGroup'>Regional</InputLabel>
                    <Select
                      name='storeGroup'
                      labelId='storeGroup'
                      value={selectedGroups || []}
                      onChange={handleChangeGroupData}
                      label='Regional'
                      renderValue={selected =>
                        selectedGroups.map(group => group.name).join(', ')
                      }
                      MenuProps={MenuProps}
                    >
                      {groups.map((group, index) => (
                        <MenuItem key={index} value={group}>
                          <Checkbox
                            checked={selectedGroups.some(el => el === group)}
                            style={{
                              color: '#2A3ECB'
                            }}
                          />
                          <ListItemText primary={group.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={classes.config}>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.button}
                    style={{ width: '100%' }}
                    onClick={() => history.push('/groups')}
                  >
                    {loading ? (
                      <CircularProgress size={24} style={{ color: '#fff' }} />
                    ) : (
                      'Editar Regionais'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>

        <Card>
          <CardHeader
            title='Personalizar aplicativo'
            subheader='Defina as opções de uso do app da NeoPro'
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid
                container
                alignItems='flex-start'
                style={{ padding: '0 16px' }}
              >
                <Grid item>
                  <div className={classes.switch}>
                    <LockerIcon />
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <CardHeader
                    title={
                      <div className={classes.cardHeader}>
                        Chave-mestra
                        <Tooltip
                          title={<img src={ChaveMestraImage} width={200} />}
                          placement='right'
                          arrow
                        >
                          <ErrorOutlineIcon
                            className={classes.iconCardHeader}
                          />
                        </Tooltip>
                      </div>
                    }
                    subheader='Código de acesso usado pelos gestores para acessar os perfis dos vendedores da loja no app.'
                    titleTypographyProps={{ variant: 'h6' }}
                    classes={{
                      root: classes.cardHeaderRoot
                    }}
                  />
                  <TextField
                    label='Chave-mestra'
                    className={classes.masterKey}
                    margin='dense'
                    name='masterCode'
                    onChange={handleChangeData}
                    required
                    type={visibility ? 'text' : 'password'}
                    value={data.masterCode}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      'data-clarity-mask': 'True',
                      maxLength: 4,
                      endAdornment: (
                        <InputAdornment
                          className={classes.adornment}
                          position='end'
                        >
                          {visibility ? (
                            <VisibilityOffIcon
                              onClick={() => setVisibility(false)}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => setVisibility(true)}
                            />
                          )}
                        </InputAdornment>
                      ),
                      classes: { input: classes.masterKeyInput }
                    }}
                  />
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                  container
                  alignItems='center'
                  className={classes.sectionsContainer}
                >
                  <Grid item>
                    <div
                      className={
                        data.remunerationVisible
                          ? classes.switch
                          : classes.switchDisabled
                      }
                    >
                      <ReactSwitch
                        onColor='#09177d'
                        height={15}
                        width={40}
                        onHandleColor='#364AD3'
                        offHandleColor='#364AD3'
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={20}
                        checked={data.remunerationVisible}
                        onChange={() => {
                          setData({
                            ...data,
                            remunerationVisible: !data.remunerationVisible
                          })
                          setHasEditedFormInfo(
                            JSON.stringify(defaultValues) !==
                              JSON.stringify({
                                ...data,
                                remunerationVisible: !data.remunerationVisible
                              })
                          )
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <CardHeader
                      title={
                        <div className={classes.cardHeader}>
                          Apresentar remuneração
                          <Tooltip
                            title={<img src={RemuneracaoImage} width={200} />}
                            placement='right'
                            arrow
                          >
                            <ErrorOutlineIcon
                              className={classes.iconCardHeader}
                            />
                          </Tooltip>
                        </div>
                      }
                      subheader='Mostrar valores de remuneração individual no app aos vendedores.'
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                  </Grid>
                </Grid>
                {/* <Divider className={classes.divider} />
                 <Grid
                  container
                  alignItems='center'
                  className={classes.sectionsContainer}
                >
                  <Grid item>
                    <div
                      className={
                        data.rankingEnabled
                          ? classes.switch
                          : classes.switchDisabled
                      }
                    >
                      <ReactSwitch
                        onColor='#09177d'
                        height={15}
                        width={40}
                        onHandleColor='#364AD3'
                        offHandleColor='#364AD3'
                        checkedIcon={''}
                        uncheckedIcon={''}
                        handleDiameter={20}
                        checked={data.rankingEnabled}
                        onChange={() =>
                          setData({
                            ...data,
                            rankingEnabled: !data.rankingEnabled
                          })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <CardHeader
                      title={
                        <div className={classes.cardHeader}>
                          Ranking - mostrar vendas de outros vendedores
                          <Tooltip
                            title={<img src={RankingImage} width={200} />}
                            placement='right'
                            arrow
                          >
                            <ErrorOutlineIcon
                              className={classes.iconCardHeader}
                            />
                          </Tooltip>
                        </div>
                      }
                      subheader='Na tela de Ranking do app, apresentar os valores de vendas dos outros vendedores da loja ao usuário.'
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title='Ranking de Vendedores'
            subheader='Defina as opções de Ranking'
          />
          <Divider />
          <CardContent style={{ padding: '0 0 16px' }}>
            <Grid
              container
              alignItems='flex-start'
              style={{ padding: '0 16px' }}
            >
              <Grid
                container
                item
                xs={12}
                className={classes.config}
                direction='column'
              >
                <CardHeader
                  title='Desempenho dos Vendedores'
                  subheader='Caso mostrar, ou não, o desempenho dos vendedores na tela de ranking.'
                  style={{
                    paddingBottom: 6,
                    paddingLeft: 0,
                    marginRight: 'auto'
                  }}
                />
                <FormControl
                  variant='outlined'
                  className={classes.formControl}
                  size='small'
                  required
                >
                  <Select
                    name='sellersPerformance'
                    value={
                      data.config.sellersPerformance > -1
                        ? data.config.sellersPerformance
                        : ''
                    }
                    onChange={handleChangeConfig}
                    MenuProps={MenuProps}
                    style={{
                      maxWidth: isDesktop ? '50%' : '100%'
                    }}
                  >
                    {Object.entries(config.sellersConfig.performance).map(
                      ([index, config]) => (
                        <MenuItem key={index} value={Number(index)}>
                          {config}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Divider
                className={classes.divider}
                style={{ marginTop: '16px' }}
              />

              <Grid container item xs={12} md={6} direction='column'>
                <CardHeader
                  title='Ordenação do Ranking loja'
                  subheader='A referência para ordenar os vendedores no ranking.'
                  style={{
                    paddingBottom: 6,
                    paddingLeft: 0,
                    marginRight: 'auto'
                  }}
                />
                <FormControl
                  variant='outlined'
                  className={classes.formControl}
                  size='small'
                  required
                >
                  <Select
                    name='sellersSortRanking'
                    value={
                      data.config.sellersSortRanking > -1
                        ? data.config.sellersSortRanking
                        : ''
                    }
                    onChange={handleChangeConfig}
                    MenuProps={MenuProps}
                  >
                    {Object.entries(config.sellersConfig.sortRanking).map(
                      ([index, sortType]) => (
                        <MenuItem key={index} value={Number(index)}>
                          {sortType}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Divider
                className={classes.divider}
                style={{ marginTop: '16px' }}
              />

              <Grid
                container
                item
                xs={12}
                md={12}
                className={classes.config}
                direction='column'
              >
                <RankingCardExample
                  sellersPerformance={data.config.sellersPerformance}
                  sellersSortRanking={data.config.sellersSortRanking}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider className={classes.divider} />
        <CardActions className={classes.actions}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => submitData()}
            disabled={JSON.stringify(defaultValues) === JSON.stringify(data)}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: '#fff' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </CardActions>
      </Grid>
    </Grid>
  )
}

StoreSettings.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  store: PropTypes.object,
  handleSubmitData: PropTypes.func,
  handleUpdateStoreAvatar: PropTypes.func
}

export default StoreSettings
