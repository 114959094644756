import styled from 'styled-components'
import { TableCell as TableCellMUI } from '@mui/material'

export const TableCell = styled(TableCellMUI)`
  min-width: 150px;

  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
