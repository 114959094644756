import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { BootstrapTooltip } from 'components/BootstrapTooltip'

const headCells = [
  { id: 'name', label: 'Nome', minWidth: 275 },
  { id: 'salesDay', label: 'Vendas do dia', minWidth: 150 },
  { id: 'sold', label: 'Faturamento', minWidth: 125 },
  { id: 'goalPercent', label: '%', minWidth: 50 },
  { id: 'acDayGoal', label: 'Meta ideal', minWidth: 150 },
  { id: 'mainGoalPercent', label: '%', minWidth: 50 },
  { id: 'mainGoal', label: 'Meta do mês', minWidth: 200 },
  { id: 'projection', label: 'Projeção de venda', minWidth: 175 },
  { id: 'sales', label: 'Nº Vendas', minWidth: 125 },
  { id: 'average.tm', label: 'Ticket Médio', minWidth: 150 },
  { id: 'average.pv', label: 'Peça/Venda', minWidth: 100 },
  { id: 'average.pm', label: 'Preço Médio', minWidth: 150 },
  { id: 'total.items', label: 'Nº Peças', minWidth: 125 }
] as const

type OrderByProps = (typeof headCells)[number]['id']

interface StoresTableHeadProps {
  order: 'asc' | 'desc'
  orderBy: OrderByProps
  onHoverHeader: () => void
  onRequestSort: (property: OrderByProps) => void
}

const StoresTableHead = ({
  order,
  orderBy,
  onHoverHeader,
  onRequestSort
}: StoresTableHeadProps) => {
  const createSortHandler = (property: OrderByProps) => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow onMouseEnter={onHoverHeader}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: headCell.minWidth,
              ...(index === 0 && { zIndex: 10 })
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label === 'Meta ideal' ? (
                <BootstrapTooltip title='Meta até último dia com vendas.'>
                  <ErrorOutlineIcon
                    style={{ color: '#C4C4C4', fontSize: 20, marginLeft: 5 }}
                  />
                </BootstrapTooltip>
              ) : (
                headCell.label === 'Meta do mês' && (
                  <BootstrapTooltip title='Meta total mensal cadastrada para a loja.'>
                    <ErrorOutlineIcon
                      style={{ color: '#C4C4C4', fontSize: 20, marginLeft: 5 }}
                    />
                  </BootstrapTooltip>
                )
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default StoresTableHead
