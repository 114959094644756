import { useState, useEffect } from 'react'
import { History } from 'history'
import PropTypes from 'prop-types'
import moment from 'moment'

// Redux
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Divider, colors, Grid } from '@material-ui/core'
import PieChartIcon from '@material-ui/icons/PieChart'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import { useConfirm } from 'material-ui-confirm'

// Components
import Header from 'components/Header'
import { formatPrice } from '../../utils/format'
import { monthGoalRequest } from '../../store/modules/goal/actions'
import {
  inputRequest,
  inputUpdateRequest,
  inputDeleteRequest
} from '../../store/modules/input/actions'
import Users from './components/Users'
import ValueCard from '../../components/ValueCard'
import ReleaseHistory from './components/ReleaseHistory'
import { IInput, IInputProps, ISeller, ITotal } from 'store/modules/input/types'
import { IDays, IGoal, IGoalProps } from 'store/modules/goal/types'
import { RouteComponentProps } from 'react-router'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface InputProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
      id: string
    }
    path: string
    url: string
  }
  history: History
}

const Input = ({ match, history }: InputProps) => {
  const { id, storeId } = match.params

  // Estado
  const [input, setInput] = useState<IInput | null>(null)
  const [days, setGoal] = useState<IDays | null>(null)
  const [openModal, setOpenModal] = useState(false)

  const {
    inputList,
    metadata: inputMetadata,
    input: requestedInput
  } = useSelector<SelectorInput, IInputProps>(state => state.input)

  const { goal: requestedGoal, currentGoal } = useSelector<
    SelectorGoal,
    IGoalProps
  >(state => state.goal)

  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const token = window.localStorage.getItem('@NeoPro:token')

  // UseEffect para encontrar o input no redux
  // caso não ache faça uma requisição
  useEffect(() => {
    if (requestedInput && requestedInput._id === id) {
      setInput(requestedInput)
    } else {
      // Verifica se existe o inputList
      if (inputMetadata.pagination.totalCount > 0) {
        // Busca o input dentro do redux
        const inputExists = inputList.find(i => i._id === id)
        // Se não existir o input dentro do redux ele faz uma requisição
        if (inputExists) {
          setInput(inputExists)
        } else {
          dispatch(inputRequest(id, token))
        }
      } else {
        dispatch(inputRequest(id, token))
      }
    }
  }, [inputList, requestedInput])

  useEffect(() => {
    // Verifica se já tem o input, ele é necessário pra identificar o mês
    if (input) {
      // Mês desse input
      const inputMonth = moment(input.date)
      inputMonth.utcOffset(0).set({
        date: 1,
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      // Se já tem um mês requisitado no redux, vê se ele corresponde ao mês do input
      if (
        requestedGoal &&
        requestedGoal.month &&
        inputMonth.toISOString() === moment(requestedGoal.month).toISOString()
      ) {
        const selectedGoal = requestedGoal.days.find(d => d.date === input.date)
        if (selectedGoal) {
          setGoal(selectedGoal)
        }
      } else {
        // Se não, vê se o mês atual é o do input
        if (
          currentGoal &&
          currentGoal.month &&
          inputMonth.toISOString() === moment(currentGoal.month).toISOString()
        ) {
          const selectedGoal = currentGoal.days.find(d => d.date === input.date)
          selectedGoal && setGoal(selectedGoal)
        } else {
          // Se não, faz requisição do mês da input, que vai ser detectada no primeiro if
          dispatch(monthGoalRequest(storeId, token, String(inputMonth)))
        }
      }
    }
  }, [input, requestedGoal])

  const handleDeleteInput = () => {
    confirm({
      title: `Lançamento de ${
        input ? moment(input.date).format('DD/MMMM - dddd') : '...'
      }`,
      description: 'Você tem certeza que deseja deletar o lançamento?',
      confirmationText: 'Sim',
      cancellationText: 'Não'
    })
      .then(() => {
        if (input) {
          dispatch(inputDeleteRequest(input._id, token))
        }
        history.push(`/${storeId}/inputs`)
      })
      .catch(() => {})
  }

  return (
    <div className={classes.root}>
      <Header
        data={input}
        route={`/${storeId}/inputs`}
        actionText='APAGAR LANÇAMENTO'
        title='Lançamentos'
        subtitle={`Lançamento de ${
          input ? moment(input.date).format('DD/MMMM - dddd') : '...'
        }`}
        handleDelete={handleDeleteInput}
        openModal={() => setOpenModal(true)}
        dataCy='deleteInputButton'
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='percentual da meta'
            value={
              days
                ? days.goal > 0 && input
                  ? ((input.total.sold / days.goal) * 100).toFixed(1) + '%'
                  : 'Sem meta'
                : '...'
            }
            icon={<PieChartIcon />}
            color='#FFC700'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='faturamento'
            value={`${input ? formatPrice(input.total.sold) : '0'}`}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='quantidade de vendas'
            value={input?.total.sales.toFixed() ?? ''}
            icon={<LocalMallIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='peças vendidas'
            value={input?.total.items.toFixed() ?? ''}
            icon={<ShoppingBasketIcon />}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Users
          input={input}
          onUpdateSeller={(data: ISeller | ITotal, isStore: boolean) =>
            input &&
            dispatch(
              inputUpdateRequest(storeId, input._id, data, token, isStore)
            )
          }
        />
      </div>
      {openModal && (
        <ReleaseHistory
          open={openModal}
          onClose={() => setOpenModal(false)}
          input={input}
          integrated
        />
      )}
    </div>
  )
}

Input.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default Input
