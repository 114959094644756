export const convertFileSizeName = (file?: File) => {
  const fileSize = file?.size ?? 0

  if (fileSize >= 1048576) {
    return `${(fileSize / 1024 / 1024).toFixed(2)} Mb`
  } else if (fileSize >= 1024) {
    return `${(fileSize / 1024).toFixed(2)} Kb`
  } else if (fileSize > 1) {
    return `${fileSize.toFixed(2)} bytes`
  }
  return '0 bytes'
}
