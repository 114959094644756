import { UploadFile as UploadFileIcon } from '@mui/icons-material'

import { CustomICreateStoryline, IFile, SelectedMediaProps } from '../../'

import {
  AsideContainer,
  BadgeNumber,
  Label,
  Media,
  MediaPreviewContainer,
  MediasCarrousel,
  Video
} from './styles'

interface PreviewMediaListProps {
  files: IFile
  previewUrls: string[]
  selectedMedia: SelectedMediaProps
  storyData: CustomICreateStoryline
  handleEditMedia: (url: string) => void
}

export const PreviewMediaList = ({
  files,
  handleEditMedia,
  selectedMedia,
  previewUrls,
  storyData
}: PreviewMediaListProps) => {
  const title = storyData.name || 'Digite um título'

  return (
    <AsideContainer>
      <h2>{title}</h2>
      <MediasCarrousel>
        {previewUrls.map((url, index) => {
          const isADifferentMedia =
            url !== selectedMedia.url && index !== selectedMedia.index

          return (
            <MediaPreviewContainer
              key={url}
              onClick={() => {
                if (isADifferentMedia) {
                  handleEditMedia(url)
                }
              }}
            >
              <Label>
                <BadgeNumber>{index + 1}</BadgeNumber>
                {files[url]?.type === 'video/mp4' ? (
                  <Video src={url} disablePictureInPicture id='video-tag'>
                    <source id='video-source' src={url} />
                    Your browser does not support the video tag.
                  </Video>
                ) : (
                  <Media src={url} alt='Imagem do story'>
                    {!url && (
                      <UploadFileIcon
                        style={{
                          width: 29,
                          height: 38
                        }}
                      />
                    )}
                  </Media>
                )}
              </Label>
            </MediaPreviewContainer>
          )
        })}
      </MediasCarrousel>
    </AsideContainer>
  )
}
