import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '@material-ui/core'
import {
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon
} from '@mui/icons-material'
import { BiTrash } from 'react-icons/bi'
import { MdInsertLink, MdSchedule } from 'react-icons/md'

import { PromptModal } from 'views/CreateStoryline/styles'
import { formatVideoDuration } from 'utils/formatVideoDuration'

import {
  Container,
  Content,
  HeaderSection,
  Title,
  Footer,
  FooterSaveButton,
  FooterDeleteButton,
  Divider,
  UploadLabelButton,
  TextInput,
  Subtitle,
  Section,
  FooterCancelButton
} from './styles'
import { MediaData } from './components/ImageData'

interface MediaSettingsProps {
  currentMediaUrlPreview: string
  currentFile: File | undefined
  previewMedias: string[]
  setLink: Dispatch<SetStateAction<string>>
  link: string
  handleAddNewMedia: (link: string) => void
  handleCloseMediaSettings: () => void
  handleDeleteSelectedMedia: (imgSrc: string) => void
  handleRemoveSelectedMedia: (imgSrc: string) => void
}

export const MediaSettings = ({
  link,
  setLink,
  currentFile,
  previewMedias,
  handleAddNewMedia,
  currentMediaUrlPreview,
  handleCloseMediaSettings,
  handleRemoveSelectedMedia,
  handleDeleteSelectedMedia
}: MediaSettingsProps) => {
  const [videoDuration, setVideoDuration] = useState(0)

  const [isDeleteCurrentPageModalOpen, setIsDeleteCurrentPageModalOpen] =
    useState(false)

  const page =
    previewMedias.indexOf(currentMediaUrlPreview) > -1
      ? previewMedias.indexOf(currentMediaUrlPreview) + 1
      : previewMedias.length + 1

  useEffect(() => {
    const video = document?.getElementById('video-tag') as HTMLVideoElement
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        setVideoDuration(video.duration)
      })
    }

    return () => video.removeEventListener('onloadedmetadata', () => {})
  }, [currentFile])

  return (
    <Container>
      <Content>
        <HeaderSection>
          <Title>Página {page}</Title>
          <Section>
            <InsertDriveFileOutlinedIcon />
            MÍDIA
          </Section>
          {currentMediaUrlPreview ? (
            <MediaData
              currentFile={currentFile}
              currentMediaUrlPreview={currentMediaUrlPreview}
              handleRemoveSelectedMedia={handleRemoveSelectedMedia}
            />
          ) : (
            <UploadLabelButton htmlFor='story'>
              Enviar mídia <CloudUploadOutlinedIcon />
            </UploadLabelButton>
          )}
          <Divider />
          <Section>
            <MdInsertLink size={16} />
            LINK
          </Section>
          <TextInput
            label='URL do link'
            variant='outlined'
            placeholder='Link para página'
            value={link}
            onChange={event => setLink(event.target.value)}
          />
          <Subtitle>
            Adicione link na página para os usuários poderem clicar no botão ou
            arrastar para cima para acessá-lo.
          </Subtitle>
          <Divider />
          <Section style={{ marginBottom: '1rem' }}>
            <MdSchedule size={16} />
            DURAÇÃO
          </Section>
          <span>
            {'\u2022'}{' '}
            {currentFile?.type === 'video/mp4'
              ? formatVideoDuration(videoDuration)
              : '15 segundos'}
          </span>
        </HeaderSection>

        <Footer>
          <FooterDeleteButton
            onClick={() => setIsDeleteCurrentPageModalOpen(true)}
          >
            Apagar Página {page}
          </FooterDeleteButton>

          <FooterCancelButton onClick={handleCloseMediaSettings}>
            Cancelar
          </FooterCancelButton>
          <FooterSaveButton
            onClick={() => handleAddNewMedia(link)}
            disabled={!currentMediaUrlPreview}
          >
            Salvar
          </FooterSaveButton>
        </Footer>
      </Content>

      <Modal open={isDeleteCurrentPageModalOpen}>
        <PromptModal
          icon={<BiTrash color='#F34456' />}
          title={`Apagar "Página ${page}"?`}
          onClose={() => setIsDeleteCurrentPageModalOpen(false)}
          leftTitle='Cancelar'
          onRight={() => handleDeleteSelectedMedia(currentMediaUrlPreview)}
          rightTitle='Apagar página'
        />
      </Modal>
    </Container>
  )
}
