import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Material ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Utils
import { formatPrice, formatToPercentage } from '../../../../utils/format'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
// Components
import StoresTableHead from './components/StoresTableHead'
import { useMemo } from 'react'
import { VisiblePerfectScrollbar } from '../GroupsTable/styles'
import Tooltip from 'components/Tooltip'
import { format } from 'date-fns'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { dateWithNoTimezone } from 'utils/dateFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

    '& .MuiTableHead-root th:nth-child(4), & .MuiTableHead-root th:nth-child(6)':
      {
        textAlign: 'right',
        paddingRight: '0px',
        minWidth: '80px'
      }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  typographyPercent: {
    textAlign: 'right',
    fontSize: '11px',
    fontWeight: 'bold'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white'
  }
}))

const StoresTable = ({ storesReport, handleSelectStore, disabledValues }) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRow, setSelectedRow] = useState(-1)

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    } else if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const formattedStoresToStoresTable = () => {
    const stores = storesReport.stores || storesReport
    return stores?.length > 0
      ? stores.map(store => ({
          ...store,
          'average.tm': store.average.tm,
          'average.pv': store.average.pv,
          'average.pm': store.average.pm,
          'total.items': store.total.items,
          salesDay: store.daySold.sold,
          sold: store.total.sold,
          sales: store.total.sales,
          items: store.total.items.toFixed(),
          goalPercent: Number(
            ((store.total.sold / store.metric.acDayGoal || 0) * 100).toFixed(2)
          ),
          acDayGoal: store.metric.acDayGoal,
          mainGoal: store.metric.mainGoal,
          projection: store.projection.sold,
          mainGoalPercent: Number(
            ((store.total.sold / store.metric.mainGoal || 0) * 100).toFixed(2)
          )
        }))
      : []
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <VisiblePerfectScrollbar>
            {disabledValues && (
              <Tooltip
                id={`view-goal-modal`}
                content={
                  <p>Informações disponíveis apenas para datas do mesmo mês</p>
                }
                place='bottom'
              />
            )}
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              aria-label='enhanced table'
              stickyHeader
              onMouseLeave={() => {
                if (selectedRow > -1) {
                  setSelectedRow(-1)
                }
              }}
            >
              <StoresTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={formattedStoresToStoresTable().length}
                onHoverHeader={() => {
                  if (selectedRow > -1) {
                    setSelectedRow(-1)
                  }
                }}
              />
              <TableBody>
                {!formattedStoresToStoresTable() ||
                formattedStoresToStoresTable().length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} />
                  </TableRow>
                ) : (
                  <>
                    {storesReport.storesInfos && (
                      <TableRow
                        hover
                        key={0}
                        selected
                        onMouseEnter={() => {
                          if (selectedRow > -1) {
                            setSelectedRow(-1)
                          }
                        }}
                      >
                        <TableCell
                          className={classes.sticky}
                          style={{ backgroundColor: '#fdf8ec' }}
                        >
                          <b>Todas Lojas</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {formatPrice(storesReport.storesInfos.daySold.sold)}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for='view-goal-modal'>
                          <b>
                            {formatPrice(storesReport.storesInfos.total.sold)}
                          </b>
                        </TableCell>
                        <TableCell
                          data-tip
                          data-for='view-goal-modal'
                          padding='none'
                          style={{ paddingRight: 0, textAlign: 'end' }}
                        >
                          <b>
                            {disabledValues
                              ? '-'
                              : formatToPercentage(
                                  (storesReport.storesInfos.total.sold /
                                    storesReport.storesInfos.metric.acDayGoal) *
                                    100
                                )}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for='view-goal-modal'>
                          <b>
                            {disabledValues
                              ? '-'
                              : formatPrice(
                                  storesReport.storesInfos.metric.acDayGoal
                                )}
                          </b>
                        </TableCell>
                        <TableCell
                          data-tip
                          data-for='view-goal-modal'
                          style={{ paddingRight: 0, textAlign: 'end' }}
                        >
                          <b>
                            {disabledValues
                              ? '-'
                              : formatToPercentage(
                                  (storesReport.storesInfos.total.sold /
                                    storesReport.storesInfos.metric.mainGoal) *
                                    100
                                )}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for='view-goal-modal'>
                          <b>
                            {disabledValues
                              ? '-'
                              : formatPrice(
                                  storesReport.storesInfos.metric.mainGoal
                                )}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for='view-goal-modal'>
                          <b>
                            {disabledValues
                              ? '-'
                              : formatPrice(
                                  storesReport.storesInfos.projection.sold
                                )}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {storesReport.storesInfos.total.sales
                              ? storesReport.storesInfos.total.sales
                              : 0}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {formatPrice(storesReport.storesInfos.average.tm)}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {storesReport.storesInfos.average.pv.toFixed(2)}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {formatPrice(storesReport.storesInfos.average.pm)}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {storesReport.storesInfos.total.items
                              ? storesReport.storesInfos.total.items
                              : 0}
                          </b>
                        </TableCell>
                      </TableRow>
                    )}
                    {stableSort(
                      formattedStoresToStoresTable(),
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((store, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => handleSelectStore(store._id)}
                            tabIndex={-1}
                            key={store.name}
                            style={{ cursor: 'pointer' }}
                            data-cy='store-row'
                            onMouseEnter={() => setSelectedRow(index)}
                          >
                            <TableCell
                              scope='row'
                              className={classes.sticky}
                              style={{
                                ...(index === selectedRow && {
                                  backgroundColor: '#f5f5f5'
                                })
                              }}
                            >
                              {store.name}
                            </TableCell>
                            <TableCell
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {formatPrice(store.daySold.sold)}
                              {store.daySold.updatedAt && (
                                <BootstrapTooltip
                                  title={`Ultima atualização: ${format(
                                    dateWithNoTimezone(store.daySold.updatedAt),
                                    'pp'
                                  )}`}
                                >
                                  <ErrorOutlineIcon
                                    style={{
                                      color: '#C4C4C4',
                                      fontSize: 20,
                                      marginLeft: 'auto'
                                    }}
                                  />
                                </BootstrapTooltip>
                              )}
                            </TableCell>
                            <TableCell>{formatPrice(store.sold)}</TableCell>
                            <TableCell
                              style={{ paddingRight: 0 }}
                              data-tip
                              data-for='view-goal-modal'
                            >
                              <Typography
                                style={{
                                  color: (value =>
                                    value >= 100
                                      ? '#27ae60'
                                      : value >= 80
                                      ? '#f39c12'
                                      : '#e74c3c')(
                                    (store.sold / store.metric.acDayGoal) * 100
                                  )
                                }}
                                className={classes.typographyPercent}
                              >
                                {disabledValues
                                  ? '-'
                                  : store.metric.acDayGoal !== 0
                                  ? +(
                                      (store.sold / store.metric.acDayGoal) *
                                      100
                                    ).toFixed(2) + '%'
                                  : '0%'}
                              </Typography>
                            </TableCell>
                            <TableCell data-tip data-for='view-goal-modal'>
                              {disabledValues
                                ? '-'
                                : formatPrice(store.acDayGoal)}
                            </TableCell>
                            <TableCell
                              style={{ paddingRight: 0 }}
                              data-tip
                              data-for='view-goal-modal'
                            >
                              <Typography
                                style={{
                                  color: (value =>
                                    value >= 100
                                      ? '#27ae60'
                                      : value >= 80
                                      ? '#f39c12'
                                      : '#e74c3c')(
                                    (store.sold / store.metric.mainGoal) * 100
                                  )
                                }}
                                className={classes.typographyPercent}
                              >
                                {disabledValues
                                  ? '-'
                                  : store.metric.mainGoal !== 0
                                  ? +(
                                      (store.sold / store.metric.mainGoal) *
                                      100
                                    ).toFixed(2) + '%'
                                  : '0%'}
                              </Typography>
                            </TableCell>
                            <TableCell data-tip data-for='view-goal-modal'>
                              {disabledValues
                                ? '-'
                                : formatPrice(store.mainGoal)}
                            </TableCell>
                            <TableCell data-tip data-for='view-goal-modal'>
                              {disabledValues
                                ? '-'
                                : formatPrice(store.projection)}
                            </TableCell>
                            <TableCell>{`${
                              store ? store.total.sales : 0
                            }`}</TableCell>
                            <TableCell>
                              {formatPrice(store.average.tm)}
                            </TableCell>
                            <TableCell>{store.average.pv.toFixed(2)}</TableCell>
                            <TableCell>
                              {formatPrice(store.average.pm)}
                            </TableCell>
                            <TableCell>{`${
                              store ? store.total.items : 0
                            }`}</TableCell>
                          </TableRow>
                        )
                      })}
                  </>
                )}
              </TableBody>
            </Table>
          </VisiblePerfectScrollbar>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={formattedStoresToStoresTable().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={event => {
            handleChangeRowsPerPage(Number(event.target.value))
          }}
        />
      </Paper>
    </div>
  )
}

StoresTable.propTypes = {
  formattedStoresToStoresTable: PropTypes.array,
  disabledValues: PropTypes.bool,
  handleSelectStore: PropTypes.func
}

export default StoresTable
