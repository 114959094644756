import styled from 'styled-components'

interface ContainerProps {
  topTable: string[]
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.topTable.length}, 1fr);
  text-align: left;
  font-family: 'Roboto', sans-serif;
  color: #546e7a;

  span {
    background-color: #fff;
    font-size: 14px;
    color: #546e7a;
    padding: 5px;
    border-bottom: 2px solid #eeeeee;
    border-right: ${props => (props.topTable.length === 1 ? '0' : '2')}px solid
      #eeeeee;
  }
`
