import { useState, useEffect } from 'react'
import 'react-day-picker/lib/style.css'
import 'moment/locale/pt-br'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Libs
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import jwt_decode from 'jwt-decode'

// Material UI
import { makeStyles, useTheme } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Components
import { Sidebar, Topbar, Footer } from './components'

// Redux
import { inputListRequest } from '../../store/modules/input/actions'
import {
  currentGoalRequest,
  goalListRequest
} from '../../store/modules/goal/actions'
import { storeRequest } from '../../store/modules/store/actions'
import { userRequest, logout } from '../../store/modules/user/actions'
import { clearRedux } from 'store/modules/main/actions'
import { monthRequest } from 'store/modules/report/actions'

import { getTokenStoreId } from 'utils/getTokenStoreId'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { browserHistory as history } from 'App'

LoadingOverlay.propTypes = undefined

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  inner: {
    minWidth: 350,
    minHeight: 250,
    marginLeft: 45
  },
  inputForm: {
    width: 100,
    minWidth: 150,
    marginBottom: 15,
    marginRight: 5
  }
}))

export default function Main({ children, match }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const confirm = useConfirm()

  const [openSidebar, setOpenSidebar] = useState(false)
  const [inputTimeoutID, setInputTimeoutID] = useState()
  const [reportTimeoutID, setReportTimeoutID] = useState()

  // Error
  const error = useSelector(state => state.error.error)

  // User
  const user = useSelector(state => state.user.user)
  const userLoading = useSelector(state => state.user.loading)

  // Input
  const inputLoading = useSelector(state => state.input.loading)
  const inputMetadata = useSelector(state => state.input.metadata)
  const lastInputUpdate = useSelector(state => state.input.lastUpdate)

  // Store
  const store = useSelector(state => state.store.store)
  const storeLoading = useSelector(state => state.store.loading)

  // Goal
  const currentGoalLoaded = useSelector(state => state.goal.currentGoalLoaded)
  const goalMetadata = useSelector(state => state.goal.metadata)
  const goalLoading = useSelector(state => state.goal.loading)
  const { goalList, loading } = useSelector(state => state.goal)

  // Report
  const reportLoading = useSelector(state => state.report.loading)
  const lastReportUpdate = useSelector(state => state.report.lastUpdate)

  // Day
  const dayLoading = useSelector(state => state.day.loading)

  // Storage
  const token = window.localStorage.getItem('@NeoPro:token')
  const params = useParams()

  const storeId =
    params.storeId.length === 24 && !isNaN(Number('0x' + params.storeId))
      ? params.storeId
      : undefined

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  useEffect(() => {
    if (error) {
      if (error.error?.status === 401) logoff()

      if (
        error?.error?.friendlyMsg ===
        'A meta ainda não foi definida para esse mês.'
      )
        return

      confirm({
        title: 'Ops!',
        description:
          error.error && error.error.friendlyMsg
            ? error.error.friendlyMsg
            : JSON.stringify(error),
        confirmationText: 'Ok!',
        cancellationText: 'Fechar'
      })
        .then(() => {})
        .catch(() => {})
    }
  }, [error])

  const logoff = async (redirect = true) => {
    const token = window.localStorage.getItem('@NeoPro:token')

    if (token) {
      const removeTokenPromise = new Promise(resolve => {
        resolve(window.localStorage.removeItem('@NeoPro:token'))
      })

      removeTokenPromise.then(() => {
        dispatch(logout())
        if (redirect) history.push('/login')
      })
    } else {
      dispatch(logout())
      if (redirect) history.push('/login')
    }
  }

  //Como o Main é o ambiente logado da loja, aqui valida se o storeId da URL tá certo
  useEffect(() => {
    if (!storeId) {
      logoff(false)
      if (user.stores.length === 0) history.push('/login')
    }
  }, [storeId, user.stores])

  // Verifica se user está logado
  useEffect(() => {
    if (!token) {
      dispatch(logout())
      history.push('/login')
    }

    if (!user._id) {
      if (token) {
        const decoded = jwt_decode(token)
        dispatch(userRequest(decoded.id, token))
      } else {
        history.push('/login')
      }
    } else {
      //Está logado. Popula os estados do redux:
      if (inputMetadata.pagination.totalCount < 0)
        dispatch(inputListRequest(storeId, token))
      if (!currentGoalLoaded) dispatch(currentGoalRequest(storeId, token))
      if (goalMetadata.pagination.totalCount < 0)
        dispatch(goalListRequest(storeId, token))
      if (!store || store._id !== storeId)
        dispatch(storeRequest(storeId, token))
      if (token && storeId) {
        const containsCurrentStore = user.stores.some(
          store => getTokenStoreId(store) === storeId
        )
        if (!containsCurrentStore) logoff()
      }
    }
  }, [user, token, storeId])

  useEffect(() => {
    if (lastInputUpdate) {
      const remainTimeToUpdate =
        (moment().add(1, 'day').unix() - lastInputUpdate) * 1000
      const inputTimeout = setTimeout(() => {
        dispatch(inputListRequest(storeId, token))
      }, remainTimeToUpdate)
      if (inputTimeoutID && inputTimeout !== inputTimeoutID)
        clearTimeout(inputTimeoutID)
      setInputTimeoutID(inputTimeout)
    }
  }, [lastInputUpdate])

  useEffect(() => {
    if (lastReportUpdate) {
      const remainTimeToUpdate =
        (moment().add(1, 'day').unix() - lastReportUpdate) * 1000
      const reportTimeout = setTimeout(() => {
        dispatch(monthRequest(storeId, token))
      }, remainTimeToUpdate)
      if (reportTimeoutID && reportTimeout !== reportTimeoutID)
        clearTimeout(reportTimeoutID)
      setReportTimeoutID(reportTimeout)
    }
  }, [lastReportUpdate])

  useEffect(() => {
    if (history.location.pathname !== `/${storeId}/goals`) {
      if (goalList?.length > 0) {
        if (
          new Date(goalList[0].month).getMonth() === new Date().getMonth() &&
          goalList[0].config.active !== undefined &&
          !goalList[0].config.active &&
          !loading
        ) {
          confirm({
            title: 'Meta em rascunho',
            description: `A sua meta de ${format(
              new Date(goalList[0].month),
              'MMMM',
              { locale: ptBR }
            )}
          está em rascunho. Finalize-a para ativá-la!`
          })
            .then(() => history.push(`/${store._id}/goals/${goalList[0]._id}`))
            .catch(() => {})
        }
      }
    }
  }, [goalList, history.location.pathname, loading])

  useEffect(() => {
    if (user && user.stores.length > 0) {
      const isCommunicator = user.stores.every(
        store => store.type === 'communicator'
      )

      if (isCommunicator) {
        history.push('/storylines')
      }
    }
  }, [history.location.pathname])

  return (
    <LoadingOverlay
      active={
        userLoading ||
        inputLoading ||
        goalLoading ||
        reportLoading ||
        storeLoading ||
        dayLoading
      }
      spinner
    >
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop
        })}
      >
        <Topbar
          onSidebarOpen={() => setOpenSidebar(true)}
          storeIntegrationData={store?.integration}
          onLogoff={logoff}
          storeName={store && store.name}
          user={user}
          currentStoreId={store && store._id}
          goToStore={() => {
            dispatch(clearRedux())
            history.push('/stores')
          }}
        />
        <Sidebar
          storeId={storeId}
          onClose={() => setOpenSidebar(false)}
          open={isDesktop ? true : openSidebar}
          onOpen={() => setOpenSidebar(true)}
          variant={isDesktop ? 'persistent' : 'temporary'}
          user={user}
          storeProfileImage={store && store.photo.url}
          storeLocation={store && store.city}
          onLogoff={logoff}
        />
        <main className={classes.content}>
          {children}
          <Footer />
        </main>
      </div>
    </LoadingOverlay>
  )
}

Main.propTypes = {
  children: PropTypes.node
}
