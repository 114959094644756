import {
  CardContent,
  List,
  ListItem,
  Typography,
  ListItemText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatPrice, formatToPercentage } from 'utils/format'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { sellers } from './sellersCase'
import { FlexText, SubscriptionValue } from './styles'
import palette from 'theme/palette'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  place: {
    paddingRight: 10,
    paddingLeft: 10
  }
}))

export const RankingCardExample = ({
  sellersSortRanking,
  sellersPerformance
}) => {
  const classes = useStyles({})
  const userIndex = 2

  return (
    <CardContent className={classes.content}>
      <List>
        {sellersSortRanking > -1 && sellersPerformance > -1 > 0 ? (
          sellers[sellersPerformance].map((seller, i) => {
            let value = ''

            if (sellersSortRanking) {
              if (sellersPerformance === 3) {
                value = `${(seller.total[sellersSortRanking] * 100).toFixed(
                  2
                )}% da meta`
              }

              if (sellersPerformance === 0) {
                value =
                  seller.sellerId === '3'
                    ? formatToPercentage(seller.total[sellersSortRanking] * 100)
                    : ''
              }

              if (sellersPerformance === 1 || sellersPerformance === 2) {
                value = i !== 0 && (
                  <SubscriptionValue>
                    <ArrowDropDownIcon style={{ color: '#c91e1e' }} />
                    {(seller.total[sellersSortRanking] * 100).toFixed(2)}%
                  </SubscriptionValue>
                )
              }

              if (sellersPerformance === 4) {
                if (userIndex < i) {
                  value = (
                    <SubscriptionValue>
                      <ArrowDropDownIcon style={{ color: '#1b8f1b' }} />
                      {(seller.total[sellersSortRanking] * 100).toFixed(2)}%
                    </SubscriptionValue>
                  )
                } else if (userIndex > i) {
                  value = (
                    <SubscriptionValue>
                      <ArrowDropUpIcon style={{ color: '#c91e1e' }} />
                      {`+${(seller.total[sellersSortRanking] * 100).toFixed(
                        2
                      )}`}
                      %
                    </SubscriptionValue>
                  )
                }
              }
            } else {
              if (sellersPerformance === 3) {
                value = `${formatPrice(
                  seller.total[sellersSortRanking]
                )} vendidos`
              }

              if (sellersPerformance === 0) {
                value =
                  seller.sellerId === '3'
                    ? `${formatPrice(
                        seller.total[sellersSortRanking]
                      )} vendidos`
                    : ''
              }

              if (sellersPerformance === 1 || sellersPerformance === 2) {
                value = i !== 0 && (
                  <SubscriptionValue>
                    <ArrowDropDownIcon style={{ color: '#c91e1e' }} />
                    {formatPrice(seller.total[sellersSortRanking])}
                  </SubscriptionValue>
                )
              }

              if (sellersPerformance === 4) {
                if (userIndex < i) {
                  value = (
                    <SubscriptionValue
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        maxHeight: '14px'
                      }}
                    >
                      <ArrowDropDownIcon style={{ color: '#1b8f1b' }} />

                      {formatPrice(seller.total[sellersSortRanking])}
                    </SubscriptionValue>
                  )
                } else if (userIndex > i) {
                  value = (
                    <SubscriptionValue>
                      <ArrowDropUpIcon style={{ color: '#c91e1e' }} />
                      {`+${formatPrice(seller.total[sellersSortRanking])}`}
                    </SubscriptionValue>
                  )
                }
              }
            }

            return (
              <ListItem divider={i < sellers.length - 1} key={i}>
                <Typography
                  style={{ color: palette.primary[300] }}
                  variant='h2'
                  className={classes.place}
                >
                  {i + 1}˚
                </Typography>
                <ListItemText
                  secondaryTypographyProps={{ component: 'span' }}
                  primary={
                    <Typography
                      variant='h6'
                      style={{ display: 'inline-block' }}
                    >
                      {'3' === seller.sellerId ? (
                        <FlexText isCurrentSeller>Você</FlexText>
                      ) : (
                        'Lugar'
                      )}
                    </Typography>
                  }
                  secondary={
                    <FlexText isCurrentSeller={'3' === seller.sellerId}>
                      {value}
                    </FlexText>
                  }
                />
              </ListItem>
            )
          })
        ) : (
          <ListItem key={0}>
            <ListItemText secondary='Selecione uma opção para o desempenho e uma para a ordenação do ranking' />
          </ListItem>
        )}
      </List>
    </CardContent>
  )
}
