import styled from 'styled-components'

const BaseButton = styled.button`
  padding: 9px 12px;
  border: 0;
  border-radius: 3px;

  cursor: pointer;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
`

export const SubmitButton = styled(BaseButton)`
  background-color: #2a3ecb;
  color: #fff;
`

export const CancelButton = styled(BaseButton)`
  color: #37474f;
  background-color: transparent;

  margin-right: 8px;
`
