import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const VisiblePerfectScrollbar = styled(PerfectScrollbar)`
  .ps__rail-x {
    opacity: 0.6;
  }

  .ps__thumb-x {
    height: 11px;
  }
`
