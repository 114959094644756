import { Dispatch, SetStateAction, useState } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { DateRange, DateRangePicker } from '../DateRanger'
import { IRange } from 'store/modules/report/types.js'

interface CalendarWithRangeDatesProps {
  openCalendar: boolean
  setOpenCalendar: Dispatch<SetStateAction<boolean>>
  maxDate?: string | Date
  range: IRange
  handleDayClick: (calendarRange: IRange) => void
}
const CalendarWithRangeDates = ({
  openCalendar,
  setOpenCalendar,
  handleDayClick,
  range,
  maxDate
}: CalendarWithRangeDatesProps) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(range.from),
    endDate: new Date(range.to)
  })

  const toggle = () => setOpenCalendar(old => !old)
  return (
    <DateRangePicker
      open={openCalendar}
      toggle={toggle}
      locale={ptBR}
      initialDateRange={dateRange}
      maxDate={maxDate}
      onChange={currentRange => {
        setDateRange(currentRange)
        if (currentRange.startDate && currentRange.endDate) {
          handleDayClick({
            from: currentRange.startDate,
            to: currentRange.endDate
          })
          toggle()
        }
      }}
    />
  )
}

export default CalendarWithRangeDates
