import { ImageDragAndDrop } from '../ImageDragAndDrop'
import { Container, Content } from './styles'

interface PreviewMediaProps {
  currentMediaUrlPreview: string
  previewMedias: string[]
  isAddingNewMedia: boolean
  isEditingMedia: boolean
  hasLink: boolean
  currentFile: File | undefined
  handleChangeMedia: (image: File, srcImg: string) => void
}

export const PreviewMedia = ({
  hasLink,
  currentFile,
  previewMedias,
  isEditingMedia,
  isAddingNewMedia,
  handleChangeMedia,
  currentMediaUrlPreview
}: PreviewMediaProps) => {
  const page =
    previewMedias.indexOf(currentMediaUrlPreview) > -1
      ? previewMedias.indexOf(currentMediaUrlPreview) + 1
      : previewMedias.length + 1
  return (
    <Container>
      <Content>
        <ImageDragAndDrop
          currentFile={currentFile}
          media={currentMediaUrlPreview}
          handleSubmitMedia={handleChangeMedia}
          hasLink={hasLink}
          currentPage={page}
          mediasLength={previewMedias.length}
          canPlay
          size='lg'
          {...(isAddingNewMedia && {
            icon: 'uploadFile'
          })}
        />
        {!isAddingNewMedia && !isEditingMedia && previewMedias.length > 0
          ? 'Clique para adicionar mais uma página'
          : `Página ${page}`}
      </Content>
    </Container>
  )
}
