import React from 'react'

import { makeStyles } from '@material-ui/styles'

// Libs
import clsx from 'clsx'

// Utils
import { formatPrice, formatToPercentage } from '../../../utils/format'

// Material UI
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'

import {
  AttachMoney as AttachMoneyIcon,
  Timeline as TimelineIcon,
  ShoppingCart as ShoppingCartIcon,
  Loyalty as LoyaltyIcon,
  InsertChart as InsertChartIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    minWidth: 300
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.08)'
    },
    cursor: 'pointer'
  },
  avatar: {
    justifyContent: 'center',
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(1)
  }
}))

export const GridStoresTable = ({ store, handleSelectStore }) => {
  const classes = useStyles()

  return (
    <Grid item lg={3} sm={6} xl={3} xs={12}>
      <Card
        onClick={() => handleSelectStore(store._id)}
        key={String(store._id)}
        className={clsx(classes.root, classes.card)}
      >
        <CardContent className={classes.container}>
          <Avatar
            alt={store.name}
            className={classes.avatar}
            src={store.photo.url}
          />
          <Typography variant='h5' component='h2'>
            {store.name}
          </Typography>
          {/* <Typography
              className={classes.pos}
              color='textSecondary'
            >
              {store.city}
          </Typography> */}
          <Divider />

          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#2ecc71' }}>
                  <LoyaltyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Peças vendidas'
                secondary={store.total.items}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#2ecc71' }}>
                  <ShoppingCartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Quantidade de vendas'
                secondary={store.total.sales}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#2ecc71' }}>
                  <AttachMoneyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Faturamento'
                secondary={formatPrice(store.total.sold)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#2ecc71' }}>
                  <TimelineIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Projeção'
                secondary={formatPrice(store.projection.sold)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ background: '#2ecc71' }}>
                  <InsertChartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Percentual da meta'
                secondary={formatToPercentage(
                  (store.total.sold / store.metric.mainGoal) * 100
                )}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}
