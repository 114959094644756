import React from 'react'
import {
  ListSeller,
  HistoryWrapper,
  ReviewWrapper,
  SellerHeader,
  Header,
  SoldInfo,
  SellerSold,
  FontBold,
  FontNormal,
  HistoryContainer
} from './style'

import { formatPrice } from '../../../../../../utils/format'

const ListSellers = ({ old, current, index }) => {
  const {
    total: { sold, sales, items },
    sellers,
    store
  } = old || current

  return (
    <ListSeller>
      <ReviewWrapper key={index} different={sold !== current.total.sold}>
        <Header>
          <FontBold>Faturamento</FontBold>
          <FontBold>{formatPrice(sold)}</FontBold>
        </Header>
        <SoldInfo>
          <FontNormal>
            Total de vendas:
            {sales}
          </FontNormal>
          <FontNormal>
            Total de peças:
            {items}
          </FontNormal>
        </SoldInfo>
      </ReviewWrapper>
      <HistoryContainer>
        {sellers.map((seller, thisSellerIndex) => {
          const thisSellerFromCurrent = current.sellers.find(
            sellerFromCurrent => sellerFromCurrent._id === seller._id
          )
          return (
            <HistoryWrapper
              key={seller.date}
              different={
                thisSellerFromCurrent
                  ? seller.sold !== thisSellerFromCurrent.sold
                  : false
              }
            >
              <SellerHeader>
                <FontBold>
                  {seller.sellerId.name.complete.toUpperCase()}
                </FontBold>
                <SellerSold>{formatPrice(seller.sold)}</SellerSold>
              </SellerHeader>
              <SoldInfo>
                <FontNormal>Vendas: {seller.items}</FontNormal>
                <FontNormal>Peças: {seller.sales}</FontNormal>
              </SoldInfo>
            </HistoryWrapper>
          )
        })}
        <HistoryWrapper different={store.sold !== current.store.sold}>
          <SellerHeader>
            <FontBold>Outros</FontBold>
            <SellerSold>{formatPrice(store.sold)}</SellerSold>
          </SellerHeader>
          <SoldInfo>
            <FontNormal>Vendas: {store.sales}</FontNormal>
            <FontNormal>Peças: {store.items}</FontNormal>
          </SoldInfo>
        </HistoryWrapper>
      </HistoryContainer>
    </ListSeller>
  )
}

export default ListSellers
