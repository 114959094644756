import { useDispatch } from 'react-redux'
import { Modal } from '@material-ui/core'
import { MdErrorOutline } from 'react-icons/md'

import { PromptModal } from 'views/CreateStoryline/styles'
import { ITag } from 'store/modules/storylines/types'
import { deleteStorylineTagRequest } from 'store/modules/storylines/actions'
import { toast } from 'react-toastify'

interface DeleteTagModalProps {
  isOpen: boolean
  companyId: string | undefined
  onCancel: () => void
  tag: ITag
}

export const DeleteTagModal = ({
  isOpen,
  onCancel,
  companyId,
  tag
}: DeleteTagModalProps) => {
  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  const handleDeleteTag = () => {
    if (!companyId) {
      return toast.error('Algo deu errado')
    }

    dispatch(deleteStorylineTagRequest(tag._id, companyId, token))
    onCancel()
  }
  return (
    <Modal open={isOpen}>
      <PromptModal
        icon={<MdErrorOutline color='#F34456' />}
        title={`Excluir tag "${tag.name}"`}
        onClose={onCancel}
        leftTitle='Cancelar'
        onRight={handleDeleteTag}
        rightTitle='Excluir tag'
      />
    </Modal>
  )
}
