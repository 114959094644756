import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .content-card-info {
    display: flex;
    align-items: center;

    .content-card-left {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: 600;
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .content-card-title {
      color: #9e9e9e;
      font-size: 16px;
    }

    .content-card-arrow {
      color: #bdbdbd;
    }
  }

  .content-card-value {
    color: #455a64;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 3px;
  }

  .content-card-subvalue {
    background-color: #e9e9e9;
    font-size: 12px;
    padding: 3px 5px;
    color: #78909c;
    font-weight: 600;
    border-radius: 5px;
    margin-left: 8px;
  }

  .content-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    .content-card-content-children {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
