export const formatVideoDuration = (videoDuration: number) => {
  const minutes = Math.floor(videoDuration / 60)
  const seconds = Math.floor(videoDuration % 60)
  if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? 'minutos' : 'minuto'} ${seconds} ${
      seconds > 1 ? 'segundos' : 'segundo'
    }`
  }
  return `${seconds} ${seconds > 1 ? 'segundos' : 'segundo'}`
}
