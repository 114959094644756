import { Container } from './styles'

interface UserDefaultPhotoProps {
  username: string
  size?: number
}

const UserDefaultPhoto = ({ username, size = 24 }: UserDefaultPhotoProps) => {
  return (
    <Container size={size} className='user-nophoto'>
      {username.substring(0, 2)}
    </Container>
  )
}

export default UserDefaultPhoto
