import { browserHistory } from 'App'
import { Container, ExitButton, LeftSide, RightSide } from './styles'

export const Header = () => {
  return (
    <Container>
      <LeftSide>
        <img src='/images/logo-blue.svg' alt='NeoPro Logo' />
        Visualizar comunicado - NeoStory
      </LeftSide>

      <RightSide>
        <ExitButton onClick={() => browserHistory.push('/storylines')}>
          Sair para o painel
        </ExitButton>
      </RightSide>
    </Container>
  )
}
