import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.tr`
  background: #f5f6f8;
  border-radius: 10px;
  padding: 5px 15px;
  border-top: 10px solid white;

  td {
    padding: 10px 0 10px 10px;
    font-size: 12px;
    color: #263238;
    text-align: center;
  }

  td:nth-child(2) {
    text-align: left;
  }

  td.commission-cell {
    color: ${palette.primary.main};
    font-weight: bold;
  }

  div.goal-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #e2e4e9;
    border-radius: 24px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: #65747b;
    }
  }

  div.goal-percent-comission {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #546e7a;
      font-size: 18px;
      margin-left: 4px;
    }
  }

  .table-row-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .edit:hover {
      background-color: #37474f;

      svg {
        color: white;
      }
    }

    .delete:hover {
      background-color: #e64c3d;

      svg {
        color: white;
      }
    }
  }

  button:nth-child(1) {
    margin-right: 8px;
  }

  button {
    border: 0;
    background-color: #e2e4e9;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      color: #37474f;
      font-size: 16px;
    }
  }
`
