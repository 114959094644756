import styled from 'styled-components'

export const Container = styled.div`
  margin: 5px 0px;
  font-weight: 600;

  .maingoal-info-option-title {
    display: flex;
    align-items: center;
    color: #546e7a;
    text-transform: uppercase;

    span {
      margin: 2px 5px 1px 0px;
      font-size: 11px;
    }
  }

  .option-price {
    color: #27ae60;
    font-size: 22px;
  }

  .option-quantity {
    color: #263238;
    font-size: 16px;
  }
`
