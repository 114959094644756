export function requestFailed(error) {
  return {
    type: '@error/REQUEST_FAILED',
    payload: { error }
  }
}

export function resetErrorsList() {
  return {
    type: '@error/RESET_ERRORS_LIST'
  }
}
