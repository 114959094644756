import React from 'react'

import { Container } from './styles'

import CheckIcon from '@material-ui/icons/Check'
import CreateIcon from '@material-ui/icons/Create'
import palette from 'theme/palette'

const ReviewCard = ({ setActiveStep, name, items, children }) => {
  return (
    <Container>
      <CheckIcon style={{ color: palette.primary.main }} />
      <div className='information-container'>
        <div onClick={setActiveStep} className='title'>
          <h1>{name}</h1>
          <CreateIcon
            style={{ color: '#37474F', fontSize: 15, cursor: 'pointer' }}
          />
        </div>
        <div className='content'>
          {children && <div className='children'>{children}</div>}
          {items}
        </div>
      </div>
    </Container>
  )
}

export default ReviewCard
