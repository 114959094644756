import { ButtonHTMLAttributes, ChangeEvent } from 'react'

import { toast } from 'react-toastify'
import {
  Add as AddIcon,
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  UploadFile as UploadFileIcon
} from '@mui/icons-material'
import { PiLinkSimpleBold } from 'react-icons/pi'

import {
  Label,
  Image,
  Container,
  Video,
  StoryBarContainer,
  StoryBar,
  LinkButtonFooter
} from './styles'
interface ImageDragAndDropProps extends ButtonHTMLAttributes<HTMLDivElement> {
  media?: string | undefined
  icon?: 'add' | 'addPhoto' | 'uploadFile'
  canPlay?: boolean
  currentFileType?: 'video' | 'image'
  hasLink?: boolean
  labelId?: string
  mediasLength?: number
  currentPage?: number
  handleSubmitMedia: (media: File, srcImg: string) => void
  size: 'sm' | 'md' | 'lg'
  error?: boolean | undefined
}

export const ImageDragAndDrop = ({
  media = undefined,
  handleSubmitMedia,
  size,
  mediasLength = 0,
  currentPage = 0,
  currentFileType = 'image',
  canPlay = false,
  hasLink = false,
  icon = 'add',
  labelId = 'story',
  error = false,
  ...props
}: ImageDragAndDropProps) => {
  const icons = {
    add: <AddIcon style={{ width: 32, height: 32 }} />,
    addPhoto: <AddAPhotoOutlinedIcon style={{ width: 24, height: 24 }} />,
    uploadFile: (
      <UploadFileIcon
        style={{
          width: size === 'sm' ? 29 : 48,
          height: size === 'sm' ? 38 : 60
        }}
      />
    )
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files && files.length > 0) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      const fileType = files[0].type

      const isFileVideoMp4 = fileType === 'video/mp4'

      if (isFileVideoMp4) {
        if (fileSize <= 16) {
          const previewURL = URL.createObjectURL(files[0])

          handleSubmitMedia(files[0], previewURL)
        } else {
          toast.error(
            'Video muito grande! O video deve ser menor ou igual a 16MB.'
          )
        }
      }
      const isFileImage =
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg'

      if (isFileImage)
        if (fileSize <= 2) {
          const previewURL = URL.createObjectURL(files[0])

          handleSubmitMedia(files[0], previewURL)
        } else {
          toast.error(
            'Imagem muito grande! A imagem deve ser menor ou igual a 2MB.'
          )
        }
    }
  }

  return (
    <Container size={size} {...props} error={error}>
      <Label htmlFor={!!media && size !== 'sm' ? '' : 'story'}>
        <Video
          src={media}
          size={size}
          controls={canPlay}
          controlsList='nodownload noplaybackrate nofullscreen'
          disablePictureInPicture
          id='video-tag'
          preload='metadata'
          style={{
            ...(currentFileType !== 'video' && {
              display: 'none'
            })
          }}
        >
          <source id='video-source' src={media} />
          Your browser does not support the video tag.
        </Video>
        {currentFileType !== 'video' && (
          <Image src={media} size={size} alt='Imagem do story' icon={icon}>
            {!media && icons[icon]}
          </Image>
        )}

        <input
          style={{ display: 'none' }}
          type='file'
          id={undefined}
          accept='image/png, image/jpg, image/jpeg, video/mp4'
          onChange={handleChange}
        />
      </Label>

      {hasLink && canPlay && (
        <LinkButtonFooter>
          <button>
            <PiLinkSimpleBold size={18} />
            ABRIR LINK
          </button>
          <p>Arraste pra cima</p>
        </LinkButtonFooter>
      )}

      {!!media && canPlay && (
        <StoryBarContainer>
          {new Array(mediasLength).fill(0).map((_, index) => (
            <StoryBar
              key={index}
              storyPosition={
                index + 1 < currentPage
                  ? 'isPreviousStory'
                  : index + 1 === currentPage
                  ? 'isCurrentStory'
                  : 'isNextStory'
              }
            />
          ))}
        </StoryBarContainer>
      )}
    </Container>
  )
}
