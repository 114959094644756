import { useEffect, useRef, useState } from 'react'

// Libs
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'

import { useHistory } from 'react-router-dom'
import '../../styles/DateRangePicker.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

// Material UI
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import GroupIcon from '@material-ui/icons/Group'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { makeStyles } from '@material-ui/styles'

// Components
import ValueCard from 'components/ValueCard'
import HeatMap from '../../components/HeatMap'
import Toolbar from '../../components/Toolbar'
import UsersPerformanceTable from '../../components/UsersPerformanceTable'
import DaysTable from './components/DaysTable'

import palette from 'theme/palette'

// Actions
import { Typography } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import CalendarWithRangeDates from '../../components/CalendarWithRangeDates'
import {
  dayListRequest,
  dayReportRequest,
  dayRequest
} from '../../store/modules/day/actions'
import { ReportCalendar } from '../Reports/components/styles'
import {
  endDayFromCurrentMonth,
  startDayFromCurrentMonth
} from '../../utils/getDateFromCurrentMonth'
import { formatToPercentage, safeDivision } from 'utils/format'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(1)
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  input: {
    background: '#e0e0e0',
    width: 350,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputHeader: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  calendar: {
    left: '114px',
    display: 'flex',
    flexDirection: 'column',
    marrginTop: ' -10px',
    padding: '6px 4px',
    borderRadius: '4px',
    position: 'absolute',
    zIndex: 1000
  },
  sheetButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#188038',
    padding: '12px 16px',
    border: 'none',
    borderRadius: 5,
    color: 'white',
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    fontFamily: 'Roboto',
    fontWeight: 700,
    maxHeight: 50,
    '&:hover': {
      background: '#126d2d'
    }
  }
}))

function Services({ match }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const daysChart = useRef()
  const weekChart = useRef()

  const { storeId } = match.params

  // ReduxState
  const day = useSelector(state => state.day)
  const store = useSelector(state => state.store.store)
  const token = localStorage.getItem('@NeoPro:token')

  const [openCalendar, setOpenCalendar] = useState(false)
  // State
  const [tab, setTab] = useState('resumeTab')

  const DEFAULT_GRAPH_DATA = {
    main: {
      labels: [],
      total: [],
      success: []
    },
    week: {
      labels: [
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
        'Domingo'
      ],
      total: [0, 0, 0, 0, 0, 0, 0],
      success: [0, 0, 0, 0, 0, 0, 0]
    }
  }

  const [graphData, setGraphData] = useState(DEFAULT_GRAPH_DATA)

  const handleChangePage = page => {
    if (page + 1 <= day.metadata.pagination.pageCount) {
      dispatch(
        dayListRequest(storeId, token, page + 1, day.metadata.pagination.limit)
      )
    }
  }

  const handleChangeRows = rowsNumber => {
    dispatch(
      dayListRequest(
        storeId,
        token,
        day.metadata.pagination.currentPage,
        Number(rowsNumber.props.value)
      )
    )
  }

  // Tabs
  const tabs = [
    { value: 'resumeTab', label: 'Resumo' },
    { value: 'daysTab', label: 'Dias' }
  ]

  const handleDayClick = calendarRange => {
    if (calendarRange.from && calendarRange.to) {
      dispatch(
        dayReportRequest(storeId, token, {
          from: calendarRange.from.toISOString(),
          to: calendarRange.to.toISOString()
        })
      )
    }
  }

  useEffect(() => {
    if (day.sellers.length == 0) {
      dispatch(
        dayReportRequest(storeId, token, {
          from: startDayFromCurrentMonth.toISOString(),
          to: endDayFromCurrentMonth.toISOString()
        })
      )
    }
    if (day.metadata.pagination.totalCount < 0)
      dispatch(dayListRequest(storeId, token))
  }, [])

  useEffect(() => {
    setGraphData(
      day.days.reduce((acc, curr) => {
        const currentDate = new Date(curr.date)
        acc.main.labels.unshift(currentDate.toLocaleDateString())
        acc.main.total.unshift(curr.fail + curr.success)
        acc.main.success.unshift(curr.success)

        acc.week.total[currentDate.getDay() - 1] += curr.fail + curr.success
        acc.week.success[currentDate.getDay() - 1] += curr.success
        return acc
      }, DEFAULT_GRAPH_DATA)
    )
    if (daysChart.current) daysChart.current.update()
    if (weekChart.current) weekChart.current.update()
  }, [day.days])

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.divider,
        backgroundColor: palette.white,
        titleColor: palette.text.primary,
        bodyColor: palette.text.secondary,
        footerColor: palette.text.secondary
      }
    },
    maintainAspectRatio: false,
    cornerRadius: 20,
    elements: {
      line: {
        tension: 0.2,
        fill: true
      }
    },
    interaction: {
      intersect: false
    },
    layout: { padding: 0 },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          borderDash: [3],
          drawBorder: false
        },
        min: 0
      }
    }
  }
  const handleExportServicesSheet = () => {
    const { headers, sellerInfo, storeInfo } = day.sellers.reduce(
      (acc, curr) => {
        let currentSellerInfo = {
          name: '',
          events: {
            Atendimentos: {
              count: 0
            },
            Vendas: {
              count: 0
            }
          }
        }

        const newHeaders = []

        currentSellerInfo.name = curr.sellerId.name.complete
        curr.events.forEach(event => {
          const date = new Date(event.period.start)
          const day = date.getDate().toString().padStart(2, '0')
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const year = date.getFullYear()

          const weekDayName = date.toLocaleDateString('pt-BR', {
            weekday: 'long'
          })

          const currDate = `${day}/${month}/${year} - ${weekDayName}`

          if (
            acc.storeInfo.eventsByDay[currDate] &&
            acc.storeInfo.eventsByDay[currDate][event.selected.title]
          ) {
            acc.storeInfo.eventsByDay[currDate][event.selected.title].count++
          } else {
            acc.storeInfo.eventsByDay[currDate] = {
              ...acc.storeInfo.eventsByDay[currDate],
              ...((!acc.storeInfo.eventsByDay[currDate] ||
                (acc.storeInfo.eventsByDay[currDate] &&
                  !acc.storeInfo.eventsByDay[currDate]['Atendimentos'])) && {
                Atendimentos: {
                  count: 0
                },
                Vendas: {
                  count: 0
                }
              }),
              [event.selected.title]: {
                count: 1
              }
            }
          }

          currentSellerInfo.events['Atendimentos'].count++
          acc.storeInfo.events['Atendimentos'].count++
          acc.storeInfo.eventsByDay[currDate]['Atendimentos'].count++
          if (event.selected.title) {
            if (event.success) {
              acc.storeInfo.eventsByDay[currDate]['Vendas'].count++
              currentSellerInfo.events['Vendas'].count++
              acc.storeInfo.events['Vendas'].count++
            }
            if (
              !acc.headers.includes(event.selected.title) &&
              !newHeaders.includes(event.selected.title)
            ) {
              newHeaders.push(event.selected.title)
            }
            if (currentSellerInfo.events[event.selected.title]) {
              currentSellerInfo.events[event.selected.title] = {
                count: currentSellerInfo.events[event.selected.title].count + 1
              }
            } else {
              currentSellerInfo.events[event.selected.title] = {
                count: 1
              }
            }
            if (acc.storeInfo.events[event.selected.title]) {
              acc.storeInfo.events[event.selected.title].count++
            } else {
              acc.storeInfo.events[event.selected.title] = {
                count: 1
              }
            }
          }
        })
        return {
          ...acc,
          sellerInfo: [...acc.sellerInfo, currentSellerInfo],
          headers: [...acc.headers, ...newHeaders]
        }
      },
      {
        headers: ['Vendedores', 'Atendimentos', 'Vendas', 'Conversão'],
        sellerInfo: [],
        storeInfo: {
          name: store.name,
          eventsByDay: {},
          events: {
            Atendimentos: {
              count: 0
            },
            Vendas: {
              count: 0
            }
          }
        }
      }
    )

    if (storeInfo.events['Atendimentos'].count > 0) {
      let sheet = [headers]
      const headersMap = [...headers]
      headersMap.shift()

      sellerInfo.forEach(seller => {
        const conversion = safeDivision(
          seller.events['Vendas'].count / seller.events['Atendimentos'].count
        )

        let objectEntries = {}
        Object.entries(seller.events).forEach(([key, value]) => {
          objectEntries = {
            ...objectEntries,
            [key]: value
          }
        })

        objectEntries = {
          ...objectEntries,
          Conversão: {
            count: conversion
          }
        }

        const sellerItems = Object.values(headersMap).map(event => {
          if (objectEntries[event]) {
            return objectEntries[event].count
          }
          return 0
        })

        const rowData = [seller.name, ...sellerItems]

        sheet = [...sheet, rowData]
      })

      storeInfo.events['Conversão'] = {
        count: safeDivision(
          storeInfo.events['Vendas'].count /
            storeInfo.events['Atendimentos'].count
        )
      }

      const storeItems = Object.values(headersMap).map(event => {
        if (storeInfo.events[event]) {
          return storeInfo.events[event].count
        }
        return 0
      })

      const date = new Date()
      sheet = [
        [`${format(date, '01-MM-yyy')} até ${format(date, 'dd-MM-yyy')}`],
        ...sheet,
        [`LOJA - ${storeInfo.name}`, ...storeItems]
      ]

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(sheet)

      new Array(sellerInfo.length + 1).fill(0).forEach((_, index) => {
        new Array(headers.length).fill(0).forEach((_, idx) => {
          ws[`${String.fromCharCode(idx + 97).toUpperCase()}${index + 3}`].z =
            headers[idx] === 'Conversão' ? '0.00%' : '0'
        })
      })

      XLSX.utils.book_append_sheet(wb, ws, `Relatório`)

      const storeHeaders = [...headers]
      storeHeaders.shift()
      storeHeaders.unshift('Dias')

      let storeSheet = [storeHeaders]

      let wsStore

      Object.entries(storeInfo.eventsByDay).forEach(([key, value]) => {
        const conversion = safeDivision(
          value['Vendas'].count / value['Atendimentos'].count
        )

        let objectEntries = {}
        Object.entries(value).forEach(([key, value]) => {
          objectEntries = {
            ...objectEntries,
            [key]: value
          }
        })

        objectEntries = {
          ...objectEntries,
          Conversão: {
            count: conversion
          }
        }

        const storeItems = Object.values(headersMap).map(event => {
          if (objectEntries[event]) {
            return objectEntries[event].count
          }
          return 0
        })

        const rowData = [key, ...storeItems]

        storeSheet = [...storeSheet, rowData]

        wsStore = XLSX.utils.aoa_to_sheet(storeSheet)
      })

      new Array(Object.keys(storeInfo.eventsByDay).length)
        .fill(0)
        .forEach((_, index) => {
          new Array(storeHeaders.length).fill(0).forEach((_, idx) => {
            wsStore[
              `${String.fromCharCode(idx + 97).toUpperCase()}${index + 2}`
            ].z = storeHeaders[idx] === 'Conversão' ? '0.00%' : '0'
          })
        })
      XLSX.utils.book_append_sheet(wb, wsStore, `Relatório por dia`)

      XLSX.writeFile(wb, `[NEOPRO] Relatorio-Atendimentos.xlsx`, {
        bookType: 'xlsx',
        type: 'binary'
      })
    } else {
      toast.warn('Não há atendimentos registrados')
    }
  }

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        onChange={(e, value) => setTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      {tab === 'resumeTab' && (
        <>
          <div className={classes.subHeader}>
            <div style={{ position: 'relative' }}>
              <div className={classes.inputHeader}>
                <Typography style={{ whiteSpace: 'nowrap' }}>
                  Atendimentos para:
                </Typography>
                <ReportCalendar
                  onClick={() => {
                    setOpenCalendar(true)
                  }}
                  hasReport
                >
                  <DateRange style={{ width: 16, marginRight: 5 }} />
                  <Typography>
                    {day.period.from
                      ? moment(day.period.from).format('DD/MM/YYYY')
                      : '-'}
                  </Typography>
                  <Typography>até</Typography>
                  <Typography>
                    {day.period.to
                      ? moment(day.period.to).format('DD/MM/YYYY')
                      : '-'}
                  </Typography>
                </ReportCalendar>
              </div>
              {/* <Calendar isDesktop={isDesktop}> */}
              {openCalendar && (
                <div className={classes.calendar}>
                  <CalendarWithRangeDates
                    openCalendar={openCalendar}
                    setOpenCalendar={setOpenCalendar}
                    handleDayClick={handleDayClick}
                    range={day.period}
                    maxDate={new Date()}
                  />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <Toolbar
                onRefresh={() =>
                  dispatch(
                    dayReportRequest(storeId, token, {
                      from: day.period.from.toISOString(),
                      to: day.period.to.toISOString()
                    })
                  )
                }
              />
              <button
                className={classes.sheetButton}
                onClick={handleExportServicesSheet}
              >
                Exportar atendimentos
              </button>
            </div>
          </div>
          <Grid container spacing={4}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ValueCard
                title='Taxa de conversão'
                value={
                  day.conversion
                    ? formatToPercentage(Number(day.conversion))
                    : 0
                }
                icon={<CheckCircleIcon />}
                color='#2A3ECB'
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ValueCard
                title='Atendimentos por dia'
                value={day.dayServices ? day.dayServices.toFixed(2) : 0}
                icon={<GroupIcon />}
                color='#37474F'
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ValueCard
                title='Vendas realizadas'
                value={day.totalSales ? day.totalSales : 0}
                color='#2ecc71'
                icon={<ThumbUpIcon />}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ValueCard
                title='Atendimentos no período'
                value={day.totalServices ? day.totalServices : 0}
                icon={<GroupIcon />}
                color='#37474F'
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader title='Vendas e Motivos de não compra - por vendedor' />
                <Divider />
                <CardContent>
                  <HeatMap
                    totalSales={day.totalSales ? day.totalSales : 0}
                    total={day.totalServices}
                    sellers={day.sellers}
                    store={store}
                    loading={day.loading}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader title='Atendimentos por dia' />
                <Divider />
                <CardContent className={classes.content}>
                  <Line
                    ref={daysChart}
                    data={{
                      labels: graphData.main.labels,
                      datasets: [
                        {
                          label: 'Atendimentos',
                          backgroundColor: palette.neutral,
                          data: graphData.main.total
                        },
                        {
                          label: 'Vendas',
                          backgroundColor: palette.primary.main,
                          data: graphData.main.success
                        }
                      ]
                    }}
                    options={graphOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader title='Atendimentos por dia da semana' />
                <Divider />
                <CardContent className={classes.content}>
                  <Line
                    ref={weekChart}
                    data={{
                      labels: graphData.week.labels,
                      datasets: [
                        {
                          label: 'Atendimentos',
                          backgroundColor: palette.neutral,
                          data: graphData.week.total
                        },
                        {
                          label: 'Vendas',
                          backgroundColor: palette.primary.main,
                          data: graphData.week.success
                        }
                      ]
                    }}
                    options={graphOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <UsersPerformanceTable
                users={day.sellers.map(seller => ({
                  _id: seller.sellerId._id,
                  name: seller.sellerId.name.complete,
                  conversion: Number(
                    (
                      (seller.success / seller.events.length > 0
                        ? seller.success / seller.events.length
                        : 0) * 100
                    ).toFixed(2)
                  ),
                  success: seller.success,
                  total: seller.fail + seller.success,
                  lineTime: Number(seller.time.line),
                  workingTime: seller.time.working
                }))}
              />
            </Grid>
          </Grid>
        </>
      )}
      {tab === 'daysTab' && (
        <>
          <Toolbar onRefresh={() => dispatch(dayListRequest(storeId, token))} />
          <DaysTable
            handleSelectDay={(dayId, date) => {
              dispatch(dayRequest(dayId, token))
              history.push(`/${storeId}/events/${dayId}`)
            }}
            storeId={storeId}
            days={day.days}
            metadata={day.metadata}
            onChangeRows={handleChangeRows}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </div>
  )
}

export default Services
