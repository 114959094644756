import { forwardRef, useMemo } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, Button, colors } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

CustomRouterLink.displayName = 'CustomRouterLink'

const SidebarNav = props => {
  const { pages, className, onLogoff, ...rest } = props

  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)

  const userType = useMemo(() => {
    return user?.stores.find(userStore => userStore.storeId._id === store?._id)
      ?.type
  }, [user?.stores, store?._id])

  const classes = useStyles()

  const availableCashierRoutes = [
    'Início',
    'Lançamentos',
    'Atendimentos',
    'Relatórios',
    'Configurações'
  ]

  let filteredPages = []

  if (userType === 'cashier') {
    filteredPages = pages.filter(page =>
      availableCashierRoutes.includes(page.title)
    )
  } else if (userType) {
    filteredPages = pages
  }

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {filteredPages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
}

export default SidebarNav
