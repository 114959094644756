import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination
} from '@material-ui/core'
import {
  Container,
  StorylineNameAsAvatar,
  VisiblePerfectScrollbar
} from './styles'
import { Avatar } from '@mui/material'
import { differenceInDays, format } from 'date-fns'
import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ISettings,
  IStorylineMetadata,
  IStorylineTable
} from 'store/modules/storylines/types'
import { browserHistory } from 'App'
import {
  getFinishedStorylinesRequest,
  getStoriesFromStorylineRequest
} from 'store/modules/storylines/actions'
import { dateWithNoTimezone } from 'utils/dateFunctions'
import { IUser } from 'store/modules/user/types'
import Tooltip from 'components/Tooltip'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

interface StorylinesTableProps {
  storylines: IStorylineTable[]
  tableTitle: string
  user?: IUser
  metadata: IStorylineMetadata
  isLoading: boolean
  settings: ISettings
}

export const StorylinesTable = ({
  storylines,
  tableTitle,
  metadata,
  user,
  isLoading,
  settings
}: StorylinesTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const dispatch = useDispatch()

  const isFinishedTable = tableTitle === 'Encerrados'
  const token = window.localStorage.getItem('@NeoPro:token')

  const headCells = [
    { id: 'title', label: 'Comunicado', minWidth: 200 },
    { id: 'createdBy', label: 'Autor', minWidth: 100 },
    { id: 'tags', label: 'Tags', minWidth: 100 },
    { id: 'storesAmount', label: 'Lojas', minWidth: 100 },
    { id: 'totalUsers', label: 'Usuários', minWidth: 100 },
    { id: 'totalViews', label: 'Visualizações totais', minWidth: 100 },
    {
      id: 'totalViewsPercentage',
      label: 'Visualizações únicas',
      minWidth: 100
    },
    { id: 'startDate', label: 'Início', minWidth: 100 },
    { id: 'endDate', label: 'Encerramento (duração)', minWidth: 200 },
  ]

  const handleChangePage = (newPage: number) => {
    if (isFinishedTable && user) {
      const token = window.localStorage.getItem('@NeoPro:token')

      dispatch(
        getFinishedStorylinesRequest(user._id, token, newPage + 1, rowsPerPage)
      )
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isFinishedTable && user) {
      const newRowsPerPage = Number(event.target.value)
      dispatch(getFinishedStorylinesRequest(user._id, token, 1, newRowsPerPage))
      setRowsPerPage(newRowsPerPage)
      setPage(0)
    }
  }

  const storylineDuration = (endDate: string, days: number) => {
    return (
      <>
        {format(dateWithNoTimezone(endDate), 'dd/MM/yyyy')}
        <p
          style={{
            display: 'inline',
            paddingLeft: '6px'
          }}
        >
          ({days + ' '}
          {days > 1 ? 'dias' : 'dia'})
        </p>
      </>
    )
  }

  const userViewPercentage = (storyline: IStorylineTable) => {
    return (typeof storyline.viewingHistory?.userViewPercentage === 'number') &&
      `${(storyline.viewingHistory.userViewPercentage * 100).toFixed(0)}%`
  }

  const createdBy = (storyline: IStorylineTable) => {
    return (typeof storyline.createdBy === 'string')
      ? storyline.createdBy
      : user?.name?.complete || ''
  }

  const storylineStats = (storyline: IStorylineTable) => {
    return (
      <>
        <TableCell>{storyline.viewingHistory.totalUsers}</TableCell>
        <TableCell>
          {storyline.viewingHistory.totalViews}
          <Tooltip
            id={`total-views-${storyline._id}`}
            content={
              <div>
                {storyline.viewingHistory.stories?.length > 1 && (
                  <>
                    {storyline.viewingHistory.stories.map((store, index) => (
                      <div key={`page-${index}-${storyline._id}`}>
                        <strong>{`Página ${index + 1}: ${
                          store.totalViews
                        }`}</strong>
                      </div>
                    ))}
                  </>
                )}
              </div>
            }
            place='bottom'
          />
          {storyline.viewingHistory.stories?.length > 1 && (
            <ErrorOutlineIcon
              data-tip
              data-for={`total-views-${storyline._id}`}
              style={{
                color: '#C4C4C4',
                fontSize: 20,
                marginLeft: 5,
                marginBottom: -5
              }}
            />
          )}
        </TableCell>
        <TableCell>
          {userViewPercentage(storyline)}
          <Tooltip
            id={`total-views-percentage-${storyline._id}`}
            content={
              <div>
                {storyline.viewingHistory.stories?.length > 1 && (
                  <>
                    {storyline.viewingHistory.stories.map((store, index) => (
                      <div key={`page-${index}-percentage-${storyline._id}`}>
                        <strong>
                          {`Página ${index + 1}: ${(
                            store.userViewPercentage * 100
                          ).toFixed(0)}%`}
                        </strong>
                      </div>
                    ))}
                  </>
                )}
              </div>
            }
            place='bottom'
          />
          {storyline.viewingHistory.stories?.length > 1 && (
            <ErrorOutlineIcon
              data-tip
              data-for={`total-views-percentage-${storyline._id}`}
              style={{
                color: '#C4C4C4',
                fontSize: 20,
                marginLeft: 5,
                marginBottom: -5
              }}
            />
          )}
        </TableCell>
      </>
    )
  }

  return (
    <Container>
      <h1>{tableTitle}</h1>
      <Paper style={{ marginBottom: '32px' }}>
        <TableContainer>
          <VisiblePerfectScrollbar>
            <Table aria-labelledby='tableTitle' aria-label='enhanced table'>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      style={{ minWidth: headCell.minWidth }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(metadata.pagination.totalCount > 0 || !isFinishedTable) &&
                storylines.length > 0 ? (
                  storylines.map(storyline => {
                    const diffInDaysBetweenStartAndEnd =
                      differenceInDays(
                        dateWithNoTimezone(storyline.period.end),
                        dateWithNoTimezone(storyline.period.start)
                      ) + 1

                    const storylineThumbnail =
                      storyline.photo?.url || settings.photo?.url || undefined

                    const splitName = storyline.name.split(' ')

                    const storyDefaultNameImage =
                      splitName.length > 1
                        ? `${splitName[0]
                            ?.charAt(0)
                            .toUpperCase()} ${splitName[1]
                            ?.charAt(0)
                            .toUpperCase()}`
                        : `${splitName[0]
                            ?.charAt(0)
                            .toUpperCase()} ${splitName[0]
                            ?.charAt(1)
                            .toUpperCase()}`

                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          if (!isFinishedTable) {
                            dispatch(
                              getStoriesFromStorylineRequest(
                                storyline._id,
                                token
                              )
                            )
                            browserHistory.push(
                              `/storylines/${storyline._id}/edit`
                            )
                          }
                        }}
                        tabIndex={-1}
                        key={storyline._id}
                        style={{
                          cursor: isFinishedTable ? 'default' : 'pointer'
                        }}
                        data-cy='store-row'
                      >
                        <TableCell
                          scope='row'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          {storylineThumbnail ? (
                            <Avatar
                              src={storylineThumbnail}
                              sx={{
                                width: '40px',
                                height: '40px'
                              }}
                            />
                          ) : (
                            <StorylineNameAsAvatar>
                              {storyDefaultNameImage}
                            </StorylineNameAsAvatar>
                          )}
                          {storyline.name}
                        </TableCell>
                        <TableCell>
                          {createdBy(storyline)}
                        </TableCell>
                        <TableCell>
                          {storyline.tags.map(tag => tag.name).join(', ')}
                        </TableCell>
                        <TableCell>{storyline.totalStores}</TableCell>
                        {storylineStats(storyline)}
                        <TableCell>
                          {format(
                            dateWithNoTimezone(storyline.period.start),
                            'dd/MM/yyyy'
                          )}
                        </TableCell>
                        <TableCell>
                          {storyline.type !== 'fixed'
                            ? storylineDuration(
                                storyline.period.end,
                                diffInDaysBetweenStartAndEnd
                              )
                            : '-'}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={headCells.length}
                      style={{ textAlign: 'center' }}
                    >
                      {isLoading
                        ? 'Carregando...'
                        : isFinishedTable
                        ? 'Sem comunicados encerrados cadastrados.'
                        : 'Sem comunicados ativos cadastrados.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </VisiblePerfectScrollbar>
        </TableContainer>
        {isFinishedTable && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={metadata?.pagination.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={event => handleChangeRowsPerPage(event)}
          />
        )}
      </Paper>
    </Container>
  )
}
