import { Avatar } from '@material-ui/core'
import { Box, Grid } from '@mui/material'
import { FaCamera } from 'react-icons/fa'

import {
  Button,
  Card,
  IconContainer,
  IconLabel,
  ImagePreviewContainer
} from './styles'

interface ThumbnailProps {
  storyUrl: string | undefined
}

export const Thumbnail = ({ storyUrl }: ThumbnailProps) => {
  return (
    <Grid item xs={12} md={12}>
      <Card variant='outlined'>
        <IconLabel>Ícone</IconLabel>
        <Box>
          <IconContainer>
            {storyUrl ? (
              <ImagePreviewContainer>
                <img
                  src={storyUrl}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                />
              </ImagePreviewContainer>
            ) : (
              <Avatar style={{ cursor: 'pointer' }}>
                <FaCamera style={{ width: 18, height: 18 }} />
              </Avatar>
            )}
            <Button htmlFor='icon-tag-image'>Enviar</Button>
          </IconContainer>
        </Box>
      </Card>
    </Grid>
  )
}
