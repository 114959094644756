import styled, { css } from 'styled-components'
import { Avatar, TextField } from '@material-ui/core'
import { MdImageSearch } from 'react-icons/md'

interface StoryBarProps {
  storyPosition: 'isCurrentStory' | 'isPreviousStory' | 'isNextStory'
}

export const Container = styled.div`
  padding: 2rem 2rem 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  > h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #263238;
  }
`

export const Content = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`

export const Main = styled.main`
  max-width: 546px;
`

export const CardContainer = styled.div`
  background-color: #fff;

  &:last-child {
    margin-top: 1.5rem;
  }
`

export const Card = styled.div`
  padding: 1.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #546e7a;
  }
`

export const CardTitle = styled.h6`
  font-size: 1rem;
  color: #263238;

  margin-right: auto;
  padding-bottom: 1.5rem;
`

export const TextInput = styled(TextField)`
  width: 100%;

  label {
    color: #263238;
    font-size: 0.875rem;
    font-weight: 400;
  }
  fieldset {
    border-color: #c4c4c4;
  }

  input {
    color: #263238;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  padding-bottom: 1.5rem;
`

export const BadgeContent = styled(MdImageSearch)`
  color: #fff;
  background-color: #2a3ecb;
  border-radius: 50%;
  padding: 5px;
  height: 42px;
  width: 42px;
  border: 3px solid #fff;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e8;
  margin: 1.5rem 0;
`

export const StoreAvatar = styled(Avatar)`
  height: 150px;
  width: 150px;
  border: 1px solid #e3e3e8;
  border-radius: 50%;
`

const ButtonBase = styled.button`
  background-color: #2a3ecb;
  color: #fff;
  border-radius: 3px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;

  letter-spacing: 0.6px;
  font-size: 0.875rem;
  font-weight: 700;
  border: 0;
`

export const AddTagButton = styled(ButtonBase)`
  padding: 0px 12px 0px 9.75px;
  max-height: 2.125rem;

  svg {
    margin-right: 4px;
  }
`

export const Tag = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 12px 0;
  border-bottom: 1px solid #e3e3e8;
`

export const IconsContainer = styled.div`
  display: flex;
  gap: 1rem;

  svg {
    cursor: pointer;
  }
`

export const StoryPreview = styled.aside`
  font-size: 1rem;
  max-width: 578px;
  margin: 0 auto;
  text-align: center;

  > div {
    position: relative;
    margin-bottom: 1rem;

    img {
      width: 18.75rem;
      height: 36.5rem;
      object-fit: cover;
      border-radius: 24px;
    }
  }
`

export const SaveButton = styled(ButtonBase)`
  padding: 9px 12px;
  margin: 1rem 0 1.75rem;
  margin-left: auto;

  &:disabled {
    background: #0000001f;
    color: #00000042;
    cursor: not-allowed;
  }
`

export const StoryBarContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 8px;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 12;
`

export const StoryBar = styled.div<StoryBarProps>`
  height: 2px;
  width: 100%;
  border-radius: 4px;

  ${({ storyPosition }) =>
    storyPosition === 'isPreviousStory'
      ? css`
          background-color: #fff;
        `
      : storyPosition === 'isCurrentStory'
      ? css`
          background: -webkit-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: -moz-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: -ms-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: linear-gradient(left, #fff 30%, #ffffff99 70%);
        `
      : storyPosition === 'isNextStory' &&
        css`
          background-color: #ffffff99;
        `}
`

export const StoryHeader = styled.header`
  position: absolute;
  top: 26px;
  left: 16px;
  letter-spacing: 0.6px;

  display: flex;
  align-items: center;
  text-align: left;

  span {
    display: flex;
    flex-direction: column;

    margin-left: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 0.625rem;
    color: #fff;

    p {
      font-size: 0.75rem;
      margin-top: 2px;
    }
  }
`
export const StoryButtonFooter = styled.footer`
  button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;

    border: 0;
    font-weight: 700;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;

    background-color: #fff;
    color: #2a3ecb;

    padding: 9.5px 12px 9.5px 15px;
    border-radius: 8px;

    svg {
      margin-right: 5px;
    }
  }

  p {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif;

    margin-top: 1.5rem;
    letter-spacing: 0.8px;
  }
`

export const NameTagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 400;
  font-size: 0.875rem;
`

export const IconTagContainer = styled.div`
  display: flex;

  gap: 8px;
  flex-direction: column;
`

export const TagFooter = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`

export const Text = styled.h4`
  font-size: 1.125rem;
  color: #263238;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
`

export const Button = styled.label`
  border: 2px solid #5b5b76;
  background-color: transparent;
  border-radius: 50px;
  min-width: 136px;
  padding: 6px;
  text-align: center;
  color: #5b5b76;
  font-size: 0.825rem;
  font-weight: 700;
  font-family: 'Commissioner', sans-serif;

  max-height: 33px;
  cursor: pointer;
`
export const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;

  gap: 16px;
`

export const MediaInfoCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: 8px;

  text-align: left;

  div:first-child {
    display: flex;
    flex-direction: column;

    > span {
      color: #9a9ab1;
      vertical-align: top;
      text-align: left;

      margin-top: 8%;
      width: 100%;
    }
  }

  div:last-child {
    display: flex;
    flex-direction: row;
  }
`
export const IconButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  background-color: #e3e3e8;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:last-child {
    margin-left: 6px;
  }

  & > svg {
    color: #5b5b76;
  }
`
