import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #eeeeee;
  margin: 8px 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.03em;

  color: ${props => (props.feedback === 'error' ? '#E64C3D' : '#263238')};
`
