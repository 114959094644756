import {
  IAddNewUser,
  IEditUser,
  IStorylineStoresAndGroups,
  IUser,
  IUserExists,
  IUserLink,
  IUserList,
  IUserListResponse,
  IUsersGroupList
} from './types'

export function userRequest(userId: string, token: string | null) {
  return {
    type: '@user/REQUEST',
    payload: { userId, token }
  }
}

export function userRequestOnSignIn(userId: string, token: string | null) {
  return {
    type: '@user/USER_REQUEST_ON_SIGN_IN',
    payload: { userId, token }
  }
}

export function userSuccess(user: IUser) {
  return {
    type: '@user/SUCCESS',
    payload: { user }
  }
}

export function userFailed(error: any) {
  return {
    type: '@user/FAILED',
    payload: { error }
  }
}

export function userListRequest(
  storeId: string,
  token: null | string,
  page = 1,
  limit = 10,
  forceLoading = true
) {
  return {
    type: '@user/LIST_REQUEST',
    payload: { storeId, token, page, limit, forceLoading }
  }
}

export function userListSuccess(dataRequest: IUserListResponse) {
  return {
    type: '@user/LIST_SUCCESS',
    payload: { dataRequest }
  }
}

export function usersGroupListRequest(token: string | null) {
  return {
    type: '@user/USERS_GROUP_LIST_REQUEST',
    payload: { token }
  }
}

export function usersGroupListSuccess(usersGroupList: IUsersGroupList[]) {
  return {
    type: '@user/USERS_GROUP_LIST_SUCCESS',
    payload: { usersGroupList }
  }
}

export function updateUserFromUsersGroupList(updatedUser: IUsersGroupList) {
  return {
    type: '@user/UPDATE_USER_GROUP_LIST',
    payload: { updatedUser }
  }
}

export function userAddRequest(
  newUser: Omit<IAddNewUser, '_id'>,
  token: string | null
) {
  return {
    type: '@user/ADD_REQUEST',
    payload: { newUser, token }
  }
}

export function userAddSuccess(newUser: IAddNewUser) {
  return {
    type: '@user/ADD_SUCCESS',
    payload: { newUser }
  }
}

export function logout() {
  return {
    type: '@user/LOGOUT'
  }
}

export function userEditRequest(
  updatedUser: IEditUser,
  token: string | null,
  userId: string
) {
  return {
    type: '@user/EDIT_REQUEST',
    payload: { updatedUser, token, userId }
  }
}

export function userChangePasswordOpenModal() {
  return {
    type: '@user/CHANGE_PASSWORD_OPEN_MODAL'
  }
}

export function userChangePasswordCloseModal() {
  return {
    type: '@user/CHANGE_PASSWORD_CLOSE_MODAL'
  }
}

export function userChangePasswordRequest(
  userPasswords: { oldPassword: string; newPassword: string },
  token: string | null,
  userId: string
) {
  return {
    type: '@user/CHANGE_PASSWORD_REQUEST',
    payload: { userPasswords, token, userId }
  }
}

export function userChangePasswordSuccess() {
  return {
    type: '@user/CHANGE_PASSWORD_SUCCESS'
  }
}

export function userEditSuccess(data: IUserList, userId: string) {
  return {
    type: '@user/EDIT_SUCCESS',
    payload: { data, userId }
  }
}

export function unLinkUser(
  storeId: string,
  userId: string,
  token: string | null
) {
  return {
    type: '@user/UNLINK',
    payload: { storeId, userId, token }
  }
}

export function userLinkRequest(
  data: IUserLink,
  userId: string,
  token: string | null
) {
  return {
    type: '@user/LINK_REQUEST',
    payload: { data, userId, token }
  }
}

export function userLinkSuccess(data: IAddNewUser) {
  return {
    type: '@user/LINK_SUCCESS',
    payload: { data }
  }
}

export function userUnlinkSuccess(userId: string) {
  return {
    type: '@user/UNLINK_SUCCESS',
    payload: { userId }
  }
}

export function userUpdateLink(
  data: IUserLink,
  userId: string,
  token: string | null,
  storeId: string
) {
  return {
    type: '@user/UPDATE_LINK',
    payload: { data, userId, token, storeId }
  }
}

export function userAvatarUpdateRequest(
  newAvatar: FormData,
  userId: string,
  token: string | null
) {
  return {
    type: '@user/AVATAR_UPDATE_REQUEST',
    payload: { newAvatar, userId, token }
  }
}

export function userAvatarUpdateSuccess(url: string | null) {
  return {
    type: '@user/AVATAR_UPDATE_SUCCESS',
    payload: { url }
  }
}

export function userExistsRequest(cpf: string, token: string | null) {
  return {
    type: '@user/EXISTS_REQUEST',
    payload: { cpf, token }
  }
}

export function userExistsSuccess(userExists: IUserExists) {
  return {
    type: '@user/EXISTS_SUCCESS',
    payload: { userExists }
  }
}

export function userExistsFailed(error: { error: { friendlyMsg: string } }) {
  return {
    type: '@user/USER_EXISTS_FAILED',
    payload: { error }
  }
}

export function userAddModal(isAddUserModalOpen: boolean) {
  return {
    type: '@user/ADD_MODAL',
    payload: { isAddUserModalOpen }
  }
}

export function getStorylineStoresAndGroupsRequest(
  userId: string,
  token: string | null
) {
  return {
    type: '@user/GET_STORYLINE_STORES_AND_GROUPS_REQUEST',
    payload: { userId, token }
  }
}

export function getStorylineStoresAndGroupsSuccess(
  storylineStores: IStorylineStoresAndGroups[]
) {
  return {
    type: '@user/GET_STORYLINE_STORES_AND_GROUPS_SUCCESS',
    payload: { storylineStores }
  }
}

export function clearNewUser() {
  return {
    type: '@user/CLEAR_NEW_USER'
  }
}
