import React, { useEffect, useState } from 'react'

// Libs
import moment from 'moment'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TP,
  Legend,
  Filler
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TP,
  Legend,
  Filler
)

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { Divider, colors, Grid } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import GroupIcon from '@material-ui/icons/Group'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import CoffeeIcon from '@mui/icons-material/Coffee'
import BathroomIcon from '@mui/icons-material/Wc'
import TasksIcon from '@mui/icons-material/Assignment'
import OutsideIcon from '@mui/icons-material/Signpost'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'

// Components
import Header from 'components/Header'
import { formatPrice, formatToPercentage } from '../../utils/format'
// import Users from './components/Users'
import ValueCard from '../../components/ValueCard'
import UsersPerformanceTable from '../../components/UsersPerformanceTable'

// Actions
import { dayRequest } from '../../store/modules/day/actions'
import Tooltip from 'components/Tooltip'

// Styles
import palette from 'theme/palette'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  cardRoot: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const ServiceDay = ({ match }) => {
  const { dayId, storeId } = match.params

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  // Redux
  const day = useSelector(state => state.day.day)

  const token = localStorage.getItem('@NeoPro:token')

  // Component State
  // Tabs
  const tabs = [
    { value: 'performance', label: 'Desempenho' },
    { value: 'timeLine', label: 'Linha do tempo' }
  ]
  const [tab, setTab] = useState('performance')

  const status = {
    start: {
      text: 'Iniciou o dia'
    },
    end_operational: {
      text: 'Saiu para tarefas operacionais',
      dot: (
        <TimelineDot>
          <TasksIcon />
        </TimelineDot>
      )
    },
    start_operational: {
      text: 'Voltou das tarefas operacionais',
      dot: (
        <TimelineDot>
          <TasksIcon />
        </TimelineDot>
      )
    },
    end_food: {
      text: 'Saiu para almoçar',
      dot: (
        <TimelineDot>
          <FastfoodIcon />
        </TimelineDot>
      )
    },
    start_food: {
      text: 'Voltou do almoço',
      dot: (
        <TimelineDot>
          <FastfoodIcon />
        </TimelineDot>
      )
    },
    end_coffee: {
      text: 'Saiu para lanchar',
      dot: (
        <TimelineDot>
          <CoffeeIcon />
        </TimelineDot>
      )
    },
    start_coffee: {
      text: 'Voltou do lanche',
      dot: (
        <TimelineDot>
          <CoffeeIcon />
        </TimelineDot>
      )
    },
    end_bathroom: {
      text: 'Foi ao banheiro',
      dot: (
        <TimelineDot>
          <BathroomIcon />
        </TimelineDot>
      )
    },
    start_bathroom: {
      text: 'Voltou do banheiro',
      dot: (
        <TimelineDot>
          <BathroomIcon />
        </TimelineDot>
      )
    },
    end_external: {
      text: 'Tarefas externas',
      dot: (
        <TimelineDot>
          <OutsideIcon />
        </TimelineDot>
      )
    },
    start_external: {
      text: 'Voltou das tarefas externas',
      dot: (
        <TimelineDot>
          <OutsideIcon />
        </TimelineDot>
      )
    },
    end: {
      text: 'Finalizou o dia'
    }
  }

  useEffect(() => {
    if (!day) dispatch(dayRequest(dayId, token))
  }, [])

  const DEFAULT_GRAPH_DATA = {
    main: {
      labels: [],
      total: [],
      success: []
    }
  }

  const [graphData, setGraphData] = useState(DEFAULT_GRAPH_DATA)

  useEffect(() => {
    if (day?.sellers) {
      const newGraphData = { ...DEFAULT_GRAPH_DATA }
      const hoursSeen = new Set()
      const everyEvent = []

      day.sellers.forEach(seller => {
        seller.events.forEach(event => {
          const eventStart = new Date(event.period.start).getHours()
          const eventEnd = new Date(event.period.end).getHours()
          hoursSeen.add(eventStart)
          hoursSeen.add(eventEnd)
          everyEvent.push(event)
        })
        seller.activities.forEach(activity =>
          hoursSeen.add(new Date(activity.date).getHours())
        )
      })

      const { begin, end } =
        hoursSeen.size > 0
          ? [...hoursSeen].reduce(
              (acc, hour) => {
                if (hour < acc.begin) acc.begin = hour
                if (hour > acc.end) acc.end = hour
                return acc
              },
              { begin: Infinity, end: -Infinity }
            )
          : { begin: 12, end: 12 }

      // Generate labels for every hour between begin and end
      for (let i = begin; i <= end; i++) {
        newGraphData.main.labels.push(`${i}:00`)
        newGraphData.main.total.push(0)
        newGraphData.main.success.push(0)
      }

      everyEvent.reduce((acc, event) => {
        const eventDate = new Date(event.period.start)
        const eventHour = eventDate.getHours()
        const hourIndex = acc.labels.findIndex(
          label => label === `${eventHour}:00`
        )
        acc.total[hourIndex] += 1
        if (event.success) acc.success[hourIndex] += 1

        return acc
      }, newGraphData.main) // Reduce altera newGraphData por referência

      setGraphData(newGraphData)
    }
  }, [day])

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.divider,
        backgroundColor: palette.white,
        titleColor: palette.text.primary,
        bodyColor: palette.text.secondary,
        footerColor: palette.text.secondary
      }
    },
    maintainAspectRatio: false,
    cornerRadius: 20,
    elements: {
      line: {
        tension: 0.2,
        fill: true
      }
    },
    interaction: {
      intersect: false
    },
    layout: { padding: 0 },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          borderDash: [3],
          drawBorder: false
        },
        min: 0
      }
    }
  }

  return (
    <div className={classes.root}>
      <Header
        dayEvent
        data={{
          updatedAt: day ? day.sellers[0].activities[0].date : ''
        }}
        handleBack={() => {
          history.push(`/${storeId}/events`)
        }}
        title='Atendimentos'
        subtitle={`Dia de ${
          day
            ? moment(day.sellers[0].activities[0].date).format('DD/MMMM - dddd')
            : '...'
        }`}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Taxa de conversão'
            value={day ? formatToPercentage(Number(day.conversion)) : '0'}
            icon={<CheckCircleIcon />}
            color='#2A3ECB'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Vendas realizadas'
            value={day ? day.success : '0'}
            color='#2ecc71'
            icon={<ThumbUpIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='VENDAS NÃO REALIZADAS'
            value={day ? day.fail : '0'}
            icon={<ThumbDownAltIcon />}
            color='#e74c3c'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Qtd. de atendimentos'
            value={day ? day.fail + day.success : '0'}
            color='#37474F'
            icon={<GroupIcon />}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Tabs
          className={classes.tabs}
          onChange={(e, value) => setTab(value)}
          scrollButtons='auto'
          value={tab}
          variant='scrollable'
        >
          {tabs.map(tab => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {tab == 'performance' && (
            <>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Card>
                  <CardHeader title='Atendimentos por hora' />
                  <Divider />
                  <CardContent className={classes.content}>
                    <Line
                      data={{
                        labels: graphData.main.labels,
                        datasets: [
                          {
                            label: 'Atendimentos',
                            backgroundColor: palette.neutral,
                            data: graphData.main.total
                          },
                          {
                            label: 'Vendas',
                            backgroundColor: palette.primary.main,
                            data: graphData.main.success
                          }
                        ]
                      }}
                      options={graphOptions}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <UsersPerformanceTable
                users={
                  day
                    ? day.sellers.map(seller => ({
                        _id: seller.sellerId?._id,
                        name: seller.sellerId?.name?.complete,
                        conversion: (
                          (seller.success / seller.events.length > 0
                            ? seller.success / seller.events.length
                            : 0) * 100
                        ).toFixed(2),
                        success: seller.success,
                        total: seller.fail + seller.success,
                        lineTime: seller.time.line,
                        workingTime: seller.time.working
                      }))
                    : []
                }
              />
            </>
          )}
          {tab == 'timeLine' && (
            <Grid container>
              {day ? (
                day.sellers.map(seller => (
                  <Grid
                    key={seller.sellerId?._id}
                    item
                    lg={4}
                    sm={6}
                    xl={4}
                    xs={12}
                  >
                    <Card className={classes.cardRoot}>
                      <CardContent>
                        <Typography variant='h5' component='h2'>
                          {seller.sellerId?.name?.complete}
                        </Typography>
                        <Timeline align='left'>
                          {seller.activities.map((aC, index) => {
                            const DEFAULT_BETWEEN = { success: 0, total: 0 }
                            let { type, date, _id } = aC
                            const lastActivity = seller.activities[index - 1]
                            const nextActivityDate = seller.activities[
                              index + 1
                            ]
                              ? new Date(seller.activities[index + 1].date)
                              : Infinity
                            const activityDate = new Date(date)

                            let between =
                              type === 'start'
                                ? seller.events.reduce((acc, event) => {
                                    const eventDate = new Date(
                                      event.period.start
                                    )
                                    if (
                                      eventDate >= activityDate &&
                                      eventDate <= nextActivityDate
                                    ) {
                                      acc.total++
                                      acc.success += Number(event.success)
                                    }
                                    return acc
                                  }, DEFAULT_BETWEEN)
                                : DEFAULT_BETWEEN

                            if (
                              lastActivity?.type.indexOf('end_') === 0 &&
                              type === 'start'
                            ) {
                              type = lastActivity.type.replace('end', 'start')
                            }
                            const { dot, text } = {
                              dot: <TimelineDot />,
                              ...status[type]
                            }
                            return (
                              <React.Fragment key={`${type}${index}`}>
                                <TimelineItem>
                                  <TimelineOppositeContent>
                                    <Typography color='textSecondary'>
                                      {DateTime.fromISO(date).toLocaleString({
                                        ...DateTime.TIME_24_SIMPLE
                                      })}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    {dot}
                                    {(type !== 'end' || between.total > 0) && (
                                      <TimelineConnector
                                        data-tip
                                        data-for={`${type}${index}tooltip`}
                                      />
                                    )}
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Typography>{text}</Typography>
                                  </TimelineContent>
                                </TimelineItem>
                                {between.total > 0 && (
                                  <>
                                    <Tooltip
                                      id={`${_id}tooltip`}
                                      content={
                                        <p>
                                          {between.success} venda
                                          {between.success > 1 && 's'} realizada
                                          {between.success > 1 && 's'}
                                        </p>
                                      }
                                      place='top'
                                    />
                                    <TimelineItem
                                      data-tip
                                      data-for={`${_id}tooltip`}
                                    >
                                      <TimelineSeparator>
                                        <TimelineDot />
                                        {type !== 'end' && (
                                          <TimelineConnector />
                                        )}
                                      </TimelineSeparator>
                                      <TimelineContent>
                                        <Typography color='textSecondary'>
                                          {between.total} atendimento
                                          {between.total > 1 && 's'} (
                                          {(between.total > 0
                                            ? (between.success * 100) /
                                              between.total
                                            : 0
                                          ).toFixed(0)}
                                          %)
                                        </Typography>
                                      </TimelineContent>
                                    </TimelineItem>
                                  </>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </Timeline>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}

ServiceDay.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default ServiceDay
