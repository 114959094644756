import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
// Material UI
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
// Redux
import { useDispatch } from 'react-redux'
// Libs
import { useParams } from 'react-router-dom'
import {
  integrationDisconnectRequest,
  integrationRequest
} from '../../../../../../store/modules/integration/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}))

const TagPlus = ({
  className,
  storeIntegrationData,
  store,
  loading,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'tagplus'

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
      storeId: ''
    }
  })

  // LocalStorage
  const token = localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  const onSubmit = data => {
    confirm({
      title: 'Atenção',
      description:
        'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na TagPlus?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(
          integrationRequest({ ...data, provider }, provider, storeId, token)
        )
      })
      .catch(() => {})
  }

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com TagPlus?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() =>
        dispatch(integrationDisconnectRequest(provider, storeId, token))
      )
      .catch(() => {})
  }

  useEffect(() => {
    if (
      storeIntegrationData?.username &&
      storeIntegrationData?.provider === provider
    )
      reset({
        username: storeIntegrationData.username,
        password: storeIntegrationData.password,
        storeId: storeIntegrationData.storeId
      })
  }, [storeIntegrationData])

  const renderError = error =>
    errors[error] && (
      <FormHelperText id='component-error-text'>
        Este campo é obrigatório
      </FormHelperText>
    )
  // useEffect(() => {
  //   console.log(document.getElementById("tagplus_iframe").contentWindow.location.href)
  // }, [document.getElementById("tagplus_iframe").contentWindow.location.href])

  return (
    <>
      <Card {...rest}>
        <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='TagPlus' />
          <Divider />
          <CardContent>
            <Typography>
              Antes de iniciar a integração, certifique-se de que todos os
              vendedores estão cadastrados, assim como seus CPFs estão definidos
              e idênticos à TagPlus. Caso haja qualquer diferença nos valores, o
              vendedor não será identificado e a venda entrará em "Outras
              vendas", prejudicando a validade do relatório.
              <br />
              Se acontecer de um vendedor não ter sido identificado e sua venda
              entrar em "Outras vendas", corrija os dados do vendedor e aguarde
              a próxima sincronização.
              <br />
              A ID da TagPlus é aquela que aparece na URL (Ex: Em
              https://app.tagplus.com.br/abcd1234/ a ID é "abcd1234".
              <br />A sincronização acontece todo dia à 01:00 AM
            </Typography>
            <Divider style={{ marginTop: '1rem' }} />
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='ID'
                  placeholder='(Ex: abcd1234)'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='storeId'
                  name='storeId'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.storeId}
                />
                {renderError('storeId')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Login'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='username'
                  name='username'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.username}
                />
                {renderError('username')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Senha'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='password'
                  name='password'
                  type='password'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.password}
                  inputProps={{
                    'data-clarity-mask': 'True'
                  }}
                />
                {renderError('password')}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              disabled={loading}
              className={classes.button}
              type='submit'
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#fff', marginRight: '8px' }}
                  />{' '}
                  {' Conectando ao TagPlus...'}
                </>
              ) : (
                'Salvar'
              )}
            </Button>
            {storeIntegrationData?.provider === provider && (
              <Button
                style={{ color: '#e74c3c' }}
                className={classes.button}
                disabled={loading}
                onClick={() => handleDisconnectIntegration()}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={24}
                      style={{ color: '#e74c3c', marginRight: '8px' }}
                    />{' '}
                    {' Desconectando...'}
                  </>
                ) : (
                  'Desconectar'
                )}
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
    </>
  )
}

TagPlus.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default TagPlus
