import React from 'react'

const FixIcon = () => {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.7501 10.2666L18.3334 12.8333L15.7668 8.24996L18.3334 3.66663L13.7501 6.23329L9.16677 3.66663L11.7334 8.24996L9.16677 12.8333L13.7501 10.2666ZM35.7501 28.2333L31.1668 25.6666L33.7334 30.25L31.1668 34.8333L35.7501 32.2666L40.3334 34.8333L37.7668 30.25L40.3334 25.6666L35.7501 28.2333ZM40.3334 3.66663L35.7501 6.23329L31.1668 3.66663L33.7334 8.24996L31.1668 12.8333L35.7501 10.2666L40.3334 12.8333L37.7668 8.24996L40.3334 3.66663ZM26.3451 13.365C25.6301 12.65 24.4751 12.65 23.7601 13.365L2.36511 34.76C1.65011 35.475 1.65011 36.63 2.36511 37.345L6.65511 41.635C7.37011 42.35 8.52511 42.35 9.24011 41.635L30.6168 20.2583C31.3318 19.5433 31.3318 18.3883 30.6168 17.6733L26.3451 13.365ZM24.4568 23.43L20.5701 19.5433L25.0434 15.07L28.9301 18.9566L24.4568 23.43Z'
        fill='#1E2FA9'
      />
    </svg>
  )
}

export default FixIcon
