import styled from 'styled-components'
import DefaultPromptModal from 'views/Goals/components/AddGoal/components/PromptModal'

interface ContentProps {
  hasSomeMedia: boolean
}

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
`

export const Content = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: ${({ hasSomeMedia }) =>
    hasSomeMedia ? '5.5fr 2.1fr 2.4fr' : '7.6fr 2.4fr'};

  height: calc(100% - 92px);
`

export const PromptModal = styled(DefaultPromptModal).attrs({
  className: 'container'
})`
  .center-content {
    text-align: center;
  }

  .footer-buttons {
    button:first-child {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      border-top: 1px solid #f5f6f8;
      background-color: #f5f6f8;
      color: #263238;
    }

    button:last-child {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      background-color: #e64c3d;
      color: #fff;
    }
  }
`
