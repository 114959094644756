// React
import { useState, useEffect } from 'react'

// Libs
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker'

// Components
import {
  InputContainer,
  SliderContainer,
  WeightSelection,
  WeightSelectionItem
} from 'components/UsersGoal/components/GoalSliderModal/styles'
import Tooltip from 'components/Tooltip'

// MaterialUI
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/core/styles'
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  ErrorOutline as ErrorOutlineIcon,
  Speed as SpeedIcon
} from '@material-ui/icons'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import {
  Checkbox,
  Slider,
  CircularProgress,
  Tooltip as TooltipMUI,
  Box,
  circularProgressClasses
} from '@mui/material'
import {
  TripOrigin as TripOriginIcon,
  Lens as LensIcon
} from '@mui/icons-material'
import { formatPrice } from '../../../../utils/format'
import {
  ActionButtons,
  Container,
  CustomButton,
  DSRCheckbox,
  DayPickerDescription,
  DayPickerFooterContainer,
  GoalCard,
  Item,
  ItemTitle,
  LockButton,
  Picker
} from './styles'
import dayPickerBR from '../../../../common/DayPicker-pt-BR'
import {
  IDayOff,
  IDays,
  IDaysUsers,
  IGoal,
  IInitialCommission,
  IUserWorking
} from 'store/modules/goal/types'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import api from 'repositories/api'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}))

interface SellerProps {
  dsr: boolean
  goalWeight: number | string
  weightLocked: boolean
  edited: boolean
}

interface EditUserGoalModalProps {
  modal: boolean
  currentMonthGoal: IGoal
  usersWorkingLength: number
  handleRemoveUser: (user: IUserWorking) => void
  user: IUserWorking | undefined
  onClose: () => void
  handleUpdateUserWeight: (
    days: IDays[],
    user: IUserWorking,
    tab: 'fixed' | 'auto',
    sellerWeight: number,
    goalId: string,
    isRemoving: boolean
  ) => void
  loading: boolean
}

interface IAccGoal {
  _id: number
  value: number
  commission: IInitialCommission
}

export default function EditUserGoalModal({
  modal,
  currentMonthGoal,
  usersWorkingLength,
  handleRemoveUser,
  user,
  onClose,
  handleUpdateUserWeight,
  loading
}: EditUserGoalModalProps) {
  const classes = useStyles()
  const confirm = useConfirm()

  const [isLoading, setIsLoading] = useState(false)
  const [days, setDays] = useState<IDays[]>([])
  const [seller, setSeller] = useState<SellerProps>({
    dsr: true,
    goalWeight: 100,
    weightLocked: false,
    edited: false
  })

  const [isDaysBlocked, setIsDaysBlocked] = useState(() => {
    const isCurrentMonthEqualsToMonthGoal =
      new Date(currentMonthGoal.month).getMonth() === new Date().getMonth()

    return isCurrentMonthEqualsToMonthGoal
  })

  const [tab, setTab] = useState(0)
  const tabColorOptions = tab === 0 ? '#FFC700' : '#263238'

  const [accGoal, setAccGoal] = useState<IAccGoal[]>()

  useEffect(() => {
    if (!!currentMonthGoal && !!user) {
      const workingDays = currentMonthGoal.days.filter(day => {
        const isUserWorking = day.users.find(
          usr => usr.userId === user.userId._id
        )

        return user.active && isUserWorking && day.working
      })

      setDays(user.active ? workingDays : currentMonthGoal.days)

      const selectedUser = currentMonthGoal.usersWorking.find(
        userW => userW.userId?._id === user.userId?._id
      )

      setSeller(prev => ({
        ...prev,
        dsr:
          user.active && selectedUser
            ? selectedUser.dsr
            : currentMonthGoal.dsr > 0,
        goalWeight:
          (user.active && selectedUser ? selectedUser.goalWeight : 1) * 100
      }))
      setTab(selectedUser?.weightLocked ? 1 : 0)
    }
  }, [currentMonthGoal, user])

  useEffect(() => {
    if (!!currentMonthGoal && !!user && !user.active) {
      const inactiveUserDays = currentMonthGoal.days.map(day => {
        const newUser = {
          userId: user.userId._id,
          dayOff: {
            enable: false
          }
        }
        const users = [...day.users, newUser]

        return {
          ...day,
          users
        }
      })
      setDays(inactiveUserDays)
    }
  }, [currentMonthGoal, user])

  const isoStringDays = days.map(day => new Date(day.date).toISOString())
  const daysNotWorked = user?.userId?._id
    ? currentMonthGoal?.days
        .filter(day => {
          return !isoStringDays.includes(day.date)
        })
        .map(day => day.date)
    : []

  const daysWithStoreClosed =
    user?.userId?._id && currentMonthGoal?.days
      ? currentMonthGoal.days
          .filter(day => {
            return typeof day.working === 'boolean' && !day.working
          })
          .map(day => day.date)
      : []

  const isCurrentMonthEqualsToMonthGoal =
    new Date(currentMonthGoal.month).getMonth() === new Date().getMonth()

  const getMonthGoals = async () => {
    try {
      if (!!user && days.length > 0) {
        setIsLoading(true)
        const formattedDays: { dayOff: IDayOff; date: string }[] = []
        days.forEach(day => {
          const userId = user?.userId?._id ?? ''
          const isUserWorkingOnThisDay = day.users.find(
            usr => usr.userId === userId
          )

          if (!!isUserWorkingOnThisDay) {
            formattedDays.push({
              dayOff: isUserWorkingOnThisDay.dayOff,
              date: day.date
            })
          }
        })

        const data = {
          dsr: seller.dsr,
          goalWeight: Number(seller.goalWeight) / 100,
          userId: user.userId._id,
          days: formattedDays
        }

        const response = await api.axios.post<IAccGoal[]>(
          `/report/goal/${currentMonthGoal._id}/user-goal-preview`,
          data
        )

        const goalsWithId = response.data.map((goal, index) => {
          return {
            ...goal,
            _id: index
          }
        })

        setAccGoal(goalsWithId)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getMonthGoals()
    }, 500)

    return () => clearTimeout(getData)
  }, [days, seller?.goalWeight])

  const remainWeight = currentMonthGoal?.usersWorking.reduce(
    (acc, currUser, i) => {
      if (currUser.active) {
        acc.weightToDistribute += 1
        if (
          currUser.weightLocked &&
          !(currUser?.userId?._id === user?.userId?._id)
        ) {
          acc.weightToDistribute -= currUser.goalWeight
        } else {
          acc.usersToDistribute += 1
        }
      }
      if (i === currentMonthGoal.usersWorking.length - 1) {
        if (!user?.active) {
          acc.weightToDistribute += 1
          acc.usersToDistribute += 1
        }
        const weightDistributed =
          (acc.weightToDistribute / acc.usersToDistribute) * 100
        acc.weightToDistribute = weightDistributed < 0 ? 0 : weightDistributed
      }
      return acc
    },
    { weightToDistribute: 0, usersToDistribute: 0 }
  )

  const indexDaysWithDayOff: number[] = []
  days.forEach((day, index) => {
    const userProps = day.users.find(usr => usr.userId === user?.userId._id)
    const isDayOff = userProps?.dayOff.enable

    if (isDayOff) {
      indexDaysWithDayOff.push(index)
    }
  })

  const workingDays: IDays[] = []
  const dayOffDays: IDays[] = []

  days.forEach(day => {
    const userStatus = day.users.find(usr => usr.userId === user?.userId._id)

    if (userStatus) {
      userStatus.dayOff.enable ? dayOffDays.push(day) : workingDays.push(day)
    }
  })

  const handleClickOnDay = (day: Date, { selected }: DayModifiers) => {
    const isPreviousDay = new Date(day).getDate() < new Date().getDate()
    if (!isDaysBlocked || !isPreviousDay) {
      const currDayIndex = days.findIndex(
        prevDay => new Date(prevDay.date).getUTCDate() === day.getUTCDate()
      )
      if (
        user &&
        currentMonthGoal.days
          .filter(day => day.working)
          .map(dayM => new Date(dayM.date).getUTCDate())
          .includes(day.getUTCDate())
      ) {
        if (!selected) {
          const clickedDay = currentMonthGoal.days.find(
            eaDay => new Date(eaDay.date).getUTCDate() === day.getUTCDate()
          )

          if (clickedDay) {
            const isUserOnArray = clickedDay.users.find(
              usr => usr.userId === user.userId._id
            )

            if (isUserOnArray) {
              const formattedUsersDay = clickedDay.users.map(usr => {
                if (usr.userId === user.userId._id) {
                  return {
                    userId: usr.userId,
                    dayOff: {
                      enable: false
                    }
                  }
                }
                return usr
              })

              const formattedDay = {
                ...clickedDay,
                users: formattedUsersDay
              }

              const newDays = [...days, formattedDay].sort(
                (a, b) =>
                  new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
              )

              setDays(newDays)
            } else {
              const newUsers: IDaysUsers[] = [
                ...clickedDay.users,
                {
                  userId: user.userId._id,
                  dayOff: {
                    enable: false
                  }
                }
              ]

              const formattedDay = {
                ...clickedDay,
                users: newUsers
              }

              const newDays = [...days, formattedDay].sort(
                (a, b) =>
                  new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
              )

              setDays(newDays)
            }
          }
        } else if (selected && !indexDaysWithDayOff.includes(currDayIndex)) {
          // des-selecionar
          const newDays = days.map(eachDay => {
            const currDay = new Date(eachDay.date).getUTCDate()

            if (currDay === day.getUTCDate()) {
              const isUserOnArray = eachDay.users.find(
                usr => usr.userId === user.userId._id
              )
              const newUsers: IDaysUsers[] = []

              if (isUserOnArray) {
                const updatedUsers = eachDay.users.map(usr => {
                  if (usr.userId === user.userId._id) {
                    return {
                      ...usr,
                      dayOff: {
                        enable: true
                      }
                    }
                  }

                  return usr
                })

                newUsers.push(...updatedUsers)
              } else {
                const newUser = {
                  userId: user.userId._id,
                  dayOff: {
                    enable: true
                  }
                }
                const updatedUsers = [...eachDay.users, newUser]

                newUsers.push(...updatedUsers)
              }

              return {
                ...eachDay,
                users: newUsers
              }
            }

            return eachDay
          })

          setDays(newDays)
        } else {
          const newDays = days.filter(
            eachDay => !DateUtils.isSameDay(new Date(eachDay.date), day)
          )

          setDays(newDays)
        }
      }
    }
  }

  return (
    <Container className={classes.root}>
      {!!user && (
        <Dialog open={modal} onClose={onClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              height: 40,
              display: isLoading ? 'block' : 'none'
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant='determinate'
                sx={theme => ({
                  color: theme.palette.grey[200]
                })}
                size={40}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant='indeterminate'
                disableShrink
                sx={() => ({
                  animationDuration: '550ms',
                  position: 'absolute',
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round'
                  },
                  color: '#308fe8'
                })}
                size={40}
                thickness={4}
              />
            </Box>
          </Box>

          <DialogTitle id='form-dialog-title'>
            Editar {user?.userId?.name.complete || '...'}
          </DialogTitle>
          <DialogContent>
            <Typography>
              Defina o peso e os dias que
              <strong> {user?.userId?.name.first || '...'}</strong> irá
              trabalhar. <br />A meta do vendedor será proporcional a esses
              parâmetros.
            </Typography>
            {user && currentMonthGoal ? (
              <div style={{ marginTop: 20 }}>
                <Grid container className={classes.margin}>
                  <div
                    style={{
                      borderRight: '2px solid #EEEEEE',
                      paddingRight: 20
                    }}
                  >
                    <Picker>
                      <DayPicker
                        month={new Date(currentMonthGoal.month)}
                        canChangeMonth={false}
                        months={dayPickerBR.months}
                        renderDay={day => {
                          const isPreviousDay =
                            new Date(day).getMonth() < new Date().getMonth() ||
                            new Date(day).getDate() < new Date().getDate()

                          const currentDay = currentMonthGoal?.days.find(
                            currDay =>
                              new Date(currDay.date).getUTCDate() ===
                              new Date(day).getUTCDate()
                          )

                          const isStoreClosedOnThisDay =
                            !currentDay || !currentDay.working

                          const currentDayAux = days.find(
                            currDay =>
                              new Date(currDay.date).getUTCDate() ===
                              new Date(day).getUTCDate()
                          )

                          const currentUser = currentDayAux?.users.find(
                            eachUser => eachUser.userId === user.userId._id
                          )

                          const isBlockedDay = isPreviousDay && isDaysBlocked
                          const isNonWorkingDay = daysNotWorked.includes(
                            day.toISOString()
                          )

                          const tooltipText = isBlockedDay
                            ? 'Dia anterior. Desbloqueie para edição'
                            : isStoreClosedOnThisDay
                            ? 'Loja fechada'
                            : isNonWorkingDay
                            ? 'Folga sem meta'
                            : currentUser?.dayOff.enable
                            ? 'Folga com meta'
                            : 'Irá trabalhar'

                          return (
                            <TooltipMUI
                              title={
                                <Typography
                                  style={{ color: '#fff', fontSize: 14 }}
                                >
                                  {tooltipText}
                                </Typography>
                              }
                            >
                              <p>{new Date(day).getDate()}</p>
                            </TooltipMUI>
                          )
                        }}
                        weekdaysLong={dayPickerBR.weekdaysLong}
                        weekdaysShort={dayPickerBR.weekdaysShort}
                        firstDayOfWeek={0}
                        labels={dayPickerBR.labels}
                        {...(isDaysBlocked &&
                          isCurrentMonthEqualsToMonthGoal && {
                            disabledDays: {
                              before: new Date()
                            }
                          })}
                        modifiers={{
                          working: day => {
                            const stringDays = days
                              .filter((elDay, index) => {
                                const currentDay = days.find(
                                  currDay =>
                                    new Date(currDay.date).getUTCDate() ===
                                    new Date(elDay.date).getUTCDate()
                                )

                                const isStoreClosedOnThisDay =
                                  !currentDay || !currentDay.working

                                return (
                                  !isStoreClosedOnThisDay &&
                                  !indexDaysWithDayOff.includes(index)
                                )
                              })
                              .map(day => new Date(day.date).getUTCDate())

                            const isSameMonth =
                              new Date(currentMonthGoal.month).getMonth() ===
                              day.getMonth()

                            return (
                              isSameMonth &&
                              stringDays.includes(day.getUTCDate())
                            )
                          },
                          notWorked: day =>
                            daysNotWorked.includes(day.toISOString()),
                          dayOff: day => {
                            const isADayFromDifferentMonth =
                              new Date(day).getMonth() !==
                              new Date(currentMonthGoal.month).getMonth()
                            const stringDays = days
                              .filter((day, index) =>
                                indexDaysWithDayOff.includes(index)
                              )
                              .map(day => new Date(day.date).getUTCDate())
                            return (
                              !isADayFromDifferentMonth &&
                              stringDays.includes(day.getUTCDate())
                            )
                          },
                          pastDay: day => {
                            const isSameMonth =
                              new Date(currentMonthGoal.month).getMonth() ===
                              day.getMonth()

                            const isPreviousDay =
                              new Date(day).getMonth() <
                                new Date().getMonth() ||
                              new Date(day).getDate() < new Date().getDate()
                            return isSameMonth && isDaysBlocked && isPreviousDay
                          },
                          storeClosed: day =>
                            daysWithStoreClosed.includes(day.toISOString()),
                          daysFromAnotherMonth: day => {
                            const isSameMonth =
                              new Date(currentMonthGoal.month).getMonth() ===
                              day.getMonth()

                            return !isSameMonth
                          }
                        }}
                        selectedDays={days
                          .filter(day => day.working)
                          .map(day => new Date(day.date))}
                        onDayClick={handleClickOnDay}
                      />
                    </Picker>

                    <DayPickerDescription>
                      <TooltipMUI
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            O vendedor terá meta para o dia
                          </Typography>
                        }
                      >
                        <div data-for='days-to-work'>
                          Irá trabalhar
                          <span>
                            <LensIcon
                              fontSize='small'
                              style={{
                                color: '#93C5FD'
                              }}
                            />
                            {workingDays.length} dias
                          </span>
                        </div>
                      </TooltipMUI>

                      <TooltipMUI
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            A meta do vendedor desses dias é distribuída nos
                            outros dias em que for trabalhar
                          </Typography>
                        }
                      >
                        <div data-for='weight-selection-left'>
                          Folga c/ meta
                          <span>
                            <TripOriginIcon
                              fontSize='small'
                              style={{
                                color: '#93C5FD'
                              }}
                            />
                            {dayOffDays.length} dias
                          </span>
                        </div>
                      </TooltipMUI>
                      <TooltipMUI
                        color='#000'
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            A meta do vendedor desses dias é distribuída para os
                            outros vendedores
                          </Typography>
                        }
                      >
                        <div>
                          Folga s/ meta
                          <span>
                            <LensIcon
                              fontSize='small'
                              style={{
                                color: '#EEEEF1'
                              }}
                            />
                            {daysNotWorked.length} dias
                          </span>
                        </div>
                      </TooltipMUI>
                    </DayPickerDescription>

                    <DayPickerFooterContainer>
                      <CustomButton
                        onClick={() => {
                          const allDaysWorked = currentMonthGoal.days.map(
                            day => {
                              const isPreviousDay =
                                new Date(day.date).getDate() <
                                new Date().getDate()

                              if (isDaysBlocked && isPreviousDay) {
                                return day
                              }

                              const hasUserInCurrentDay = day.users.find(
                                usr => usr.userId === user.userId._id
                              )

                              if (hasUserInCurrentDay) {
                                const usersArray = day.users.map(usr => {
                                  if (usr.userId === user.userId._id) {
                                    return {
                                      ...usr,
                                      dayOff: {
                                        enable: false
                                      }
                                    }
                                  }

                                  return usr
                                })

                                return {
                                  ...day,
                                  users: usersArray
                                }
                              }

                              const usersArrayWithCurrentUser = [
                                ...day.users,
                                {
                                  dayOff: {
                                    enable: false
                                  },
                                  userId: user.userId._id
                                }
                              ]

                              return {
                                ...day,
                                users: usersArrayWithCurrentUser
                              }
                            }
                          )

                          setDays(allDaysWorked)
                        }}
                      >
                        <svg
                          style={{ marginRight: 4 }}
                          width='18'
                          height='11'
                          viewBox='0 0 18 11'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M13.5 1.25L12.4425 0.192505L7.6875 4.9475L8.745 6.005L13.5 1.25ZM16.68 0.192505L8.745 8.1275L5.61 5L4.5525 6.0575L8.745 10.25L17.745 1.25L16.68 0.192505ZM0.307495 6.0575L4.5 10.25L5.5575 9.1925L1.3725 5L0.307495 6.0575Z'
                            fill='#37474F'
                          />
                        </svg>
                        Todos os dias
                      </CustomButton>
                      {isCurrentMonthEqualsToMonthGoal && (
                        <TooltipMUI
                          title={
                            <Typography style={{ color: '#fff', fontSize: 14 }}>
                              {isDaysBlocked ? 'Desbloquear' : 'Bloquear'} dias
                              anteriores
                            </Typography>
                          }
                        >
                          <LockButton
                            onClick={() => setIsDaysBlocked(prev => !prev)}
                          >
                            {isDaysBlocked ? <LockIcon /> : <LockOpenIcon />}
                          </LockButton>
                        </TooltipMUI>
                      )}
                    </DayPickerFooterContainer>
                  </div>
                  <Grid style={{ paddingLeft: 20 }} item xs>
                    <Item>
                      <ItemTitle>Vendedor recebe DSR?</ItemTitle>
                      <DSRCheckbox
                        onChange={(_, checked) => {
                          setSeller({ ...seller, dsr: checked })
                        }}
                        label={seller.dsr ? 'Receberá' : 'Não receberá'}
                        dsr={String(seller.dsr)}
                        control={
                          <Checkbox
                            disableRipple
                            color='primary'
                            checked={seller.dsr}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon color='primary' />}
                          />
                        }
                      />
                    </Item>
                    <Item>
                      <ItemTitle>Peso do vendedor</ItemTitle>
                      <WeightSelection>
                        <Tooltip
                          fixed
                          id='weight-selection-left'
                          content={
                            <p>
                              Peso ajustado automaticamente em referência aos
                              outros vendedores
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-left'
                          onClick={() => {
                            setTab(0)
                            setSeller(state => ({
                              ...state,
                              edited: true,
                              weightLocked: false,
                              goalWeight: currentMonthGoal.distribute
                                ? remainWeight.weightToDistribute.toFixed(2)
                                : 100
                            }))
                          }}
                          selected={tab === 0}
                        >
                          <SpeedIcon fontSize='small' />
                          <span>Auto</span>
                        </WeightSelectionItem>

                        <Tooltip
                          fixed
                          id='weight-selection-right'
                          content={
                            <p>
                              O peso do vendedor é fixado no valor inserido
                              abaixo
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-right'
                          onClick={() => {
                            setSeller(state => {
                              return {
                                ...state,
                                goalWeight: (user.goalWeight ?? 1) * 100,
                                edited: true,
                                weightLocked: true
                              }
                            })
                            setTab(1)
                          }}
                          right
                          selected={tab === 1}
                        >
                          <LockIcon fontSize='small' />
                          <span>Fixado</span>
                        </WeightSelectionItem>
                      </WeightSelection>
                      <InputContainer>
                        <input
                          maxLength={7}
                          value={
                            !user.active && !seller.edited
                              ? remainWeight.weightToDistribute.toFixed(3)
                              : typeof seller.goalWeight === 'number'
                              ? seller.goalWeight.toFixed(3)
                              : seller.goalWeight
                          }
                          style={{ color: tab === 0 ? '#FFC700' : '#000' }}
                          pattern='[0-9]*'
                          onChange={e => {
                            const { value } = e.target
                            setSeller(state => ({
                              ...state,
                              edited: true,
                              weightLocked: true,
                              goalWeight:
                                Number(value) > 300
                                  ? 300
                                  : value.replace(',', '.')
                            }))
                            setTab(1)
                          }}
                          data-clarity-unmask='true'
                        />
                        <label>%</label>
                      </InputContainer>

                      <SliderContainer>
                        <span>0%</span>
                        <Slider
                          className='slider'
                          defaultValue={100}
                          step={5}
                          min={0}
                          max={300}
                          onChange={(e, value) => {
                            setSeller(state => ({
                              ...state,
                              goalWeight: value as number,
                              edited: true,
                              weightLocked: true
                            }))
                            setTab(1)
                          }}
                          value={Math.round(
                            !user.active && !seller.edited
                              ? Number(
                                  remainWeight.weightToDistribute.toFixed(2)
                                )
                              : Number(seller.goalWeight)
                          )}
                          sx={{
                            '& .MuiSlider-track': {
                              color: tabColorOptions,
                              transition: '0.2s ease-in'
                            },
                            '& .MuiSlider-thumb': {
                              borderColor: tabColorOptions,
                              transition: '0.2s ease-in'
                            },
                            '& .MuiSlider-mark': {
                              backgroundColor: tabColorOptions,
                              transition: '0.2s ease-in'
                            }
                          }}
                          marks={[
                            { value: 0 },
                            { value: 100 },
                            { value: 200 },
                            { value: 300 }
                          ]}
                        />
                        <span>300%</span>
                      </SliderContainer>
                    </Item>
                    <Item>
                      <ItemTitle>Meta individual / Remuneração</ItemTitle>
                      {days &&
                        Array.isArray(accGoal) &&
                        accGoal.length > 0 &&
                        !!user &&
                        accGoal.map((goal, index) => {
                          const {
                            complement,
                            dsr,
                            goalName,
                            sumCommission,
                            salary,
                            commission,
                            bonus,
                            fixedCommission
                          }: IInitialCommission = goal.commission

                          const userGoalTooltipInfos = {
                            ...(fixedCommission?.value && {
                              'Comissão base': formatPrice(
                                fixedCommission.value
                              )
                            }),
                            ...(commission?.value && {
                              Comissão: formatPrice(commission.value)
                            }),
                            ...(dsr && { DSR: formatPrice(dsr) }),
                            ...(salary && { Piso: formatPrice(salary) }),
                            ...(complement && {
                              Complemento: formatPrice(complement)
                            }),
                            ...(bonus?.value && {
                              Bônus: formatPrice(bonus.value)
                            })
                          }

                          const remuneration = sumCommission || 0

                          return (
                            <GoalCard key={goalName}>
                              <h1>{goalName}</h1>
                              <h2>
                                <strong
                                  {...(index === 0 && {
                                    'data-cy': 'mainGoalValueGoalUserModal'
                                  })}
                                  style={{
                                    ...(goal.value <= 0 && {
                                      color: '#E64C3D'
                                    })
                                  }}
                                >
                                  {formatPrice(goal.value < 0 ? 0 : goal.value)}
                                </strong>{' '}
                                <span
                                  {...(index === 0 && {
                                    'data-cy':
                                      'mainCommissionValueGoalUserModal'
                                  })}
                                >
                                  /{' '}
                                  <Tooltip
                                    id={`user-goal-commission-${goal._id}`}
                                    content={
                                      <div>
                                        <strong
                                          style={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontSize: '14px'
                                          }}
                                        >
                                          Remuneração Total <br />
                                        </strong>
                                        {Object.entries(
                                          userGoalTooltipInfos
                                        ).map(([key, value]) => (
                                          <p
                                            key={key}
                                            style={{ marginBottom: '2px' }}
                                          >
                                            <strong>{key}: </strong> {value}
                                            <br />
                                          </p>
                                        ))}
                                        <p style={{ marginTop: '10px' }}>
                                          <strong>Total: </strong>
                                          {formatPrice(remuneration)}
                                        </p>
                                      </div>
                                    }
                                    place='bottom'
                                  />
                                  {formatPrice(remuneration)}
                                  <ErrorOutlineIcon
                                    data-tip
                                    data-for={`user-goal-commission-${goal._id}`}
                                    style={{
                                      color: '#C4C4C4',
                                      fontSize: 20,
                                      marginLeft: 5,
                                      marginBottom: -5
                                    }}
                                  />
                                </span>
                              </h2>
                            </GoalCard>
                          )
                        })}
                    </Item>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <DialogContentText>Carregando...</DialogContentText>
            )}
          </DialogContent>
          <ActionButtons>
            {user?.active &&
              (usersWorkingLength > 1 ? (
                <Button
                  onClick={() => {
                    confirm({
                      title: 'Remover vendedor da meta',
                      description: `Você tem certeza que deseja remover ${user.userId.name.first} da meta?`,
                      confirmationText: 'Sim',
                      cancellationText: 'Não'
                    })
                      .then(() => {
                        handleRemoveUser(user)
                      })
                      .catch(() => {})
                  }}
                  style={{
                    color: '#e74c3c'
                  }}
                >
                  <PersonRemoveIcon style={{ marginRight: 4 }} />
                  Remover da meta
                </Button>
              ) : (
                <BootstrapTooltip title='A meta deve ter pelo menos um vendedor'>
                  <Button
                    style={{
                      color: '#e74c3c',
                      cursor: 'not-allowed',
                      opacity: 0.6
                    }}
                  >
                    <PersonRemoveIcon style={{ marginRight: 4 }} />
                    Remover da meta
                  </Button>
                </BootstrapTooltip>
              ))}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                data-cy='cancelGoalUserModalButton'
                style={{ margin: '0 5px' }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={days?.length === 0}
                onClick={() => {
                  confirm({
                    title: 'Salvar',
                    description: `Atenção: A meta de ${user.userId.name.first} será proporcional aos dias trabalhados.`,
                    confirmationText: 'Ok',
                    cancellationText: 'Cancelar'
                  })
                    .then(() => {
                      handleUpdateUserWeight(
                        days,
                        user,
                        tab === 1 ? 'fixed' : 'auto',
                        Number(seller.goalWeight) / 100,
                        currentMonthGoal._id,
                        false
                      )
                      onClose()
                    })
                    .catch(() => {})
                }}
                type='submit'
              >
                {loading && <CircularProgress size={24} />}
                {user?.active ? 'Salvar' : 'Adicionar à meta'}
              </Button>
            </div>
          </ActionButtons>
        </Dialog>
      )}
    </Container>
  )
}
