import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

// Components
import {
  AccountSettings,
  StoreSettings,
  IntegrationSettings
} from './components'

// Redux Actions
import {
  userEditRequest,
  userChangePasswordRequest,
  userChangePasswordOpenModal,
  userChangePasswordCloseModal,
  userAvatarUpdateRequest
} from '../../store/modules/user/actions'
import {
  storeRequest,
  storeUpdateRequest,
  storeAvatarUpdateRequest
} from '../../store/modules/store/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Settings = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { storeId } = match.params
  const token = localStorage.getItem('@NeoPro:token')

  const [hasEditedFormInfo, setHasEditedFormInfo] = useState(false)

  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)
  const storeLoading = useSelector(state => state.store.loading)
  const userLoading = useSelector(state => state.user.loading)
  const passwordModal = useSelector(state => state.user.passwordModal)

  const userType = useMemo(() => {
    return user?.stores.find(userStore => userStore.storeId._id === store?._id)
      ?.type
  }, [user?.stores, store?._id])

  // State
  const [tab, setTab] = useState('storeSettings')

  useEffect(() => {
    if (userType === 'cashier') {
      setTab('accountSettings')
    }
  }, [userType])

  // Tabs
  const tabs = [
    { value: 'storeSettings', label: 'Loja' },
    { value: 'accountSettings', label: 'Usuário' },
    { value: 'integrations', label: 'Integrações' }
  ]

  // Função para atualizar os dados do usuário
  const handleUpdateUser = userData => {
    dispatch(userEditRequest(userData, token, user._id))
  }

  // Função para atualizar a senha do usuário
  const handleUpdatePassword = userPasswords => {
    dispatch(userChangePasswordRequest(userPasswords, token, user._id))
  }

  // Função para atualizar os dados da loja
  const handleUpdateStore = storeData => {
    dispatch(storeUpdateRequest(storeId, storeData, token))
  }

  const handleUpdateStoreAvatar = dataRequest => {
    dispatch(storeAvatarUpdateRequest(dataRequest, storeId, token))
  }

  const handleUpdateUserAvatar = dataRequest => {
    dispatch(userAvatarUpdateRequest(dataRequest, user._id, token))
  }

  useEffect(() => {
    // Verificar se existe uma loja no redux, se nao existir fazer uma requisição
    if (!store) {
      dispatch(
        storeRequest(storeId, window.localStorage.getItem('@NeoPro:token'))
      )
    }
  }, [])

  const handleChangeTab = newTab => {
    if (hasEditedFormInfo) {
      const forceChangeTab = window.confirm(
        'Existem alterações que não foram salvas, você deseja realmente trocar de aba?'
      )
      if (forceChangeTab) {
        setHasEditedFormInfo(false)
        setTab(newTab)
      }
    } else {
      setTab(newTab)
    }
  }

  window.onbeforeunload = () => {
    const currentUrl = window.location.href
    if (hasEditedFormInfo && currentUrl.includes('/settings')) {
      return 'As alterações que você fez talvez não sejam salvas.'
    }
  }

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        onChange={(e, value) => handleChangeTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map(tab => (
          <Tab
            disabled={userType === 'cashier' && tab.value !== 'accountSettings'}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'storeSettings' && store && (
          <StoreSettings
            loading={storeLoading}
            handleSubmitData={handleUpdateStore}
            setHasEditedFormInfo={setHasEditedFormInfo}
            handleUpdateStoreAvatar={dataRequest =>
              handleUpdateStoreAvatar(dataRequest)
            }
            store={store}
          />
        )}
        {tab === 'accountSettings' && (
          <AccountSettings
            loading={userLoading}
            user={user}
            setHasEditedFormInfo={setHasEditedFormInfo}
            passwordModal={passwordModal}
            handleSubmitData={handleUpdateUser}
            handleUpdatePassword={handleUpdatePassword}
            handleOpenModal={() => dispatch(userChangePasswordOpenModal())}
            handleCloseModal={() => dispatch(userChangePasswordCloseModal())}
            handleUpdateUserAvatar={dataRequest =>
              handleUpdateUserAvatar(dataRequest)
            }
          />
        )}
        {tab === 'integrations' && (
          <IntegrationSettings
            storeIntegrationData={store && store.integration}
          />
        )}
      </div>
    </div>
  )
}

export default Settings
