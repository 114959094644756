import { UploadFile as UploadFileIcon } from '@mui/icons-material'

import { SelectedMediaProps } from '../../'

import {
  AsideContainer,
  BadgeNumber,
  Label,
  Media,
  MediaPreviewContainer,
  MediasCarrousel,
  Video
} from './styles'
import { IStorylineTable } from 'store/modules/storylines/types'

interface PreviewMediaListProps {
  selectedMedia: SelectedMediaProps
  storyData: IStorylineTable
  handleEditMedia: (url: string) => void
}

export const PreviewMediaList = ({
  handleEditMedia,
  selectedMedia,
  storyData
}: PreviewMediaListProps) => {
  const title = storyData.name || 'Digite um título'

  return (
    <AsideContainer>
      <h2>{title}</h2>
      <MediasCarrousel>
        {storyData.stories?.map((story, index) => (
          <MediaPreviewContainer
            key={story.url}
            onClick={() => {
              if (
                story.url !== selectedMedia.url &&
                index !== selectedMedia.index
              ) {
                handleEditMedia(story.url)
              }
            }}
          >
            <Label>
              <BadgeNumber>{index + 1}</BadgeNumber>
              {story.type === 'video' ? (
                <Video src={story.url} disablePictureInPicture id='video-tag'>
                  <source id='video-source' src={story.url} />
                  Your browser does not support the video tag.
                </Video>
              ) : (
                <Media src={story.url} alt='Imagem do story'>
                  {!story.url && (
                    <UploadFileIcon
                      style={{
                        width: 29,
                        height: 38
                      }}
                    />
                  )}
                </Media>
              )}
            </Label>
          </MediaPreviewContainer>
        ))}
      </MediasCarrousel>
    </AsideContainer>
  )
}
