import { useEffect, useState } from 'react'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import { animateScroll as scroll } from 'react-scroll'
import { endOfMonth, set, startOfMonth } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { goalAddSuccess, goalListRequest } from 'store/modules/goal/actions'
import { useParams, useHistory } from 'react-router'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import ReviewCard from './Components/ReviewCard'
import ReviewItem from './Components/ReviewItem'
import Tooltip from 'components/Tooltip'
import UserDefaultPhoto from '../../UserDefaultPhoto'

import moment from 'moment'
import { monthRequest } from '../../../../../../../store/modules/report/actions'
import { inputListRequest } from '../../../../../../../store/modules/input/actions'
import { formatPrice, formatToPercentage } from 'utils/format'
import CreateGoalIcon from 'assets/Icons/CreateGoal'

import { Container, SellersGoalTable } from './styles'
import { Edit, Warning } from '@material-ui/icons'
import { clearCalendarGoalRedux } from 'store/modules/calendarGoal/actions'
import api from 'repositories/api'

const ReviewStep = ({ data, setActiveStep, storeGoal, updateGoal }) => {
  const [usersCommissionInfo, setUsersCommissionInfo] = useState()
  const history = useHistory()
  const { storeId, goalId } = useParams()

  const dispatch = useDispatch()

  const store = useSelector(state => state.store.store)
  const reportsList = useSelector(state => state.report.reportsList)
  const monthReport = useSelector(state => state.report.monthReport)
  const inputList = useSelector(state => state.input.inputList)

  const token = localStorage.getItem('@NeoPro:token')

  const month = new Date(data.month)
  month.setMonth(month.getMonth() + 1)
  month.setDate(0)

  const [users, setUsers] = useState([])
  const [reports, setReports] = useState()

  // Ao inicializar, se não tiver exibindo nenhum report, solicita os do mês
  useEffect(() => {
    if (!monthReport && !reportsList && !reports) {
      dispatch(monthRequest(storeId, token))
    } else {
      dispatch(inputListRequest(storeId, token))
    }
  }, [])

  useEffect(() => {
    // apresenta reports do intervalo selecionado
    if (reportsList) {
      setReports(reportsList)
    } else setReports(monthReport)
  }, [monthReport, reportsList])

  const [accGoal, setAccGoal] = useState([])

  // CurrentMonthGoal filtrado
  const currMonth = (obj => ({
    days: obj.days,
    usersWorking: obj.usersWorking,
    updatedAt: obj.updatedAt,
    mainGoals: obj.mainGoals,
    monthGoals: obj.monthGoals,
    distribute: obj.distribute,
    month: obj.month,
    activeUsers: obj.usersWorking.filter(user => user.active)?.length
  }))(data)

  const daysWithWeight = currMonth?.days.map(day => ({
    ...day,
    dayWeight:
      day.users?.length > 0
        ? day.users.reduce(
            (acc, seller) =>
              (acc +=
                currMonth.usersWorking.find(user => user.userId?._id === seller)
                  ?.goalWeight || 0),
            0
          )
        : 1 // Se não tiver nenhum vendedor, o peso do dia é 1
  }))

  const goalSeller = (actualSeller, uwSellersGoal) => {
    // Filtra os dias que o vendedor está ativo
    const currUserDays =
      daysWithWeight.length > 0 &&
      daysWithWeight.filter(day =>
        day.users?.includes(actualSeller.userId?._id)
      )

    // Essa função tem dupla funcionalidade:
    // Vendedor Inativo => Ela calcula a meta remanescente dele até o ultimo dia de venda
    // Vendedor Ativo => É o calculo da meta para o vendedor ativo, conforme cada vendedor inativo após a data de seus ultimos lançamentos.
    const addAccGoal = lastInput => {
      return (
        (currUserDays.length > 0 &&
          currUserDays?.reduce((acc, day) => {
            acc +=
              ((!uwSellersGoal &&
                day.users.some(
                  seller => seller === actualSeller.userId?._id
                )) ||
                (uwSellersGoal &&
                  currUserDays.some(modalDay => modalDay.date === day.date))) &&
              // Se o vendedor não tiver lastInput (ultimo dia de venda), significa que é um vendedor inativo, então irá calcular sua meta remanescente a partir do ultimo dia de venda
              // Se tiver ultimo dia de venda e se a data do ultimo dia de venda for menor que a data do dia atual, significa que o vendedor não vendeu nada, então a meta remanescente é a meta do vendedor
              (!lastInput ||
                (lastInput && new Date(day.date) > new Date(lastInput)))
                ? day.goal *
                  (uwSellersGoal ? 1 : actualSeller.goalWeight / day.dayWeight)
                : 0
            return acc
          }, 0)) ||
        0
      )
    }

    // Verificação é pra saber se a função ta sendo utilizada para o vendedor ativo ou inativo
    // Caso não tenha essa variavel (uwSellersGoal) é porque o vendedor ainda é um inativo.
    // uwSellersGoal => É a meta remanescente dos vendedores inativos
    if (uwSellersGoal) {
      const uwSellersGoalAfterExit =
        uwSellersGoal.length > 0 &&
        uwSellersGoal.map(uwSeller => {
          uwSeller.accGoalAfterExit = addAccGoal(uwSeller?.lastInput)
          return uwSeller
        })

      // Calcula todas as metas com suas respectivas distribuições (se for o modelo de meta distribuida) e também a distribuição dos vendedores inativos (complemento)
      const daysGoalAcc = (mainGoal, currGoal, i = 0, recalculatedGoal = 0) => {
        if (currUserDays.length > 0) {
          const complementSum = (day, dayWeight, i) => {
            if (
              !uwSellersGoalAfterExit[i]?.lastInput ||
              day.date > uwSellersGoalAfterExit[i]?.lastInput
            ) {
            }
            return uwSellersGoalAfterExit?.length > 0 &&
              (!uwSellersGoalAfterExit[i]?.lastInput ||
                day.date > uwSellersGoalAfterExit[i]?.lastInput)
              ? // (((Residual Inicial * (Metal indivudal atual / Meta individual principal)) - Venda total removido) * Novo peso do dia * Peso do vendedor no dia)
                (uwSellersGoalAfterExit[i].remainingGoal *
                  (currGoal.value / mainGoal.value) -
                  uwSellersGoalAfterExit[i].sold) *
                  dayWeight *
                  (day.goal / uwSellersGoalAfterExit[i].accGoalAfterExit)
              : 0
          }

          // Se não possuir usuário ativo, o peso será 1
          const dayWeight =
            currMonth.activeUsers === 0
              ? 1
              : actualSeller.goalWeight / currMonth.activeUsers

          if (i === 0) {
            recalculatedGoal += currUserDays.reduce((acc, day) => {
              acc += currMonth.distribute
                ? day.goal *
                    (currGoal.value / mainGoal.value) *
                    (actualSeller.goalWeight / day.dayWeight) +
                  complementSum(day, dayWeight, i)
                : ((currGoal.value * day.goal) / currMonth.mainGoals.total) *
                  actualSeller.goalWeight

              return acc
            }, 0)
          } else {
            recalculatedGoal += currUserDays.reduce((acc, day) => {
              return (acc += complementSum(day, dayWeight, i))
            }, 0)
          }

          return uwSellersGoalAfterExit?.length > 0 &&
            uwSellersGoalAfterExit.length - 1 !== i
            ? daysGoalAcc(mainGoal, currGoal, i + 1, recalculatedGoal)
            : recalculatedGoal
        } else {
          return 0
        }
      }

      return {
        seller: actualSeller,
        goals: currMonth.monthGoals.map(currGoal => ({
          ...currGoal,
          value: daysGoalAcc(currMonth.monthGoals[0], currGoal)
        }))
      }
    } else {
      // Meta acumulada do inativo
      return addAccGoal()
    }
  }

  const getMonthGoals = () => {
    const sellerInactiveWithSold = []
    const result = currMonth.usersWorking // Ordena os vendedores por ativo ou inativo
      .sort((a, b) => a.active - b.active)
      .reduce((acc, curr) => {
        // Informações do vendedor INATIVO
        if (!curr.active) {
          // Se for um vendedor inativo, irei calcular o quanto ele vendeu e o seu remanescente
          let sellerSold = 0
          const inputsFromUwSeller = inputList.filter(input => {
            // Conferir se estão por ordem decrescente de data
            const actualMonth =
              moment(input.date).month() === moment(currMonth.month).month()

            // Vendedor atual do input
            const currSeller = input.sellers.find(
              seller =>
                seller.sellerId?._id === curr.userId?._id && seller.sold > 0
            )

            // Caso tenha mês atual e vendedor do input, é acrescido ao total de vendas do vendedor
            if (actualMonth && currSeller) {
              sellerSold += currSeller.sold
              return input
            }
          })

          // Meta remanescente do vendedor (meta do vendedor menos o total de vendas do vendedor)
          const remainingGoal = goalSeller(curr)

          //  Calcular a meta restante para cada vendedor, caso a remanescente seja maior do que o que foi vendido por ele
          if (sellerSold < remainingGoal) {
            sellerInactiveWithSold.push({
              remainingGoal,
              sold: sellerSold,
              lastInput: inputsFromUwSeller[0]?.date
            })
          }
        } else {
          const goal = goalSeller(curr, sellerInactiveWithSold)
          acc.push(goal)
        }
        return acc
      }, [])

    if (result) {
      setAccGoal(result)
      return result
    }
  }

  useEffect(() => {
    if (inputList) getMonthGoals()
  }, [inputList])

  useEffect(() => {
    const getUsersCommissionGoalInfo = async () => {
      try {
        const response = await api.axios.get(
          `report/goal/${data._id}/users-commission-goals`
        )
        setUsersCommissionInfo(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    getUsersCommissionGoalInfo()
  }, [data._id])

  const valueMultiply = data.monthGoals[0].value * users.length
  const valueTotalGoal = data.mainGoals.total

  const getCommission = ({ goal, commission, weight }) => {
    return goal * commission * weight
  }

  const getDSR = ({ goal, commission, weight, dsr, days }) => {
    return (getCommission({ goal, commission, weight }) / (days - dsr)) * dsr
  }

  const getBonus = ({ isPercent, bonus, typeBonus, goal }) => {
    return isPercent
      ? bonus * (typeBonus === 'store' ? data.mainGoals.total : goal)
      : bonus
  }

  const getSalary = ({ goal, base, commission, weight }) => {
    return (
      (data.salary.directCommission ? 0 : base) +
      getCommission({ goal, commission, weight })
    )
  }

  const getStoreSalary = ({ base, storeGoal, commission }) => {
    return (data.salary.directCommission ? 0 : base) + storeGoal * commission
  }

  useEffect(() => {
    if (store) {
      // Usuários ativos no mês e que estão vinculados a loja
      setUsers(
        store.users.filter(({ _id }) =>
          data.usersWorking.some(
            user => user.active && user.userId?._id === _id
          )
        )
      )
    }
  }, [store, data.usersWorking])

  useEffect(() => {
    if (data.sections.length === 0) {
      updateGoal({
        sections: [
          {
            start: set(startOfMonth(new Date(data.month)), {
              hours: 12,
              minutes: 0,
              seconds: 0,
              milliseconds: 0
            }).toISOString(),
            end: set(endOfMonth(new Date(data.month)), {
              hours: 12,
              minutes: 0,
              seconds: 0,
              milliseconds: 0
            }).toISOString()
          }
        ]
      })
    }
  }, [data.sections])

  useEffect(() => scroll.scrollToTop(), [])

  const showWarningModal =
    valueMultiply < valueTotalGoal - 0.03 ||
    valueMultiply > valueTotalGoal + 0.03

  const goals = usersCommissionInfo
    ?.find(userCommission => userCommission.active)
    ?.goals?.map(goal => ({
      name: goal.name,
      value: goal.value
    }))

  return (
    <Container>
      <h1>Revise a meta para: {store.name}</h1>
      <ReviewCard
        name='Mês'
        items={
          <ReviewItem
            title='Mês da meta'
            value={format(new Date(data.month), 'MMMM/yyyy', { locale: ptBR })}
          />
        }
        setActiveStep={() => setActiveStep(0)}
      />
      <ReviewCard
        name='Comissão'
        items={
          <>
            <ReviewItem
              title='Tipo de Comissão'
              value={`${
                data.salary.directCommission
                  ? 'Comissão direta'
                  : 'Piso salarial + comissão'
              }`}
            />
            <ReviewItem
              title='Período da meta'
              value={
                data.sections.length > 1 ? 'Vários períodos' : 'Período único'
              }
              subValue={`${data.sections.length}`}
            />
            <ReviewItem
              title='Base da comissão'
              value={`${
                data.salary.splitCommission
                  ? 'Resultado por período'
                  : 'Resultado por mês'
              }`}
            />
            <ReviewItem
              title='Distribuição da meta'
              value={`${data.distribute ? 'Meta distribuída' : 'Meta fixa'}`}
            />
            <ReviewItem
              title='Recebimento da comissão'
              value={`${
                data.config.commission.storeSold
                  ? 'Total da loja'
                  : 'Meta individual'
              }`}
            />
          </>
        }
        setActiveStep={() => setActiveStep(1)}
      />
      <ReviewCard
        name='Valores'
        items={
          <>
            <ReviewItem
              title='DSR'
              value={data.dsr === 0 ? 'Inativo' : data.dsr}
            />
            <ReviewItem
              title='Piso Salarial'
              value={formatPrice(data.salary.base)}
            />
            {data.salary.complement && (
              <ReviewItem title='Complemento do salário' value='Ativo' />
            )}
            <ReviewItem
              title='Meta da loja'
              value={formatPrice(data.mainGoals.total)}
              valueTextColor='#27AE60'
            />
            <ReviewItem
              title='Meta de ticket médio'
              value={formatPrice(data.mainGoals.ticket)}
              valueTextColor='#27AE60'
            />
            <ReviewItem
              title='Meta de preço médio'
              value={formatPrice(data.mainGoals.price)}
              valueTextColor='#27AE60'
            />
          </>
        }
        setActiveStep={() => setActiveStep(2)}
      />
      <ReviewCard
        name='Meta'
        items={
          <>
            {data.config?.commission?.fixed?.commission > 0 && (
              <ReviewItem
                title='Comissão base'
                spanDataCy='commissionBaseSpanReview'
                value={formatToPercentage(
                  data.config?.commission?.fixed?.commission * 100
                )}
              />
            )}
            <ReviewItem
              title='Dias de loja aberta'
              value={data.days?.reduce((acc, curr) => {
                return (acc += curr.working)
              }, 0)}
            />

            {/* Verifica se o valor da meta multiplicado pela quantidade de
            usuários ativos está entre a margem de erro (0.03) do valor total da
            meta. */}

            {showWarningModal && (
              <div className='distribute-message'>
                <Warning style={{ color: '#700c0c' }} />
                <p>
                  Aconselhamos que o valor da Meta Principal para os vendedores
                  (
                  <strong>
                    {`${data.monthGoals[0].name} ${formatPrice(
                      data.monthGoals[0].value
                    )}`}
                  </strong>
                  ) seja o valor da Meta da loja dividida pelo número de
                  vendedores que participarão da meta. Ex.:
                  <strong>
                    {`${formatPrice(data.mainGoals.total)} / ${
                      users.length
                    } = ${formatPrice(data.mainGoals.total / users.length)}`}
                  </strong>
                  <br />
                  <br />
                  <strong>
                    Edite clicando no <Edit style={{ fontSize: 13 }} /> de Meta
                    para alterar as Metas individuais dos vendedores.
                  </strong>
                </p>
              </div>
            )}
            <ReviewItem>
              <SellersGoalTable>
                <div className='top-header'>
                  <h2>Meta por vendedor</h2>
                  <div className='top-header-goals'>
                    {goals &&
                      goals.length > 0 &&
                      goals.map((goal, i) => (
                        <div key={i} className='goal'>
                          <h1>
                            {goal.name.length > 15
                              ? goal.name.substring(0, 15 - 3) + '...'
                              : goal.name}
                          </h1>
                          <span
                            style={{
                              color: showWarningModal && i === 0 && '#700c0c',
                              fontWeight: showWarningModal && i === 0 && 'bold'
                            }}
                          >
                            {formatPrice(goal.value)}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='sellers-goals'>
                  {accGoal &&
                    !!usersCommissionInfo &&
                    accGoal.map((user, i) => {
                      const currentUser = usersCommissionInfo.find(
                        userInfo => userInfo.userId === user.seller.userId?._id
                      )

                      return (
                        data.days?.some(({ users }) =>
                          users.find(
                            usr => usr.userId === user.seller.userId?._id
                          )
                        ) &&
                        store.users.some(
                          ({ _id }) => user.seller.userId?._id === _id
                        ) &&
                        user.seller.active && (
                          <div
                            key={user.seller.userId?._id}
                            className='seller-card'
                          >
                            <div className='seller-card-info'>
                              <div className='seller-card-info-left'>
                                <h3>
                                  {user.seller.userId.name.complete.length > 16
                                    ? user.seller.userId.name.complete.substring(
                                        0,
                                        16 - 3
                                      ) + '...'
                                    : user.seller.userId.name.complete}
                                </h3>
                                <span>{`Peso ${(
                                  user.seller.goalWeight * 100
                                ).toFixed(2)}% / ${
                                  user.seller.dsr && data.dsr > 0
                                    ? 'Com DSR'
                                    : 'Sem DSR'
                                }`}</span>
                              </div>
                              {user.seller?.userId?.photo?.url ? (
                                <img
                                  style={{ backgroundSize: 'cover' }}
                                  src={user.seller.userId.photo.url}
                                />
                              ) : (
                                <UserDefaultPhoto
                                  username={user.seller.userId.name.first}
                                  size={30}
                                />
                              )}
                            </div>
                            <div className='seller-card-goals'>
                              {currentUser.goals.map((userGoal, index) => {
                                const {
                                  complement,
                                  dsr,
                                  sumCommission,
                                  bonus,
                                  commission,
                                  fixedCommission,
                                  salary
                                } = userGoal.initialCommission

                                const monthGoalTooltipInfos = {
                                  ...(fixedCommission?.value && {
                                    'Comissão base': formatPrice(
                                      fixedCommission.value
                                    )
                                  }),
                                  ...(commission?.value && {
                                    Comissão: formatPrice(commission.value)
                                  }),
                                  ...(dsr && { DSR: formatPrice(dsr) }),
                                  ...(salary && {
                                    Piso: formatPrice(salary)
                                  }),
                                  ...(complement && {
                                    Complemento: formatPrice(complement)
                                  }),
                                  ...(bonus?.value && {
                                    Bônus: formatPrice(bonus.value)
                                  })
                                }

                                return (
                                  <div
                                    key={userGoal._id + index}
                                    className='seller-card-goal'
                                  >
                                    <h3>{formatPrice(userGoal.value)}</h3>
                                    <div>
                                      <span>
                                        {/* Verificar se o previsto a receber é menor que o piso salárial*/}
                                        {formatPrice(sumCommission)}
                                      </span>
                                      <ErrorOutlineIcon
                                        data-tip
                                        data-for={`seller-card-tooltip-${i}-${index}`}
                                        style={{
                                          fontSize: 16,
                                          color: '#BDBDBD',
                                          marginLeft: 5
                                        }}
                                      />
                                      <Tooltip
                                        id={`seller-card-tooltip-${i}-${index}`}
                                        content={
                                          <div
                                            style={{
                                              flexDirection: 'column',
                                              justifyContent: 'flex-start',
                                              alignItems: 'flex-start'
                                            }}
                                          >
                                            <strong
                                              style={{
                                                display: 'block',
                                                fontSize: '14px'
                                              }}
                                            >{`${user.seller.userId.name.first} (${userGoal.name})`}</strong>
                                            <strong
                                              style={{
                                                display: 'block',
                                                marginTop: '10px',
                                                marginBottom: '4px',
                                                fontSize: '14px'
                                              }}
                                            >
                                              Remuneração prevista:
                                            </strong>
                                            <span>
                                              {Object.entries(
                                                monthGoalTooltipInfos
                                              ).map(([key, value]) => (
                                                <p
                                                  key={key}
                                                  style={{
                                                    fontSize: '13px',
                                                    marginBottom: '2px',
                                                    lineHeight: 1.5,
                                                    fontWeight: 'normal'
                                                  }}
                                                >
                                                  <strong>{key}: </strong>{' '}
                                                  {value}
                                                  <br />
                                                </p>
                                              ))}

                                              <p
                                                style={{
                                                  marginTop: '10px',
                                                  fontWeight: 'normal'
                                                }}
                                              >
                                                <strong>Total: </strong>
                                                {formatPrice(
                                                  sumCommission ?? 0
                                                )}
                                              </p>
                                            </span>
                                          </div>
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      )
                    })}
                </div>
              </SellersGoalTable>
            </ReviewItem>
            <ReviewItem
              title={`Períodos da meta (${data.sections.length})`}
              extraChildren={
                data.config.suggestion ? (
                  <h3 style={{ color: '#FAB300' }}>Com sugestão NeoPro</h3>
                ) : (
                  <h3 style={{ color: 'grey' }}>Sem sugestão NeoPro</h3>
                )
              }
              contentChildren={data.days
                .reduce(
                  (sections, day) => {
                    // Passa por todos os days já somando a meta dentro de cada section
                    if (day.working) {
                      const date = new Date(day.date)
                      sections = sections.map(section => {
                        if (date >= section.start && date <= section.end) {
                          section.goal += day.goal
                          section.days++
                        }
                        return section
                      })
                    }
                    return sections
                  },
                  data.sections.reduce((acc, { start, end }) => {
                    //Inicializa valor inicial do reduce apenas com sections completas e valores zerados
                    if (end)
                      acc.push({
                        start:
                          typeof start === 'string' ? new Date(start) : start,
                        end: typeof end === 'string' ? new Date(end) : end,
                        goal: 0,
                        days: 1
                      })
                    return acc
                  }, [])
                )
                .map((section, i) => (
                  <div key={i} className='content-card-content-children'>
                    <span className='content-card-value'>{`Período ${
                      i + 1
                    } - ${formatPrice(section.goal)}`}</span>
                    <span className='content-card-subvalue'>
                      {`${format(new Date(section.start), 'dd/MM')} a ${format(
                        new Date(section.end),
                        'dd/MM'
                      )}
                      - ${section.days - 1} dias úteis`}
                    </span>
                  </div>
                ))}
            />
          </>
        }
        setActiveStep={() => setActiveStep(3)}
      />

      <button
        data-cy='finishRegisterGoalButton'
        onClick={() => {
          storeGoal({ ...data, storeId })
          dispatch(goalAddSuccess(data))
          dispatch(goalListRequest(storeId, token))
          dispatch(clearCalendarGoalRedux())

          history.push(`/${storeId}/goals/${goalId}`)
        }}
        className='create-goal-button'
      >
        <span>Finalizar e voltar para o painel</span>
        <CreateGoalIcon />
      </button>
    </Container>
  )
}

export default ReviewStep
