import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { browserHistory } from 'App'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    height: '100%'
  }
}))

const Minimal = props => {
  const { children } = props
  const token = window.localStorage.getItem('@NeoPro:token')

  if (children.props.location.pathname.includes('/login') && token) {
    browserHistory.push('/stores')
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Minimal
