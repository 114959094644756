import { ChangeEvent, FocusEvent, useState, useEffect, useMemo } from 'react'
import {
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Box,
  Chip
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import {
  Avatar as AvatarMUI,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Card,
  Switch
} from '@mui/material'

import palette from 'theme/palette'

import 'react-day-picker/lib/style.css'
import { addMonths, differenceInDays, format } from 'date-fns'
import { Avatar } from '../Avatar'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { createNewSprintRequest } from 'store/modules/sprints/actions'
import { ICreateSprint } from 'store/modules/sprints/types'
import { IReportProps } from 'store/modules/report/types'
import { logout } from 'store/modules/user/actions'
import { useHistory } from 'react-router'
import jwtDecode from 'jwt-decode'
import { storesReportRequest } from 'store/modules/report/actions'
import { KeyboardArrowDown } from '@mui/icons-material'
import { toast } from 'react-toastify'
import CurrencyTextField from '../../../../components/CurrencyTextField'

interface SetNewSprintFieldValueFuncProps {
  field:
    | 'title'
    | 'description'
    | 'isRecurring'
    | 'goalType'
    | 'goal'
    | 'rewardType'
    | 'reward'
    | 'startDate'
    | 'endDate'
    | 'avatar'

  data: string | FormData | boolean
}

// const pra gente conseguir lidar com os fusos tanto no início quanto durante
// a manipulação dos dados pelo picker
const initialDateState = new Date(
  new Date(format(new Date(), 'yyyy-MM-dd')).getTime() +
    Math.abs(
      new Date(
        format(new Date().setDate(1), 'yyyy-MM-dd')
      ).getTimezoneOffset() * 60000
    )
).toString()

const initialNewSprintState: ICreateSprint = {
  title: '',
  description: '',
  goalType: 'financial',
  goal: '',
  rewardType: 'money',
  reward: '',
  avatar: undefined,
  isRecurring: false,
  startDate: initialDateState,
  endDate: initialDateState
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface CreateSprintModalProps {
  isOpen: boolean
  closeModal: () => void
  storeId: string
  isLoading: boolean
}

interface IUserProps {
  id: string
  cpf: string
  email: string
  iat: number
  phone: string
  type: string
  stores: {
    storeId: string
    type: string
  }[]
}

export const CreateSprintModal = ({
  isOpen,
  closeModal,
  storeId,
  isLoading
}: CreateSprintModalProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [newSprint, setNewSprint] = useState<ICreateSprint>(
    initialNewSprintState
  )
  const [previewURLImage, setPreviewURLImage] = useState<string | undefined>(
    undefined
  )
  const { storesReport } = useSelector<SelectorReport, IReportProps>(
    state => state.report
  )
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({})

  const [isStoreSelected, setIsStoreSelected] = useState<{
    [key: string]: boolean
  }>({})

  const token = window.localStorage.getItem('@NeoPro:token')
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      const user = jwtDecode<IUserProps>(token)
      if (!storesReport && user) {
        dispatch(storesReportRequest(user.id, token))
      }
    } else {
      window.localStorage.removeItem('@NeoPro:token')
      dispatch(logout())
      history.push(`/login`)
    }
  }, [token, storesReport, dispatch, history])

  useEffect(() => {
    if (storesReport) {
      if (Object.keys(isStoreSelected).length === 0) {
        if (storesReport.groups.length > 0) {
          const storesFromGroups = storesReport.groups.reduce<{
            [key: string]: boolean
          }>((acc, curr) => {
            return {
              ...acc,
              ...curr.stores.reduce((accStore, currStore) => {
                if (!acc[currStore._id]) {
                  return {
                    ...accStore,
                    [currStore._id]: false
                  }
                }
                return accStore
              }, {})
            }
          }, {})

          if (storesReport.stores.length > 0) {
            const stores = storesReport.stores.reduce<{
              [key: string]: boolean
            }>((accStore, currStore) => {
              if (!accStore[currStore._id]) {
                return {
                  ...accStore,
                  [currStore._id]: false
                }
              }
              return accStore
            }, {})
            setIsStoreSelected({ ...stores, ...storesFromGroups })
          } else {
            setIsStoreSelected({ ...storesFromGroups })
          }
        }
      }

      if (Object.keys(isListOpen).length === 0) {
        if (storesReport.groups.length > 0) {
          const storesFromGroup = storesReport.groups.reduce<{
            [key: string]: boolean
          }>((acc, curr) => {
            return {
              ...acc,
              [curr.groupId]: false
            }
          }, {})

          if (storesReport.stores.length > 0) {
            setIsListOpen({ ...storesFromGroup, 'no-groups': false })
          } else {
            setIsListOpen(storesFromGroup)
          }
        }
      }
    }
  }, [storesReport])

  const storeNames = useMemo(() => {
    if (storesReport) {
      if (storesReport.groups.length > 0) {
        const storesFromGroup = storesReport.groups.reduce<{
          [key: string]: string
        }>((acc, curr) => {
          return {
            ...acc,
            ...curr.stores.reduce<{ [key: string]: string }>(
              (accStore, currStore) => {
                if (!acc[currStore._id]) {
                  return {
                    ...accStore,
                    [currStore._id]: currStore.name
                  }
                }
                return accStore
              },
              {}
            )
          }
        }, {})

        if (storesReport.stores.length > 0) {
          return {
            ...storesFromGroup,
            ...storesReport.stores.reduce<{ [key: string]: string }>(
              (accStore, currStore) => {
                if (!accStore[currStore._id]) {
                  return {
                    ...accStore,
                    [currStore._id]: currStore.name
                  }
                }
                return accStore
              },
              {}
            )
          }
        }
        return storesFromGroup
      }
    }
    return {}
  }, [storesReport])

  const setNewSprintFieldValue = ({
    field,
    data
  }: SetNewSprintFieldValueFuncProps) => {
    setNewSprint(previousState => ({ ...previousState, [field]: data }))
  }

  const handleCloseModal = () => {
    closeModal()
    setNewSprint(initialNewSprintState)
    setPreviewURLImage(undefined)
  }

  const handleCreateNewSprint = () => {
    // checa se todos os campos estão preenchidos e caso não estiverem ativa um estado
    // "hasSubmitted" pra mostrar os erros no modal pro ser humano
    const itemsLength = Object.values(newSprint).filter(
      item => item !== ''
    ).length
    if (itemsLength < Object.keys(newSprint).length) {
      if (!hasSubmitted) {
        setHasSubmitted(true)
      }
      return
    }

    const storesIds = Object.entries(isStoreSelected)
      .filter(([_, value]) => value)
      .map(([key]) => [key])
      .flat(1)

    if (storesIds.length === 0) {
      if (!hasSubmitted) {
        setHasSubmitted(true)
      }
      toast.error('Seleciona ao menos uma loja')
      return
    }

    const formattedNewSprint: ICreateSprint = {
      ...newSprint,
      goal: Number(
        String(newSprint.goal).replaceAll('.', '').replace(',', '.')
      ),
      ...(['money', 'points'].includes(newSprint.rewardType) && {
        reward: Number(
          String(newSprint.reward).replaceAll('.', '').replace(',', '.')
        )
      })
    }

    dispatch(
      createNewSprintRequest(formattedNewSprint, storeId, storesIds, token)
    )
    handleCloseModal()
    setIsStoreSelected({})
    setIsListOpen({})
  }

  const checkIfInputHasError = (field: keyof ICreateSprint) => {
    if (field === 'goal' || field === 'reward') {
      const inputNumber = Number(String(newSprint[field]).replace(',', '.'))
      return hasSubmitted && (newSprint[field] === '' || inputNumber === 0)
    }
    return hasSubmitted && newSprint[field] === ''
  }

  const sprintDaysAmount =
    differenceInDays(
      new Date(newSprint.endDate),
      new Date(newSprint.startDate)
    ) + 1

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} fullWidth maxWidth='sm'>
      <DialogTitle style={{ paddingTop: 22 }}>
        <Typography style={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Criar Corridinha
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          style={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item container>
            <Grid item xs={4} md={4}>
              <Avatar
                image={previewURLImage}
                subTitle='Imagem para corridinha'
                handleSubmitImage={(formData: FormData, previewURL: string) => {
                  setNewSprintFieldValue({
                    field: 'avatar',
                    data: formData
                  })
                  setPreviewURLImage(previewURL)
                }}
              />
            </Grid>

            <Grid
              item
              xs={8}
              md={8}
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column'
              }}
            >
              <Grid>
                <TextField
                  fullWidth
                  error={checkIfInputHasError('title')}
                  label='Título'
                  value={newSprint.title}
                  variant='outlined'
                  placeholder='Título'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event =>
                    event.target.value.length < 36 &&
                    setNewSprintFieldValue({
                      field: 'title',
                      data: event.target.value
                    })
                  }
                />
              </Grid>
              <Grid>
                <TextField
                  fullWidth
                  multiline
                  maxRows={5}
                  error={checkIfInputHasError('description')}
                  label='Descrição'
                  value={newSprint.description}
                  variant='outlined'
                  placeholder='Descrição'
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event =>
                    event.target.value.length < 140 &&
                    setNewSprintFieldValue({
                      field: 'description',
                      data: event.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 1,
                border: '1px solid rgba(196, 196, 196)'
              }}
            >
              <Switch
                style={{
                  color: '#364AD3'
                }}
                checked={newSprint.isRecurring}
                onChange={event =>
                  setNewSprintFieldValue({
                    field: 'isRecurring',
                    data: event.target.checked
                  })
                }
              />

              <Box marginLeft={2} fontFamily='Roboto, sans-serif'>
                <b style={{ fontSize: '1rem' }}>Meta Recorrente</b>
                <p style={{ fontSize: '0.875rem', color: '#ADADBD' }}>
                  A premiação acumula a cada vez que a meta for atingida durante
                  a corridinha.
                </p>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='goalType'>Tipo da Meta</InputLabel>
              <Select
                labelId='goalType'
                label='Tipo da Meta'
                error={checkIfInputHasError('goalType')}
                autoComplete='off'
                value={newSprint.goalType}
                onChange={event => {
                  setNewSprintFieldValue({
                    field: 'goalType',
                    data: String(event.target.value)
                  })
                  setNewSprintFieldValue({
                    field: 'goal',
                    data: ''
                  })
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value='financial'>Financeira</MenuItem>
                <MenuItem value='unity'>Unidade</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            {newSprint.goalType === 'financial' ? (
              <CurrencyTextField
                fullWidth
                onFocus={(event: FocusEvent<HTMLInputElement>) =>
                  event.target.select()
                }
                label='Meta'
                error={checkIfInputHasError('goal')}
                variant='outlined'
                currencySymbol='R$'
                decimalCharacter=','
                digitGroupSeparator='.'
                minimumValue='0'
                placeholder='0,00'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { textAlign: 'left' }
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setNewSprintFieldValue({
                    field: 'goal',
                    data: event.target.value
                  })
                }
              />
            ) : (
              <TextField
                fullWidth
                type='number'
                label='Meta'
                error={checkIfInputHasError('goal')}
                variant='outlined'
                placeholder={'0'}
                InputLabelProps={{
                  shrink: true
                }}
                onKeyDown={event =>
                  ['.', ','].includes(event.key) && event.preventDefault()
                }
                value={newSprint.goal}
                onChange={event =>
                  setNewSprintFieldValue({
                    field: 'goal',
                    data:
                      Number(event.target.value) > 0
                        ? String(parseInt(event.target.value))
                        : '0'
                  })
                }
              />
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='rewardType'>Tipo do Prêmio</InputLabel>
              <Select
                labelId='rewardType'
                label='Tipo do Prêmio'
                error={checkIfInputHasError('rewardType')}
                autoComplete='off'
                value={newSprint.rewardType}
                onChange={event => {
                  setNewSprintFieldValue({
                    field: 'rewardType',
                    data: String(event.target.value)
                  })
                  setNewSprintFieldValue({
                    field: 'reward',
                    data: ''
                  })
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value='money'>Dinheiro</MenuItem>
                <MenuItem value='points'>Pontos</MenuItem>
                <MenuItem value='general'>Geral</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            {newSprint.rewardType === 'money' ? (
              <CurrencyTextField
                fullWidth
                onFocus={(event: FocusEvent<HTMLInputElement>) =>
                  event.target.select()
                }
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                currencySymbol='R$'
                decimalCharacter=','
                digitGroupSeparator='.'
                minimumValue='0'
                placeholder='0,00'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { textAlign: 'left' }
                }}
                // value={newSprint.reward}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setNewSprintFieldValue({
                    field: 'reward',
                    data: event.target.value
                  })
                }
              />
            ) : newSprint.rewardType === 'points' ? (
              <TextField
                fullWidth
                type='number'
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                placeholder='0'
                InputLabelProps={{
                  shrink: true
                }}
                onKeyDown={event =>
                  ['.', ','].includes(event.key) && event.preventDefault()
                }
                value={newSprint.reward}
                onChange={event => {
                  setNewSprintFieldValue({
                    field: 'reward',
                    data:
                      Number(event.target.value) > 0
                        ? String(parseInt(event.target.value))
                        : '0'
                  })
                }}
              />
            ) : (
              <TextField
                fullWidth
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                value={newSprint.reward}
                onChange={event =>
                  event.target.value.length < 36 &&
                  setNewSprintFieldValue({
                    field: 'reward',
                    data: event.target.value
                  })
                }
              />
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl style={{ width: '100%' }} variant='outlined'>
              <InputLabel id='stores'>Lojas</InputLabel>
              <Select
                labelId='stores'
                name='selectedStores'
                label='Lojas'
                multiple
                error={
                  hasSubmitted &&
                  Object.entries(isStoreSelected)
                    .filter(([_, value]) => value)
                    .map(([key]) => [key])
                    .flat(1).length === 0
                }
                value={Object.entries(isStoreSelected)
                  .filter(([_, value]) => value)
                  .map(([key]) => [key])
                  .flat(1)}
                renderValue={selected => {
                  const selectedItems = selected as string[]
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selectedItems.map(selectedItem => {
                        return (
                          <Chip
                            style={{ marginRight: 4, marginBottom: 4 }}
                            key={selectedItem}
                            label={storeNames[selectedItem]}
                          />
                        )
                      })}
                    </Box>
                  )
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {storesReport &&
                  storesReport.groups.map(group => (
                    <Box
                      sx={{
                        bgcolor: isListOpen[group.groupId]
                          ? 'rgba(0, 0, 0, 0.08)'
                          : null,
                        pb: isListOpen[group.groupId] ? 2 : 0
                      }}
                      width='100%'
                      key={group.groupId}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                          checked={
                            group.stores.every(
                              store => isStoreSelected[store._id]
                            ) ?? false
                          }
                          onChange={(_, checked) => {
                            const newList = group.stores.reduce((acc, curr) => {
                              return {
                                ...acc,
                                [curr._id]: checked
                              }
                            }, {})

                            setIsStoreSelected(prev => ({
                              ...prev,
                              ...newList
                            }))
                          }}
                        />

                        <ListItemButton
                          alignItems='flex-start'
                          style={{ width: '100%' }}
                          onClick={() =>
                            setIsListOpen(previousValue => ({
                              ...previousValue,
                              [group.groupId]: !previousValue[group.groupId]
                            }))
                          }
                          sx={{
                            px: 3,
                            pt: 2.5,
                            pb: isListOpen[group.groupId] ? 2 : 2.5,
                            cursor: 'pointer',
                            '&:hover, &:focus': {
                              '& svg': {
                                opacity: isListOpen[group.groupId] ? 1 : 0
                              }
                            }
                          }}
                        >
                          <ListItemText
                            primary={group.name}
                            primaryTypographyProps={{
                              fontSize: 15,
                              fontWeight: 'medium',
                              lineHeight: '20px',
                              mb: '2px'
                            }}
                            sx={{ my: 0 }}
                          />
                          <KeyboardArrowDown
                            sx={{
                              opacity: 0,
                              transform: isListOpen[group.groupId]
                                ? 'rotate(-180deg)'
                                : 'rotate(0)',
                              transition: '0.2s'
                            }}
                          />
                        </ListItemButton>
                      </div>
                      {isListOpen[group.groupId] &&
                        group.stores.map(store => {
                          return (
                            <ListItem
                              key={store._id}
                              sx={{
                                cursor: 'pointer',
                                ...(!isStoreSelected[store._id] && {
                                  bgcolor: '#fff'
                                }),
                                '&:hover, &:focus': {
                                  bgcolor: 'rgba(0, 0, 0, 0.08)'
                                }
                              }}
                              onClick={() => {
                                setIsStoreSelected(prev => ({
                                  ...prev,
                                  [store._id]: !prev[store._id]
                                }))
                              }}
                            >
                              <ListItemAvatar style={{ minWidth: 30 }}>
                                <AvatarMUI
                                  style={{ height: 22, width: 22 }}
                                  src={store.photo.url ?? undefined}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                style={{ fontSize: 12 }}
                                primary={store.name}
                              />
                            </ListItem>
                          )
                        })}
                    </Box>
                  ))}
                {storesReport && storesReport.stores.length > 0 && (
                  <Box
                    sx={{
                      bgcolor: isListOpen['no-groups']
                        ? 'rgba(0, 0, 0, 0.08)'
                        : null,
                      pb: isListOpen['no-groups'] ? 2 : 0
                    }}
                    width='100%'
                    key={'no-groups'}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Checkbox
                        checked={
                          storesReport.stores.every(
                            store => isStoreSelected[store._id]
                          ) ?? false
                        }
                        onChange={(_, checked) => {
                          const newList = storesReport.stores.reduce(
                            (acc, curr) => {
                              return {
                                ...acc,
                                [curr._id]: checked
                              }
                            },
                            {}
                          )

                          setIsStoreSelected(prev => ({
                            ...prev,
                            ...newList
                          }))
                        }}
                      />

                      <ListItemButton
                        alignItems='flex-start'
                        style={{ width: '100%' }}
                        onClick={() =>
                          setIsListOpen(previousValue => ({
                            ...previousValue,
                            ['no-groups']: !previousValue['no-groups']
                          }))
                        }
                        sx={{
                          px: 3,
                          pt: 2.5,
                          pb: isListOpen['no-groups'] ? 2 : 2.5,
                          cursor: 'pointer',
                          '&:hover, &:focus': {
                            '& svg': {
                              opacity: isListOpen['no-groups'] ? 1 : 0
                            }
                          }
                        }}
                      >
                        <ListItemText
                          primary={'Lojas sem regional'}
                          primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px'
                          }}
                          sx={{ my: 0 }}
                        />
                        <KeyboardArrowDown
                          sx={{
                            opacity: 0,
                            transform: isListOpen['no-groups']
                              ? 'rotate(-180deg)'
                              : 'rotate(0)',
                            transition: '0.2s'
                          }}
                        />
                      </ListItemButton>
                    </div>
                    {isListOpen['no-groups'] &&
                      storesReport.stores.map(store => {
                        return (
                          <ListItem
                            key={store._id}
                            sx={{
                              cursor: 'pointer',
                              ...(!isStoreSelected[store._id] && {
                                bgcolor: '#fff'
                              }),
                              '&:hover, &:focus': {
                                bgcolor: 'rgba(0, 0, 0, 0.08)'
                              }
                            }}
                            onClick={() => {
                              setIsStoreSelected(prev => ({
                                ...prev,
                                [store._id]: !prev[store._id]
                              }))
                            }}
                          >
                            <ListItemAvatar style={{ minWidth: 30 }}>
                              <AvatarMUI
                                style={{ height: 22, width: 22 }}
                                src={store.photo.url ?? undefined}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              style={{ fontSize: 12 }}
                              primary={store.name}
                            />
                          </ListItem>
                        )
                      })}
                  </Box>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={5}>
            <TextField
              label='Data de Início'
              type='date'
              fullWidth
              variant='outlined'
              margin='normal'
              id='startDate'
              onChange={event => {
                // Por n ter o timezone ele zoa o dia jogando pra um dia a menos
                // dependendo da hora que o usuário ta mexendo
                // Esse workaround aqui evita esse tipo de problema
                const newDate = new Date(
                  new Date(event.target.value).getTime() +
                    Math.abs(
                      new Date(event.target.value).getTimezoneOffset() * 60000
                    )
                ).toString()

                setNewSprintFieldValue({
                  field: 'startDate',
                  data: newDate
                })

                const diffBetweenStartAndEndDates = differenceInDays(
                  new Date(newSprint.endDate),
                  new Date(newDate)
                )

                // Previne o usuário de colocar um início depois do encerramento
                if (diffBetweenStartAndEndDates < 0) {
                  setNewSprintFieldValue({
                    field: 'endDate',
                    data: newDate
                  })
                }
              }}
              value={format(new Date(newSprint.startDate), 'yyyy-MM-dd')}
              onFocus={event => event.target.select()}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: format(new Date().setDate(1), 'yyyy-MM-dd'),
                max: format(new Date(), 'yyyy-MM-dd')
                // max: format(addMonths(new Date().setDate(0), 3), 'yyyy-MM-dd')
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography>até</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label='Data de Encerramento'
              type='date'
              fullWidth
              variant='outlined'
              margin='normal'
              id='endDate'
              onChange={event => {
                // Por n ter o timezone ele zoa o dia jogando pra um dia a menos
                // dependendo da hora que o usuário ta mexendo
                // Esse workaround aqui evita esse tipo de problema
                const newDate = new Date(
                  new Date(event.target.value).getTime() +
                    Math.abs(
                      new Date(event.target.value).getTimezoneOffset() * 60000
                    )
                ).toString()

                setNewSprintFieldValue({
                  field: 'endDate',
                  data: newDate
                })
              }}
              value={format(new Date(newSprint.endDate), 'yyyy-MM-dd')}
              onFocus={event => event.target.select()}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: format(new Date(newSprint.startDate), 'yyyy-MM-dd'),
                max: format(addMonths(new Date().setDate(0), 3), 'yyyy-MM-dd')
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'end', paddingTop: 0, paddingBottom: 0 }}
          >
            <Typography>
              {sprintDaysAmount} {sprintDaysAmount > 1 ? 'dias' : 'dia'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{
          paddingLeft: '24px',
          paddingRight: '24px'
        }}
      >
        <Button
          variant='text'
          onClick={handleCloseModal}
          disabled={isLoading}
          style={{ color: '#37474F' }}
        >
          CANCELAR
        </Button>
        <Button
          variant='text'
          type='submit'
          onClick={handleCreateNewSprint}
          disabled={isLoading}
          style={{ background: palette.primary.main, color: palette.white }}
        >
          {isLoading && <CircularProgress size={14} color='inherit' />}
          {!isLoading && 'ADICIONAR'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
