import produce from 'immer'
import moment from 'moment'
import {
  endDayFromCurrentMonth,
  startDayFromCurrentMonth
} from '../../../utils/getDateFromCurrentMonth'
import { IReportProps } from './types'
import { Action } from 'redux'

const INITIAL_STATE: IReportProps = {
  reportsList: null,
  storesReport: null,
  monthReport: null,
  storesInfos: null,
  range: {
    from: startDayFromCurrentMonth,
    to: endDayFromCurrentMonth
  },
  storesRange: {
    from: startDayFromCurrentMonth,
    to: endDayFromCurrentMonth
  },
  lastUpdate: -1,
  loading: false,
  hasRequested: false,
  axiosCancelToken: null
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function report(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@report/MONTH_REQUEST': {
        draft.loading = true
        break
      }
      case '@report/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@report/LIST_SUCCESS': {
        draft.reportsList = action.payload.reportsList
        draft.range = action.payload.range
        draft.loading = false
        break
      }
      case '@report/MONTH_SUCCESS': {
        draft.monthReport = action.payload.monthReport
        draft.range = {
          from: startDayFromCurrentMonth,
          to: endDayFromCurrentMonth
        }
        draft.lastUpdate = moment().unix()
        draft.loading = false
        break
      }
      case '@report/UPDATE_STORES_FROM_STORES_REPORT': {
        if (draft.storesReport) {
          draft.storesReport.stores = action.payload.newStoresFromStoresReport
        }
        break
      }
      case '@report/UPDATE_GROUPS_FROM_STORES_REPORT': {
        if (draft.storesReport) {
          draft.storesReport.groups = action.payload.newGroupsFromStoresReport
        }
        break
      }
      case '@report/ADD_GROUP_TO_STORES_REPORT': {
        if (draft.storesReport) {
          draft.storesReport.groups.push(action.payload.newGroup)
        }
        break
      }
      case '@report/UPDATE_GROUP_FROM_STORES_REPORT': {
        if (draft.storesReport && state.storesReport) {
          const index = state.storesReport.groups.findIndex(
            group => group.groupId === action.payload.updatedGroup.groupId
          )

          draft.storesReport.groups[index] = action.payload.updatedGroup
        }
        break
      }
      case '@user/LOGOUT': {
        if (draft.axiosCancelToken) {
          draft.axiosCancelToken.cancel()
        }

        return INITIAL_STATE
      }
      case '@input/DELETE_SUCCESS': {
        draft.monthReport = null
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@report/STORES_REQUEST': {
        draft.loading = true
        break
      }
      case '@report/STORES_SUCCESS': {
        draft.storesReport = {
          ...action.payload.storesReport,
          groups: action.payload.storesReport.groups.map((group: any) => {
            const completeSupervisorName = group.supervisor.name
            const firstSupervisorName = group.supervisor.name.split(' ')[0]
            const secondSupervisorName = group.supervisor.name.split(' ')[1]
            return {
              ...group,
              supervisor: {
                ...group.supervisor,
                name: {
                  first: firstSupervisorName,
                  last: secondSupervisorName || '',
                  complete: completeSupervisorName
                }
              }
            }
          })
        }
        draft.axiosCancelToken = null
        draft.loading = false
        draft.hasRequested = true

        if (action.payload.range) {
          draft.storesRange = action.payload.range
        }
        break
      }
      case '@report/ADD_AXIOS_CANCEL_TOKEN': {
        draft.axiosCancelToken = action.payload.axiosCancelToken
        break
      }
      case '@main/CLEAR_REDUX': {
        draft.monthReport = null
        draft.reportsList = null
        break
      }
      default:
        return state
    }
  })
}
