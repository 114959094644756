import { all } from 'redux-saga/effects'

import goal from './goal/sagas'
import input from './input/sagas'
import user from './user/sagas'
import report from './report/sagas'
import store from './store/sagas'
import main from './main/sagas'
import integration from './integration/sagas'
import day from './day/sagas'
import group from './group/sagas'
import sprints from './sprints/sagas'
import storylines from './storylines/sagas'

export default function* rootSaga() {
  return yield all([
    goal,
    input,
    user,
    report,
    store,
    main,
    integration,
    day,
    group,
    sprints,
    storylines
  ])
}
