import styled from 'styled-components'
import palette from 'theme/palette'

export const SubscriptionValue = styled.div`
  display: flex;
  align-items: center;
  max-height: 14px;
  margin-left: -8px;
`
export const FlexText = styled.p`
  color: ${props => props.isCurrentSeller && palette.primary[300]};
  font-weight: ${props => props.isCurrentSeller && 'bold'};
`
