const date = new Date()

export const startDayFromCurrentMonth = new Date(
  date.getFullYear(),
  date.getMonth(),
  1,
  0
)

export const endDayFromCurrentMonth = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  23,
  59,
  59
)
