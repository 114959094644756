import { ChangeEvent } from 'react'

// Libs
import { toast } from 'react-toastify'

// Material UI
import { Badge, Typography } from '@material-ui/core'
import {
  ImageSearch as ImageSearchIcon,
  SportsScore as SportsScoreIcon
} from '@mui/icons-material'
import { Label, Card, CustomAvatar } from './styles'
import palette from 'theme/palette'

interface AvatarProps {
  image: string | undefined
  title?: string
  subTitle?: string
  handleSubmitImage: (image: FormData, srcImg: string) => void
}

export const Avatar = ({
  image,
  title = '',
  subTitle = '',
  handleSubmitImage
}: AvatarProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const data = new FormData()
    const { files } = e.target

    if (files && files.length > 0) {
      var fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      if (fileSize < 5) {
        const previewURL = URL.createObjectURL(files[0])
        data.append('photo', files[0])

        handleSubmitImage(data, previewURL)
      } else {
        toast.error('Imagem muito grande! A imagem deve ser menor que 5MB.')
      }
    }
  }

  return (
    <Card>
      <Label htmlFor='avatar'>
        <Badge
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={
            <ImageSearchIcon
              fontSize='large'
              style={{
                color: '#fff',
                backgroundColor: palette.primary.light,
                borderRadius: 999,
                padding: 5
              }}
            />
          }
        >
          <CustomAvatar src={image} alt='Imagem de perfil'>
            {!image && <SportsScoreIcon style={{ width: 50, height: 50 }} />}
          </CustomAvatar>
        </Badge>
        <input
          style={{ display: 'none' }}
          type='file'
          id='avatar'
          accept='.png, .jpg, .jpeg'
          onChange={handleChange}
        />
      </Label>
      <Typography variant='h3'>{title}</Typography>
      <Typography variant='body1' style={{ marginTop: 12 }}>
        {subTitle}
      </Typography>
    </Card>
  )
}
