import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import { getDate, endOfMonth, getDay } from 'date-fns'
import { animateScroll as scroll, Element, Link } from 'react-scroll'

// Components
import OrLineDivisor from '../../OrLineDivisor'
import InputCard from '../../InputCard'
import GoalIndicatorCard from '../../GoalIndicatorCard'
import { TextField } from '@material-ui/core'

// Icons UI
import CreateIcon from '@material-ui/icons/Create'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import WandIcon from '../../../../../../../assets/Icons/WandIcon'

// Styles
import { Container } from './style'
import CardCheck from '../../CardCheck'
import { eachWeekendOfMonth } from 'date-fns/esm'
import { useSelector } from 'react-redux'
import palette from 'theme/palette'

const ValueStep = ({
  goal,
  month,
  salary,
  dsr,
  mainGoals,
  handleNextStep,
  updateGoal,
  setDeleteStep,
  storedMainGoals,
  deleteGoalStep
}) => {
  const daysInMonth = getDate(endOfMonth(new Date(month)))

  const goalList = useSelector(state => state.goal.goalList)

  const getSundaysInMonth = () => {
    // Pega quantidade de domingos para definir DSR
    const weekends = eachWeekendOfMonth(new Date(month))

    return weekends.reduce((acc, day) => {
      if (getDay(day) === 0) acc++
      return acc
    }, 0)
  }

  const [valueStep, setValueStep] = useState({
    base: salary?.base || null,
    dsr: typeof dsr === 'number' ? dsr : getSundaysInMonth(),
    total: mainGoals.total || null,
    ticket: mainGoals.ticket || null,
    price: mainGoals.price || null,
    complement: salary?.complement || true
  })

  useEffect(() => {
    if (salary.base && dsr) scroll.scrollToBottom()
    else scroll.scrollToTop()
  }, [])

  useEffect(() => {
    const lastGoalMonthActive = goalList.find(
      goal => goal.config.active && goal.month === month
    )

    const lastGoalActive = goalList.find(goal => goal.config.active)

    const setNewValueStep = goal => {
      goal &&
        setValueStep(state => ({
          ...state,
          base: goal.salary?.base,
          total: goal.mainGoals?.total,
          ticket: goal.mainGoals?.ticket,
          price: goal.mainGoals?.price
        }))
    }

    const hasAllValues =
      !!valueStep.base &&
      !!valueStep.price &&
      !!valueStep.ticket &&
      !!valueStep.total

    // Pega o salario da ultima meta criada
    if (goalList.length > 0 && !hasAllValues) {
      if (lastGoalMonthActive) setNewValueStep(lastGoalMonthActive)
      else if (lastGoalActive) setNewValueStep(lastGoalActive)
    }
  }, [goalList])

  const storedTotalGoal = () => {
    // Caso o valor da meta seja igual ao valor anterior, não irá recalcular o calendário
    if (
      storedMainGoals.total !== valueStep.total &&
      Array.isArray(goal['days'])
    ) {
      goal['days'] = []
    }

    updateGoal({
      mainGoals: {
        total: valueStep.total,
        ticket: valueStep.ticket,
        price: valueStep.price
      }
    })

    scroll.scrollToBottom()
  }

  return (
    <Container>
      <Element name='remuneration' className='goal-step-card-container'>
        <div
          style={
            salary?.base
              ? { pointerEvents: 'none', opacity: 0.3 }
              : { pointerEvents: 'auto', opacity: 1 }
          }
          className='remuneration goal-step-card-container'
        >
          <h1 className='goal-step-card-container-title'>
            Informação sobre remuneração
          </h1>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <InputCard
              hasError={valueStep.base ? valueStep.base <= 0 : false}
              dataCy={'wageFloorInput'}
              cardTitle='Piso Salarial'
              disclaimerText='O mínimo que pode ser pago para a classe de vendedores.'
              tooltipContent={
                <p>Mínimo fixado por lei ou convenção coletiva</p>
              }
              inputLabel='Valor do piso salarial'
              value={valueStep.base}
              onChange={(e, value) =>
                setValueStep({ ...valueStep, base: value })
              }
            />
            <CardCheck
              cardTitle='Descanso Semanal Remunerado (DSR)'
              inputProps
              inputDataCy='dsrCheckbox'
              tooltipContent={
                <p>
                  Dia de folga semanal garantido por lei, que não será
                  descontado do salário.
                  <br />
                  <br />
                  Em caso de dúvidas, consulte o setor do RH ou contabilidade.
                </p>
              }
              disclaimerText='Deseja incluir DSR no cálculo da remuneração?'
              setIsSelected={() =>
                setValueStep({ ...valueStep, dsr: valueStep.dsr === 0 ? 1 : 0 })
              }
              isSelected={valueStep.dsr > 0}
            >
              <TextField
                error={valueStep.dsr < 0}
                data-cy='dsrInput'
                value={valueStep.dsr || ''}
                disabled={valueStep.dsr === 0}
                label={'Número de dias inclusos'}
                onChange={event =>
                  setValueStep({
                    ...valueStep,
                    dsr:
                      event.target.value > daysInMonth
                        ? daysInMonth - 1
                        : parseInt(event.target.value)
                  })
                }
                variant='outlined'
                fullWidth
              />
            </CardCheck>

            {!!salary?.directCommission && (
              <CardCheck
                data-cy='salaryComplementDiv'
                inputDataCy='salaryComplementCheckbox'
                cardTitle='Complemento de salário'
                tooltipContent={
                  <p>
                    João vendeu <span>R$ 25.000</span>, e sua comissão é de{' '}
                    <span>5%</span>.
                    <br />
                    Piso Salarial é de <span>R$ 1.500</span>
                    <br />O complemento automático será de <span>R$ 250</span>.
                    <br />
                    <br />
                    25.000 * 0,05 = R$ 1.250
                    <br />
                    <span style={{ color: '#FFC700' }}>R$ 1.250</span> {'<'} R$
                    1.500 <br />
                    1.500 - 1.250 = <span>R$ 250</span>
                  </p>
                }
                disclaimerText='Complementar a remuneração automaticamente quando a comissão não alcançar o Piso Salarial?'
                setIsSelected={() =>
                  setValueStep({
                    ...valueStep,
                    complement: !valueStep.complement
                  })
                }
                isSelected={salary?.complement || valueStep.complement}
              />
            )}
          </div>

          <Link to='goal-value' offset={-150} smooth={true}>
            {valueStep.base > 0 && !salary?.base > 0 ? (
              <button
                data-cy='remunerationInfoNextButton'
                onClick={() => {
                  updateGoal({
                    salary: {
                      ...salary,
                      base: parseFloat(valueStep.base ? valueStep.base : 0),
                      complement: !!salary?.directCommission
                        ? valueStep.complement
                        : false
                    },
                    dsr: parseInt(valueStep.dsr ? valueStep.dsr : 0)
                  })

                  scroll.scrollToBottom()
                }}
                disabled={!valueStep.base}
                className='stepper-button-next'
              >
                Avançar
              </button>
            ) : undefined}
          </Link>
        </div>

        <Link to='remuneration' offset={-170} smooth={true}>
          {salary?.base && (
            <button
              data-cy=''
              onClick={() => {
                setDeleteStep(['base', 'complement'])
              }}
              className='stepper-button-edit'
            >
              Alterar
              <CreateIcon
                style={{ marginLeft: 5, fontSize: 18 }}
                fontSize='small'
              />
            </button>
          )}
        </Link>
      </Element>

      {salary?.base || mainGoals?.total ? (
        <Element name='goal-value'>
          <div className='goals-price-step'>
            <div
              style={
                mainGoals?.total
                  ? { pointerEvents: 'none', opacity: 0.3 }
                  : { pointerEvents: 'auto', opacity: 1 }
              }
            >
              <h1 className='goals-price-step-title'>
                Quais são as metas da loja para{' '}
                {month
                  ? format(new Date(month), 'MMMM', { locale: ptBR })
                  : '...'}
                ?
              </h1>

              <div className='goals-price-step-info'>
                <div className='goals-price-inputs'>
                  <InputCard
                    hasError={valueStep.total ? valueStep.total <= 0 : false}
                    cardTitle='1ª Meta da loja'
                    inputLabel='Valor do Faturamento'
                    value={valueStep.total}
                    tooltipContent={
                      <p>Valor esperado de faturamento mensal.</p>
                    }
                    onChange={(e, value) =>
                      setValueStep({ ...valueStep, total: value })
                    }
                    dataCy='salesValueInput'
                  />
                  <InputCard
                    hasError={valueStep.ticket ? valueStep.ticket <= 0 : false}
                    cardTitle='Meta de Ticket Médio'
                    inputLabel='Valor do Ticket Médio'
                    tooltipContent={<p>Valor médio esperado por venda.</p>}
                    value={valueStep.ticket}
                    onChange={(e, value) =>
                      setValueStep({ ...valueStep, ticket: value })
                    }
                    dataCy='averageTicketValueInput'
                  />
                  <InputCard
                    hasError={valueStep.price ? valueStep.price <= 0 : false}
                    cardTitle='Meta de Preço Médio'
                    inputLabel='Valor do Preço Médio'
                    tooltipContent={
                      <p>Valor médio esperado dos produtos por venda.</p>
                    }
                    value={valueStep.price}
                    onChange={(e, value) =>
                      setValueStep({ ...valueStep, price: value })
                    }
                    dataCy='averagePriceValueInput'
                  />
                </div>

                <OrLineDivisor>
                  <WandIcon color={palette.primary.main} />
                </OrLineDivisor>

                <div className='goals-price-overview'>
                  <span>Como alcançar a meta:</span>
                  <div className='goals-price-overview-indicator'>
                    <GoalIndicatorCard
                      cardTitle='Total de vendas'
                      tooltipId='goals-price-overview-tv'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={
                        valueStep.ticket > 0 && valueStep.ticket !== 0
                          ? Math.ceil(valueStep.total / valueStep.ticket)
                          : 0
                      }
                    />
                    <GoalIndicatorCard
                      cardTitle='Peças por venda'
                      tooltipId='goals-price-overview-pv'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={
                        valueStep.price > 0 && valueStep.price !== 0
                          ? Number(
                              (valueStep.ticket / valueStep.price).toFixed(2)
                            )
                          : 0
                      }
                    />
                    <GoalIndicatorCard
                      cardTitle='Total de peças'
                      tooltipId='goals-price-overview-tp'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={
                        valueStep.price > 0 && valueStep.price !== 0
                          ? Math.ceil(valueStep.total / valueStep.price)
                          : 0
                      }
                    />
                  </div>
                </div>
              </div>

              <Link to='next-step-button' smooth={true} offset={-200}>
                {!mainGoals?.total &&
                valueStep.total > 0 &&
                valueStep.price > 0 &&
                valueStep.ticket > 0 ? (
                  <button
                    data-cy='goalValuesNextButton'
                    style={{ margin: '40px auto 20px' }}
                    onClick={() => storedTotalGoal()}
                    disabled={
                      !valueStep.total && !valueStep.ticket && !valueStep.price
                    }
                    className='stepper-button-next'
                  >
                    Avançar
                  </button>
                ) : (
                  <></>
                )}
              </Link>
            </div>

            {mainGoals?.total && mainGoals?.price && mainGoals?.ticket ? (
              <Link to='goal-value' offset={-170} smooth={true}>
                <button
                  onClick={() => {
                    setDeleteStep(['total', 'ticket', 'price'])
                  }}
                  className='stepper-button-edit'
                >
                  Alterar
                  <CreateIcon
                    style={{ marginLeft: 5, fontSize: 18 }}
                    fontSize='small'
                  />
                </button>
              </Link>
            ) : (
              <></>
            )}
          </div>
        </Element>
      ) : (
        <></>
      )}

      {mainGoals?.total && salary?.base ? (
        <Element name='next-step-button'>
          <button
            onClick={() => {
              handleNextStep()
            }}
            style={{ margin: 'auto' }}
            className='stepper-button-next'
            data-cy='nextStepButton'
          >
            Próxima etapa
            <ArrowForwardIosIcon className='next-button-icon' />
          </button>
        </Element>
      ) : undefined}
    </Container>
  )
}

export default React.memo(ValueStep)
