import styled, { css } from 'styled-components'

interface ContainerProps {
  defaultCell: boolean
  value: number
  backgroundColor: string
  textColor?: string
}

export const Container = styled.td<ContainerProps>`
  font-family: 'Roboto';
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h1 {
    font-size: 12px;
    color: ${props =>
      props.textColor ? props.textColor : props.value ? '#fff' : '#BABAC5'};
    ${props =>
      props.defaultCell &&
      !props.textColor &&
      css`
        color: #333e44;
      `};
  }
  background-color: ${props =>
    props.value ? props.backgroundColor : '#f7f7f7'};

  ${props =>
    props.textColor &&
    css`
      border: 1px solid ${props.textColor};
    `}
`
