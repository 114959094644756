import { useMemo } from 'react'

import { formatPrice } from '../../../utils/format'

import { Grid } from '@material-ui/core'

import ValueCard from 'components/ValueCard'

export const HeaderCards = ({ storesReport }) => {
  const currentDate = useMemo(() => {
    return new Date()
      .toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo'
      })
      .split('/')
  }, [])

  return (
    <>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <ValueCard
          title={`Vendas do dia ${currentDate[0]}/${currentDate[1]}`}
          value={`${storesReport ? formatPrice(storesReport.daySold.sold) : 0}`}
          color='#2A3ECB'
          inversed
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <ValueCard
          title='Faturamento'
          value={`${storesReport ? formatPrice(storesReport.total.sold) : 0}`}
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <ValueCard
          title='Projeção'
          value={`${
            storesReport ? formatPrice(storesReport.total.projection.sold) : 0
          }`}
        />
      </Grid>
    </>
  )
}
