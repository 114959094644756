import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
  FormHelperText
} from '@material-ui/core'

// Material UI
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useConfirm } from 'material-ui-confirm'

// Redux
import { useDispatch } from 'react-redux'

// Libs
import { useParams } from 'react-router-dom'
import {
  integrationDisconnectRequest,
  integrationRequest
} from '../../../../../../store/modules/integration/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  selectedBadge: {
    backgroundColor: '#FE6B8B',
    color: 'white'
  },
  formControl: {
    width: '100%'
  }
}))

const Nexaas = ({
  className,
  storeIntegrationData,
  store,
  loading,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'nexaas'

  const DEFAULT_PROVIDER = {
    token: '',
    environment: 'production'
  }

  const environments = ['Produção', 'Homologação']

  const evironmentsDictionary = {
    0: 'production',
    1: 'homologation'
  }

  const [providerData, setProviderData] = useState(DEFAULT_PROVIDER)
  const [storeLoading, setStoreLoading] = useState(false)
  const [error, setError] = useState(false)

  // LocalStorage
  const token = localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com a Nexaas?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(integrationDisconnectRequest(provider, storeId, token))
        setProviderData(DEFAULT_PROVIDER)
      })
      .catch(() => {})
  }

  const handleSubmit = () => {
    setError(false)
    if (!providerData.token || !(providerData.environment !== undefined)) {
      return setError('Todos os campos devem estar preenchidos.')
    } else {
      confirm({
        title: 'Atenção',
        description:
          'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na Nexaas?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar'
      })
        .then(() => {
          dispatch(
            integrationRequest(
              {
                ...providerData,
                environment: evironmentsDictionary[providerData.environment],
                provider
              },
              provider,
              storeId,
              token
            )
          )
        })
        .catch(err => {
          setError('Erro ao conectar com a Nexaas.')
          console.log(err)
          setStoreLoading(false)
        })
    }
  }

  useEffect(() => {
    if (
      storeIntegrationData?.token &&
      storeIntegrationData?.provider === provider
    )
      setProviderData({
        token: storeIntegrationData.token || ''
      })
    if (
      storeIntegrationData?.environment &&
      storeIntegrationData?.provider === provider
    )
      setProviderData({
        ...providerData,
        environment: storeIntegrationData.environment || ''
      })
  }, [storeIntegrationData])

  return (
    <>
      <Card {...rest}>
        <CardHeader title='Nexaas' />
        <Divider />
        <CardContent>
          <Typography>
            Antes de iniciar a integração, certifique-se de que todos os
            vendedores estão cadastrados, assim como seus CPFs estão definidos e
            idênticos à Nexaas. Caso haja qualquer diferença nos valores, o
            vendedor não será identificado e a venda entrará em "Outras vendas",
            prejudicando a validade do relatório.
            <br />
            Se acontecer de um vendedor não ter sido identificado e sua venda
            entrar em "Outras vendas", corrija os dados do vendedor e aguarde a
            próxima sincronização.
            <br />
          </Typography>
          <Divider style={{ marginTop: '1rem' }} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Token'
                fullWidth
                variant='outlined'
                margin='normal'
                id='token'
                name='token'
                onFocus={event => event.target.select()}
                onChange={e =>
                  setProviderData({ ...providerData, token: e.target.value })
                }
                value={providerData.token}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                variant='outlined'
                className={classes.formControl}
                size='medium'
                margin='normal'
              >
                <InputLabel id='environment'>Ambiente</InputLabel>
                <Select
                  id='environment'
                  name='environment'
                  value={providerData.environment}
                  onChange={event =>
                    setProviderData({
                      ...providerData,
                      environment: event.target.value
                    })
                  }
                  labelWidth={60}
                >
                  {environments.map((environment, index) => (
                    <MenuItem key={index} value={index}>
                      {environment}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12}>
              {error && (
                <FormHelperText style={{ color: 'red' }}>
                  {error}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {storeIntegrationData?.provider !== provider && (
            <Button
              color='primary'
              variant='contained'
              disabled={loading || storeLoading}
              onClick={handleSubmit}
              className={classes.button}
              type='submit'
            >
              {loading || storeLoading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#fff', marginRight: '8px' }}
                  />{' '}
                  {' Conectando à Nexaas...'}
                </>
              ) : (
                'Conectar'
              )}
            </Button>
          )}
          {storeIntegrationData?.provider === provider && (
            <Button
              style={{ color: '#e74c3c' }}
              className={classes.button}
              disabled={loading || storeLoading}
              onClick={handleDisconnectIntegration}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#e74c3c', marginRight: '8px' }}
                  />{' '}
                  {' Desconectando...'}
                </>
              ) : (
                'Desconectar'
              )}
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  )
}

Nexaas.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default Nexaas
