import { TextField } from '@material-ui/core'
import { addDays, addMonths, format } from 'date-fns'

import { CalendarContainer } from './styles'
import { IStorylineTable } from 'store/modules/storylines/types'

interface CalendarProps {
  storyData: IStorylineTable
}

export const Calendar = ({ storyData }: CalendarProps) => {
  return (
    <CalendarContainer>
      <TextField
        label='Início divulgação'
        type='date'
        fullWidth
        variant='outlined'
        margin='normal'
        id='start'
        value={format(new Date(storyData.period.start), 'yyyy-MM-dd')}
        onFocus={event => event.target.select()}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: format(new Date(), 'yyyy-MM-dd'),
          max: format(addMonths(new Date(), 1), 'yyyy-MM-dd')
        }}
      />

      {storyData.type === 'temporary' && storyData.period.end && (
        <TextField
          label='Último dia'
          type='date'
          fullWidth
          variant='outlined'
          margin='normal'
          id='end'
          value={format(new Date(storyData.period.end), 'yyyy-MM-dd')}
          onFocus={event => event.target.select()}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: format(new Date(storyData.period.start), 'yyyy-MM-dd'),
            max: format(
              addDays(new Date(storyData.period.start), 13),
              'yyyy-MM-dd'
            )
          }}
        />
      )}
    </CalendarContainer>
  )
}
