import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px;
`
export const Content = styled.div``

export const SubHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3px;
  gap: 5px;

  cursor: pointer;

  padding: 9px;
  padding-right: 12px;
`

export const AddStorylineButton = styled(Button)`
  background-color: #2a3ecb;
  color: #fff;

  margin-left: 1rem;
`
export const StorylineSettingsButton = styled(Button)`
  background-color: #e3e3e8;
  color: #5b5b76;
`
