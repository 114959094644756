import styled from 'styled-components'

interface ContainerProps {
  size: number
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  font-size: ${props => props.size / 2}px;
  font-weight: 600;
  border-radius: 50%;
  margin: 0;
  background-color: #d5dde3;
  color: #77828c;
`
