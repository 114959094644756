import { BiFileFind, BiTrash } from 'react-icons/bi'

import { ImageDragAndDrop } from 'views/CreateStoryline/components/ImageDragAndDrop'

import {
  Video,
  Subtitle,
  SubTitleContainer,
  MediaInfoCard,
  IconButton,
  ImagePreviewContainer
} from './styles'

interface MediaDataProps {
  currentMediaUrlPreview: string
  fileType: 'video' | 'image'
  fileName: string
}

export const MediaData = ({
  currentMediaUrlPreview,
  fileType,
  fileName
}: MediaDataProps) => {
  const isFileTypeVideo = fileType === 'video'

  return (
    <>
      <ImagePreviewContainer>
        {isFileTypeVideo ? (
          <Video
            src={currentMediaUrlPreview}
            disablePictureInPicture
            id='video-tag'
          >
            <source id='video-source' src={currentMediaUrlPreview} />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <ImageDragAndDrop
            media={currentMediaUrlPreview}
            handleSubmitMedia={() => {}}
            size='md'
          />
        )}
        <MediaInfoCard>
          {fileName}
          {fileType === 'video' ? ' (video)' : ' (img)'}
          <div>
            <span>
              <IconButton htmlFor='story'>
                <BiFileFind size={24} />
              </IconButton>
              <IconButton>
                <BiTrash size={24} />
              </IconButton>
            </span>
          </div>
        </MediaInfoCard>
      </ImagePreviewContainer>
      <SubTitleContainer>
        <Subtitle>
          <b>Dimensão recom:</b> 1080px x 2160px
        </Subtitle>
        <Subtitle>
          <b>Imagem:</b> peso máx. 15mb
        </Subtitle>
        <Subtitle>
          <b>Vídeo:</b> peso máx. 16mb; duração máx. 120s
        </Subtitle>
      </SubTitleContainer>
    </>
  )
}
