import { ICreateInputProps, IInput, IInputList, ISeller, ITotal } from './types'

export function inputRequest(inputId: string, token: string | null) {
  return {
    type: '@input/REQUEST',
    payload: { inputId, token }
  }
}

export function inputSuccess(input: IInput) {
  return {
    type: '@input/SUCCESS',
    payload: { input }
  }
}

export function inputListRequest(
  storeId: string,
  token: string | null,
  page = 1,
  rows = 35
) {
  return {
    type: '@input/LIST_REQUEST',
    payload: { storeId, token, page, rows }
  }
}

export function inputListSuccess(input: IInputList) {
  return {
    type: '@input/LIST_SUCCESS',
    payload: { input }
  }
}

export function inputAddRequest(
  newInput: ICreateInputProps,
  token: string | null
) {
  return {
    type: '@input/ADD_REQUEST',
    payload: { newInput, token }
  }
}

export function inputAddSuccess(newInput: IInput) {
  return {
    type: '@input/ADD_SUCCESS',
    payload: { newInput }
  }
}

export function inputUpdateRequest(
  storeId: string,
  inputId: string,
  // ISeller caso seja um vendedor ITotal caso seja outros lançamentos
  updatedInput: ISeller | ITotal,
  token: string | null,
  isStore: boolean
) {
  return {
    type: '@input/UPDATE_REQUEST',
    payload: { storeId, inputId, updatedInput, token, isStore }
  }
}

export function inputUpdateSuccess(updatedInput: IInput) {
  return {
    type: '@input/UPDATE_SUCCESS',
    payload: { updatedInput }
  }
}

export function handleOpenModal() {
  return {
    type: '@modal/INPUT_OPEN'
  }
}

export function handleCloseModal() {
  return {
    type: '@modal/INPUT_CLOSE'
  }
}

export function inputDeleteRequest(inputId: string, token: string | null) {
  return {
    type: '@input/DELETE_REQUEST',
    payload: { inputId, token }
  }
}

export function inputDeleteSuccess() {
  return {
    type: '@input/DELETE_SUCCESS'
  }
}
