import { IDay, IDayListResponse, IListDayReport, IPeriod } from './types'

export function dayReportRequest(
  storeId: string,
  token: string | null,
  period: IPeriod
) {
  return {
    type: '@day/REPORT_REQUEST',
    payload: { storeId, token, period }
  }
}

export function dayReportSuccess(dayReport: IListDayReport, period: IPeriod) {
  return {
    type: '@day/REPORT_SUCCESS',
    payload: { dayReport, period }
  }
}

export function dayListRequest(
  storeId: string,
  token: string | null,
  page = 1,
  limit = 25
) {
  return {
    type: '@day/LIST_REQUEST',
    payload: { storeId, token, page, limit }
  }
}

export function dayListSuccess(dayListResponse: IDayListResponse) {
  return {
    type: '@day/LIST_SUCCESS',
    payload: { dayListResponse }
  }
}

export function dayRequest(dayId: string, token: string | null) {
  return {
    type: '@day/REQUEST',
    payload: { dayId, token }
  }
}

export function daySuccess(day: IDay) {
  return {
    type: '@day/SUCCESS',
    payload: { day }
  }
}
