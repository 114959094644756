import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Material UI
import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/styles'
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Stepper,
  Step,
  StepLabel,
  InputAdornment
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

// Components
import TabPanel from '../../../components/TabPanel'
import { CodeInputMask } from './EditUserModal'
import { BootstrapTooltip } from 'components/BootstrapTooltip'

// Utils
import { phoneMask, cpfMask } from '../../../utils/inputMasks'

const useStyles = makeStyles(theme => ({
  root: {},
  textField: {},
  selectMargin: { marginTop: theme.spacing(2), marginBottom: theme.spacing(1) },

  //override do tema padrão do Dialog linha 190
  overFlowActive: {
    overflow: 'hidden'
  }
}))

const AddUserModal = ({
  active,
  onClose,
  addUser,
  loading,
  searchLoading,
  handleCheckCpf,
  userExists,
  linkUser,
  error,
  currentGoal,
  currentUserStoreType,
  availableRoles,
  ...rest
}) => {
  const classes = useStyles()
  const confirm = useConfirm()

  const defaultData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    birthDate: '',
    gender: '',
    storeType: 'seller',
    phone: '',
    code: '',
    cpf: ''
  }

  const [tab, setTab] = useState(0)
  const [data, setData] = useState(defaultData)
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  const formattedCpf = data.cpf.replace(/[^a-zA-Z0-9]/g, '')

  useEffect(() => {
    if (formattedCpf.length === 11) {
      handleCheckCpf(formattedCpf)
    }
  }, [formattedCpf])

  useEffect(() => {
    setData(defaultData)
    setTab(0)
  }, [active])

  useEffect(() => {
    if (tab === 1 && userExists.found) {
      setTab(0)
    } else if (tab === 0 && !userExists.found) {
      setData({
        ...data,
        password: formattedCpf.substring(0, 6),
        code: formattedCpf.substring(0, 4)
      })
      setTab(1)
    } else if (tab === 0 && userExists.found) {
      setData({
        ...data,
        code: formattedCpf.substring(0, 4)
      })
    }
  }, [userExists])

  const handleLinkUser = () => {
    const submitData = {
      type: data.storeType
    }
    if (data.storeType.length > 0) {
      if (data.storeType === 'seller' && data.code.length === 0) {
        confirm({
          title: 'Ops!',
          description: `O Código de segurança é obrigatório!`,
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
          .then(() => {})
          .catch(() => {})
        return
      } else {
        if (data.storeType === 'seller') submitData.code = data.code
      }
    } else {
      confirm({
        title: 'Ops!',
        description: `Preencha todos os campos antes de avançar!`,
        confirmationText: 'Ok!',
        cancellationText: 'Fechar'
      })
        .then(() => {})
        .catch(() => {})
      return
    }
    linkUser(submitData)
  }
  const handleChangeTab = (iOld, iNew) => {
    if (iOld === 0 && iNew === 1) {
      if (!error) {
        setTab(1)
      } else {
        confirm({
          title: 'Ops!',
          description: `Preencha todos os campos corretamente!`,
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
          .then(() => {})
          .catch(() => {})
      }
    } else {
      if (
        data.firstName.length > 0 &&
        data.lastName.length > 0 &&
        data.gender.length > 0 &&
        data.phone.length > 0 &&
        data.storeType.length > 0 &&
        data.password.length > 0 &&
        (data.storeType !== 'seller' ? data.email.length > 0 : true) &&
        (data.storeType === 'seller' ? data.code.length > 0 : true)
      ) {
        const submitData = {
          name: {
            first: data.firstName,
            last: data.lastName
          },
          gender: data.gender,
          phone: data.phone.replace(/[^a-zA-Z0-9]/g, ''),
          storeType: data.storeType,
          password: data.password
        }

        if (data.cpf) submitData.cpf = data.cpf.replace(/[^a-zA-Z0-9]/g, '')
        if (data.code) submitData.code = data.code
        if (data.email) submitData.email = data.email
        if (data.birthDate) submitData.birthDate = data.birthDate

        addUser(submitData)
      } else {
        confirm({
          title: 'Ops!',
          description: `Preencha todos os campos!`,
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
      }
    }
  }

  return (
    <div {...rest} className={classes.root}>
      <Dialog open={active} onClose={onClose} maxWidth='xs' fullWidth>
        <form className={classes.root} autoComplete='off'>
          <DialogTitle id='form-dialog-title'>Adicionar Usuário</DialogTitle>
          <DialogContent className={classes.overFlowActive}>
            <Stepper activeStep={tab} className={classes.root}>
              <Step key={0}>
                <StepLabel>Buscar usuário</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Dados</StepLabel>
              </Step>
            </Stepper>
            <TabPanel value={tab} index={0}>
              {userExists.found && data.cpf.length === 14 && !error ? (
                <DialogContentText>
                  Já existe um usuário com este CPF. Preencha os dados abaixo
                  para vincular o usuário a sua loja.
                </DialogContentText>
              ) : (
                <DialogContentText>
                  Digite um CPF para buscar por um usuário já existente ou vá
                  para o próximo passo.
                </DialogContentText>
              )}
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='cpf'
                name='cpf'
                label='CPF'
                disabled={searchLoading}
                value={data.cpf}
                onChange={event =>
                  setData({
                    ...data,
                    cpf: event.target.value
                  })
                }
                onFocus={event => event.target.select()}
                className={classes.textField}
                InputProps={{
                  'data-cy': 'cpf',
                  inputComponent: cpfMask,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {searchLoading && <CircularProgress size={24} />}
                    </InputAdornment>
                  )
                }}
                error={!!error}
                helperText={error && error.error.friendlyMsg}
              />
              {userExists.found && data.cpf.length === 14 && !error && (
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.selectMargin}
                >
                  <InputLabel htmlFor='storeType'>Tipo</InputLabel>
                  <Select
                    id='storeType'
                    name='storeType'
                    value={data.storeType}
                    onChange={event => {
                      if (event.target.value !== 'seller') {
                        setData({
                          ...data,
                          storeType: event.target.value,
                          code: ''
                        })
                      } else {
                        setData({
                          ...data,
                          storeType: event.target.value,
                          ...(data.cpf && {
                            code: formattedCpf.substring(0, 4)
                          })
                        })
                      }
                    }}
                    labelWidth={35}
                    data-cy='store-type-user-exists'
                  >
                    {currentUserStoreType &&
                      Object.entries(availableRoles[currentUserStoreType]).map(
                        ([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              )}
              {data.storeType === 'seller' &&
                userExists.found &&
                data.cpf.length === 14 &&
                !error && (
                  <BootstrapTooltip
                    disableHoverListener
                    title='Defina um código de 4 números que o vendedor utilizará para acessar sua conta através do aplicativo da loja.'
                    arrow
                    placement='left'
                  >
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='code'
                      name='code'
                      label='Código de segurança - 4 dígitos'
                      InputProps={{
                        inputComponent: CodeInputMask
                      }}
                      inputProps={{ 'date-cy': 'code-user-exists' }}
                      value={data.code}
                      onChange={event =>
                        setData({
                          ...data,
                          code: event.target.value
                        })
                      }
                      onFocus={event => event.target.select()}
                      className={classes.textField}
                    />
                  </BootstrapTooltip>
                )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='firstName'
                    name='firstName'
                    label='Nome'
                    value={data.firstName}
                    onChange={event =>
                      setData({
                        ...data,
                        firstName: event.target.value
                      })
                    }
                    onFocus={event => event.target.select()}
                    className={classes.textField}
                    inputProps={{
                      'data-cy': 'last-name'
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='lastName'
                    name='lastName'
                    label='Sobrenome'
                    value={data.lastName}
                    onChange={event =>
                      setData({
                        ...data,
                        lastName: event.target.value
                      })
                    }
                    onFocus={event => event.target.select()}
                    className={classes.textField}
                    inputProps={{
                      'data-cy': 'first-name'
                    }}
                  />
                </Grid>
              </Grid>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.selectMargin}
              >
                <InputLabel htmlFor='storeType'>Tipo</InputLabel>
                <Select
                  id='storeType'
                  name='storeType'
                  value={data.storeType}
                  onChange={event => {
                    if (event.target.value !== 'seller') {
                      setData({
                        ...data,
                        storeType: event.target.value,
                        code: ''
                      })
                    } else {
                      setData({
                        ...data,
                        storeType: event.target.value,
                        ...(data.cpf && { code: formattedCpf.substring(0, 4) })
                      })
                    }
                  }}
                  labelWidth={35}
                  inputProps={{
                    'data-cy': 'store-type'
                  }}
                >
                  {currentUserStoreType &&
                    Object.entries(availableRoles[currentUserStoreType]).map(
                      ([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              {data.storeType !== 'seller' ? (
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='email'
                  name='email'
                  label='Email'
                  value={data.email}
                  onChange={event =>
                    setData({
                      ...data,
                      email: event.target.value
                    })
                  }
                  onFocus={event => event.target.select()}
                  className={classes.textField}
                  inputProps={{
                    'data-cy': 'email'
                  }}
                />
              ) : (
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='cpf'
                  name='cpf'
                  label='CPF'
                  value={data.cpf}
                  onChange={event =>
                    setData({
                      ...data,
                      cpf: event.target.value
                    })
                  }
                  onBlur={() => {
                    if (
                      !data.password ||
                      (!data.code && data.storeType === 'seller')
                    ) {
                      setData({
                        ...data,
                        ...(!data.password && {
                          password: formattedCpf.substring(0, 6)
                        }),
                        ...(data.storeType === 'seller' &&
                          !data.code && {
                            code: formattedCpf.substring(0, 4)
                          })
                      })
                    }
                  }}
                  onFocus={event => event.target.select()}
                  className={classes.textField}
                />
              )}
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.selectMargin}
              >
                <InputLabel htmlFor='gender'>Gênero</InputLabel>
                <Select
                  id='gender'
                  name='gender'
                  value={data.gender}
                  onChange={event =>
                    setData({
                      ...data,
                      gender: event.target.value
                    })
                  }
                  labelWidth={35}
                  data-cy='gender-select'
                >
                  <MenuItem value='female'>Feminino</MenuItem>
                  <MenuItem data-cy='gender-male' value='male'>
                    Masculino
                  </MenuItem>
                  <MenuItem value='other'>Outro</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='phone'
                name='phone'
                label='Telefone'
                value={data.phone}
                onChange={event =>
                  setData({
                    ...data,
                    phone: event.target.value
                  })
                }
                onFocus={event => event.target.select()}
                className={classes.textField}
                InputProps={{
                  'data-cy': 'phone',
                  inputComponent: phoneMask
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='password'
                name='password'
                label='Senha'
                value={data.password}
                onChange={event =>
                  setData({
                    ...data,
                    password: event.target.value
                  })
                }
                onFocus={event => event.target.select()}
                className={classes.textField}
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete='new-password'
                inputProps={{
                  'data-cy': 'password',
                  'data-clarity-mask': 'True'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setIsPasswordVisible(prev => !prev)}
                      >
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {data.storeType === 'seller' && (
                <BootstrapTooltip
                  disableHoverListener
                  title='Defina um código de 4 números que o vendedor utilizará para acessar sua conta através do aplicativo da loja.'
                  arrow
                  placement='left'
                >
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='code'
                    name='code'
                    label='Código de segurança - 4 dígitos'
                    placeholder='0000'
                    value={data.code}
                    InputProps={{
                      inputComponent: CodeInputMask
                    }}
                    inputProps={{ 'data-cy': 'code' }}
                    onChange={event =>
                      setData({
                        ...data,
                        code: event.target.value
                      })
                    }
                    onFocus={event => event.target.select()}
                    className={classes.textField}
                  />
                </BootstrapTooltip>
              )}
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => (tab === 0 ? onClose() : setTab(0))}
              color='primary'
              disabled={loading || searchLoading}
            >
              {tab === 0 ? 'Cancelar' : 'Voltar'}
            </Button>
            <Button
              disabled={
                loading ||
                searchLoading ||
                (tab === 0 && formattedCpf.length !== 11)
              }
              color='primary'
              onClick={() =>
                userExists.found && !error
                  ? handleLinkUser()
                  : handleChangeTab(tab, tab + 1)
              }
              data-cy='btn-create-user'
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {userExists.found
                    ? 'Vincular usuário'
                    : tab !== 1
                    ? 'Próximo'
                    : 'Adicionar'}
                </>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

AddUserModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  addUser: PropTypes.func,
  loading: PropTypes.bool,
  searchLoading: PropTypes.bool,
  handleCheckCpf: PropTypes.func,
  userExists: PropTypes.object,
  linkUser: PropTypes.func,
  error: PropTypes.object,
  currentGoal: PropTypes.object
}

export default AddUserModal
