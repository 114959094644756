import { Container } from './style'

interface TopTableDaysProps {
  topTable: string[]
}

const TopTableDays = ({ topTable }: TopTableDaysProps) => {
  return (
    <Container topTable={topTable}>
      {topTable?.map((table, i) => (
        <span key={i}>{table}</span>
      ))}
    </Container>
  )
}

export default TopTableDays
