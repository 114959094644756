import React from 'react'
import PropTypes from 'prop-types'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { Container } from './styles'
import CurrencyTextField from 'components/CurrencyTextField'
import Tooltip from 'components/Tooltip'

const SalaryCard = ({
  value,
  onChange,
  cardTitle,
  tooltipContent,
  disclaimerText,
  inputLabel,
  dataCy = null,
  hasError = false
}) => {
  return (
    <Container>
      <header className='card-check-header'>
        <h1>{cardTitle}</h1>
        {tooltipContent && (
          <>
            <ErrorOutlineIcon
              data-tip
              data-for={cardTitle}
              style={{ color: '#C4C4C4', fontSize: 20 }}
            />
            <Tooltip id={cardTitle} content={tooltipContent} />
          </>
        )}
      </header>
      {disclaimerText && <span className='disclaimer'>{disclaimerText}</span>}
      <div className='card-check-input-container' data-cy={dataCy}>
        <CurrencyTextField
          error={hasError}
          data-cy='wageFloorInput'
          label={inputLabel}
          variant='outlined'
          fullWidth
          value={value}
          currencySymbol='R$'
          decimalCharacter=','
          digitGroupSeparator='.'
          inputProps={{
            style: { textAlign: 'left' },
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
          InputLabelProps={{ shrink: false }}
          onChange={onChange}
          onWheel={e => e.target.blur()}
        />
      </div>
    </Container>
  )
}

SalaryCard.propTypes = {
  cardTitle: PropTypes.string,
  inputLabel: PropTypes.string,
  tooltipContent: PropTypes.element,
  disclaimerText: PropTypes.string,
  value: PropTypes.number,
  setValue: PropTypes.func
}

export default SalaryCard
