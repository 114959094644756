import { Action } from 'redux'
import produce from 'immer'
import { ICalendarGoalProps } from './types'

const INITIAL_STATE: ICalendarGoalProps = {
  days: [],
  disabledDays: []
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function store(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@calendarGoal/SET_INITIAL_CALENDAR_VALUES': {
        draft.days = action.payload.days
        break
      }
      case '@calendarGoal/SET_DISABLED_DAYS': {
        draft.disabledDays = action.payload.daysIndex
        break
      }
      case '@calendarGoal/ADD_DISABLED_DAY': {
        draft.disabledDays.push(action.payload.dayIndex)
        break
      }
      case '@calendarGoal/CLEAR_DISABLED_DAYS': {
        draft.disabledDays = []
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@calendarGoal/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
