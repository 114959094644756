import styled from 'styled-components'
import { Button as ButtonUI } from '@material-ui/core'
import palette from 'theme/palette'

export const Container = styled.div`
  padding: 32px;
`
export const Content = styled.div``

export const SubHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Button = styled(ButtonUI)`
  min-height: 36.5px;
  background: ${palette.primary.main} !important;
  color: ${palette.white} !important;

  transform: background-color 0.2s;

  &:hover {
    background: ${palette.primary.light} !important;
  }
`
