import { Action } from 'redux'
import produce from 'immer'
import { IUserProps, IUsersGroupList } from './types'

const INITIAL_STATE: IUserProps = {
  user: {
    _id: '',
    birthDate: '',
    cpf: '',
    createdAt: '',
    companyId: undefined,
    company: {
      _id: '',
      hasNeoStory: false,
      tags: [],
      name: '',
      photo: {
        url: ''
      }
    },
    email: '',
    fcm: '',
    gender: 'other',
    open: false,
    password: '',
    permissions: {
      changePassword: false
    },
    phone: '',
    photo: {
      url: null
    },
    twofa: [],
    name: {
      first: '...',
      last: '...',
      complete: '...'
    },
    type: 'user',
    stores: [],
    updatedAt: ''
  },
  loading: false,
  searchLoading: false,
  error: null,
  newUser: {
    success: false,
    type: '',
    userId: ''
  },
  userList: [],
  usersGroupList: [],
  storylineStoresAndGroups: null,
  passwordModal: false,
  addUserModal: false,
  userExists: {
    found: false
  },
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 10
    },
    sorting: {
      createdAt: -1
    }
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function user(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/REQUEST': {
        draft.loading = true
        break
      }
      case '@user/SUCCESS': {
        draft.user = action.payload.user
        draft.loading = false
        break
      }
      case '@user/USER_REQUEST_ON_SIGN_IN': {
        draft.loading = true
        break
      }
      case '@user/FAILED': {
        draft.error = action.payload.error
        draft.loading = false
        break
      }
      case '@user/LIST_REQUEST': {
        if (action.payload.forceLoading) draft.loading = true
        draft.searchLoading = true
        break
      }
      case '@user/LIST_SUCCESS': {
        draft.userList = action.payload.dataRequest.items
        draft.metadata = action.payload.dataRequest.metadata
        draft.searchLoading = false
        draft.loading = false
        break
      }
      case '@user/USERS_GROUP_LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/USERS_GROUP_LIST_SUCCESS': {
        draft.usersGroupList = action.payload.usersGroupList.sort(
          (a: IUsersGroupList, b: IUsersGroupList) =>
            a.name.complete.localeCompare(b.name.complete)
        )
        draft.loading = false
        break
      }
      case '@user/UPDATE_USER_GROUP_LIST': {
        const { updatedUser } = action.payload
        const userIndex = draft.usersGroupList.findIndex(
          user => user._id === updatedUser._id
        )

        draft.usersGroupList[userIndex] = updatedUser
        draft.usersGroupList = draft.usersGroupList.sort((a, b) =>
          a.name.complete.localeCompare(b.name.complete)
        )

        draft.loading = false
        break
      }
      case '@user/ADD_REQUEST': {
        draft.loading = true
        draft.newUser.type = action.payload.newUser.storeType
        break
      }
      case '@user/EDIT_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/CHANGE_PASSWORD_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/CHANGE_PASSWORD_SUCCESS': {
        draft.passwordModal = false
        draft.loading = false
        break
      }
      case '@user/CHANGE_PASSWORD_OPEN_MODAL': {
        draft.passwordModal = true
        break
      }
      case '@user/CHANGE_PASSWORD_CLOSE_MODAL': {
        draft.passwordModal = false
        break
      }
      case '@user/EDIT_SUCCESS': {
        const userIndex = draft.userList.findIndex(
          u => u._id === action.payload.userId
        )
        if (userIndex >= 0) {
          draft.userList[userIndex] = {
            ...action.payload.data,
            stores: draft.userList[userIndex].stores,
            isOnThisMonthGoal: draft.userList[userIndex].isOnThisMonthGoal
          }
        } else if (draft.user._id == action.payload.data._id) {
          draft.user = {
            ...action.payload.data,
            stores: draft.user.stores
          }
        }
        draft.loading = false
        break
      }
      case '@user/UNLINK_SUCCESS': {
        draft.userList = draft.userList.filter(
          u => u._id !== action.payload.userId
        )
        const updatePagination = {
          ...draft.metadata,
          pagination: {
            ...draft.metadata.pagination,
            pageCount: draft.metadata.pagination.pageCount - 1,
            totalCount: draft.metadata.pagination.totalCount - 1
          }
        }
        draft.metadata = updatePagination
        break
      }
      case '@user/ADD_SUCCESS': {
        draft.userList = [action.payload.newUser, ...draft.userList]
        draft.addUserModal = false
        draft.loading = false
        draft.error = null
        draft.newUser.success = true
        draft.newUser.userId = action.payload.newUser._id //Relacionado ao BUG de tela branca quando vincula usuario
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@user/AVATAR_UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/AVATAR_UPDATE_SUCCESS': {
        draft.user.photo.url = action.payload.url
        draft.loading = false
        break
      }
      case '@user/EXISTS_REQUEST': {
        draft.searchLoading = true
        break
      }
      case '@user/EXISTS_SUCCESS': {
        draft.userExists = action.payload.userExists
        draft.searchLoading = false
        draft.error = null
        break
      }
      case '@user/USER_EXISTS_FAILED': {
        draft.searchLoading = false
        draft.loading = false
        draft.error = action.payload.error
        break
      }
      case '@user/LINK_REQUEST': {
        draft.loading = false
        draft.newUser.type = action.payload.data.type
        const updatePagination = {
          ...draft.metadata,
          pagination: {
            ...draft.metadata.pagination,
            pageCount:
              draft.metadata.pagination.pageCount < 10
                ? draft.metadata.pagination.pageCount + 1
                : draft.metadata.pagination.pageCount,
            totalCount: draft.metadata.pagination.totalCount + 1
          }
        }
        draft.metadata = updatePagination
        break
      }
      case '@user/LINK_SUCCESS': {
        draft.userList = [action.payload.data, ...draft.userList]
        draft.loading = false
        draft.addUserModal = false
        draft.error = null
        draft.newUser.success = true
        draft.newUser.userId = action.payload.data._id
        break
      }
      case '@user/CLEAR_NEW_USER': {
        draft.newUser = {
          success: false,
          type: '',
          userId: ''
        }
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        draft.searchLoading = false
        draft.error = action.payload?.error
        break
      }
      case '@user/ADD_MODAL': {
        draft.addUserModal = action.payload.isAddUserModalOpen
        draft.error = null
        break
      }
      case '@user/GET_STORYLINE_STORES_AND_GROUPS_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/GET_STORYLINE_STORES_AND_GROUPS_SUCCESS': {
        draft.storylineStoresAndGroups = action.payload.storylineStores
        draft.loading = false
        break
      }
      case '@main/CLEAR_REDUX': {
        draft.userList = []
        draft.metadata = {
          pagination: {
            currentPage: 1,
            pageCount: 1,
            totalCount: -1,
            limit: 10
          },
          sorting: {
            createdAt: -1
          }
        }
        break
      }
      default:
        return state
    }
  })
}
