import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears
} from 'date-fns'

// eslint-disable-next-line no-unused-vars
import { DefinedRange } from './types'

export const getDefaultRanges = (
  date: Date,
  locale?: Locale
): DefinedRange[] => [
  {
    label: 'Hoje',
    startDate: date,
    endDate: date
  },
  {
    label: 'Ontem',
    startDate: addDays(date, -1),
    endDate: addDays(date, -1)
  },
  {
    label: 'Esta semana',
    startDate: startOfWeek(date, { locale }),
    endDate: endOfWeek(date, { locale })
  },
  {
    label: 'Última semana',
    startDate: startOfWeek(addWeeks(date, -1), { locale }),
    endDate: endOfWeek(addWeeks(date, -1), { locale })
  },
  {
    label: 'Últimos 7 dias',
    startDate: addWeeks(date, -1),
    endDate: date
  },
  {
    label: 'Este mês',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date)
  },
  {
    label: 'Último mês',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1))
  },
  {
    label: 'Este ano',
    startDate: startOfYear(date),
    endDate: endOfYear(date)
  },
  {
    label: 'Último ano',
    startDate: startOfYear(addYears(date, -1)),
    endDate: endOfYear(addYears(date, -1))
  }
]
