import { useEffect, useState } from 'react'

// Material UI
import Checkbox from '@material-ui/core/Checkbox'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import SpeedIcon from '@material-ui/icons/Speed'
import CloseIcon from '@material-ui/icons/Close'

// Styles
import { Container } from './styles'
import GoalSliderModal from '../GoalSliderModal'
import Tooltip from 'components/Tooltip'
import UserDefaultPhoto from 'views/Goals/components/AddGoal/components/UserDefaultPhoto'
import { Edit, Lock } from '@material-ui/icons'
import { IGoal, IUserWorking } from 'store/modules/goal/types'
import { ICurrentGoalProps } from 'views/Goals/components/AddGoal/components/StepperContainer/GoalStep'
interface TransferListCardProps {
  data?: ICurrentGoalProps | IGoal
  usersWorking?: IUserWorking[]
  working?: boolean
  inGoal?: boolean
  user: IUserWorking
  days?: number
  onClick: () => void
  updateUserDSR?: (user: IUserWorking, dsr: boolean) => void
  updateUserWeight?: (
    user: IUserWorking,
    type: 'auto' | 'fixed',
    weight: number
  ) => void
  isGoalOverview?: boolean
}

const TransferListCard = ({
  data,
  usersWorking,
  working,
  inGoal,
  user,
  days,
  onClick,
  updateUserDSR,
  updateUserWeight,
  isGoalOverview = false
}: TransferListCardProps) => {
  const [isGoalModalOpen, setIsGoalModalOpen] = useState(false)

  const [seller, setSeller] = useState<IUserWorking | null>(null) // state para armazenar e nao modificar o user
  const [auxSellerWeight, setAuxSellerWeight] = useState<string | number>(1) // state que vai capturar o peso se for alterado no slider

  useEffect(() => {
    if (user) {
      setSeller(user)
      setAuxSellerWeight(user.goalWeight)
    }
  }, [user])

  // isGoalOverview quer dizer que esse componente ta sendo rederizado no tab vendedores la no overview da meta
  return (
    <Container
      isGoalOverview={isGoalOverview}
      onClick={() => isGoalOverview && onClick()}
    >
      <div className='sellers-list-info-container'>
        {user.userId?.photo?.url ? (
          <img
            style={{ backgroundSize: 'cover' }}
            src={user.userId?.photo?.url}
            alt={user.userId?.name?.complete}
          />
        ) : (
          <UserDefaultPhoto username={user.userId?.name?.first} />
        )}
        <strong>
          {user.userId?.name?.complete.length >= (working ? 20 : 30)
            ? (user.userId?.name?.complete).substring(
                0,
                (working ? 20 : 30) - 3
              ) + '...'
            : user.userId?.name?.complete}
        </strong>
        {isGoalOverview && <span>{days} dia(s)</span>}
      </div>
      <div className='sellers-list-action-container'>
        {inGoal && (
          <>
            {data && data.dsr > 0 && !isGoalOverview && (
              <>
                <Tooltip id='dsr-tooltip' content={<p>Irá receber DSR?</p>} />
                <button
                  className='user-card-button'
                  disabled={isGoalOverview}
                  data-tip
                  data-for='dsr-tooltip'
                  onClick={() => {
                    updateUserDSR && updateUserDSR(user, !seller?.dsr)
                    seller && setSeller({ ...seller, dsr: !seller.dsr })
                  }}
                >
                  <Checkbox
                    color='default'
                    style={{ margin: 0, padding: 0 }}
                    checked={seller?.dsr ?? false}
                  />
                  DSR
                </button>
              </>
            )}

            {!isGoalOverview && (
              <Tooltip
                id='weight-tooltip'
                content={<p>Alterar peso do vendedor</p>}
              />
            )}
            <button
              disabled={isGoalOverview}
              className='user-card-button'
              data-tip
              data-for='weight-tooltip'
              onClick={() => !isGoalOverview && setIsGoalModalOpen(true)}
              style={{ ...(user.weightLocked && { color: '#263238' }) }}
            >
              {user.weightLocked ? <Lock /> : <SpeedIcon />}
              <span>{((seller?.goalWeight ?? 1) * 100).toFixed(2)}%</span>
            </button>
          </>
        )}
        <button className={!working ? 'notworking' : 'null'} onClick={onClick}>
          {isGoalOverview ? (
            <Edit fontSize='small' />
          ) : inGoal ? (
            <CloseIcon className='ungoal-back-button' />
          ) : (
            <ChevronRightIcon />
          )}
        </button>
      </div>
      <GoalSliderModal
        usersWorking={usersWorking}
        sellerWeight={user.goalWeight}
        sellerName={user.userId?.name?.complete}
        data={data}
        seller={seller}
        auxSellerWeight={auxSellerWeight}
        setAuxSellerWeight={setAuxSellerWeight}
        open={isGoalModalOpen}
        onClose={() => {
          setIsGoalModalOpen(false)
        }}
        onSave={(type: 'auto' | 'fixed') => {
          setIsGoalModalOpen(false)
          updateUserWeight &&
            updateUserWeight(
              user,
              type,
              typeof auxSellerWeight === 'number'
                ? auxSellerWeight
                : Number(auxSellerWeight) / 100
            )
        }}
      />
    </Container>
  )
}

export default TransferListCard
