import { Action } from 'redux'
import produce from 'immer'
import { ISprint, ISprintProps } from './types'

const INITIAL_STATE: ISprintProps = {
  activeSprints: [],
  finishedSprints: [],
  loading: false,
  hasRequested: false,
  metadata: {
    pagination: {
      currentPage: 1,
      limit: 5,
      pageCount: 1,
      totalCount: -1
    }
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function sprints(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@sprints/GET_ACTIVE_SPRINTS_REQUEST': {
        draft.loading = true
        break
      }
      case '@sprints/GET_FINISHED_SPRINTS_REQUEST': {
        draft.loading = true
        break
      }
      case '@sprints/GET_ACTIVE_SPRINTS_SUCCESS': {
        draft.activeSprints = action.payload.activeSprints.sort(
          (a: ISprint, b: ISprint) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        )
        draft.loading = false
        draft.hasRequested = true
        break
      }
      case '@sprints/GET_FINISHED_SPRINTS_SUCCESS': {
        draft.finishedSprints = action.payload.finishedSprints.sort(
          (a: ISprint, b: ISprint) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        )
        draft.metadata = action.payload.metadata
        draft.loading = false
        draft.hasRequested = true
        break
      }
      case '@sprints/CREATE_NEW_SPRINT_REQUEST': {
        draft.loading = true
        break
      }
      case '@sprints/CREATE_NEW_SPRINT_SUCCESS': {
        if (!!action.payload.newSprint) {
          draft.activeSprints = [
            ...draft.activeSprints,
            action.payload.newSprint
          ].sort(
            (a: ISprint, b: ISprint) =>
              new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
          )
        }
        draft.loading = false
        break
      }
      case '@sprints/UPDATE_SPRINT_SUCCESS': {
        const updatedSprintIndex = draft.activeSprints.findIndex(
          sprints => sprints._id === action.payload.updatedSprint._id
        )

        if (updatedSprintIndex >= 0) {
          draft.activeSprints[updatedSprintIndex] = action.payload.updatedSprint
        }
        break
      }
      case '@sprints/UPDATE_SPRINT_AVATAR_SUCCESS': {
        const sprint = draft.activeSprints.find(sprint =>
          action.payload.sprintsIds.includes(sprint._id)
        )
        const updatedSprintIndex = draft.activeSprints.findIndex(
          sprints => sprints._id === sprint?._id
        )

        if (updatedSprintIndex >= 0) {
          draft.activeSprints[updatedSprintIndex].photo.url =
            action.payload.updatedSprintAvatarUrl
        }
        break
      }
      case '@sprints/DELETE_SPRINT_SUCCESS': {
        const deletedSprintIndex = draft.activeSprints.findIndex(
          sprints => sprints._id === action.payload.sprintId
        )
        if (deletedSprintIndex >= 0) {
          draft.activeSprints.splice(deletedSprintIndex, 1)
        }
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
