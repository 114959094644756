import { Action } from 'redux'
import produce from 'immer'

import { IGroupStoresList, IStoreProps } from './types'

const INITIAL_STATE: IStoreProps = {
  groupStoresList: [],
  store: null,
  loading: false,
  metadata: {
    pagination: {
      totalCount: -1
    }
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function store(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@store/LIST_GROUP_STORES_REQUEST': {
        draft.loading = true
        break
      }
      case '@store/LIST_GROUP_STORES_SUCCESS': {
        draft.groupStoresList = action.payload.groupStoresList.sort(
          (a: IGroupStoresList, b: IGroupStoresList) =>
            a.name.localeCompare(b.name)
        )
        draft.loading = false
        break
      }
      case '@store/LIST_GROUP_STORES_FAILED': {
        draft.loading = false
        draft.groupStoresList = []
        break
      }
      case '@store/SUCCESS': {
        draft.store = action.payload.store
        break
      }
      case '@store/UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@store/UPDATE_SUCCESS': {
        draft.store = action.payload.updatedStore
        draft.loading = false
        break
      }
      case '@store/AVATAR_UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@store/AVATAR_UPDATE_SUCCESS': {
        if (draft.store) {
          draft.store.photo.url = action.payload.url
        }
        draft.loading = false
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@integration/SUCCESS': {
        if (draft.store) {
          draft.store.integration = action.payload.dataRequest
        }
        break
      }
      case '@integration/DISCONNECT_SUCCESS': {
        if (draft.store) {
          draft.store.integration = null
        }
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@user/EDIT_SUCCESS': {
        if (draft.store) {
          const modifiedUserIndex = draft.store.users.findIndex(
            user => user._id === action.payload.data._id
          )
          // Atualiza os usuários da loja quando um usuário é editado
          if (modifiedUserIndex >= 0) {
            draft.store.users[modifiedUserIndex] = action.payload.data
          }
        }
        break
      }
      case '@user/ADD_SUCCESS': {
        if (draft.store) {
          // Atualiza os usuários da store conforme um novo usuário é adicionar
          draft.store.users = [action.payload.newUser, ...draft.store.users]
        }
        break
      }
      case '@user/LINK_SUCCESS': {
        if (draft.store) {
          // Atualiza a store caso um usuário já existente seja vinculado a meta
          draft.store.users = [action.payload.data, ...draft.store.users]
        }
        break
      }
      case '@user/UNLINK_SUCCESS': {
        if (draft.store) {
          // Index do usuário que está sendo desvinculado
          const unLinkUserIndex = draft.store.users.findIndex(
            user => user._id === action.payload.userId
          )
          // Remove o usuário da store
          draft.store.users.splice(unLinkUserIndex, 1)
        }
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
