import styled from 'styled-components'
import { Card as CardMui } from '@mui/material'

export const IconContainer = styled.div`
  display: flex;
  width: 100%;

  gap: 1rem;
`

export const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  gap: 16px;
`

export const MediaInfoCard = styled.div`
  width: 100%;
  display: flex;

  gap: 14px;

  text-align: left;

  div:first-child {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: #5b5b76;

    > span {
      color: #9a9ab1;
      vertical-align: top;
      text-align: left;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 0.75rem;

      margin-top: 8px;
      width: 100%;
    }
  }

  div:last-child {
    display: flex;
    flex-direction: row;
  }
`
export const IconButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  background-color: #e3e3e8;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:last-child {
    margin-left: 6px;
  }

  & > svg {
    color: #5b5b76;
  }
`

export const Button = styled.label`
  border: 2px solid #5b5b76;
  background-color: transparent;
  border-radius: 50px;
  min-width: 136px;
  padding: 6px;
  text-align: center;
  color: #5b5b76;
  font-size: 0.825rem;
  font-weight: 700;
  font-family: 'Commissioner', sans-serif;

  max-height: 33px;
  cursor: pointer;
`

export const IconLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 0.65625rem;
  color: #949494;
  font-weight: 400;
  margin-bottom: 8px;
`
export const Card = styled(CardMui)`
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 14px !important;
  background-color: transparent !important;
  border: 1px solid rgba(196, 196, 196) !important;
`
