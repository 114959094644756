import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import { Backdrop, Checkbox, FormControlLabel, Modal } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { makeStyles } from '@material-ui/core/styles'

// Assets
import ProftLogo from '../../../../../../../../assets/Icons/ProftLogo'
import PromptModal from '../../../PromptModal'
import ExitIcon from '../../../../../../../../assets/Icons/ExitIcon'
import { useDispatch, useSelector } from 'react-redux'
import { goalListRequest } from 'store/modules/goal/actions'
import palette from 'theme/palette'

const Navbar = ({
  data,
  onBack,
  onBackSave,
  onSave,
  loading,
  canSaveGoal,
  storeId,
  token,
  activeStep
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const store = useSelector(state => state.store.store)

  const [notShowAgain, setNotShowAgain] = useState()
  const [isShowModal, setIsShowModal] = useState(false)

  const handleToggleShowModal = show => {
    localStorage.setItem('@NeoPro:CreateGoal-Show-Navbar-Exit-Modal', show)
  }

  useEffect(() => {
    if (localStorage.getItem('@NeoPro:CreateGoal-Show-Navbar-Exit-Modal'))
      setNotShowAgain(true)
  }, [])

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        alignContent='space-between'
      >
        <Grid item xs={12}>
          <div className={styles.container}>
            <div
              onClick={onBackSave}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <img src='/images/logo-blue.svg' alt='NeoPro Logo' />
              <span
                style={{
                  fontFamily: 'Roboto, sans-serif',
                  marginLeft: 10,
                  fontSize: 14
                }}
              >
                Cadastrar meta do mês{' '}
                <strong>{store && `| ${store.name}`}</strong>
              </span>
            </div>
            <div className={styles.buttonContainer}>
              {!canSaveGoal && (
                <div className={styles.saveMessageContainer}>
                  <DoneAllIcon />
                  <Typography
                    style={{
                      color: '#27AE60',
                      marginLeft: '0.5rem',
                      marginRight: 10
                    }}
                  >
                    Informações salvas com sucesso
                  </Typography>
                </div>
              )}
              {canSaveGoal && data.month && (
                <Button
                  className={styles.button}
                  onClick={onSave}
                  disabled={
                    loading ||
                    data == {} ||
                    (activeStep === 3 && data.usersWorking.length === 0)
                  }
                >
                  {loading ? (
                    <CircularProgress style={{ color: '#27AE60' }} size={20} />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              )}
              <Button
                variant='outlined'
                onClick={() => {
                  canSaveGoal && data.month
                    ? !notShowAgain
                      ? setIsShowModal(true)
                      : onBackSave()
                    : onBack()

                  dispatch(goalListRequest(storeId, token))
                }}
                className={styles.button}
                color='primary'
              >
                Sair para o painel
              </Button>

              <Modal
                open={isShowModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500
                }}
              >
                <PromptModal
                  icon={<ExitIcon />}
                  onClose={() => setIsShowModal(false)}
                  title='Deseja voltar para o painel?'
                  description='Não se preocupe! Todas as informações da meta foram salvas no rascunho. Você pode continuar de onde parou quando quiser.'
                  leftTitle='Cancelar'
                  onLeft={() => setIsShowModal(false)}
                  rightTitle='Salvar e sair'
                  onRight={onBack}
                >
                  <FormControlLabel
                    checked={notShowAgain}
                    onChange={() => handleToggleShowModal(!notShowAgain)}
                    control={<Checkbox color='primary' name='checkedA' />}
                    label='Não mostrar novamente'
                  />
                </PromptModal>
              </Modal>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Navbar

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '20px 20px',
    background: '#fff'
  },
  button: {
    color: palette.primary.main,
    textTransform: 'none',
    marginRight: theme.spacing(1),
    fontWeight: 'medium'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  saveMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#27AE60'
  }
}))
