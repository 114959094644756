import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import RefreshIcon from '@material-ui/icons/Refresh'
import { ReactNode } from 'react'

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  refresh: {
    marginRight: theme.spacing(1)
  }
}))

interface ToolbarProps {
  onAdd?: () => void
  onRefresh: () => void
  actionText?: string
  children?: ReactNode
}

const Toolbar = ({ onAdd, onRefresh, actionText, children }: ToolbarProps) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color='primary'
          onClick={onRefresh}
          className={classes.refresh}
          data-cy='btn-refresh'
        >
          <RefreshIcon />
        </Button>
        {children}
        {actionText && actionText.length > 0 && (
          <Button
            color='primary'
            variant='contained'
            onClick={onAdd}
            data-cy='btn-new-data'
          >
            {actionText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Toolbar
