import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import moment from 'moment'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import {
  goalListRequest,
  handleOpenModal as handleOpenGoalModal
} from '../store/modules/goal/actions'
import { userListRequest } from '../store/modules/user/actions'
import { handleOpenModal } from '../store/modules/input/actions'
import { IInputProps } from 'store/modules/input/types'
import { IGoalProps } from 'store/modules/goal/types'
import { IUserProps } from 'store/modules/user/types'
import { IStoreProps } from 'store/modules/store/types'

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface INotifications {
  hasNextGoal: boolean
  hasCurrentMonthGoal: boolean
  hasInputPending: boolean
  hasTalk: boolean
  hasInputsFromIntegration: number
  hasSellers: boolean
  inputsFromIntegration: string[]
  hasUsersWithoutCpf: number
}

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  notification: {
    cursor: 'pointer'
  }
}))

const Alert = (props: AlertProps) => {
  return <MuiAlert style={{ fontSize: '14px' }} elevation={1} {...props} />
}

interface NotificationsProps {
  storeId: string
}

export const Notifications = ({ storeId }: NotificationsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [notifications, setNotifications] = useState<INotifications>({
    hasNextGoal: false,
    hasCurrentMonthGoal: true,
    hasInputPending: false,
    hasTalk: false,
    hasInputsFromIntegration: 0,
    hasSellers: false,
    inputsFromIntegration: [],
    hasUsersWithoutCpf: 0
  })

  // Redux

  // Goal
  const { metadata, goalList, currentGoal, currentGoalLoaded } = useSelector<
    SelectorGoal,
    IGoalProps
  >(state => state.goal)

  // Input
  const { inputList, metadata: inputMetadata } = useSelector<
    SelectorInput,
    IInputProps
  >(state => state.input)

  // User
  const { userList } = useSelector<SelectorUser, IUserProps>(
    state => state.user
  )

  // Store
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  // Local Storage
  const token = localStorage.getItem('@NeoPro:token')

  const today = moment().format('DD')
  const nextMonth = moment()
    .add(1, 'month')
    .utcOffset(-3)
    .set({ date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 })

  // UseEffect para verificar se existe meta no próximo mes
  useEffect(() => {
    // Verifica se dia atual é maior que dia 25
    if (Number(today) > 25) {
      // Verifica se goalList esta na primeira pagina
      if (metadata.pagination.totalCount >= 0) {
        // Busca pela meta do proximo mes no goalList
        const nextGoal = goalList.some(
          goal => String(goal.month) === nextMonth.toISOString()
        )
        // Se meta nao existir ele seta a notificação de goal como true
        // Precisa tratar os erros aqui, caso nao esteja nessa lista
        setNotifications(state => ({ ...state, hasNextGoal: !nextGoal }))
      } else {
        dispatch(goalListRequest(storeId, token, 1, 10))
      }
    }
  }, [goalList, storeId, token])

  // Efeito para gerar lista de inputs pendentes e inputs que vieram da integração que nao sao dias uteis
  useEffect(() => {
    if (
      inputList &&
      currentGoal &&
      goalList &&
      store &&
      inputMetadata.pagination.currentPage === 1
    ) {
      // Verificar se existe meta para o mes atual

      // Instancia data de hoje, e define um horário padrão
      const today = moment()
      today.set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

      // variável p/ realizar a contagem dos dias, é definida no primeiro dia do mês atual
      const lastDay = today.clone().subtract(1, 'M').set({ date: 1 })

      // cria uma lista com a data dos últimos lançamentos e transforma as datas em unix.
      const inputDates = inputList.map(input =>
        moment(input.date)
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .unix()
      )
      // lista que vai pro campo select
      const dates = []
      // lista de dias úteis
      let workingDays: number[] = []

      let days = [...currentGoal.days]
      let usersWorking = [...currentGoal.usersWorking.filter(u => u.active)]

      // Lista de dias úteis do mês anterior
      const currentGoalIndex = goalList.findIndex(
        g => g._id === currentGoal._id
      )

      // Busca pelos dias uteis do mes atual
      workingDays = [
        ...currentGoal.days
          .filter(dayGoal => dayGoal.working && dayGoal.users.length > 0)
          .map(day => moment(day.date).unix())
      ]

      // Verifica se tem algum input que veio da integração que veio de dia nao util
      if (store.integration && store.integration.provider) {
        const hasInputFromIntegration = inputList.filter(
          input =>
            input.origin === store.integration?.provider &&
            moment(input.date).month() === moment(currentGoal.month).month() &&
            !workingDays.includes(moment(input.date).unix())
        )

        setNotifications(state => ({
          ...state,
          hasInputsFromIntegration: hasInputFromIntegration.length,
          inputsFromIntegration: hasInputFromIntegration.map(
            input => ` ${moment(input.date).format('DD/MM')}`
          )
        }))
      }

      if (currentGoalIndex >= 0 && goalList[currentGoalIndex + 1]) {
        workingDays = [
          ...workingDays,
          ...goalList[currentGoalIndex + 1].days
            .filter(dayGoal => dayGoal.working && dayGoal.users.length > 0)
            .map(day => moment(day.date).unix())
        ]
        days = [...days, ...goalList[currentGoalIndex + 1].days]
        usersWorking = [
          ...usersWorking,
          ...goalList[currentGoalIndex + 1].usersWorking.filter(u => u.active)
        ]
      }

      workingDays.sort((a, b) => b - a)
      workingDays = workingDays.slice(0, 34)

      while (lastDay <= today) {
        // Verifica se essa data já foi lançada, converte p/ unix pra poder comparar os números
        // Verifica se é um dia útil
        // Verifica se tem vendedores trabalhando aquele dia
        if (
          !inputDates.includes(lastDay.unix()) &&
          workingDays.includes(lastDay.unix())
        ) {
          dates.push(moment(lastDay))
        }
        lastDay.add(1, 'd')
      }

      if (dates.length > 1)
        setNotifications(state => ({ ...state, hasInputPending: true }))
    }
  }, [currentGoal, inputList, goalList, store])

  // Notificar para caso haja usuario sem o cpf cadastrado
  useEffect(() => {
    if (userList && userList.length > 0) {
      // Busca apenas por vendedores
      const usersWithoutCpf = userList.filter(
        user =>
          user.stores.find(
            store => store.storeId === storeId && store.type === 'seller'
          ) && !user.cpf
      )
      // const usersWithoutCpf = userList.filter(user => !user.cpf || user.cpf.length == 0)
      if (usersWithoutCpf.length > 0)
        setNotifications(state => ({
          ...state,
          hasUsersWithoutCpf: usersWithoutCpf.length
        }))
    } else {
      dispatch(userListRequest(storeId, token, 1, 10))
    }
  }, [userList])

  useEffect(() => {
    if (currentGoalLoaded) {
      setNotifications(state => ({
        ...state,
        hasCurrentMonthGoal: !!currentGoal
      }))
    }
  }, [currentGoal, currentGoalLoaded])

  // Verifica se existe vendedor
  useEffect(() => {
    if (store && store.users) {
      const hasSellers = store.users.some(u => {
        const findUserStore = u.stores.find(s => s.storeId === storeId)
        if (findUserStore) {
          return findUserStore.type === 'seller'
        }
      })
      setNotifications(state => ({ ...state, hasSellers: !hasSellers }))
    }
  }, [store])

  const handleNavigateToInputs = () => {
    history.push(`/${storeId}/inputs`)
    dispatch(handleOpenModal())
  }

  const handleNavigateToGoals = () => {
    history.push(`/${storeId}/goals`)
    dispatch(handleOpenGoalModal())
  }

  return (
    <div className={classes.root}>
      {notifications.hasSellers && (
        <Alert
          className={classes.notification}
          onClick={() => history.push(`/${storeId}/users`)}
          severity='info'
        >
          Você ainda não tem vendedores cadastrados. Bora lá criar?
        </Alert>
      )}
      {!notifications.hasSellers && notifications.hasTalk && (
        <Alert className={classes.notification} severity='info'>
          Não se esqueça que hoje tem reunião com os vendedores 😉.
        </Alert>
      )}
      {notifications.hasNextGoal && (
        <Alert
          className={classes.notification}
          onClick={() => handleNavigateToGoals()}
          severity='info'
        >
          Já está chegando o fim do mês. Que tal criarmos a próxima meta? 🎯
        </Alert>
      )}
      {!notifications.hasCurrentMonthGoal && (
        <Alert
          className={classes.notification}
          onClick={() => handleNavigateToGoals()}
          severity='warning'
        >
          Você ainda não criou a meta para o mês atual. Bora lá criar? 😉.
        </Alert>
      )}
      {notifications.hasInputPending && (
        <Alert
          className={classes.notification}
          onClick={() =>
            store && store.integration?.provider
              ? handleNavigateToInputs()
              : dispatch(handleOpenModal())
          }
          severity='warning'
        >
          Você está com lançamentos pendentes. Verifique o quanto antes para que
          seus vendedores tenham sempre as informações mais atualizadas 😉.
        </Alert>
      )}
      {notifications.hasInputsFromIntegration >= 1 && (
        <Alert
          className={classes.notification}
          onClick={() => {
            currentGoal && history.push(`/${storeId}/goals/${currentGoal._id}`)
          }}
          severity='warning'
        >
          {`
            Fo${notifications.hasInputsFromIntegration > 1 ? 'ram' : 'i'}
            identificado${
              notifications.hasInputsFromIntegration > 1 ? 's' : ' um'
            }
            lançamento${notifications.hasInputsFromIntegration > 1 ? 's' : ''}
            na sincronização que não est${
              notifications.hasInputsFromIntegration > 1 ? 'ão' : 'á'
            }
            na meta. Para corrigir, defina uma meta para o${
              notifications.hasInputsFromIntegration > 1 ? 's' : ''
            }
            dia${notifications.hasInputsFromIntegration > 1 ? 's' : ''}
            ${
              notifications.hasInputsFromIntegration >= 1 &&
              notifications.inputsFromIntegration
            }.
            `}
        </Alert>
      )}
      {notifications.hasUsersWithoutCpf >= 1 && (
        <Alert
          className={classes.notification}
          onClick={() => history.push(`/${storeId}/users`)}
          severity='warning'
        >
          {`Fo${
            notifications.hasUsersWithoutCpf > 1 ? 'ram' : 'i'
          } identificado${
            notifications.hasUsersWithoutCpf > 1 ? 's' : ' um'
          } usuário${notifications.hasUsersWithoutCpf > 1 ? 's' : ''} que est${
            notifications.hasUsersWithoutCpf > 1 ? 'ão' : 'á'
          } sem o CPF cadastrado. Para corrigir, insira o CPF para esse${
            notifications.hasUsersWithoutCpf > 1 ? 's' : ''
          } usuário${notifications.hasUsersWithoutCpf > 1 ? 's' : ''}.`}
        </Alert>
      )}
    </div>
  )
}
