import styled from 'styled-components'
import { Button as ButtonUI } from '@material-ui/core'
import palette from 'theme/palette'

export const Button = styled(ButtonUI)`
  min-height: 36.5px;
  background: ${palette.primary.main} !important;
  color: ${palette.white} !important;

  transform: background-color 0.2s;

  &:hover {
    background: ${palette.primary.light} !important;
  }
`
