import { Action } from 'redux'

import produce from 'immer'
import moment from 'moment'
import { IGoalProps } from './types'

const INITIAL_STATE: IGoalProps = {
  currentGoal: null,
  currentGoalLoaded: false,
  goal: null,
  goalList: [],
  error: null,
  loading: false,
  modal: false,
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 10
    },
    sorting: {
      month: -1
    }
  },
  savedGoal: null,
  saved: false
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function goal(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@goal/GOAL_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/LIST_SUCCESS': {
        draft.goalList = action.payload.goalsList.items
        draft.metadata = action.payload.goalsList.metadata
        draft.loading = false
        break
      }
      case '@goal/SAVE': {
        break
      }
      case '@goal/CLEAR_SAVE': {
        draft.saved = false
        break
      }
      case '@goal/ADD_REQUEST': {
        draft.loading = true
        draft.saved = false
        break
      }
      case '@goal/ADD_SUCCESS': {
        draft.savedGoal = action.payload.goal
        draft.goalList = [action.payload.goal, ...state.goalList].sort(
          (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime()
        )
        draft.loading = false
        draft.modal = false
        draft.saved = true
        break
      }
      case '@goal/FAILED': {
        draft.loading = false
        draft.error = action.payload.error
        break
      }
      case '@goal/GOAL_SUCCESS': {
        draft.goal = action.payload.goal
        draft.loading = false
        const currentMonth = moment()
        currentMonth.set({
          date: 1,
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        // Já aproveita e vê se é o mês atual p/ atualizar o redux
        if (
          (currentMonth.toISOString() ===
            moment(draft.goal?.month).toISOString() ||
            (draft.currentGoal && draft.currentGoal._id === draft.goal?._id)) &&
          draft.goal?.config.active
        ) {
          draft.currentGoal = draft.goal
          draft.currentGoalLoaded = true
        }
        // Atualizar na lista do listGoal também
        if (draft.goalList.length > 0) {
          draft.goalList = draft.goalList.map(goal =>
            goal._id === draft.goal?._id ? draft.goal : goal
          )
        }

        break
      }
      case '@modal/GOAL_OPEN': {
        draft.modal = true
        break
      }
      case '@modal/GOAL_CLOSE': {
        draft.modal = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@goal/CURRENT_SUCCESS': {
        draft.currentGoal = action.payload.currentGoal
        draft.currentGoalLoaded = true
        break
      }
      case '@goal/CURRENT_FAILED': {
        draft.currentGoalLoaded = false
        break
      }
      case '@goal/DELETE_REQUEST': {
        const goalIndex = draft.goalList.findIndex(
          g => g._id === action.payload.goalId
        )
        draft.goalList.splice(goalIndex, 1)
        break
      }
      case '@goal/EDIT_DAY_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/EDIT_MAINGOALS_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/EDIT_SUCCESS': {
        if (draft.currentGoal) {
          const userIndex = draft.currentGoal.usersWorking.findIndex(
            u => u.userId?._id === action.payload.data._id
          )
          // if (userIndex >= 0) {
          //   draft.currentGoal.usersWorking[userIndex].name =
          //     action.payload.data.name
          // }
        }
        draft.loading = false
        break
      }
      case '@user/UNLINK_SUCCESS': {
        const thisMonth = new Date()
        thisMonth.setDate(1)
        thisMonth.setHours(0, 0, 0, 0)

        if (draft.currentGoal) {
          draft.currentGoal.usersWorking = [
            ...draft.currentGoal.usersWorking
          ].filter(u => u.userId?._id !== action.payload.userId)
          draft.currentGoal.days = [...draft.currentGoal.days].map(day => ({
            ...day,
            users: day.users.filter(user => user !== action.payload.userId)
          }))

          draft.goalList = draft.goalList.map(gl => {
            if (new Date(gl.month) >= thisMonth) {
              gl.usersWorking = [...gl.usersWorking].filter(
                u => u.userId?._id !== action.payload.userId
              )
              gl.days = [...gl.days].map(day => ({
                ...day,
                users: day.users.filter(user => user !== action.payload.userId)
              }))
            }
            return gl
          })
        }

        if (draft.goal && new Date(draft.goal.month) >= thisMonth) {
          draft.goal.usersWorking = [...draft.goal.usersWorking].filter(
            u => u.userId?._id !== action.payload.userId
          )
        }
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
