import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// MaterialUI
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// Assets
import IdBrasilLogo from '../../../../assets/sofstore-logo.png'
import SetaDigitalLogo from '../../../../assets/setadigital-logo.png'
import TagPlusLogo from '../../../../assets/tagplus-logo.png'
import MicrovixLogo from '../../../../assets/microvix-logo.png'
import QuantoSobraLogo from '../../../../assets/quantosobra-logo.jpeg'
import KigiLogo from '../../../../assets/kigi-logo.png'
import ProtheusLogo from '../../../../assets/protheus-logo.png'
import AllsoftLogo from '../../../../assets/allSoft-logo.png'
import NexaasLogo from '../../../../assets/nexaas-logo.svg'
import AutcomLogo from '../../../../assets/erp-autcom-logo.png'

// ERPs Views
import {
  Autcom,
  IdBrasil,
  SetaDigital,
  TagPlus,
  Introduction,
  Microvix,
  QuantoSobra,
  Kigi,
  Protheus,
  Allsoft,
  Nexaas
} from './components'

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(3)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  customBadge: {
    width: '60px',
    fontSize: 10,
    backgroundColor: '#2ecc71',
    color: 'white'
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15
  },
  providerLogo: {
    maxHeight: 22,
    maxWidth: 130
  }
}))

const IntegrationSettings = ({ storeIntegrationData }) => {
  const classes = useStyles()

  const loading = useSelector(state => state.integration.loading)
  const store = useSelector(state => state.store.store)

  const [tab, setTab] = useState('')

  const options = [
    { value: 'autcom', label: 'Autcom', logoImg: AutcomLogo },
    { value: 'idbrasil', label: 'Sofstore', logoImg: IdBrasilLogo },
    { value: 'setadigital', label: 'SetaDigital', logoImg: SetaDigitalLogo },
    { value: 'tagplus', label: 'TagPlus', logoImg: TagPlusLogo },
    // { value: 'microvix', label: 'Linx Microvix', logoImg: MicrovixLogo },
    { value: 'quantosobra', label: 'Quanto Sobra', logoImg: QuantoSobraLogo },
    { value: 'kigi', label: 'Kigi', logoImg: KigiLogo },
    { value: 'protheus', label: 'Protheus', logoImg: ProtheusLogo },
    { value: 'allSoft', label: 'Allsoft', logoImg: AllsoftLogo },
    { value: 'nexaas', label: 'Nexaas', logoImg: NexaasLogo }
  ]

  const handleChangeTab = option => {
    if (option === tab) {
      setTab('')
    } else {
      setTab(option)
    }
  }

  useEffect(() => {
    if (
      storeIntegrationData?.provider &&
      storeIntegrationData.provider !== 'microvix'
    ) {
      setTab(storeIntegrationData?.provider)
    }
  }, [storeIntegrationData])

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <List component='nav'>
                  {options
                    .sort((a, b) =>
                      a.value == storeIntegrationData?.provider
                        ? -1
                        : b.value == storeIntegrationData?.provider
                        ? 1
                        : 0
                    )
                    .map(option => (
                      <div key={option.value}>
                        <ListItem
                          className={classes.itemContainer}
                          selected={option.value == tab}
                          key={option.value}
                          button
                          onClick={() => handleChangeTab(option.value)}
                        >
                          {/* <ListItemText primary={option.label} /> */}
                          <img
                            className={classes.providerLogo}
                            src={option.logoImg}
                            alt={option.label}
                          />
                          {option.value == storeIntegrationData?.provider && (
                            <Badge
                              classes={{ badge: classes.customBadge }}
                              style={{ left: -25 }}
                              badgeContent='Conectado'
                            />
                          )}
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={9}>
                {tab == 'autcom' && (
                  <Autcom
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'idbrasil' && (
                  <IdBrasil
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'setadigital' && (
                  <SetaDigital
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'tagplus' && (
                  <TagPlus
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {/* {tab == 'microvix' && (
                  <Microvix
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )} */}
                {tab == 'quantosobra' && (
                  <QuantoSobra
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'kigi' && (
                  <Kigi
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'protheus' && (
                  <Protheus
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'allSoft' && (
                  <Allsoft
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == 'nexaas' && (
                  <Nexaas
                    store={store && store}
                    loading={loading}
                    storeIntegrationData={
                      storeIntegrationData && storeIntegrationData
                    }
                  />
                )}
                {tab == '' && <Introduction />}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  )
}

IntegrationSettings.propTypes = {
  store: PropTypes.object
}

export default IntegrationSettings
