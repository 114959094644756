import { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { FormControl, InputLabel, Select, Box } from '@material-ui/core'

import {
  Avatar as AvatarMUI,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material'

import { KeyboardArrowDown } from '@mui/icons-material'
import { IGroup, IReportProps, IStoresGroup } from 'store/modules/report/types'
import { IUserProps } from 'store/modules/user/types'

import {
  getStorylineStoresAndGroupsRequest,
  logout
} from 'store/modules/user/actions'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router'

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

interface UserProps {
  id: string
  cpf: string
  email: string
  iat: number
  phone: string
  type: string
  stores: {
    storeId: string
    type: string
  }[]
}

interface StoresSelectProps {
  isStoreSelected: {
    [key: string]: boolean
  }
  setIsStoreSelected?: Dispatch<
    SetStateAction<{
      [key: string]: boolean
    }>
  >
  error: boolean | undefined
}

export const StoresSelect = ({
  isStoreSelected,
  setIsStoreSelected,
  error
}: StoresSelectProps) => {
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({})

  const { storesReport } = useSelector<SelectorReport, IReportProps>(
    state => state.report
  )

  const { storylineStoresAndGroups } = useSelector<SelectorUser, IUserProps>(
    state => state.user
  )

  const token = window.localStorage.getItem('@NeoPro:token')
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (token) {
      const user = jwtDecode<UserProps>(token)
      if (!storylineStoresAndGroups && !storesReport && user) {
        dispatch(getStorylineStoresAndGroupsRequest(user.id, token))
      }
    } else {
      window.localStorage.removeItem('@NeoPro:token')
      dispatch(logout())
      history.push(`/login`)
    }
  }, [token, storylineStoresAndGroups, storesReport, dispatch, history])

  const groupStores = useMemo(() => {
    const groupsWithStoreAsDirector: IGroup[] = []
    if (!!storesReport) {
      storesReport.groups.forEach(group => {
        const newGroupStores = group.stores.filter(
          store =>
            store.type === 'director' ||
            store.type === 'communicator' ||
            store.type === 'supervisor'
        )

        const hasNewGroupStores = newGroupStores.length > 0

        if (hasNewGroupStores) {
          groupsWithStoreAsDirector.push({
            ...group,
            stores: newGroupStores
          })
        }
      })
      return groupsWithStoreAsDirector
    } else if (!!storylineStoresAndGroups) {
      return storylineStoresAndGroups.groups.map(group => ({
        ...group,
        groupId: group._id
      }))
    }
    return []
  }, [storylineStoresAndGroups, storesReport])

  const storesWithNoGroups = useMemo(() => {
    if (!!storesReport) {
      const stores = storesReport.stores.filter(
        store =>
          store.type === 'director' ||
          store.type === 'communicator' ||
          store.type === 'supervisor'
      )

      return stores
    } else if (!!storylineStoresAndGroups) {
      return storylineStoresAndGroups.stores
    }
    return []
  }, [storylineStoresAndGroups, storesReport])

  const hasStoresWithNoGroups = storesWithNoGroups.length > 0

  return (
    <FormControl style={{ width: '100%' }} variant='outlined'>
      <InputLabel id='stores'>Lojas</InputLabel>
      <Select
        labelId='stores'
        name='selectedStores'
        label='Lojas'
        multiple
        error={error}
        value={Object.entries(isStoreSelected)
          .filter(([_, value]) => value)
          .map(([key]) => [key])
          .flat(1)}
        renderValue={selected => {
          const selectedItems = selected as string[]
          const length = selectedItems.length
          return `${length} ${length > 1 ? 'lojas' : 'loja'} `
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {groupStores.map(group => (
          <Box
            sx={{
              bgcolor: isListOpen[group.groupId] ? 'rgba(0, 0, 0, 0.08)' : null,
              pb: isListOpen[group.groupId] ? 2 : 0
            }}
            width='100%'
            key={group.groupId}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox
                checked={
                  group.stores.every(store => isStoreSelected[store._id]) ??
                  false
                }
                onChange={(_, checked) => {
                  const groupStore = group.stores as IStoresGroup[]
                  const newList = groupStore.reduce((acc, curr) => {
                    return {
                      ...acc,
                      [curr._id]: checked
                    }
                  }, {})

                  setIsStoreSelected &&
                    setIsStoreSelected(prev => ({
                      ...prev,
                      ...newList
                    }))
                }}
              />

              <ListItemButton
                alignItems='flex-start'
                style={{ width: '100%' }}
                onClick={() =>
                  setIsListOpen(previousValue => ({
                    ...previousValue,
                    [group.groupId]: !previousValue[group.groupId]
                  }))
                }
                sx={{
                  px: 3,
                  pt: 2.5,
                  pb: isListOpen[group.groupId] ? 2 : 2.5,
                  cursor: 'pointer',
                  '&:hover, &:focus': {
                    '& svg': {
                      opacity: isListOpen[group.groupId] ? 1 : 0
                    }
                  }
                }}
              >
                <ListItemText
                  primary={group.name}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px'
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    opacity: 0,
                    transform: isListOpen[group.groupId]
                      ? 'rotate(-180deg)'
                      : 'rotate(0)',
                    transition: '0.2s'
                  }}
                />
              </ListItemButton>
            </div>
            {isListOpen[group.groupId] &&
              group.stores.map(store => {
                return (
                  <ListItem
                    key={store._id}
                    sx={{
                      cursor: 'pointer',
                      ...(!isStoreSelected[store._id] && {
                        bgcolor: '#fff'
                      }),
                      '&:hover, &:focus': {
                        bgcolor: 'rgba(0, 0, 0, 0.08)'
                      }
                    }}
                    onClick={() => {
                      setIsStoreSelected &&
                        setIsStoreSelected(prev => ({
                          ...prev,
                          [store._id]: !prev[store._id]
                        }))
                    }}
                  >
                    <ListItemAvatar style={{ minWidth: 30 }}>
                      <AvatarMUI
                        style={{ height: 22, width: 22 }}
                        src={store.photo.url ?? undefined}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ fontSize: 12 }}
                      primary={store.name}
                    />
                  </ListItem>
                )
              })}
          </Box>
        ))}
        {hasStoresWithNoGroups && (
          <Box
            sx={{
              bgcolor: isListOpen['no-groups'] ? 'rgba(0, 0, 0, 0.08)' : null,
              pb: isListOpen['no-groups'] ? 2 : 0
            }}
            width='100%'
            key={'no-groups'}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox
                checked={
                  storesWithNoGroups.every(
                    store => isStoreSelected[store._id]
                  ) ?? false
                }
                onChange={(_, checked) => {
                  const newList = storesWithNoGroups.reduce((acc, curr) => {
                    return {
                      ...acc,
                      [curr._id]: checked
                    }
                  }, {})

                  setIsStoreSelected &&
                    setIsStoreSelected(prev => ({
                      ...prev,
                      ...newList
                    }))
                }}
              />

              <ListItemButton
                alignItems='flex-start'
                style={{ width: '100%' }}
                onClick={() =>
                  setIsListOpen(previousValue => ({
                    ...previousValue,
                    ['no-groups']: !previousValue['no-groups']
                  }))
                }
                sx={{
                  px: 3,
                  pt: 2.5,
                  pb: isListOpen['no-groups'] ? 2 : 2.5,
                  cursor: 'pointer',
                  '&:hover, &:focus': {
                    '& svg': {
                      opacity: isListOpen['no-groups'] ? 1 : 0
                    }
                  }
                }}
              >
                <ListItemText
                  primary={'Lojas sem regional'}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px'
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    opacity: 0,
                    transform: isListOpen['no-groups']
                      ? 'rotate(-180deg)'
                      : 'rotate(0)',
                    transition: '0.2s'
                  }}
                />
              </ListItemButton>
            </div>
            {isListOpen['no-groups'] &&
              storesWithNoGroups.map(store => {
                return (
                  <ListItem
                    key={store._id}
                    sx={{
                      cursor: 'pointer',
                      ...(!isStoreSelected[store._id] && {
                        bgcolor: '#fff'
                      }),
                      '&:hover, &:focus': {
                        bgcolor: 'rgba(0, 0, 0, 0.08)'
                      }
                    }}
                    onClick={() => {
                      setIsStoreSelected &&
                        setIsStoreSelected(prev => ({
                          ...prev,
                          [store._id]: !prev[store._id]
                        }))
                    }}
                  >
                    <ListItemAvatar style={{ minWidth: 30 }}>
                      <AvatarMUI
                        style={{ height: 22, width: 22 }}
                        src={store.photo.url ?? undefined}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ fontSize: 12 }}
                      primary={store.name}
                    />
                  </ListItem>
                )
              })}
          </Box>
        )}
      </Select>
    </FormControl>
  )
}
