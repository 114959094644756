const providers = {
  user: 'Manual',
  idbrasil: 'Sofstore',
  setadigital: 'Seta Digital',
  tagplus: 'TagPlus',
  quantosobra: 'Quanto Sobra',
  microvix: 'Linx Microvix',
  kigi: 'Kigi',
  protheus: 'Protheus',
  allSoft: 'Allsoft',
  nexaas: 'Nexaas'
}

export default provider => providers[provider] || provider || 'Não identificado'
