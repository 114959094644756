import { useState } from 'react'
import {
  Box,
  Chip,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button as ButtonUI,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core'

import { useTheme } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useDispatch, useSelector } from 'react-redux'
import palette from 'theme/palette'

import { Button } from './styles'
import {
  updateGroupsFromStoresReport,
  updateStoresFromStoresReport
} from '../../../../store/modules/report/actions'

export const AddGroupModal = ({
  isOpen,
  closeModal,
  setGroupName,
  setSelectedResponsible,
  selectedResponsible,
  selectedStores,
  handleChangeStoreList,
  handleSubmit
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { groupStoresList, loading } = useSelector(state => state.store)
  const { usersGroupList } = useSelector(state => state.user)
  const { storesReport } = useSelector(state => state.report)
  const { groupsFromReport } = useSelector(state => state.group)

  const [storesToShowInModal, setStoresToShowInModal] = useState([])
  const [isConfirmCreateGroupModalOpen, setIsConfirmCreateGroupModalOpen] =
    useState(false)

  // Função pra caso tenha necessidade de paginação no input de lojas
  // eslint-disable-next-line no-unused-vars
  const handleOnScroll = event => {
    const hasReachedBottom =
      event.target.scrollHeight - event.target.clientHeight <=
      event.target.scrollTop + 50
    if (hasReachedBottom) {
      // chamar a api para fazer a request da nova pagina de stores e popular o state
      // setIsLoading(false)
    }
  }

  const handleOpenModalToConfirmCreateGroup = () => {
    const storesToAddCurrentResponsible = selectedStores.filter(store => {
      const storesFromResponsible = selectedResponsible.stores
      const isCurrentResponsibleInStore = storesFromResponsible.find(
        responsibleStore => responsibleStore.storeId === store._id
      )
      if (!isCurrentResponsibleInStore) {
        return store
      }
    })

    if (storesToAddCurrentResponsible.length > 0) {
      setStoresToShowInModal(storesToAddCurrentResponsible)
      setIsConfirmCreateGroupModalOpen(true)
    } else {
      // Abaixo casos em que
      // - Não precisa adicionar o responsável nas lojas atuais
      // - Em caso de nenhum dos casos acima, ele apenas salva

      const currentResponsible = usersGroupList.find(
        user => user._id === selectedResponsible.id
      )

      const storesAddedToOwner = []
      groupStoresList.forEach(store => {
        const currentOwnerStore = currentResponsible?.stores.find(
          responsibleStore =>
            responsibleStore.storeId === store._id &&
            ['owner', 'director'].includes(responsibleStore.type)
        )
        if (currentOwnerStore) {
          const hasOtherGroupWithSameStore = groupsFromReport.find(group =>
            group.stores.some(store => store._id === currentOwnerStore.storeId)
          )

          const isSelectedStore = selectedStores.some(
            store => store._id === currentOwnerStore.storeId
          )

          if (!hasOtherGroupWithSameStore && isSelectedStore) {
            // Caso esteja adicionando uma loja em que ele é dono e não tem em nenhuma outra regional
            // Essa loja precisa ser removida da lista de storesReport.stores
            storesAddedToOwner.push(currentOwnerStore.storeId)
          }
        }
      })

      if (storesAddedToOwner.length > 0) {
        dispatch(
          updateStoresFromStoresReport([
            ...storesReport.stores.filter(
              store => !storesAddedToOwner.includes(store._id)
            )
          ])
        )
      }
      handleSubmit()
    }
  }

  const handleConfirmGroupCreation = () => {
    setIsConfirmCreateGroupModalOpen(false)
    setStoresToShowInModal([])

    const formattedStores = selectedStores.map(store => ({
      ...store,
      storeId: store._id,
      type: selectedResponsible.stores.some(
        storeFromResponsible =>
          storeFromResponsible.storeId === store._id &&
          storeFromResponsible.type === 'owner'
      )
        ? 'owner'
        : 'manager'
    }))

    const updatedNewResponsible =
      storesToShowInModal.length > 0
        ? {
            ...selectedResponsible,
            stores: [...selectedResponsible.stores, ...formattedStores]
          }
        : undefined

    const formattedStoresIds = formattedStores.map(store => store._id)

    // Checa se alguma loja que estava sem grupo, foi adicionada nesse novo grupo
    // pra assim tirar ela dos storesReport e nao aparecer na tela de lojas como sem grupo
    const storesWithNoGroup = storesReport.stores.filter(
      store => !formattedStoresIds.includes(store._id)
    )

    if (storesWithNoGroup.length > 0) {
      dispatch(updateStoresFromStoresReport(storesWithNoGroup))
    }

    handleSubmit(updatedNewResponsible)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      fullWidth
    >
      <DialogTitle style={{ paddingTop: 22 }}>
        <Typography style={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Adicionar Regional
        </Typography>
        <IconButton
          aria-label='close'
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: colors => colors.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              error={false}
              label='Nome'
              required
              variant='outlined'
              onChange={event => setGroupName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='responsible'>Responsável</InputLabel>
              <Select
                labelId='responsible'
                name='selectedResponsible'
                label='Responsável'
                autoComplete='off'
                value={
                  usersGroupList.find(
                    user => user._id === selectedResponsible.id
                  ) || ''
                }
                onChange={event =>
                  setSelectedResponsible({
                    ...event.target.value,
                    id: event.target.value._id
                  })
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {usersGroupList.map(user => (
                  <MenuItem key={user._id} value={user}>
                    {user.name.complete}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex' }}>
            <FormControl style={{ width: '100%' }} variant='outlined'>
              <InputLabel id='stores'>Lojas</InputLabel>
              <Select
                labelId='stores'
                name='selectedStores'
                label='Lojas'
                multiple
                value={selectedStores}
                onChange={handleChangeStoreList}
                // onScroll={event => handleOnScroll(event)} // método para paginação no input
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip
                        style={{ marginRight: 4, marginBottom: 4 }}
                        key={value._id}
                        label={value.name}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {groupStoresList.map(store => (
                  <MenuItem
                    key={store._id}
                    value={store}
                    style={{
                      fontWeight:
                        selectedStores.indexOf(store) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium
                    }}
                  >
                    {store.name}
                  </MenuItem>
                ))}
                {/* Loading visual para fetch das lojas */}
                {/* {isLoading && (
                  <MenuItem key='loading' disabled>
                    Carregando...
                  </MenuItem>
                )} */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '24px' }}>
        <ButtonUI variant='text' onClick={closeModal} disabled={loading}>
          Cancelar
        </ButtonUI>
        <Button
          autoFocus
          onClick={handleOpenModalToConfirmCreateGroup}
          disabled={loading}
        >
          {loading && <CircularProgress size={14} color='inherit' />}
          {!loading && 'Criar'}
        </Button>
      </DialogActions>

      <Dialog
        open={isConfirmCreateGroupModalOpen}
        onClose={() => setIsConfirmCreateGroupModalOpen(false)}
      >
        <DialogTitle style={{ paddingTop: 22 }}>
          <Typography variant='h4'>Adicionar Supervisor às lojas?</Typography>
          <IconButton
            aria-label='close'
            onClick={() => setIsConfirmCreateGroupModalOpen(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            O usuário <b>{selectedResponsible.name?.complete}</b> não está
            vinculado em todas as lojas dessa Regional. Para definir ele como
            responsável da Regional é preciso vincular ele às lojas: <br />
            <ul style={{ marginLeft: '18px' }}>
              {storesToShowInModal.map(store => (
                <li key={store._id}>
                  <b>{store.name}</b>
                </li>
              ))}
            </ul>
          </Typography>
        </DialogContent>

        <DialogActions
          style={{
            paddingLeft: '24px',
            paddingRight: '24px'
          }}
        >
          <ButtonUI
            variant='text'
            onClick={() => setIsConfirmCreateGroupModalOpen(false)}
            disabled={loading}
          >
            Cancelar
          </ButtonUI>
          <ButtonUI
            variant='text'
            onClick={handleConfirmGroupCreation}
            disabled={loading}
            style={{ background: palette.primary.main, color: palette.white }}
          >
            {loading && <CircularProgress size={14} color='inherit' />}
            {!loading && 'Vincular e Criar Regional'}
          </ButtonUI>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
