/* eslint-disable object-curly-newline */
import React from 'react'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { differenceInCalendarMonths, format } from 'date-fns'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import Month from './Month'
import DefinedRanges from './DefinedRanges'
import { DateRange, DefinedRange, Setter, NavigationAction } from '../types'
import { MARKERS } from './Markers'
import useMediaQuery from '@mui/material/useMediaQuery'

interface MenuProps {
  dateRange: DateRange
  ranges: DefinedRange[]
  minDate: Date
  maxDate: Date
  firstMonth: Date
  secondMonth: Date
  setFirstMonth: Setter<Date>
  setSecondMonth: Setter<Date>
  setDateRange: Setter<DateRange>
  helpers: {
    // eslint-disable-next-line no-unused-vars
    inHoverRange: (day: Date) => boolean
  }
  handlers: {
    // eslint-disable-next-line no-unused-vars
    onDayClick: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onDayHover: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void
  }
  locale?: Locale
}

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  })

  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    locale
  } = props

  const { startDate, endDate } = dateRange
  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers
  }
  return (
    <Paper
      elevation={5}
      square
      style={{
        borderRadius: '4px'
      }}
    >
      {/* <Grid container direction='row' wrap='nowrap'> */}
      <Grid
        container
        direction={isMobile ? 'column-reverse' : 'row'}
        wrap='nowrap'
      >
        <Grid>
          <DefinedRanges
            selectedRange={dateRange}
            ranges={ranges}
            setRange={setDateRange}
            maxDate={maxDate}
          />
        </Grid>
        <Divider orientation='vertical' flexItem />
        <Grid>
          <Grid container sx={{ padding: '10px 70px' }} alignItems='center'>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                {startDate
                  ? format(startDate, 'dd MMMM yyyy', { locale })
                  : 'Data inicial'}
              </Typography>
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <ArrowRightAlt color='action' />
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                {endDate
                  ? format(endDate, 'dd MMMM yyyy', { locale })
                  : 'Data final'}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction='row' justifyContent='center' wrap='nowrap'>
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              locale={locale}
            />
            <Divider orientation='vertical' flexItem />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
              locale={locale}
            />
          </Grid>
          <Grid container justifyContent='end'>
            <Button
              variant='text'
              sx={{
                color: '#546e7a'
              }}
              style={{ marginTop: '-16px' }}
              onClick={() =>
                setDateRange({ endDate: undefined, startDate: undefined })
              }
            >
              Limpar seleção
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Menu
