export const dateWithNoTimezone = (
  date: Date | string,
  hours?: number,
  minutes?: number,
  seconds?: number,
  ms?: number
) => {
  const isDateAString = typeof date === 'string'
  if (isDateAString) {
    const dateWithoutTimezone = new Date(
      new Date(
        new Date(date).getTime() +
          Math.abs(new Date(date).getTimezoneOffset() * 60 * 1000)
      )
    )

    const isHoursANumber = typeof hours === 'number'

    if (isHoursANumber) {
      const formattedDate = new Date(
        dateWithoutTimezone.setHours(hours, minutes ?? 0, seconds ?? 0, ms ?? 0)
      )

      return formattedDate
    }
    return dateWithoutTimezone
  }

  const dateWithoutTimezone = new Date(
    new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60 * 1000))
  )

  const isHoursANumber = typeof hours === 'number'

  if (isHoursANumber) {
    const formattedDate = new Date(
      dateWithoutTimezone.setHours(hours, minutes ?? 0, seconds ?? 0, ms ?? 0)
    )

    return formattedDate
  }
  return dateWithoutTimezone
}

export const toIsoStringLocalTz = (date: Date) => {
  const pad = (num: number) => {
    return (num < 10 ? '0' : '') + num
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    '15' +
    ':' +
    '00' +
    ':' +
    '00' +
    '.000Z'
  )
}
