import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'

import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import GoalsTable from './components/GoalsTable'

import { Toolbar } from '../../components'

import {
  goalListRequest,
  clearGoalSave
} from '../../store/modules/goal/actions'
import { userListRequest } from '../../store/modules/user/actions'
import { IStoreProps } from 'store/modules/store/types'
import { IGoalProps } from 'store/modules/goal/types'
import { IUserProps } from 'store/modules/user/types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface GoalsProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
    }
    path: string
    url: string
  }
}

export default function Goals({ match }: GoalsProps) {
  const { metadata: userMetadata } = useSelector<SelectorUser, IUserProps>(
    state => state.user
  )
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { goalList, metadata: goalMetadata } = useSelector<
    SelectorGoal,
    IGoalProps
  >(state => state.goal)

  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const confirm = useConfirm()

  const { storeId } = match.params
  const token = window.localStorage.getItem('@NeoPro:token')

  const hasSellers = store?.users.some(user =>
    user.stores.some(
      store => store.storeId === storeId && store.type === 'seller'
    )
  )

  useEffect(() => {
    if (goalMetadata.pagination.totalCount < 0) {
      dispatch(goalListRequest(storeId, token))
    }
    if (userMetadata.pagination.totalCount < 0) {
      dispatch(userListRequest(storeId, token))
    }
  }, [storeId, token])

  const onAdd = () => {
    if (hasSellers) {
      dispatch(clearGoalSave())
      history.push(`/${storeId}/goals/create`)
    } else {
      confirm({
        title: 'Sem vendedores!',
        description:
          'Você ainda não tem vendedores cadastrados, antes de criar uma meta, vá até a tela de usuários e registre pelo menos um vendedor.',
        confirmationText: 'Ir para lá!',
        cancellationText: 'Fechar'
      })
        .then(() => {
          history.push(`/${storeId}/users`)
        })
        .catch(() => {})
    }
  }

  const onRefresh = () => {
    dispatch(goalListRequest(storeId, token))
    dispatch(userListRequest(storeId, token))
  }

  const handleChangePage = (page: number) => {
    if (page + 1 <= goalMetadata.pagination.pageCount) {
      dispatch(
        goalListRequest(storeId, token, page + 1, goalMetadata.pagination.limit)
      )
    }
  }

  const handleChangeRows = (rowsNumber: number) => {
    dispatch(
      goalListRequest(
        storeId,
        token,
        goalMetadata.pagination.currentPage,
        rowsNumber
      )
    )
  }

  return (
    <div className={classes.root}>
      <Toolbar
        onAdd={onAdd}
        onRefresh={onRefresh}
        actionText='Adicionar Meta'
      />
      <GoalsTable
        store={store}
        storeId={storeId}
        goals={goalList}
        metadata={goalMetadata}
        onChangeRows={handleChangeRows}
        onChangePage={handleChangePage}
      />
    </div>
  )
}
