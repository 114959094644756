import { IDay } from './types'

export function setInitialCalendarValues(days: IDay[]) {
  return {
    type: '@calendarGoal/SET_INITIAL_CALENDAR_VALUES',
    payload: { days }
  }
}

export function setDisabledDays(daysIndex: number[]) {
  return {
    type: '@calendarGoal/SET_DISABLED_DAYS',
    payload: { daysIndex }
  }
}

export function addDisabledDay(dayIndex: number) {
  return {
    type: '@calendarGoal/ADD_DISABLED_DAY',
    payload: { dayIndex }
  }
}

export function clearDisabledDays() {
  return {
    type: '@calendarGoal/CLEAR_DISABLED_DAYS'
  }
}

export function clearCalendarGoalRedux() {
  return {
    type: '@calendarGoal/CLEAR_REDUX'
  }
}
