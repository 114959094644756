import styled from 'styled-components'
import { Card as CardMui } from '@mui/material'

export const IconContainer = styled.div`
  display: flex;
  width: 100%;

  gap: 1rem;
`

export const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  gap: 16px;
`

export const Button = styled.label`
  border: 2px solid #5b5b76;
  background-color: transparent;
  border-radius: 50px;
  min-width: 136px;
  padding: 6px;
  text-align: center;
  color: #5b5b76;
  font-size: 0.825rem;
  font-weight: 700;
  font-family: 'Commissioner', sans-serif;

  max-height: 33px;
  cursor: pointer;
`

export const IconLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 0.65625rem;
  color: #949494;
  font-weight: 400;
  margin-bottom: 8px;
`
export const Card = styled(CardMui)`
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 14px !important;
  background-color: transparent !important;
  border: 1px solid rgba(196, 196, 196) !important;
`
