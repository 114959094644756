import { FormControlLabel } from '@mui/material'
import styled, { css } from 'styled-components'

interface DSRCheckboxProps {
  dsr: string
}

export const Container = styled.div`
  font-family: 'Roboto';
  position: relative;
`

export const Picker = styled.div`
  font-family: 'Roboto';

  .DayPicker-wrapper {
    padding: 0 !important;
  }

  .DayPicker-Month {
    margin: 0 !important;
  }

  .DayPicker-Caption {
    div {
      font-size: 12px;
    }
    color: #546e7a;
    font-weight: 600;
  }

  .DayPicker-Week {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .DayPicker-Day {
    width: 35.5px;
    height: 35.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.2px;
    font-weight: 500 !important;
    color: #babac5 !important;
    background-color: #fff;
  }

  .DayPicker-Day:hover:not(.DayPicker-Day--pastDay) {
    cursor: not-allowed;
    background-color: #fff !important;
  }

  .DayPicker-Day--working {
    background-color: #93c5fd !important;
    font-weight: 600 !important;
    color: #000 !important;
  }

  .DayPicker-Day--working:hover:not(.DayPicker-Day--pastDay) {
    background-color: #93c5fd !important;
    border: solid 2px #19299a;
    color: #19299a !important;
    cursor: pointer;
  }

  .DayPicker-Day--dayOff {
    background-color: #fff !important;
    font-weight: 600 !important;
    border: 2px solid #93c5fd !important;
    color: #000 !important;
  }

  .DayPicker-Day--dayOff:hover:not(.DayPicker-Day--pastDay) {
    cursor: pointer;
    transition: all 0.1s;
    border: none !important;
    background-color: #dbeafe !important;
    color: #000000 !important;
  }

  .DayPicker-Day--notWorked {
    background-color: #f7f7f8 !important;
    color: #5b5b76 !important;
  }

  .DayPicker-Day--notWorked:hover:not(.DayPicker-Day--pastDay):not(
      .DayPicker-Day--storeClosed
    ) {
    cursor: pointer;
    transition: background-color 0.1s;
    background-color: #dbeafe !important;
    color: #19299a !important;
  }

  .DayPicker-Day--storeClosed {
    background-color: transparent !important;
    color: #babac5 !important;
  }

  .DayPicker-Day--storeClosed:hover {
    border: 0 !important;
    background-color: transparent !important;
    cursor: not-allowed !important;
  }

  .DayPicker-Day--daysFromAnotherMonth:hover {
    cursor: default !important;
    background-color: #fff !important;
  }

  .DayPicker-Day--disabled {
    opacity: 0.4;
  }

  .DayPicker-Day--pastDay {
    cursor: not-allowed;
  }
`

export const GoalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 4px solid #eeeeee;
  padding-left: 4px;
  margin: 8px 0;

  h1 {
    font-size: 12px;
    color: #546e7a;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;

    > strong {
      color: #263238;
    }
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  font-family: 'Roboto';
  width: 100%;
`

export const ItemTitle = styled.span`
  font-size: 12px;
  color: #546e7a;
  font-weight: 600;
  margin-bottom: 4px;
`

export const DSRCheckbox = styled(FormControlLabel)<DSRCheckboxProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: '#fff';
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 4px 12px 4px 4px;
  margin-left: 10px !important;
  cursor: pointer;

  .MuiTypography-root,
  .MuiTypography-body1,
  .MuiFormControlLabel-label,
  .css-ahj2mt-MuiTypography-root {
    color: ${({ dsr }) => (dsr === 'true' ? '#1E2FA9' : '#546E7A')} !important;
  }

  .MuiCheckbox-root,
  .MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
  }

  svg {
    border-radius: 50%;
  }
`

export const ActionButtons = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
