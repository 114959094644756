import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import {
  updateSprintSuccess,
  getActiveSprintsSuccess,
  getFinishedSprintsSuccess,
  updateSprintAvatarSuccess,
  deleteSprintSuccess,
  createNewSprintSuccess,
  updateSprintAvatarRequest,
  updateAllSprintAvatarRequest
} from './actions'
import { requestFailed } from '../error/action'

export function* getActiveSprints({ payload }) {
  try {
    const { storeId, token } = payload
    const response = yield call(
      api.axios.get,
      `/sprint/store/${storeId}/active-sprints`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    yield put(getActiveSprintsSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getFinishedSprints({ payload }) {
  try {
    const { storeId, page, limit, token } = payload
    const response = yield call(
      api.axios.get,
      `/sprint/store/${storeId}/inactive-sprints`,
      {
        params: {
          page,
          limit
        },
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    yield put(
      getFinishedSprintsSuccess(response.data.items, response.data.metadata)
    )
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* createNewSprint({ payload }) {
  try {
    const { newSprint, currentStoreId, storesIds, token } = payload
    const { avatar, ...newSprintBodyRequest } = newSprint

    const response = yield call(
      api.axios.post,
      '/sprint/stores',
      { ...newSprintBodyRequest, storesIds },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const newSprintResponse = response.data.find(
      sprint => sprint.storeId === currentStoreId
    )
    yield put(createNewSprintSuccess(newSprintResponse))

    if (avatar instanceof FormData) {
      const sprintsIds = response.data.map(sprint => sprint._id)

      avatar.append('sprintsIds', sprintsIds.join(','))

      yield put(updateAllSprintAvatarRequest(sprintsIds, avatar, token))
    }
    toast.success('Corridinha criada')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateSprint({ payload }) {
  try {
    const { updatedSprint, token } = payload
    const {
      photo,
      _id,
      storeId,
      createdAt,
      updatedAt,
      ...updatedSprintBodyRequest
    } = updatedSprint

    const response = yield call(
      api.axios.put,
      `/sprint/${updatedSprint._id}`,
      updatedSprintBodyRequest,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    if (photo instanceof FormData) {
      yield put(updateSprintAvatarRequest(updatedSprint._id, photo, token))
    }

    toast.success('Corridinha atualizada com sucesso.')
    yield put(updateSprintSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateAllSprintAvatar({ payload }) {
  try {
    const { sprintsIds, newSprintAvatar, token } = payload
    const response = yield call(
      api.axios.put,
      '/sprint/photos',
      newSprintAvatar,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    yield put(updateSprintAvatarSuccess(response.data.urls[0], sprintsIds))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateSprintAvatar({ payload }) {
  try {
    const { sprintId, newSprintAvatar, token } = payload
    const response = yield call(
      api.axios.put,
      `/sprint/${sprintId}/photo`,
      newSprintAvatar,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    yield put(updateSprintAvatarSuccess(response.data.url, [sprintId]))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* deleteSprint({ payload }) {
  try {
    const { sprintId, token } = payload
    yield call(api.axios.delete, `/sprint/${sprintId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    toast.success('Corridinha deletada com sucesso.')
    yield put(deleteSprintSuccess(sprintId))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export default all([
  takeLatest('@sprints/GET_ACTIVE_SPRINTS_REQUEST', getActiveSprints),
  takeLatest('@sprints/GET_FINISHED_SPRINTS_REQUEST', getFinishedSprints),
  takeLatest('@sprints/CREATE_NEW_SPRINT_REQUEST', createNewSprint),
  takeLatest('@sprints/UPDATE_SPRINT_REQUEST', updateSprint),
  takeLatest('@sprints/UPDATE_SPRINT_AVATAR_REQUEST', updateSprintAvatar),
  takeLatest('@sprints/DELETE_SPRINT_REQUEST', deleteSprint),
  takeLatest('@sprints/UPDATE_ALL_SPRINT_AVATAR_REQUEST', updateAllSprintAvatar)
])
