import { Container, ExitButton, LeftSide, RightSide } from './styles'

interface HeaderProps {
  onClick: () => void
}

export const Header = ({ onClick }: HeaderProps) => {
  return (
    <Container>
      <LeftSide>
        <img src='/images/logo-blue.svg' alt='NeoPro Logo' />
        Enviar comunicado - NeoStory
      </LeftSide>

      <RightSide>
        <ExitButton onClick={onClick}>Sair para o painel</ExitButton>
      </RightSide>
    </Container>
  )
}
