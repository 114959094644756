import { useEffect, useState } from 'react'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import jwt_decode from 'jwt-decode'
import { addDays, format } from 'date-fns'
import { Modal } from '@material-ui/core'
import { MdErrorOutline } from 'react-icons/md'

import { browserHistory } from 'App'
import { Form } from './components/Form'
import { Header } from './components/Header'
import { MediaSettings } from './components/MediaSettings'
import { PreviewMedia } from './components/PreviewMedia'
import { PreviewMediaList } from './components/PreviewMediaList'
import { ICreateStoryline, ITag } from 'store/modules/storylines/types'
import { IUserProps } from 'store/modules/user/types'
import { userRequest } from 'store/modules/user/actions'

import { Container, Content, PromptModal } from './styles'

export interface SelectedMediaProps {
  file: File | null
  url: string | null
  link: string
  index: number
}

interface ModalProps {
  isOpen: boolean
  link: string
  func?: () => void
}

export interface IFile {
  [key: string]: File
}
interface ILink {
  [key: string]: string
}

export interface CustomICreateStoryline
  extends Omit<ICreateStoryline, 'visibilitySettings' | 'tagIcon' | 'tags'> {
  tags: ITag[]
  visibilitySettings: {
    storeIds: string[]
    roles: (
      | 'Vendedor'
      | 'Gerente'
      | 'Caixa'
      | 'Dono'
      | 'Diretor'
      | 'Comunicador'
    )[]
  }
}

// const pra gente conseguir lidar com os fusos tanto no início quanto durante
// a manipulação dos dados pelo picker
const initialDateState = new Date(
  new Date(format(new Date(), 'yyyy-MM-dd')).getTime() +
    Math.abs(
      new Date(
        format(new Date().setDate(1), 'yyyy-MM-dd')
      ).getTimezoneOffset() * 60000
    )
).toString()

const initialStoryState: CustomICreateStoryline = {
  name: '',
  period: {
    start: initialDateState,
    end: addDays(new Date(initialDateState), 1).toString()
  },
  tags: [],
  type: 'temporary',
  visibilitySettings: {
    roles: [],
    storeIds: []
  }
}
interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

export const CreateStoryline = () => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([])
  const [files, setFiles] = useState<IFile>({})
  const [links, setLinks] = useState<ILink>({})
  const [currentMediaUrlPreview, setCurrentMediaUrlPreview] =
    useState<string>('')
  const [currentLink, setCurrentLink] = useState('')
  const [currentFile, setCurrentFile] = useState<File>()
  const [isAddingNewMedia, setIsAddingNewMedia] = useState(false)
  const [isEditingMedia, setIsEditingMedia] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState<SelectedMediaProps>({
    file: null,
    url: null,
    link: '',
    index: -1
  })

  const [storyData, setStoryData] =
    useState<CustomICreateStoryline>(initialStoryState)

  const [isDiscardStorylineModalOpen, setIsDiscardStorylineModalOpen] =
    useState(false)

  const [discardCurrentPageChangesProps, setDiscardCurrentPageChangesProps] =
    useState<ModalProps>({
      isOpen: false,
      link: '',
      func: undefined
    })

  const [isDeleteEmptyPageModalOpen, setIsDeleteEmptyPageModalOpen] =
    useState<ModalProps>({
      isOpen: false,
      link: '',
      func: undefined
    })

  const { user } = useSelector<SelectorUser, IUserProps>(state => state.user)

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (token) {
      if (!user?._id) {
        const decoded = jwt_decode<{ id: string }>(token)
        dispatch(userRequest(decoded.id, token))
      }
    } else {
      browserHistory.push('/login')
    }
  }, [token])

  useEffect(() => {
    const userHasNeoStory = user._id && !user.company.hasNeoStory

    if (userHasNeoStory) {
      const isCommunicator = user.stores.every(
        store => store.type === 'communicator'
      )
      if (isCommunicator) {
        browserHistory.push('/storylines')
      } else {
        const userStores = user.stores.filter(s =>
          ['owner', 'manager', 'cashier', 'director'].includes(s.type)
        )

        const userHasMoreThanOneStore = userStores.length > 1
        if (userHasMoreThanOneStore) {
          browserHistory.push('/stores')
        } else {
          browserHistory.push(`/${user.stores[0].storeId._id}/dashboard`)
        }
      }
    }
  }, [user])

  const insertKey = (
    key: string,
    value: File | string,
    obj: IFile | ILink,
    pos: number
  ) => {
    return Object.keys(obj).reduce((acc, current, index) => {
      if (index === pos) acc[key] = value
      acc[current] = obj[current]
      return acc
    }, {} as IFile | ILink)
  }

  const handleAddNewMedia = (link: string) => {
    const isNewMedia = selectedMedia.url !== currentMediaUrlPreview
    const isNewLink = link !== selectedMedia.link

    // Verifica se ele adicionou de fato uma nova mídia
    if (isNewMedia || isNewLink) {
      // Verifica se ele tá editando uma mídia já existente ou apenas criando uma nova (na storyline)
      const isEditingStorylineMedia =
        selectedMedia.url && currentFile && isEditingMedia && !isAddingNewMedia

      const isAddingNewMediaToStoryline = currentMediaUrlPreview && currentFile
      if (isEditingStorylineMedia) {
        // Verifica se mudou a foto
        if (selectedMedia.url !== currentMediaUrlPreview) {
          const newFiles = insertKey(
            currentMediaUrlPreview,
            currentFile,
            { ...files },
            selectedMedia.index
          ) as IFile

          delete newFiles[selectedMedia.url!]
          const newPreviewUrls = Object.keys(newFiles)

          setFiles(newFiles)
          setPreviewUrls(newPreviewUrls)
        }

        const hasChangedMediaAndLink =
          selectedMedia.url !== currentMediaUrlPreview &&
          link !== selectedMedia.link

        const onlyLinkHasChanged = link !== selectedMedia.link

        // Verifica se ele mudou o link e a foto
        if (hasChangedMediaAndLink) {
          const newLinks = insertKey(
            currentMediaUrlPreview,
            link,
            { ...links },
            selectedMedia.index
          ) as ILink

          delete newLinks[selectedMedia.url!]

          setLinks(newLinks)
        }

        // Nesse caso ele muda apenas o link e mantém a foto
        else if (onlyLinkHasChanged) {
          const newLinks = {
            ...links,
            [currentMediaUrlPreview]: link
          }
          setLinks(newLinks)
        }
      }
      // Nesse caso ele está apenas adicionando uma nova media pra storyline
      else if (isAddingNewMediaToStoryline) {
        setPreviewUrls(prev => [...prev, currentMediaUrlPreview])
        setFiles(prev => ({
          ...prev,
          [currentMediaUrlPreview]: currentFile
        }))

        if (link) {
          setLinks(prev => ({
            ...prev,
            [currentMediaUrlPreview]: link
          }))
        }
      }
    }

    if (isEditingMedia) {
      setIsEditingMedia(false)
    }
    if (isAddingNewMedia) {
      setIsAddingNewMedia(false)
    }

    setCurrentLink('')
    if (selectedMedia.url) {
      setSelectedMedia({
        file: null,
        url: null,
        link: '',
        index: -1
      })
    }
    setCurrentMediaUrlPreview('')
    setCurrentFile(undefined)
  }

  const handlePreviewNewMedia = (media: File, srcImg: string) => {
    const isEditingPreviousMedia =
      selectedMedia.url && files[selectedMedia.url] && !isAddingNewMedia

    if (isEditingPreviousMedia) {
      const newPreviewUrls = [...previewUrls]
      newPreviewUrls.splice(selectedMedia.index, 1, srcImg)
      setPreviewUrls(newPreviewUrls)
    } else {
      setIsAddingNewMedia(true)
      if (isEditingMedia) {
        setIsEditingMedia(false)
      }
    }
    setCurrentMediaUrlPreview(srcImg)
    setCurrentFile(media)
  }

  const handleDeleteSelectedMedia = (srcImg: string) => {
    if (previewUrls.includes(srcImg)) {
      setPreviewUrls(prevUrls => prevUrls.filter(url => url !== srcImg))
    }

    const fileExists = files[srcImg]

    if (fileExists) {
      const newFiles = { ...files }
      delete newFiles[srcImg]

      setFiles(newFiles)
    }

    setIsAddingNewMedia(false)
    setIsEditingMedia(false)
    setCurrentFile(undefined)
    setCurrentMediaUrlPreview('')
    setCurrentLink('')
    setSelectedMedia({
      file: null,
      url: null,
      link: '',
      index: -1
    })
  }

  const handleRemoveCurrentSelectedMedia = (srcImg: string) => {
    const isSrcImgInPreviewUrls = previewUrls.includes(srcImg)
    if (isSrcImgInPreviewUrls) {
      setPreviewUrls(prevUrls =>
        prevUrls.map(url => {
          if (url !== srcImg) {
            return url
          }
          return ''
        })
      )
    }

    const fileExists = files[srcImg]

    if (fileExists) {
      const newFiles = { ...files }
      delete newFiles[srcImg]

      setFiles(newFiles)
    }

    setCurrentLink('')
    setCurrentFile(undefined)
    setCurrentMediaUrlPreview('')
  }

  const handleEditMedia = (srcImg: string) => {
    setIsEditingMedia(true)
    if (isAddingNewMedia) {
      setIsAddingNewMedia(false)
    }

    if (selectedMedia.url) {
      const newPreviewUrls = [...previewUrls]
      newPreviewUrls.splice(selectedMedia.index, 1, selectedMedia.url)
      setPreviewUrls(newPreviewUrls)
    }

    setCurrentFile(files[srcImg])
    setCurrentLink(links[srcImg] ?? '')
    setCurrentMediaUrlPreview(srcImg)

    const isSrcImgInPreviewUrls = previewUrls.includes(srcImg)

    if (isSrcImgInPreviewUrls) {
      setSelectedMedia({
        file: files[srcImg],
        url: srcImg,
        link: links[srcImg] ?? '',
        index: previewUrls.indexOf(srcImg)
      })
    }
  }

  const handleAdd = () => {
    setCurrentFile(undefined)
    setCurrentMediaUrlPreview('')
    setCurrentLink('')
    setSelectedMedia({
      file: null,
      url: null,
      link: '',
      index: -1
    })
    setIsAddingNewMedia(true)
  }

  const handleCloseMediaSettings = () => {
    const removeSelected = () => {
      setSelectedMedia({
        file: null,
        url: null,
        link: '',
        index: -1
      })
      setCurrentFile(undefined)
      setCurrentMediaUrlPreview('')
      setCurrentLink('')
      setIsAddingNewMedia(false)
      setIsEditingMedia(false)
    }

    const hasUnsavedChanges =
      selectedMedia.url &&
      (selectedMedia.url !== currentMediaUrlPreview ||
        selectedMedia.link !== currentLink)

    const isEmptyPage =
      isAddingNewMedia && (!currentMediaUrlPreview || !isEditingMedia)

    // Verifica se tem dados novos porém não salvos
    if (hasUnsavedChanges) {
      setDiscardCurrentPageChangesProps({
        isOpen: true,
        link: currentLink,
        func: () => {
          if (!currentMediaUrlPreview) {
            const newFiles = insertKey(
              selectedMedia.url!,
              selectedMedia.file!,
              { ...files },
              selectedMedia.index
            ) as IFile
            const newPreviewUrls = Object.keys(newFiles)

            setPreviewUrls(newPreviewUrls)
            setFiles(newFiles)
          }
          removeSelected()
        }
      })
    }
    // Verifica se tem uma página nova porém em branco ( sem dados )
    else if (isEmptyPage) {
      setIsDeleteEmptyPageModalOpen({
        isOpen: true,
        link: currentLink,
        func: removeSelected
      })
    } else {
      setSelectedMedia({
        file: null,
        url: null,
        link: '',
        index: -1
      })
      setIsEditingMedia(false)
      setIsAddingNewMedia(false)
    }
  }

  const handleEditFormMedia = (url: string) => {
    const hasUnsavedChanges =
      selectedMedia.url &&
      (selectedMedia.url !== currentMediaUrlPreview ||
        selectedMedia.link !== currentLink)

    const isEmptyPage =
      isAddingNewMedia && (!currentMediaUrlPreview || !isEditingMedia)

    // Verifica se tem dados novos porém não salvos
    if (hasUnsavedChanges) {
      setDiscardCurrentPageChangesProps({
        isOpen: true,
        link: url,
        func: () => {
          if (!currentMediaUrlPreview) {
            const newFiles = insertKey(
              selectedMedia.url!,
              selectedMedia.file!,
              { ...files },
              selectedMedia.index
            ) as IFile
            const newPreviewUrls = Object.keys(newFiles)

            setPreviewUrls(newPreviewUrls)
            setFiles(newFiles)
          }
          handleEditMedia(url)
        }
      })
    }
    // Verifica se tem uma página nova porém em branco ( sem dados )
    else if (isEmptyPage) {
      setIsDeleteEmptyPageModalOpen({
        isOpen: true,
        link: url
      })
    } else {
      handleEditMedia(url)
    }
  }

  const onSubmitDiscardStorylineModal = () => {
    const isCommunicator = user.stores.every(
      store => store.type === 'communicator'
    )

    if (isCommunicator) {
      browserHistory.push('/storylines')
    } else {
      browserHistory.push('/stores')
    }
  }

  const onSubmitDiscardCurrentPageChangesModal = () => {
    discardCurrentPageChangesProps.func
      ? discardCurrentPageChangesProps.func()
      : handleEditMedia(discardCurrentPageChangesProps.link)

    setDiscardCurrentPageChangesProps({
      isOpen: false,
      link: ''
    })
  }

  const onSubmitDeleteEmptyPageModal = () => {
    isDeleteEmptyPageModalOpen.func
      ? isDeleteEmptyPageModalOpen.func()
      : handleEditMedia(isDeleteEmptyPageModalOpen.link)

    setIsDeleteEmptyPageModalOpen({
      isOpen: false,
      link: ''
    })
  }

  const hasSomeMedia =
    Boolean(currentMediaUrlPreview || previewUrls.length > 0) &&
    (isAddingNewMedia || isEditingMedia)

  const openMediaSettings = isAddingNewMedia || isEditingMedia

  const showStorylinePreviewList = !hasSomeMedia && previewUrls.length > 0

  return (
    <Container>
      <Header onClick={() => setIsDiscardStorylineModalOpen(true)} />
      <Content hasSomeMedia={openMediaSettings}>
        {showStorylinePreviewList ? (
          <PreviewMediaList
            files={files}
            previewUrls={previewUrls}
            selectedMedia={selectedMedia}
            storyData={storyData}
            handleEditMedia={handleEditMedia}
          />
        ) : (
          <PreviewMedia
            previewMedias={previewUrls}
            handleChangeMedia={handlePreviewNewMedia}
            currentMediaUrlPreview={currentMediaUrlPreview}
            currentFile={currentFile}
            isAddingNewMedia={isAddingNewMedia}
            isEditingMedia={isEditingMedia}
            hasLink={!!currentLink}
          />
        )}

        {openMediaSettings && (
          <MediaSettings
            handleCloseMediaSettings={handleCloseMediaSettings}
            currentFile={currentFile}
            link={currentLink}
            setLink={setCurrentLink}
            currentMediaUrlPreview={currentMediaUrlPreview}
            handleDeleteSelectedMedia={handleDeleteSelectedMedia}
            handleRemoveSelectedMedia={handleRemoveCurrentSelectedMedia}
            handleAddNewMedia={handleAddNewMedia}
            previewMedias={previewUrls}
          />
        )}
        <Form
          files={files}
          links={links}
          handleAdd={handleAdd}
          previewUrls={previewUrls}
          currentFile={currentFile}
          currentLink={currentLink}
          currentMediaUrlPreview={currentMediaUrlPreview}
          handleChangeMedia={handlePreviewNewMedia}
          isAddingNewMedia={isAddingNewMedia}
          isEditingMedia={isEditingMedia}
          handleEditMedia={handleEditFormMedia}
          selectedMedia={selectedMedia}
          storyData={storyData}
          setStoryData={setStoryData}
        />
      </Content>

      <Modal open={isDiscardStorylineModalOpen}>
        <PromptModal
          icon={<MdErrorOutline color='#F34456' />}
          title='Descartar comunicado?'
          onClose={() => setIsDiscardStorylineModalOpen(false)}
          leftTitle='Continuar edição'
          onRight={onSubmitDiscardStorylineModal}
          rightTitle='Descartar e sair'
        >
          Você está saindo para o painel. <br />
          Seu comunicado será descartado, assim como suas alterações.
        </PromptModal>
      </Modal>

      <Modal open={discardCurrentPageChangesProps.isOpen}>
        <PromptModal
          icon={<MdErrorOutline color='#2a3ecb' />}
          title='Descartar alterações na página?'
          onClose={() =>
            setDiscardCurrentPageChangesProps({
              isOpen: false,
              link: ''
            })
          }
          leftTitle='Continuar edição'
          onRight={onSubmitDiscardCurrentPageChangesModal}
          rightButtonStyles={{
            backgroundColor: '#2a3ecb'
          }}
          rightTitle='Descartar'
        >
          A página possuí alterações não salvas. <br />
          Caso feche a edição de página, essas alterações serão descartadas.
        </PromptModal>
      </Modal>

      <Modal open={isDeleteEmptyPageModalOpen.isOpen}>
        <PromptModal
          icon={<MdErrorOutline color='#F34456' />}
          title='Página sem conteúdo'
          onClose={() =>
            setIsDeleteEmptyPageModalOpen({
              isOpen: false,
              link: ''
            })
          }
          leftTitle='Continuar edição'
          onRight={onSubmitDeleteEmptyPageModal}
          rightTitle='Excluir página'
        >
          A página será excluída, pois não possui imagem ou vídeo enviado.
        </PromptModal>
      </Modal>
    </Container>
  )
}
