// React
import React, { useState, useEffect } from 'react'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Divider from '@material-ui/core/Divider'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import CloseIcon from '@mui/icons-material/Close'

// Components
import { DateTime } from 'luxon'
import ListSellers from './components/ListSellers'
import ReleaseModal from './components/Modal'

// Style
import {
  Container,
  ContentModal,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  ContentBox,
  DateHistoricOption,
  RevisionTime,
  CurrentReview,
  DateLastEdit,
  LastEditor,
  ActionContainer,
  CancelBtn,
  RestoreBtn
} from './style'

const ReleaseHistory = ({ open, onClose, input, integrated = false }) => {
  const [selectedInput, setSelectedInput] = useState(
    input.history[input.history.length - 1]
  )

  useEffect(() => {
    setSelectedInput(input.history[input.history.length - 1])
  }, [input])

  const [openModal, setOpenModal] = useState(false)

  const useStyles = makeStyles(() => ({
    formControl: {
      width: '267.5px',
      maxWidth: '100%'
    },
    select: {
      height: '46px'
    },
    restoreBtn: {
      height: '46px'
    },
    input: {
      padding: '15px'
    },
    menuItem: {
      height: '46px'
    }
  }))

  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        input={input}
      >
        <Container>
          <ContentModal>
            <Header>
              <HeaderTitle>Histórico de edição</HeaderTitle>
              <CloseIcon
                sx={{ color: '#263238', cursor: 'pointer' }}
                onClick={onClose}
              />
            </Header>
            <HeaderSubtitle>
              Lançamento de{' '}
              {DateTime.fromISO(input.date).toFormat('dd/MM/yyyy')} -{' '}
              {DateTime.fromISO(input.date).weekdayLong}
            </HeaderSubtitle>
            <ContentBox>
              <Grid xs={6} item>
                <FormControl variant='outlined' className={classes.formControl}>
                  <RevisionTime>Revisões anteriores</RevisionTime>
                  <Select
                    className={classes.select}
                    value={selectedInput}
                    // Override padding inputSelect
                    input={<OutlinedInput classes={{ input: classes.input }} />}
                    onChange={e => setSelectedInput(e.target.value)}
                  >
                    {input.history.map(oldInput => (
                      <MenuItem
                        key={oldInput._id}
                        value={oldInput}
                        className={classes.menuItem}
                      >
                        <DateHistoricOption>
                          {DateTime.fromISO(oldInput.date).toFormat(
                            'dd/MM/yyyy'
                          )}{' '}
                          - {oldInput.lastEditor}
                        </DateHistoricOption>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ListSellers old={selectedInput} current={input} />
              </Grid>
              <Divider orientation='vertical' flexItem />
              <Grid xs={6} item>
                <RevisionTime>Revisão atual</RevisionTime>
                <CurrentReview>
                  <DateLastEdit>
                    {DateTime.fromISO(input.updatedAt).toFormat('dd/MM/yyyy')}
                  </DateLastEdit>
                  <LastEditor>
                    por{' '}
                    {!input?.locked
                      ? input?.history[0].origin
                      : input?.history[input.history.length - 1].lastEditor}
                  </LastEditor>
                </CurrentReview>
                <ListSellers current={input} />
              </Grid>
            </ContentBox>
          </ContentModal>
          <ActionContainer>
            <CancelBtn onClick={onClose}>Cancelar</CancelBtn>
            {!integrated && (
              <Button
                className={classes.restoreBtn}
                color='primary'
                variant='contained'
                onClick={() => setOpenModal(true)}
              >
                <RestoreBtn>Restaurar integração</RestoreBtn>
              </Button>
            )}
          </ActionContainer>
        </Container>
      </Modal>
      {openModal && (
        <ReleaseModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
            onClose()
          }}
          input={input}
        />
      )}
    </>
  )
}

export default ReleaseHistory
