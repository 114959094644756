import { combineReducers } from 'redux'

import goal from './goal/reducer'
import input from './input/reducer'
import user from './user/reducer'
import error from './error/reducer'
import report from './report/reducer'
import store from './store/reducer'
import main from './main/reducer'
import day from './day/reducer'
import group from './group/reducer'
import integration from './integration/reducer'
import calendarGoal from './calendarGoal/reducer'
import sprints from './sprints/reducer'
import storylines from './storylines/reducer'

export default combineReducers({
  goal,
  input,
  user,
  error,
  report,
  store,
  main,
  integration,
  day,
  calendarGoal,
  group,
  sprints,
  storylines
})
