import styled from 'styled-components'

interface WeightSelectionItemProps {
  right?: boolean
  selected?: boolean
}

export const Container = styled.div`
  position: absolute;
  width: 400px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  outline: none;

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    cursor: pointer;
    color: lightgray;
  }

  div {
    width: 100%;

    h1 {
      text-align: left;
      font-size: 20px;
      margin: 0 0 10px;
    }

    .example-card {
      width: 90%;
      background-color: #f6f2e4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      border-radius: 8px;
      margin: 20px auto;
      padding: 10px;
      color: #bfa182;

      h3 {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
        margin: 0;
      }
    }

    button {
      cursor: pointer;
      font-family: 'Roboto', sans-serif;
      padding: 10px;
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #1e2fa9;
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  input {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    text-align: center;
    width: 90%;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;

    border: 1px solid #c4c4c4;
    border-radius: 5px;
    outline: none;

    transition: 0.2s ease-in;
  }

  label {
    position: absolute;
    right: 0;
    font-size: 18px;
    font-weight: 600;
    margin-right: 30px;
  }
`

export const WeightSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
  padding: 0 10px;
`

export const WeightSelectionItem = styled.div<WeightSelectionItemProps>`
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background: ${({ selected }) => (selected ? '#F0ECF4' : '#FAFAFA')};
  color: ${({ selected }) => (selected ? '#1E2FA9' : '#37474F')};
  border: 1px solid #dddddd;
  border-radius: ${({ right }) =>
    right ? '0px 10px 10px 0px' : '10px 0px 0px 10px'};
  transition: 0.2s ease-in;
  width: 100%;

  span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 600;
  }
`

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in;
  width: 100%;
  padding: 0 10px;

  span {
    font-weight: 500;
    color: #546e7a;
    font-size: 12px;
  }

  .slider {
    margin: 0 15px 0 10px;
    position: relative;
  }

  .MuiSlider-rail {
    background-color: #dddddd;
    height: 3px;
  }

  .MuiSlider-track {
    height: 3px;
  }

  .Mui-focusVisible {
    box-shadow: none;
  }

  .MuiSlider-mark {
    padding: 3px;
    border-radius: 50%;
  }

  .MuiSlider-thumb {
    color: white;
    border: 3px solid #ffc700;
    cursor: grab;
    padding: 4px;
    box-shadow: none;

    &::before {
      box-shadow: none;
    }

    &:active {
      cursor: grabbing !important;
    }
  }
`
