import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import {
  createRequestSuccess,
  deleteGroupSuccess,
  listGroupSuccess,
  updateGroupSuccess
} from './actions'
import { requestFailed } from '../error/action'
import {
  addGroupToStoresReport,
  storesReportRequest,
  updateGroupFromStoresReport
} from '../report/actions'
import { usersGroupListRequest } from '../user/actions'

export function* createGroup({ payload }) {
  try {
    const { userId, groupData, token } = payload
    const response = yield call(
      api.axios.post,
      '/group',
      {
        name: groupData.name,
        heads: {
          supervisor: groupData.supervisor.id
        },
        stores: groupData.stores.map(store => store._id)
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const groupsReportResponse = yield call(
      api.axios.get,
      `/report/user/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const expectedResponse = groupsReportResponse.data.groups.find(
      group => group.groupId === response.data._id
    )

    yield put(
      createRequestSuccess({
        ...expectedResponse,
        _id: expectedResponse.groupId
      })
    )
    yield put(
      addGroupToStoresReport({
        ...expectedResponse,
        _id: expectedResponse.groupId
      })
    )

    yield put(storesReportRequest(userId, token))
    toast.success('Regional criada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export function* listGroup({ payload }) {
  try {
    const { token } = payload
    const response = yield call(api.axios.get, '/group', {
      headers: { Authorization: `Bearer ${token}` }
    })

    yield put(listGroupSuccess(response.data))
  } catch (e) {
    const { friendlyMsg } = e.response.data.error
    const isErrorHasNoRegional =
      friendlyMsg === 'Nenhuma regional foi encontrado para esse usuário.'

    if (!isErrorHasNoRegional) {
      if (e.response && e.response.status >= 500) {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: friendlyMsg || 'Servidor fora do ar.'
            }
          })
        )
      } else if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateGroup({ payload }) {
  try {
    const { groupId, groupData, removeSupervisor, userId, token } = payload
    const response = yield call(
      api.axios.put,
      `/group/${groupId}`,
      {
        name: groupData.name,
        ...(groupData.supervisor.id && {
          heads: {
            supervisor: groupData.supervisor.id
          }
        }),
        stores: groupData.stores.map(store => store._id)
      },
      {
        ...(removeSupervisor && { params: { removeSupervisor } }),
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const groupsReportResponse = yield call(
      api.axios.get,
      `/report/user/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const expectedResponse = groupsReportResponse.data.groups.find(
      group => group.groupId === response.data._id
    )
    yield put(storesReportRequest(userId, token))
    yield put(usersGroupListRequest(token))
    yield put(
      updateGroupSuccess(
        {
          ...expectedResponse,
          _id: expectedResponse.groupId
        },
        groupId
      )
    )
    yield put(
      updateGroupFromStoresReport({
        ...expectedResponse,
        _id: expectedResponse.groupId
      })
    )
    toast.success('Regional atualizada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export function* deleteGroup({ payload }) {
  try {
    const { userId, groupId, removeSupervisor, token } = payload
    yield call(api.axios.delete, `/group/${groupId}`, {
      ...(removeSupervisor && { params: { removeSupervisor } }),
      headers: { Authorization: `Bearer ${token}` }
    })
    toast.success('Regional removida com sucesso.')
    yield put(storesReportRequest(userId, token))
    yield put(usersGroupListRequest(token))
    yield put(deleteGroupSuccess(groupId))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export default all([
  takeLatest('@group/CREATE_REQUEST', createGroup),
  takeLatest('@group/LIST_REQUEST', listGroup),
  takeLatest('@group/UPDATE_REQUEST', updateGroup),
  takeLatest('@group/DELETE_REQUEST', deleteGroup)
])
