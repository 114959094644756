import React from 'react';

const ErrorIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1665 24.5H20.8332V28.1667H17.1665V24.5ZM17.1665 9.83333H20.8332V20.8333H17.1665V9.83333ZM18.9815 0.666668C8.8615 0.666668 0.666504 8.88 0.666504 19C0.666504 29.12 8.8615 37.3333 18.9815 37.3333C29.1198 37.3333 37.3332 29.12 37.3332 19C37.3332 8.88 29.1198 0.666668 18.9815 0.666668ZM18.9998 33.6667C10.8965 33.6667 4.33317 27.1033 4.33317 19C4.33317 10.8967 10.8965 4.33333 18.9998 4.33333C27.1032 4.33333 33.6665 10.8967 33.6665 19C33.6665 27.1033 27.1032 33.6667 18.9998 33.6667Z"
        fill="#E64C3D"
      />
    </svg>
  );
};

export default ErrorIcon;
