import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { differenceInDays } from 'date-fns'
import { Modal } from '@material-ui/core'
import { BiSend, BiTrash } from 'react-icons/bi'
import {
  Draw as DrawIcon,
  CollectionsOutlined as CollectionsOutlinedIcon
} from '@mui/icons-material'

import { browserHistory } from 'App'
import { PromptModal } from 'views/CreateStoryline/styles'
import { Select } from './components/Select/Select'
import { StoriesListPreview } from './components/StoriesListPreview'
import { StoresSelect } from './components/StoresSelect'
import { Thumbnail } from './components/Thumbnail'
import { SwitchPermanentStory } from './components/SwitchPermanentStory'
import { Calendar } from './components/Calendar'
import { deleteStorylineRequest } from 'store/modules/storylines/actions'
import { ISettings, IStorylineTable } from 'store/modules/storylines/types'

import {
  Container,
  Content,
  Divider,
  Footer,
  FooterDeleteButton,
  FooterSaveButton,
  FormContainer,
  ImageContainer,
  Label,
  StoryPagesNumber,
  TextInput
} from './styles'

interface SelectedMediaProps {
  url: string | null
  index: number
}

interface FormProps {
  previewUrls: string[]
  settings: ISettings
  fileType: 'image' | 'video'
  storiesLength: number
  selectedMedia: SelectedMediaProps
  storyData: IStorylineTable
  handleEditMedia: (srcImg: string) => void
}

const roles = {
  Vendedor: 'seller',
  Gerente: 'manager',
  Caixa: 'cashier',
  Dono: 'owner',
  Diretor: 'director'
} as const

const inverseRoles = {
  seller: 'Vendedor',
  manager: 'Gerente',
  cashier: 'Caixa',
  owner: 'Dono',
  director: 'Diretor',
  communicator: 'Comunicador'
} as const

export const Form = ({
  storyData,
  settings,
  fileType,
  storiesLength,
  previewUrls,
  selectedMedia,
  handleEditMedia
}: FormProps) => {
  const dispatch = useDispatch()

  const [isDeleteStorylineModalOpen, setIsDeleteStorylineModalOpen] =
    useState(false)

  const handleDeleteStoryline = () => {
    const token = window.localStorage.getItem('@NeoPro:token')
    dispatch(deleteStorylineRequest(storyData._id, token))
    setIsDeleteStorylineModalOpen(false)
    browserHistory.push('/storylines')
  }

  const storylineDurationInDays =
    differenceInDays(
      new Date(storyData.period.end),
      new Date(storyData.period.start)
    ) + 1

  const selectedStores = storyData?.visibilitySettings?.storeIds.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr]: true
      }
    },
    {} as { [key: string]: boolean }
  )

  return (
    <Container>
      <Content>
        <FormContainer>
          <Label>
            <DrawIcon />
            COMUNICADO
          </Label>

          <TextInput
            label='Título'
            variant='outlined'
            value={storyData.name}
            error={false}
            disabled
          />

          <Select
            label='Tags'
            options={settings.tags.map(tag => tag.name)}
            error={false}
            value={storyData.tags.map(tag => tag.name)}
          />

          <Thumbnail storyUrl={storyData.photo?.url} />

          <SwitchPermanentStory type={storyData.type} />

          <Calendar storyData={storyData} />

          <Label style={{ fontWeight: 400 }}>
            Divulgação:
            <b style={{ marginLeft: 2 }}>
              {storyData.type === 'fixed'
                ? 'Permanente'
                : `${storylineDurationInDays} dia${
                    storylineDurationInDays > 1 ? 's' : ''
                  }`}
            </b>
          </Label>

          <Divider />
          <Label>
            <BiSend size={24} />
            DESTINATÁRIOS
          </Label>

          <Select
            label='Tipos de usuários'
            error={false}
            options={Object.keys(roles).filter(
              key => key !== 'Dono' && key !== 'Comunicador'
            )}
            value={
              storyData.visibilitySettings.roles
                ? storyData.visibilitySettings.roles
                    .map(role => inverseRoles[role])
                    .filter(role => role !== 'Dono' && role !== 'Comunicador')
                : []
            }
          />

          <StoresSelect error={false} isStoreSelected={selectedStores} />

          <Divider />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label>
              <CollectionsOutlinedIcon />
              PÁGINAS
            </Label>

            <StoryPagesNumber storiesLength={storiesLength}>
              {storiesLength}/10
            </StoryPagesNumber>
          </div>

          <ImageContainer>
            {previewUrls.length > 0 &&
              previewUrls.map((url, index) => (
                <StoriesListPreview
                  mediaType={fileType}
                  isSelected={url === selectedMedia.url}
                  index={index + 1}
                  key={url}
                  media={url}
                  onClick={() => {
                    const hasSelectedADifferentMedia =
                      url !== selectedMedia.url && index !== selectedMedia.index

                    if (hasSelectedADifferentMedia) {
                      handleEditMedia(url)
                    }
                  }}
                />
              ))}
          </ImageContainer>
        </FormContainer>

        <Footer>
          <FooterDeleteButton
            onClick={() => setIsDeleteStorylineModalOpen(true)}
          >
            Excluir comunicado
          </FooterDeleteButton>

          <Divider />

          <FooterSaveButton disabled variant='contained' onClick={() => {}}>
            Comunicado enviado
          </FooterSaveButton>
        </Footer>
      </Content>

      <Modal open={isDeleteStorylineModalOpen}>
        <PromptModal
          icon={<BiTrash color='#F34456' />}
          title='Excluir comunicado?'
          onClose={() => setIsDeleteStorylineModalOpen(false)}
          leftTitle='Cancelar'
          onRight={handleDeleteStoryline}
          rightTitle='Excluir'
        >
          Todas as informações desse comunicado serão deletadas. <br />
          Essa ação não pode ser revertida.
        </PromptModal>
      </Modal>
    </Container>
  )
}
