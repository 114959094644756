import styled from 'styled-components'
import { Card as CardMui } from '@mui/material'

export const Card = styled(CardMui)`
  display: flex !important;
  align-items: center;
  padding: 18.5px 14px !important;
  background-color: transparent !important;
  border: 1px solid rgba(196, 196, 196) !important;
`
