import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TransferListCard from './components/TransferListCard'
import EditUserGoalModal from './components/EditUserGoalModal'
import Tooltip from 'components/Tooltip'
import LockArrow from 'assets/Icons/LockArrow'
import SpeedIcon from '@material-ui/icons/Speed'
import { AccWeightCounter, ContentBox } from './styles'
import { IDays, IGoal, IUserWorking } from 'store/modules/goal/types'

interface UsersGoalProps {
  usersNotWorking: IUserWorking[]
  usersWorking: IUserWorking[]
  totalWeight: number
  loading: boolean
  showUnlock: boolean
  onClickUnlock: () => void
  goal: IGoal | undefined
  handleUpdateUserWeight: (
    days: IDays[],
    user: IUserWorking,
    tab: 'fixed' | 'auto',
    sellerWeight: number,
    goalId: string,
    isRemoving: boolean
  ) => void
  newUser: IUserWorking | undefined
}

const UsersGoal = ({
  usersNotWorking,
  usersWorking,
  totalWeight,
  loading,
  showUnlock,
  onClickUnlock,
  goal,
  handleUpdateUserWeight,
  newUser
}: UsersGoalProps) => {
  const history = useHistory()

  //Caso haja usuário novo, abre o modal para editar o mesmo e adicionar a meta
  const [editUser, setEditUser] = useState<IUserWorking | undefined>(undefined)

  useEffect(() => {
    if (newUser) {
      setEditUser(newUser)
    }
  }, [newUser])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ContentBox>
          <div className='contentbox-title'>
            <div>
              <h1>Não participam</h1>
              <span>{usersNotWorking.length}</span>
            </div>
          </div>
          <ul>
            {usersNotWorking.length > 0 ? (
              usersNotWorking.map((user, i) => (
                <TransferListCard
                  key={i}
                  user={user}
                  onClick={() => setEditUser(user)}
                />
              ))
            ) : (
              <p style={{ color: '#888', fontSize: 12 }}>
                {!loading
                  ? `Todos os vendedores estão na meta`
                  : `Carregando...`}
              </p>
            )}
          </ul>
        </ContentBox>
        <ContentBox>
          <div className='contentbox-title'>
            <div>
              <h1>Participam da meta</h1>
              <span>{usersWorking.length}</span>
            </div>
            <div>
              <AccWeightCounter
                error={Boolean(
                  totalWeight.toFixed(2) !==
                    usersWorking
                      ?.filter(user => user.goalWeight > 0)
                      .length.toFixed(2) && goal?.distribute
                )}
              >
                <div data-tip data-for='total-weight-tooltip'>
                  <Tooltip
                    fixed
                    place='top'
                    id='total-weight-tooltip'
                    content={
                      <p>
                        {totalWeight.toFixed(2) >
                          usersWorking
                            ?.filter(user => user.goalWeight > 0)
                            .length.toFixed(2) && goal?.distribute
                          ? `Ajuste os pesos para totalizar ${
                              usersWorking?.length * 100
                            }%`
                          : 'Total de pesos acumulado dos vendedores'}
                      </p>
                    }
                  />
                  <SpeedIcon fontSize='small' />
                  <h3 className='weight'>{(totalWeight * 100).toFixed(0)}%</h3>
                </div>

                <div data-tip data-for='locker-weight-tooltip'>
                  {showUnlock &&
                    (usersWorking.find(user => user.goalWeight !== 1) ||
                      usersWorking.length * 100 !==
                        usersWorking.reduce(
                          (acc, user) => (acc += user.goalWeight * 100),
                          0
                        )) && (
                      <>
                        <Tooltip
                          fixed
                          place='top'
                          id='locker-weight-tooltip'
                          content={
                            <p>
                              Desafixar peso dos vendedores para cálculo de Peso
                              Automático
                            </p>
                          }
                        />
                        <button
                          className='unlock-sellers-button'
                          onClick={onClickUnlock}
                        >
                          <LockArrow />
                        </button>
                      </>
                    )}
                </div>
              </AccWeightCounter>
            </div>
          </div>
          <ul data-cy='usersWorkingSellersListTab'>
            {usersWorking.map((userW, i) => {
              const workingUserDaysLength = goal?.days.filter(
                day =>
                  day.users.find(usr => usr.userId === userW.userId?._id) &&
                  !day.users.find(usr => usr.userId === userW.userId?._id)
                    ?.dayOff.enable &&
                  userW.active &&
                  day.working
              ).length
              return (
                <TransferListCard
                  working
                  inGoal
                  isGoalOverview
                  key={i}
                  user={userW}
                  data={goal}
                  days={workingUserDaysLength}
                  onClick={() => setEditUser(userW)}
                />
              )
            })}
          </ul>
        </ContentBox>
      </div>
      {goal && (
        <EditUserGoalModal
          modal={!!editUser}
          currentMonthGoal={goal}
          usersWorkingLength={usersWorking.length}
          handleUpdateUserWeight={handleUpdateUserWeight}
          handleRemoveUser={user => {
            handleUpdateUserWeight(
              [],
              {
                _id: user._id,
                userId: user.userId,
                goalWeight: 1,
                weightLocked: false,
                dsr: goal.dsr > 0,
                active: false
              },
              'auto',
              1,
              goal?._id,
              true
            )
            setEditUser(undefined)
          }}
          user={editUser}
          onClose={() => {
            setEditUser(undefined)
            if (window.location.href.indexOf(`/`) >= 0)
              history.push(`/${goal?.storeId}/goals/${goal?._id}`)
          }}
          loading={loading}
        />
      )}
    </>
  )
}

export default UsersGoal
