import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { formatPrice, formatToPercentage } from '../../../../utils/format'
import { IGoal } from 'store/modules/goal/types'
import Tooltip from 'components/Tooltip'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  goalNumber: {
    background: '#E2E4E9',
    color: '#65747B',
    display: 'flex',
    fontWeight: 'bold',
    width: '24px',
    height: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    borderRadius: '50%'
  }
})

interface GoalsProps {
  goal: IGoal | undefined
}

export default function Goals({ goal }: GoalsProps) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Comissão</TableCell>
            <TableCell>Bônus</TableCell>
            <TableCell>Remuneração Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {goal &&
            goal.monthGoals.map((monthGoal, i) => {
              const {
                complement,
                dsr,
                sumCommission,
                salary,
                bonus,
                fixedCommission,
                commission
              } = monthGoal.initialCommission

              const monthGoalTooltipInfos = {
                ...(fixedCommission?.value && {
                  'Comissão base': formatPrice(fixedCommission.value)
                }),
                ...(commission?.value && {
                  Comissão: formatPrice(commission.value)
                }),
                ...(dsr && { DSR: formatPrice(dsr) }),
                ...(salary && { Piso: formatPrice(salary) }),
                ...(complement && {
                  Complemento: formatPrice(complement)
                }),
                ...(bonus?.value && { Bônus: formatPrice(bonus.value) })
              }

              return (
                <TableRow key={monthGoal._id} hover>
                  <TableCell style={{ maxWidth: 30 }}>
                    {i === 0 && (
                      <Tooltip
                        id={`current-goal-type-${monthGoal._id}`}
                        content={<p>Principal</p>}
                        place='bottom'
                      />
                    )}

                    <p
                      data-tip
                      data-for={`current-goal-type-${monthGoal._id}`}
                      className={classes.goalNumber}
                      style={{
                        ...(i === 0 && { border: '2px solid #FFC700' })
                      }}
                    >
                      {i + 1}
                    </p>
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    <b>{monthGoal.name}</b>
                  </TableCell>
                  <TableCell>{formatPrice(monthGoal.value)}</TableCell>
                  <TableCell>
                    {formatToPercentage(monthGoal.commission * 100)}
                  </TableCell>
                  <TableCell>
                    {monthGoal.bonusPercentage
                      ? formatToPercentage(monthGoal.bonus * 100)
                      : formatPrice(monthGoal.bonus)}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      id={`current-goal-remuneration-${monthGoal._id}`}
                      content={
                        <div>
                          <strong
                            style={{
                              display: 'block',
                              marginBottom: '10px',
                              fontSize: '14px'
                            }}
                          >
                            Remuneração Total
                          </strong>
                          {Object.entries(monthGoalTooltipInfos).map(
                            ([key, value]) => (
                              <p key={key} style={{ marginBottom: '2px' }}>
                                <strong>{key}: </strong> {value}
                              </p>
                            )
                          )}
                          <p style={{ marginTop: '10px' }}>
                            <strong>Total: </strong>
                            {formatPrice(sumCommission ?? 0)}
                          </p>
                        </div>
                      }
                      place='bottom'
                    />

                    {formatPrice(
                      monthGoal.initialCommission.sumCommission ?? 0
                    )}

                    <ErrorOutlineIcon
                      data-tip
                      data-for={`current-goal-remuneration-${monthGoal._id}`}
                      style={{
                        color: '#C4C4C4',
                        fontSize: 20,
                        marginLeft: 5,
                        marginBottom: -5
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
