import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  link: {
    color: '#2979FF',
    textDecoration: 'none',
    fontWeight: 'bold'
  }
}))

const Introduction = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <Card {...rest}>
      <CardHeader title='Integração' />
      <Divider />
      <CardContent>
        <Typography>
          Para realizar a integração com seu ERP selecione ele na lista e siga
          as instruções do mesmo. <br />
          Caso seu ERP não esteja nessa lista envie para a empresa responsável a
          nossa documentação de integração:
          <a
            className={classes.link}
            href='https://integracao.proft.sale'
            target='__blank'
          >
            integracao.proft.sale
          </a>
        </Typography>
      </CardContent>
    </Card>
  )
}

Introduction.propTypes = {
  className: PropTypes.string
}

export default Introduction
