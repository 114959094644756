import React, { useState, useEffect } from 'react'

// Libs
import moment from 'moment'
import { useParams } from 'react-router-dom'

// Material UI
import { withStyles, useTheme, makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import Badge from '@material-ui/core/Badge'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

import { formatPrice } from '../utils/format'

// Components

// Redux
import { useSelector, useDispatch } from 'react-redux'
import {
  inputAddRequest,
  handleCloseModal,
  handleOpenModal
} from '../store/modules/input/actions'
import CurrencyTextField from './CurrencyTextField'

const AddInput = () => {
  const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff'
    },
    root: {},
    margin: { marginBottom: theme.spacing(1), marginTop: theme.spacing(2) },
    input: { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },
    pos: {
      marginBottom: 12
    },
    sectionTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  }))
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const confirm = useConfirm()

  // Input - Redux
  const inputList = useSelector(state => state.input.inputList)
  const modalState = useSelector(state => state.input.modal)
  const inputMetadata = useSelector(state => state.input.metadata)

  // Goal - Redux
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const goalList = useSelector(state => state.goal.goalList)

  // LocalStorage
  const token = window.localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  // State
  const [dates, setDates] = useState([])
  const [goalData, setGoalData] = useState({ days: [], usersWorking: [] })
  const [data, setData] = useState({
    date: '',
    storeId,
    sellers: [],
    store: {
      sales: '',
      sold: '',
      items: ''
    }
  })
  const [sum, setSum] = useState({
    sales: 0,
    sold: 0,
    items: 0
  })

  const [expanded, setExpanded] = useState(false)

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  // Efeito para gerar lista de inputs pendentes
  useEffect(() => {
    if (inputList && goalList && inputMetadata.pagination.currentPage === 1) {
      // Instancia data de hoje, e define um horário padrão
      const today = moment()
      today.utc(0).set({ hour: 15, minute: 0, second: 0, millisecond: 0 })

      // variável p/ realizar a contagem dos dias, é definida no primeiro dia do mês atual
      const lastDay = today.clone().subtract(1, 'M').set({ date: 1 })

      // cria uma lista com a data dos últimos lançamentos e transforma as datas em unix.
      const inputDates = inputList.map(input =>
        moment(input.date)
          .utc(0)
          .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
          .unix()
      )
      // lista que vai pro campo select
      const dates = []
      // lista de dias úteis
      let workingDays = []

      let days = currentGoal ? [...currentGoal.days] : []
      let usersWorking = currentGoal ? [...currentGoal.usersWorking] : []

      const lastMonth = today.clone().subtract(1, 'M').set({ date: 1 })

      // Lista de dias úteis do mês anterior
      const lastGoalIndex = goalList.findIndex(
        g => g.month === lastMonth.toISOString()
      )

      // Busca pelos dias uteis do mes atual
      workingDays = currentGoal
        ? [
            ...workingDays,
            ...currentGoal.days
              .filter(dayGoal => dayGoal.working && dayGoal.users.length > 0)
              .map(day => moment(day.date).unix())
          ]
        : workingDays

      if (lastGoalIndex >= 0 && goalList[lastGoalIndex]) {
        workingDays = [
          ...workingDays,
          ...goalList[lastGoalIndex].days
            .filter(dayGoal => dayGoal.working && dayGoal.users.length > 0)
            .map(day => moment(day.date).unix())
        ]
        days = [...days, ...goalList[lastGoalIndex].days]
        usersWorking = [
          ...usersWorking,
          ...goalList[lastGoalIndex].usersWorking
        ]
      }

      workingDays.sort((a, b) => b - a)
      workingDays = workingDays.slice(0, 34)

      while (lastDay <= today) {
        // Verifica se essa data já foi lançada, converte p/ unix pra poder comparar os números
        // Verifica se é um dia útil
        // Verifica se tem vendedores trabalhando aquele dia
        if (
          !inputDates.includes(lastDay.unix()) &&
          workingDays.includes(lastDay.unix())
        ) {
          dates.push(moment(lastDay))
        }
        lastDay.add(1, 'd')
      }

      setDates(dates)
      setGoalData({ days, usersWorking })
    }
  }, [currentGoal, inputList, goalList])

  const StyledButton = withStyles({
    root: {
      background:
        dates.length > 0
          ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
          : 'primary',
      borderRadius: 3,
      border: 5,
      color: 'white',
      height: 35,
      padding: '0 10px',
      boxShadow:
        dates.length > 0 ? '0 3px 5px 2px rgba(255, 105, 135, .3)' : undefined
    },
    label: {
      color: 'white'
    }
  })(Button)

  // Efeito p/ limpar select quando atualizar o dia
  useEffect(() => {
    setData({ ...data, date: '' })
  }, [dates])

  useEffect(() => {
    const sellers = data.sellers.reduce(
      (acc, seller) => {
        acc.sales +=
          Math.round(
            Number(seller.sales.replace(/[^0-9/./,]/g, '').replace(',', '.'))
          ) || 0
        acc.items +=
          Math.round(
            Number(seller.items.replace(/[^0-9/./,]/g, '').replace(',', '.'))
          ) || 0
        acc.sold += Number(seller.sold)
        return acc
      },
      { sold: 0, items: 0, sales: 0 }
    )
    const store = {
      sales:
        Math.round(
          Number(data.store.sales.replace(/[^0-9/./,]/g, '').replace(',', '.'))
        ) || 0,
      items:
        Math.round(
          Number(data.store.items.replace(/[^0-9/./,]/g, '').replace(',', '.'))
        ) || 0,
      sold: Number(data.store.sold)
    }
    setSum({
      sold: sellers.sold + store.sold,
      sales: sellers.sales + store.sales,
      items: sellers.items + store.items
    })
  }, [data])

  const handleInput =
    ({ name, store = false, i }) =>
    (event, value) => {
      if (store) {
        data.store[name] = value || event.target.value
        setData({
          ...data,
          store: data.store
        })
      } else {
        const sellers = [...data.sellers]
        sellers[i][name] = value || event.target.value
        setData({
          ...data,
          sellers
        })
      }
    }

  const handleInputData = () => {
    dispatch(
      inputAddRequest(
        {
          date: data.date,
          storeId: data.storeId,
          sellers: data.sellers.map(seller => ({
            sellerId: seller.sellerId,
            sales:
              Math.round(
                Number(
                  seller.sales.replace(/[^0-9/./,]/g, '').replace(',', '.')
                )
              ) || 0,
            items:
              Math.round(
                Number(
                  seller.items.replace(/[^0-9/./,]/g, '').replace(',', '.')
                )
              ) || 0,
            sold: Number(seller.sold)
          })),
          store: {
            sales:
              Math.round(
                Number(
                  data.store.sales.replace(/[^0-9/./,]/g, '').replace(',', '.')
                )
              ) || 0,
            items:
              Math.round(
                Number(
                  data.store.items.replace(/[^0-9/./,]/g, '').replace(',', '.')
                )
              ) || 0,
            sold: Number(data.store.sold)
          }
        },
        token
      )
    )
    setData({
      date: '',
      storeId,
      sellers: [],
      store: {
        sales: '',
        sold: '',
        items: ''
      }
    })
  }

  const handleSubmit = () => {
    if (sum.sold === 0) {
      confirm({
        title: 'Atenção',
        description:
          'Foi identificado que o valor do lançamento é igual a zero. Deseja continuar?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar'
      })
        .then(() => {
          handleInputData()
        })
        .catch(() => {})
    } else {
      handleInputData()
    }
  }

  const handleDate = event => {
    const selectedDay = goalData.days.find(
      day => day.date === event.target.value
    )
    const sellers = []
    if (selectedDay.users) {
      //Tentar transofrmar em map ou reduce
      selectedDay.users.forEach(user => {
        const currentUser = goalData.usersWorking.find(
          userL => userL.userId?._id === user
        ).userId
        sellers.push({
          sellerId: currentUser._id,
          name: currentUser.name.complete,
          sales: '',
          items: '',
          sold: ''
        })
      })
    }

    setData({
      ...data,
      date: event.target.value,
      sellers,
      store: {
        sales: '',
        sold: '',
        items: ''
      }
    })
  }

  const handleClose = () => {
    dispatch(handleCloseModal())
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Badge
        badgeContent={dates.length}
        color='secondary'
        overlap='rectangular'
      >
        <StyledButton
          color='secondary'
          variant='contained'
          onClick={() => dispatch(handleOpenModal())}
          disabled={!dates.length > 0}
          data-cy='btn-add-input'
        >
          <AddIcon />
          Lançamento
        </StyledButton>
      </Badge>
      <Dialog
        fullScreen={!isDesktop}
        // TransitionComponent={Transition}
        open={modalState}
        fullWidth
        maxWidth='sm'
        onClose={handleClose}
        data-cy='dialogInputModal'
      >
        {isDesktop ? (
          <DialogTitle id='addInputModal' onClose={handleClose}>
            Realizar lançamento
          </DialogTitle>
        ) : (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>

              <Typography variant='h6' className={classes.title}>
                Realizar Lançamento
              </Typography>
              <Button autoFocus color='inherit' onClick={handleSubmit}>
                Adicionar
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent dividers>
          <FormControl variant='outlined' className={classes.margin} fullWidth>
            <InputLabel htmlFor='inputDate'>Data</InputLabel>
            <Select
              value={data.date}
              onChange={handleDate}
              labelWidth={50}
              inputProps={{
                name: 'date',
                id: 'inputDate',
                'data-cy': 'input-date-select'
              }}
            >
              {dates.map(date => (
                <MenuItem
                  data-cy='input-date'
                  value={date.toISOString()}
                  key={date.unix()}
                >
                  {date.locale('pt-BR').format('DD/MM')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data.sellers.length > 0 && (
            <>
              <TableContainer component={Paper} className={classes.margin}>
                <Table stickyHeader className={classes.table} size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Faturamento</TableCell>
                      <TableCell align='center'>Vendas</TableCell>
                      <TableCell align='center'>Peças</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1} selected>
                      <TableCell>
                        <Typography data-cy='sales'>
                          <b>{formatPrice(sum.sold)}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography data-cy='salesAmount'>
                          <b>{sum.sales.toFixed(0)}</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography data-cy='items'>
                          <b>{sum.items.toFixed(0)}</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {data.sellers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((seller, i) => (
                  <Accordion
                    expanded={expanded === i}
                    onChange={handleChange(i)}
                    key={i}
                    data-cy='sellerColumn'
                    data-clarity-unmask='true'
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        data-cy='sellerColumn'
                        className={classes.heading}
                      >
                        {seller.name}
                      </Typography>
                      {data.sellers[i].sold.length > 0 ||
                      data.sellers[i].sales.length > 0 ||
                      data.sellers[i].items.length > 0 ? (
                        <CheckCircleIcon style={{ color: '#2ecc71' }} />
                      ) : (
                        <ErrorIcon style={{ color: '#3498db' }} />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container direction='row'>
                        <Grid item xs={12}>
                          <CurrencyTextField
                            fullWidth
                            className={classes.margin}
                            onFocus={event => event.target.select()}
                            label='Total de vendas'
                            variant='outlined'
                            value={data.sellers[i].sold}
                            currencySymbol='R$'
                            decimalCharacter=','
                            digitGroupSeparator='.'
                            minimumValue='0'
                            inputProps={{
                              style: {
                                textAlign: 'left'
                              },
                              'data-cy': 'totalSalesInput'
                            }}
                            onChange={handleInput({ name: 'sold', i })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            className={classes.margin}
                            label='Número de vendas'
                            variant='outlined'
                            inputProps={{
                              min: 0,
                              'data-cy': 'salesAmountInput'
                            }}
                            onFocus={event => event.target.select()}
                            onChange={handleInput({ name: 'sales', i })}
                            value={data.sellers[i].sales}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            className={classes.margin}
                            label='Quantidade de peças'
                            variant='outlined'
                            inputProps={{
                              min: 0,
                              'data-cy': 'itemsQuantityInput'
                            }}
                            onFocus={event => event.target.select()}
                            onChange={handleInput({ name: 'items', i })}
                            value={data.sellers[i].items}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              <Accordion
                expanded={expanded === 'outros'}
                onChange={handleChange('outros')}
                data-cy='otherInput'
                data-clarity-unmask='true'
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Outros</Typography>
                  {data.store.sold > 0 ||
                  data.store.sales > 0 ||
                  data.store.items > 0 ? (
                    <CheckCircleIcon style={{ color: '#2ecc71' }} />
                  ) : (
                    <ErrorIcon style={{ color: '#3498db' }} />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <CurrencyTextField
                        fullWidth
                        className={classes.margin}
                        onFocus={event => event.target.select()}
                        label='Total de vendas'
                        variant='outlined'
                        inputProps={{
                          style: { textAlign: 'left' },
                          'data-cy': 'otherInputSold'
                        }}
                        value={data.store.sold}
                        currencySymbol='R$'
                        decimalCharacter=','
                        digitGroupSeparator='.'
                        minimumValue='0'
                        onChange={handleInput({ name: 'sold', store: true })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className={classes.margin}
                        label='Número de vendas'
                        variant='outlined'
                        inputProps={{ min: 0, 'data-cy': 'otherInputSales' }}
                        onFocus={event => event.target.select()}
                        onChange={handleInput({ name: 'sales', store: true })}
                        value={data.store.sales}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className={classes.margin}
                        label='Quantidade de peças'
                        variant='outlined'
                        inputProps={{ min: 0, 'data-cy': 'otherInputItems' }}
                        onFocus={event => event.target.select()}
                        onChange={handleInput({ name: 'items', store: true })}
                        value={data.store.items}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            autoFocus
            onClick={handleSubmit}
            color='secondary'
            data-cy='submitInput'
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddInput
