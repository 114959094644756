import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  display: flex;
  width: 55vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Roboto', sans-serif;

  .create-goal-button {
    background-color: ${palette.primary.main};
    margin: auto;
    margin-top: 50px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 10px;
    }
  }

  .distribute-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin: 20px 0px;
    padding: 15px;
    width: 70%;
    border-radius: 7px;
    background-color: #ffc7c7;
    //border: 1px solid #ff8f8f;

    p {
      margin-left: 10px;
      font-size: 12px;
      color: #700c0c;
    }
  }
`

export const SellersGoalTable = styled.div`
  margin: 20px 0px;
  max-width: 800px;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: 4px;
  scrollbar-color: #b5b5b5;

  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 50px;
  }

  .top-header {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 1fr;
    column-gap: 5px;

    h2 {
      font-size: 16px;
      text-align: center;
      margin: auto;
      color: #263238;
      font-weight: 600;
    }

    .top-header-goals {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;

      .goal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0px 0px;
        background-color: #f0f2f4;
        padding: 15px 10px;
        width: 140px;

        h1 {
          margin: 0px;
          color: #455a64;
          font-size: 16px;
        }

        span {
          color: #607d8b;
          font-size: 14px;
        }
      }
    }
  }

  .sellers-goals {
    display: flex;
    flex-direction: column;

    .seller-card {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 5px;

      &:last-child {
        .seller-card-goal {
          border-radius: 0px 0px 8px 8px;
        }
      }

      .seller-card-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 10px 0px 0px 10px;
        background-color: #f0f2f4;
        padding: 10px;
        margin-bottom: 5px;

        .seller-card-info-left {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          margin-right: 8px;
          text-align: right;

          h3 {
            margin: 0;
            margin-bottom: 5px;
            font-weight: 600;
            color: #424242;
            font-size: 14px;
          }

          span {
            color: #757575;
            font-size: 11px;
          }
        }

        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }

      .seller-card-goals {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;

        .seller-card-goal {
          width: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fafafa;
          border-bottom: 6px solid #f0f2f4;
          padding: 10px;

          h3 {
            color: #424242;
            font-size: 15px;
            font-weight: 600;
            margin: 0;
          }

          div {
            display: flex;
            align-items: center;
            margin-top: 5px;

            span {
              color: #757575;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`
