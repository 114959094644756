import { ICreateGroup, IGroup, IGroupFromReport } from './types'

export function createGroupRequest(
  userId: string,
  groupData: ICreateGroup,
  token: string | null
) {
  return {
    type: '@group/CREATE_REQUEST',
    payload: { userId, groupData, token }
  }
}

export function createRequestSuccess(groupData: IGroup) {
  return {
    type: '@group/CREATE_SUCCESS',
    payload: { groupData }
  }
}

export function listGroupRequest(token: string | null) {
  return {
    type: '@group/LIST_REQUEST',
    payload: { token }
  }
}

export function listGroupSuccess(groups: IGroup[]) {
  return {
    type: '@group/LIST_SUCCESS',
    payload: { groups }
  }
}

export function listGroupsFromReport(groups: IGroupFromReport[]) {
  return {
    type: '@group/LIST_GROUPS_FROM_REPORT',
    payload: { groups }
  }
}

export function updateGroupRequest(
  groupId: string,
  groupData: ICreateGroup,
  removeSupervisor: boolean,
  userId: string,
  token: string | null
) {
  return {
    type: '@group/UPDATE_REQUEST',
    payload: { groupId, groupData, removeSupervisor, userId, token }
  }
}

export function updateGroupSuccess(
  groupData: IGroupFromReport,
  groupId: string
) {
  return {
    type: '@group/UPDATE_SUCCESS',
    payload: { groupData, groupId }
  }
}

export function deleteGroupRequest(
  userId: string,
  groupId: string,
  removeSupervisor: boolean,
  token: string | null
) {
  return {
    type: '@group/DELETE_REQUEST',
    payload: { userId, groupId, removeSupervisor, token }
  }
}

export function deleteGroupSuccess(groupId: string) {
  return {
    type: '@group/DELETE_SUCCESS',
    payload: { groupId }
  }
}
