import { useEffect, useState } from 'react'

import jwt_decode from 'jwt-decode'
import { addDays, format } from 'date-fns'
import { useParams } from 'react-router'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { browserHistory } from 'App'
import { Form } from './components/Form'
import { Header } from './components/Header'
import { MediaSettings } from './components/MediaSettings'
import { PreviewMedia } from './components/PreviewMedia'
import { PreviewMediaList } from './components/PreviewMediaList'
import {
  getActiveStorylinesRequest,
  getDraftStorylinesRequest,
  getFinishedStorylinesRequest,
  getStoriesFromStorylineRequest
} from 'store/modules/storylines/actions'
import { IUserProps } from 'store/modules/user/types'
import { userRequest } from 'store/modules/user/actions'
import {
  IStoryLineProps,
  IStorylineTable
} from 'store/modules/storylines/types'

import { Container, Content } from './styles'

export interface SelectedMediaProps {
  url: string
  link: string
  type: 'image' | 'video'
  index: number
}

interface SelectorStorylines {
  state: DefaultRootState
  storylines: IStoryLineProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

// const pra gente conseguir lidar com os fusos tanto no início quanto durante
// a manipulação dos dados pelo picker
const initialDateState = new Date(
  new Date(format(new Date(), 'yyyy-MM-dd')).getTime() +
    Math.abs(
      new Date(
        format(new Date().setDate(1), 'yyyy-MM-dd')
      ).getTimezoneOffset() * 60000
    )
).toString()

const initialStoryState: IStorylineTable = {
  _id: '',
  createdBy: '',
  name: '',
  period: {
    start: initialDateState,
    end: addDays(new Date(initialDateState), 1).toString()
  },
  totalStores: 0,
  tags: [],
  type: 'fixed',
  visibilitySettings: {
    roles: [],
    storeIds: []
  },
  viewingHistory: {
    totalUsers: 0,
    totalViews: 0,
    userViewPercentage: 0,
    userViews: 0,
    stories: [],
  }
}

export const EditStoryline = () => {
  const params = useParams<{ storylineId: string }>()

  const { activeStorylines, hasRequested, draftStorylines, settings } =
    useSelector<SelectorStorylines, IStoryLineProps>(state => state.storylines)
  const { user } = useSelector<SelectorUser, IUserProps>(state => state.user)

  const [isEditingMedia, setIsEditingMedia] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState<SelectedMediaProps>({
    url: '',
    link: '',
    type: 'image',
    index: -1
  })
  const [storyData, setStoryData] = useState<IStorylineTable>(initialStoryState)

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    const isNotAStorylineId =
      !params ||
      params.storylineId.length !== 24 ||
      isNaN(Number('0x' + params.storylineId))

    if (isNotAStorylineId) {
      browserHistory.push('/storylines')
    }
  }, [params])

  useEffect(() => {
    if (token) {
      if (!user?._id) {
        const decoded = jwt_decode<{ id: string }>(token)
        dispatch(userRequest(decoded.id, token))
      }
    } else {
      browserHistory.push('/login')
    }
  }, [token])

  useEffect(() => {
    const userHasNeoStory = user._id && !user.company.hasNeoStory

    if (userHasNeoStory) {
      const isCommunicator = user.stores.every(
        store => store.type === 'communicator'
      )
      if (isCommunicator) {
        browserHistory.push('/storylines')
      } else {
        const userStores = user.stores.filter(s =>
          ['owner', 'manager', 'cashier', 'director'].includes(s.type)
        )

        const userHasMoreThanOneStore = userStores.length > 1
        if (userHasMoreThanOneStore) {
          browserHistory.push('/stores')
        } else {
          browserHistory.push(`/${user.stores[0].storeId._id}/dashboard`)
        }
      }
    }
  }, [user])

  useEffect(() => {
    if (!hasRequested && user?._id) {
      dispatch(getActiveStorylinesRequest(user._id, token))
      dispatch(getFinishedStorylinesRequest(user._id, token))
      dispatch(getDraftStorylinesRequest(user._id, token))
    }
  }, [hasRequested, user])

  useEffect(() => {
    if (params.storylineId && user?._id) {
      const currentActiveStoryline = activeStorylines.find(
        storyline => storyline._id === params.storylineId
      )
      if (currentActiveStoryline && !currentActiveStoryline.stories) {
        dispatch(getStoriesFromStorylineRequest(params.storylineId, token))
      }

      const currentDraftStoryline = draftStorylines.find(
        storyline => storyline._id === params.storylineId
      )
      if (currentDraftStoryline && !currentDraftStoryline.stories) {
        dispatch(getStoriesFromStorylineRequest(params.storylineId, token))
      }
    }
  }, [params, user?._id, activeStorylines, draftStorylines])

  useEffect(() => {
    const currentActiveStoryline = activeStorylines.find(
      storyline => storyline._id === params.storylineId
    )

    const hasActiveStorylines =
      currentActiveStoryline &&
      currentActiveStoryline.stories &&
      currentActiveStoryline.stories.length > 0

    if (hasActiveStorylines) {
      setStoryData(currentActiveStoryline)
    }

    const currentDraftStoryline = draftStorylines.find(
      storyline => storyline._id === params.storylineId
    )

    const hasDraftStorylines =
      currentDraftStoryline &&
      currentDraftStoryline.stories &&
      currentDraftStoryline.stories.length > 0

    if (hasDraftStorylines) {
      setStoryData(currentDraftStoryline)
    }
  }, [activeStorylines, draftStorylines])

  const urls = storyData.stories
    ? storyData.stories.map(story => story.url)
    : []

  const handleEditMedia = (srcImg: string) => {
    setIsEditingMedia(true)
    const selectedStory = storyData.stories?.find(story => story.url === srcImg)
    const storiesUrl = storyData.stories?.map(story => story.url) ?? []

    if (selectedStory) {
      setSelectedMedia({
        url: srcImg,
        link: selectedStory.link ?? '',
        type: selectedStory.type,
        index: storiesUrl.indexOf(srcImg)
      })
    }
  }

  const handleCloseMediaSettings = () => {
    setSelectedMedia({
      url: '',
      link: '',
      type: 'image',
      index: -1
    })
    setIsEditingMedia(false)
  }

  const hasSomeMedia = isEditingMedia

  const showStorylinePreviewList =
    !hasSomeMedia && storyData.stories && storyData.stories.length > 0

  return (
    <Container>
      <Header />
      <Content hasSomeMedia={isEditingMedia}>
        {showStorylinePreviewList ? (
          <PreviewMediaList
            selectedMedia={selectedMedia}
            storyData={storyData}
            handleEditMedia={handleEditMedia}
          />
        ) : (
          <PreviewMedia
            previewMedias={urls}
            currentMediaUrlPreview={selectedMedia.url}
            fileType={selectedMedia.type}
            hasLink={!!selectedMedia.link}
          />
        )}

        {isEditingMedia && (
          <MediaSettings
            fileName={`Página ${selectedMedia.index + 1}`}
            fileType={selectedMedia.type}
            link={selectedMedia.link}
            currentMediaUrlPreview={selectedMedia.url}
            previewMedias={urls}
            handleCloseMediaSettings={handleCloseMediaSettings}
          />
        )}
        <Form
          settings={settings}
          fileType={selectedMedia.type}
          storiesLength={storyData.stories?.length ?? 0}
          previewUrls={urls}
          handleEditMedia={handleEditMedia}
          selectedMedia={selectedMedia}
          storyData={storyData}
        />
      </Content>
    </Container>
  )
}
