import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import { toast } from 'react-toastify'
import { Avatar } from '@material-ui/core'
import { FaCamera } from 'react-icons/fa'

import { FooterButton } from '../FooterButton'
import { TextInput } from '../TextInput'

import { ITag } from 'store/modules/storylines/types'
import {
  updateStorylineTagAvatarRequest,
  updateStorylineTagRequest
} from 'store/modules/storylines/actions'
import { BiFileFind, BiTrash } from 'react-icons/bi'

import {
  Button,
  Divider,
  IconButton,
  IconTagContainer,
  ImagePreviewContainer,
  MediaInfoCard,
  TagFooter,
  Text
} from '../../styles'

interface EditTagModalProps {
  isOpen: boolean
  companyId: string | undefined
  onCancel: () => void
  tag: ITag
}

export const EditTagModal = ({
  isOpen,
  companyId,
  onCancel,
  tag
}: EditTagModalProps) => {
  const [editedTag, setEditedTag] = useState({} as ITag)
  const [file, setFile] = useState<File | null>(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [previewUrlImage, setPreviewUrlImage] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!tag) {
      setEditedTag(tag)
    }
  }, [tag])

  const token = window.localStorage.getItem('@NeoPro:token')

  const handleCloseModal = () => {
    if (hasSubmitted) {
      setHasSubmitted(false)
    }
    handleRemoveMedia()
    onCancel()
  }

  const handleEditTag = () => {
    if (editedTag.name.length === 0) {
      setHasSubmitted(true)
      return
    }

    if (hasSubmitted) {
      setHasSubmitted(false)
    }

    if (!companyId) {
      return toast.error('Algo deu errado')
    }

    const formData = new FormData()

    if (!!file) {
      formData.append('photo', file)
    }

    if (editedTag.name !== tag.name) {
      dispatch(
        updateStorylineTagRequest(
          editedTag._id,
          editedTag.name,
          formData,
          companyId,
          token
        )
      )
    } else if (formData.has('photo')) {
      dispatch(
        updateStorylineTagAvatarRequest(
          editedTag._id,
          formData,
          companyId,
          token
        )
      )
    }

    handleCloseModal()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files && files.length > 0) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      const fileType = files[0].type

      if (
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg'
      )
        if (fileSize <= 2) {
          const previewURL = URL.createObjectURL(files[0])

          setFile(files[0])
          setPreviewUrlImage(previewURL)
        } else {
          toast.error(
            'Imagem muito grande! A imagem deve ser menor ou igual a 2MB.'
          )
        }
    }
  }

  const convertFileSize = () => {
    const fileSize = file?.size ?? 0

    if (fileSize >= 1048576) {
      return `${(fileSize / 1024 / 1024).toFixed(2)} Mb`
    } else if (fileSize >= 1024) {
      return `${(fileSize / 1024).toFixed(2)} Kb`
    } else if (fileSize > 1) {
      return `${fileSize.toFixed(2)} bytes`
    }
    return '0 bytes'
  }

  const fileExtension =
    file?.name?.split('.')[file?.name?.split('.').length - 1] ?? ''
  const fileName = file?.name?.substring(0, 14) ?? ''

  const handleRemoveMedia = () => {
    setFile(null)
    setPreviewUrlImage('')
  }

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle fontSize={'0.875rem'} fontWeight={700}>
        Editar Tag
      </DialogTitle>
      <DialogContent
        style={{
          margin: '1.5rem 0',
          minWidth: 476,
          padding: '8px 3rem 0.75rem'
        }}
      >
        <TextInput
          autoFocus
          variant='outlined'
          value={editedTag.name ?? ''}
          error={hasSubmitted}
          id='tag'
          name='tag'
          label='Nome da tag'
          fullWidth
          onChange={event => {
            const newName = event.target.value
            setEditedTag(prev => ({
              ...prev,
              name: newName
            }))
          }}
        />
        <Divider />

        <IconTagContainer>
          <Text>Ícone da tag</Text>

          <TagFooter>
            <label htmlFor='icon-tag-image'>
              {previewUrlImage || tag.photo?.url ? (
                <ImagePreviewContainer>
                  <img
                    src={
                      previewUrlImage
                        ? previewUrlImage
                        : tag.photo.url || undefined
                    }
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                  />

                  {!!file && (
                    <MediaInfoCard>
                      <div>
                        {fileName +
                          ((file?.name?.length ?? 0) > 19
                            ? `..${fileExtension}`
                            : '')}
                        <span>{convertFileSize()}</span>
                      </div>
                      <div>
                        <IconButton htmlFor='icon-tag-image'>
                          <BiFileFind size={24} />
                        </IconButton>
                        <IconButton onClick={handleRemoveMedia}>
                          <BiTrash size={24} />
                        </IconButton>
                      </div>
                    </MediaInfoCard>
                  )}
                </ImagePreviewContainer>
              ) : (
                <Avatar style={{ cursor: 'pointer' }}>
                  <FaCamera style={{ width: 18, height: 18 }} />
                </Avatar>
              )}

              <input
                style={{ display: 'none' }}
                type='file'
                id='icon-tag-image'
                accept='image/png, image/jpg, image/jpeg'
                onChange={handleChange}
              />
            </label>

            {!previewUrlImage && (
              <Button htmlFor='icon-tag-image'>Enviar</Button>
            )}
          </TagFooter>
        </IconTagContainer>
      </DialogContent>
      <DialogActions>
        <FooterButton buttonType='cancel' onClick={handleCloseModal}>
          CANCELAR
        </FooterButton>
        <FooterButton buttonType='submit' onClick={handleEditTag}>
          SALVAR
        </FooterButton>
      </DialogActions>
    </Dialog>
  )
}
