import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Libs
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

// Redux
import { useDispatch } from 'react-redux'
import {
  integrationRequest,
  integrationDisconnectRequest
} from '../../../../../../store/modules/integration/actions'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const IdBrasil = ({ className, storeIntegrationData, loading, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'idbrasil'

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  // LocalStorage
  const token = localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  const onSubmit = data => {
    confirm({
      title: 'Atenção',
      description:
        'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na Sofstore?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => dispatch(integrationRequest(data, provider, storeId, token)))
      .catch(() => {})
  }

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com Sofstore?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() =>
        dispatch(integrationDisconnectRequest(provider, storeId, token))
      )
      .catch(() => {})
  }

  useEffect(() => {
    if (
      storeIntegrationData?.username &&
      storeIntegrationData?.provider === provider
    )
      reset({
        username: storeIntegrationData.username,
        password: storeIntegrationData.password
      })
  }, [storeIntegrationData])

  const renderError = error =>
    errors[error] && (
      <FormHelperText id='component-error-text'>
        Este campo é obrigatório
      </FormHelperText>
    )

  return (
    <>
      <Card {...rest}>
        <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='Sofstore' />
          <Divider />
          <CardContent>
            <Typography>
              Antes de iniciar a integração, certifique-se de que todos os
              vendedores estão cadastrados, assim como seus CPFs estão definidos
              e idênticos à Sofstore. Caso haja qualquer diferença nos valores,
              o vendedor não será identificado e a venda entrará em "Outras
              vendas", prejudicando a validade do relatório.
              <br />
              Se acontecer de um vendedor não ter sido identificado e sua venda
              entrar em "Outras vendas", corrija os dados do vendedor e aguarde
              a próxima sincronização.
              <br />
              Para realizar a integração, antes é necessário entrar em contato
              com o suporte da Sofstore, solicitando o acesso à API para
              conectar à NeoPro. Eles irão fornecer um usuário e uma senha, que
              deverá ser informada abaixo.
              <br />A sincronização acontece todo dia à 01:00 AM
            </Typography>
            <Divider style={{ marginTop: '1rem' }} />
            <Typography variant='h4' style={{ marginTop: '1rem' }}>
              Dados
            </Typography>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Login'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='username'
                  name='username'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.username}
                />
                {renderError('username')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Senha'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='password'
                  name='password'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.password}
                  inputProps={{
                    'data-clarity-mask': 'True'
                  }}
                />
                {renderError('password')}
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '1rem' }} />
          </CardContent>
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              disabled={loading}
              className={classes.button}
              type='submit'
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#fff', marginRight: '8px' }}
                  />{' '}
                  {' Conectando ao IDBrasil...'}
                </>
              ) : (
                'Salvar'
              )}
            </Button>
            {storeIntegrationData?.provider === provider && (
              <Button
                style={{ color: '#e74c3c' }}
                className={classes.button}
                disabled={loading}
                onClick={() => handleDisconnectIntegration()}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={24}
                      style={{ color: '#e74c3c', marginRight: '8px' }}
                    />{' '}
                    {' Desconectando...'}
                  </>
                ) : (
                  'Desconectar'
                )}
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
    </>
  )
}

IdBrasil.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default IdBrasil
