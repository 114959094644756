import { ButtonHTMLAttributes } from 'react'
import { CancelButton, SubmitButton } from './styles'

interface FooterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'submit' | 'cancel'
}

export const FooterButton = ({ buttonType, ...props }: FooterButtonProps) => {
  return buttonType === 'submit' ? (
    <SubmitButton {...props} />
  ) : (
    <CancelButton {...props} />
  )
}
