import { useEffect, useState, FocusEvent, ChangeEvent } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { Close, Info } from '@material-ui/icons'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PersonIcon from '@material-ui/icons/Person'

import { formatPrice } from '../../../../../../../../../../../utils/format'
import CurrencyTextField from '../../../../../../../../../../../components/CurrencyTextField'

import { DefaultRootState, useSelector } from 'react-redux'
import { getDaysInMonth } from 'date-fns/esm'
import palette from 'theme/palette'
import {
  IGoal,
  IGoalProps,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'

import { Container } from './styles'

interface GoalModalProps {
  goal: IGoal
  usersWorking: IUserWorking[]
  isCreatingNewGoal: boolean
  isEditingGoal: boolean
  currentGoal: IMonthGoal | undefined
  monthGoals: IMonthGoal[]
  handleSetIsCreatingGoal: (value: boolean) => void
  handleSetIsEditingGoal: (value: boolean) => void
  createNewGoal: (goal: Omit<IMonthGoal, 'initialCommission' | '_id'>) => void
  editGoal: (goal: Omit<IMonthGoal, 'initialCommission' | '_id'>) => void
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}
interface SellerGoalProps {
  name: string
  value: number
  bonusPercentage: boolean | 'no-bonus'
  commission: number | string
  bonus: number | string
  type: 'store' | 'user'
  typeBonus: 'store' | 'user'
}

const GoalModal = ({
  goal,
  usersWorking,
  isCreatingNewGoal,
  isEditingGoal,
  currentGoal,
  monthGoals,
  handleSetIsCreatingGoal,
  handleSetIsEditingGoal,
  createNewGoal,
  editGoal
}: GoalModalProps) => {
  const [sellerGoal, setSellerGoal] = useState<SellerGoalProps>({
    // Estado para controlar input de dados
    name: '1a meta',
    value: 0,
    bonusPercentage: false,
    commission: 1,
    bonus: 0,
    type: goal.config?.commission?.storeSold ? 'store' : 'user',
    typeBonus: 'user'
  })

  const [totalCommission, setTotalCommission] = useState(0)
  const [isBonusEmpty, setIsBonusEmpty] = useState(true)

  // Lista das metas para realizar o calculo da porcentagem
  const { goalList } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )

  const formattedBonusValue = Number(String(sellerGoal.bonus).replace(',', '.'))
  const formattedCommissionValue = Number(
    String(sellerGoal.commission).replace(',', '.')
  )
  useEffect(() => {
    if (usersWorking?.length > 0 && monthGoals?.length === 0) {
      setSellerGoal({
        ...sellerGoal,
        name: '1a meta',
        value: goal.mainGoals.total / usersWorking.length
      })
    }
  }, [monthGoals, usersWorking])

  useEffect(() => {
    // Sempre que algo for repreenchido, ele recalcula para mostrar em tempo real
    const salary = goal.salary.directCommission ? 0 : goal.salary.base
    const commission = sellerGoal.value * (formattedCommissionValue / 100)
    const bonus =
      sellerGoal.bonusPercentage === true
        ? Number(((formattedBonusValue / 100) * sellerGoal.value).toFixed(2))
        : formattedBonusValue

    const dsr =
      goal.dsr > 0
        ? (commission / (getDaysInMonth(new Date(goal.month)) - goal.dsr)) *
          goal.dsr
        : 0

    const complementValue = goal.salary.base - commission - dsr
    const complement = complementValue >= 0 ? complementValue : 0

    setTotalCommission(
      salary +
        bonus +
        (formattedCommissionValue / 100) * sellerGoal.value +
        dsr +
        (goal.salary.complement && goal.salary.directCommission
          ? complement
          : 0)
    )
  }, [sellerGoal, goal])

  useEffect(() => {
    // Useeffect que ve se o cara ta editando a meta e modifica os valores % para virarem inteiros novamente
    if (currentGoal && isEditingGoal) {
      setSellerGoal({
        ...currentGoal,
        bonus: Number(
          (currentGoal.bonus * (currentGoal.bonusPercentage ? 100 : 1)).toFixed(
            2
          )
        ),
        commission: currentGoal.commission * 100,
        bonusPercentage:
          currentGoal.bonus === 0 ? 'no-bonus' : currentGoal.bonusPercentage
      })
      if (currentGoal.bonus > 0) setIsBonusEmpty(false)
    }
  }, [currentGoal, isEditingGoal])

  useEffect(() => {
    // Useeffect que monitora a criacao de metas p fazer as proximas virem preenchidas com os dados da anterior + alguns %

    if (monthGoals.length >= 1) {
      const lastActiveGoal = goalList.find(
        goal => goal.config.active && goal.monthGoals.length > 1
      )

      const percentage = lastActiveGoal
        ? lastActiveGoal?.monthGoals[lastActiveGoal.monthGoals.length - 1]
            .value /
          lastActiveGoal?.monthGoals[lastActiveGoal.monthGoals.length - 2].value
        : undefined

      const lastGoal = monthGoals[monthGoals.length - 1]
      setSellerGoal({
        name: `${monthGoals.length + 1}a meta`,
        value: (percentage || 1.1) * lastGoal.value,
        commission: lastGoal.commission * 100,
        bonus: Number(
          (lastGoal.bonus * (lastGoal.bonusPercentage ? 100 : 1)).toFixed(2)
        ),
        bonusPercentage:
          lastGoal.bonus === 0 ? 'no-bonus' : lastGoal.bonusPercentage,
        type: lastGoal.type,
        typeBonus: lastGoal.typeBonus
      })
    }
  }, [monthGoals, isCreatingNewGoal, goalList])

  return (
    <>
      <Modal
        onClose={() => {
          handleSetIsCreatingGoal(false)
          handleSetIsEditingGoal(false)
        }}
        open={isCreatingNewGoal || isEditingGoal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Container>
          <div className='goal-modal-content' data-clarity-unmask='true'>
            <div
              className='close'
              onClick={() => {
                handleSetIsCreatingGoal(false)
                handleSetIsEditingGoal(false)
              }}
            >
              <Close />
            </div>
            <div className='goal-modal-header'>
              <div className='goal-modal-title'>
                {isCreatingNewGoal ? (
                  <h1> Nova meta para o vendedor </h1>
                ) : (
                  <h1> Editando meta para o vendedor </h1>
                )}
                <span>
                  Indique o valor da meta, valor e referência da comissão e
                  bonificação.
                </span>
              </div>
            </div>

            <div className='goal-modal-form'>
              <div className='goal-modal-form-row'>
                <div>
                  Para a meta
                  <input
                    value={sellerGoal.name ?? ''}
                    placeholder='1a meta'
                    data-cy='goalNameInputModal'
                    style={{ width: 150 }}
                    onChange={e =>
                      setSellerGoal({ ...sellerGoal, name: e.target.value })
                    }
                  />
                  , quando o vendedor vender
                  <CurrencyTextField
                    error={sellerGoal.value < 0}
                    fullWidth
                    onFocus={(event: FocusEvent<HTMLInputElement>) =>
                      event.target.select()
                    }
                    value={sellerGoal.value ?? ''}
                    currencySymbol='R$'
                    decimalCharacter=','
                    placeholder='20000'
                    pattern='[0-9]+'
                    digitGroupSeparator='.'
                    inputProps={{
                      style: { textAlign: 'left' },
                      'data-cy': 'sellerGoalInputModal'
                    }}
                    onChange={(
                      _: ChangeEvent<HTMLInputElement>,
                      value: number
                    ) => {
                      setSellerGoal({ ...sellerGoal, value: value })
                    }}
                  />
                </div>

                <div className='goal-modal-form-row'>
                  receberá
                  <span>
                    <input
                      type='text'
                      pattern='[0-9]+'
                      maxLength={5}
                      value={sellerGoal.commission ?? ''}
                      style={{ width: 80, marginRight: 0 }}
                      onChange={e => {
                        const formattedInputValue = Number(
                          e.target.value.replace(',', '.')
                        )

                        const newCommissionValue =
                          Number(e.target.value) < 0 ||
                          isNaN(formattedInputValue)
                            ? sellerGoal.commission
                            : e.target.value

                        setSellerGoal({
                          ...sellerGoal,
                          commission:
                            Number(newCommissionValue) > 100
                              ? 100
                              : newCommissionValue
                        })
                      }}
                      placeholder='0.6'
                      data-cy='sellerCommissionPercentageInputModal'
                    />
                    <span
                      style={{
                        marginRight: 10,
                        paddingBottom: 7,
                        borderBottom: '1px solid #C4C4C4',
                        color: palette.primary.main
                      }}
                    >
                      %
                    </span>
                  </span>
                  de <strong>comissão</strong> de quanto
                  <Select
                    inputProps={{
                      'data-cy': 'selectEntityInputModal'
                    }}
                    value={sellerGoal.type ?? ''}
                    onChange={e => {
                      const value = e.target.value as 'user' | 'store'
                      setSellerGoal({ ...sellerGoal, type: value })
                    }}
                  >
                    <MenuItem value='store'>
                      <Option store />
                    </MenuItem>
                    <MenuItem value='user'>
                      <Option />
                    </MenuItem>
                  </Select>
                  vender
                </div>

                <div className='goal-modal-form-row'>
                  e{' '}
                  {typeof sellerGoal.bonusPercentage !== 'string'
                    ? 'terá '
                    : ''}
                  {!sellerGoal.bonusPercentage ? (
                    <span>
                      de <strong>bônus</strong>
                    </span>
                  ) : (
                    ''
                  )}
                  {!isBonusEmpty &&
                    (sellerGoal.bonusPercentage === true ? (
                      <span className='bonus-input-percent'>
                        <input
                          data-cy='fixedBonusPercentValueInputModal'
                          type='text'
                          pattern='[0-9]+'
                          maxLength={5}
                          placeholder='0.6'
                          value={sellerGoal.bonus ?? ''}
                          style={{ width: 80, marginRight: 0 }}
                          onChange={e => {
                            const formattedInputValue = Number(
                              e.target.value.replace(',', '.')
                            )
                            const newBonusValue =
                              Number(e.target.value) < 0 ||
                              isNaN(formattedInputValue)
                                ? sellerGoal.bonus
                                : e.target.value

                            return setSellerGoal({
                              ...sellerGoal,
                              bonus:
                                Number(newBonusValue) > 100
                                  ? 100
                                  : newBonusValue
                            })
                          }}
                        />
                        <span
                          style={{
                            paddingBottom: 7,
                            paddingRight: 5,
                            borderBottom: '1px solid #C4C4C4',
                            marginRight: -5,
                            color: palette.primary.main
                          }}
                        >
                          %
                        </span>
                      </span>
                    ) : (
                      sellerGoal.bonusPercentage === false && (
                        <CurrencyTextField
                          error={Number(sellerGoal.bonus) < 0}
                          fullWidth
                          value={sellerGoal.bonus ?? ''}
                          onFocus={(event: FocusEvent<HTMLInputElement>) =>
                            event.target.select()
                          }
                          currencySymbol='R$'
                          decimalCharacter=','
                          digitGroupSeparator='.'
                          placeholder='2000'
                          inputProps={{
                            style: { textAlign: 'left' },
                            'data-cy': 'fixedBonusCashValueInputModal'
                          }}
                          onChange={(
                            _: ChangeEvent<HTMLInputElement>,
                            value: number
                          ) => setSellerGoal({ ...sellerGoal, bonus: value })}
                        />
                      )
                    ))}
                  <Select
                    labelId='goal-bonus-label'
                    id='goal-bonus'
                    label='de bonificação'
                    className='bonus-select'
                    inputProps={{
                      'data-cy': 'selectBonusInputModal'
                    }}
                    value={String(sellerGoal.bonusPercentage) ?? ''}
                    style={{
                      width:
                        typeof sellerGoal.bonusPercentage === 'boolean'
                          ? 20
                          : 150,
                      marginLeft: 3,
                      ...(sellerGoal.bonusPercentage === true && { top: 12 })
                    }}
                    onChange={event => {
                      const value = event?.target.value as
                        | 'no-bonus'
                        | 'true'
                        | 'false'

                      setSellerGoal({
                        ...sellerGoal,
                        bonusPercentage:
                          value === 'no-bonus' ? 'no-bonus' : value === 'true',
                        bonus: 0
                      })

                      setIsBonusEmpty(false)
                    }}
                  >
                    <MenuItem id='bonus-zero' value='no-bonus'>
                      <h5 style={{ fontSize: 14 }}>Sem Bonificação</h5>
                    </MenuItem>
                    <MenuItem id='bonus-percent' value='true'>
                      <h4>% - Porcentagem</h4>
                    </MenuItem>
                    <MenuItem id='bonus-value' value='false'>
                      <h4>R$ - Valor Fechado</h4>
                    </MenuItem>
                  </Select>
                  {sellerGoal.bonusPercentage === true && ( // ta === true pq se for sem bonificacao ele recebe uma string e passa caso tenha algo
                    <>
                      de <strong>bônus</strong> de quanto
                      <Select
                        value={sellerGoal.typeBonus ?? ''}
                        onChange={event => {
                          const value = event.target.value as 'store' | 'user'
                          setSellerGoal({
                            ...sellerGoal,
                            typeBonus: value
                          })
                        }}
                      >
                        <MenuItem id='bonus-store' value='store'>
                          <Option store />
                        </MenuItem>
                        <MenuItem id='bonus-user' value='user'>
                          <Option />
                        </MenuItem>
                      </Select>
                      vender
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='goal-modal-footer'>
              <div className='sellers-count-card'>
                <Info />
                <div>
                  <span>
                    Essa é uma previsão estimada! (aconselhamos você consultar o
                    valor de comissão individual na etapa de revisão)
                  </span>
                </div>
              </div>
              <div className='goal-infos'>
                <div>
                  <span className='total-commission'>
                    {formatPrice(totalCommission)}
                  </span>
                  <span>{`${
                    !goal.salary.directCommission ? 'Piso salarial + ' : ''
                  } Comissão + Bonificação ${goal.dsr > 0 && ' + DSR'}`}</span>
                </div>
                <button
                  data-cy='sellerGoalModalButton'
                  className='stepper-button-next'
                  disabled={
                    !sellerGoal.value ||
                    (isCreatingNewGoal &&
                      typeof sellerGoal.bonusPercentage === 'boolean' &&
                      Number(sellerGoal.bonus) < 0) ||
                    !sellerGoal.commission ||
                    !sellerGoal.name ||
                    !sellerGoal.type ||
                    (sellerGoal.bonusPercentage === true &&
                      Number(sellerGoal.bonus) < 0 &&
                      !sellerGoal.typeBonus)
                  }
                  onClick={() => {
                    if (isCreatingNewGoal) {
                      createNewGoal({
                        ...sellerGoal,
                        value: Number(sellerGoal.value.toFixed(2)),
                        commission: formattedCommissionValue / 100,
                        bonusPercentage:
                          typeof sellerGoal.bonusPercentage === 'boolean' &&
                          !sellerGoal.bonus
                            ? 'no-bonus'
                            : sellerGoal.bonusPercentage,
                        bonus: sellerGoal.bonusPercentage
                          ? formattedBonusValue / 100
                          : formattedBonusValue,
                        typeBonus: sellerGoal.typeBonus
                          ? sellerGoal.typeBonus
                          : 'user'
                      })
                    } else {
                      if (isEditingGoal) {
                        editGoal({
                          name: sellerGoal.name
                            ? sellerGoal.name
                            : 'Meta sem nome',
                          value: sellerGoal.value ? sellerGoal.value : 0,
                          commission: sellerGoal.commission
                            ? formattedCommissionValue / 100
                            : 0,
                          type: sellerGoal.type
                            ? sellerGoal.type
                            : goal.config.commission.storeSold
                            ? 'store'
                            : 'user',
                          bonusPercentage:
                            typeof sellerGoal.bonusPercentage === 'boolean' &&
                            !sellerGoal.bonus
                              ? 'no-bonus'
                              : sellerGoal.bonusPercentage,
                          bonus: sellerGoal.bonusPercentage
                            ? formattedBonusValue / 100
                            : formattedBonusValue
                            ? formattedBonusValue
                            : 0,
                          typeBonus: sellerGoal.typeBonus
                            ? sellerGoal.typeBonus
                            : 'user'
                        })
                      }
                    }
                    handleSetIsCreatingGoal(false)
                    handleSetIsEditingGoal(false)
                  }}
                >
                  {isCreatingNewGoal ? 'Adicionar' : 'Editar'}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </>
  )
}

export const Option = ({ store = false }) => {
  return (
    <>
      {store ? <StorefrontIcon /> : <PersonIcon fontSize='small' />}
      <span style={{ marginLeft: 5, fontSize: 16 }}>
        {store ? 'a loja' : 'o vendedor'}
      </span>
    </>
  )
}

export default GoalModal
