import { useEffect, useState } from 'react'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

// Libs
import moment from 'moment'

// Redux
import { inputListRequest } from 'store/modules/input/actions'
import { monthRequest } from '../../store/modules/report/actions'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'

// Components
import ValueCard from 'components/ValueCard'
import Performance from './components/Performance'
import { Notifications } from '../../components/Notifications'
import InputsTable from '../../components/InputsTable'

// Utils
import { formatPrice, formatToPercentage } from '../../utils/format'
import { useMemo } from 'react'
import Ranking from './components/Ranking'
import { IInputProps } from 'store/modules/input/types'
import { IReportProps } from 'store/modules/report/types'
import { IGoalProps } from 'store/modules/goal/types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(1)
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
}))

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface NewDataProps {
  labels: string[]
  goals: string[]
  sold: string[]
  future: string[]
}

interface DashboardProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
    }
    path: string
    url: string
  }
}

const Dashboard = ({ match }: DashboardProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { inputList, metadata } = useSelector<SelectorInput, IInputProps>(
    state => state.input
  )
  const { monthReport } = useSelector<SelectorReport, IReportProps>(
    state => state.report
  )
  const { currentGoal } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )

  const token = window.localStorage.getItem('@NeoPro:token')
  const { storeId } = match.params

  const [performanceData, setPerformanceData] = useState<NewDataProps>(
    {} as NewDataProps
  )

  useEffect(() => {
    if (currentGoal) {
      if (monthReport && inputList) {
        let newData: NewDataProps = {} as NewDataProps
        const workingDays = currentGoal.days.filter(day => day.working)
        const workingDaysDates = workingDays.map(day => moment(day.date).unix())

        newData.labels = workingDays.map(day =>
          moment(day.date).format('DD - ddd')
        )
        newData.goals = workingDays.map(day => day.goal.toFixed(2))

        newData.sold = Array(workingDays.length).fill('0')
        const thisMonthInputs = inputList
          .filter(input => moment(input.date) >= moment(workingDays[0].date))
          .reverse()

        if (thisMonthInputs.length > 0) {
          thisMonthInputs.forEach(input => {
            const i = workingDaysDates.findIndex(
              day => day === moment(input.date).unix()
            )
            newData.sold[i] = input.total.sold.toFixed(2)
          })

          newData.future = Array(workingDays.length).fill('0')
          const todayIndex = workingDaysDates.findIndex(
            day => day === moment(monthReport.store.metric.today).unix()
          )
          newData.future[todayIndex] = monthReport.store.sell.toFixed(2)

          monthReport.store.next.forEach(future => {
            const i = workingDaysDates.findIndex(
              day => day === moment(future.date).unix()
            )
            newData.future[i] = Number(future.sell).toFixed()
          })
        }

        setPerformanceData(newData)
      }

      if (!monthReport && token) {
        dispatch(monthRequest(storeId, token))
      }
    }
  }, [currentGoal, monthReport, inputList])

  useEffect(() => {
    if (metadata.pagination.currentPage > 1 && token)
      dispatch(inputListRequest(storeId, token, 1, 35))
  }, [metadata])

  const currentDate = useMemo(() => {
    return new Date()
      .toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo'
      })
      .split('/')
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.headerBox}>
        <Typography variant='h3' className={classes.title}>
          {(name => name.charAt(0).toUpperCase() + name.slice(1))(
            currentGoal
              ? moment(currentGoal.month).format('MMMM/YYYY')
              : moment().format('MMMM/YYYY')
          )}
        </Typography>
        <Button
          color='primary'
          onClick={() => {
            if (token) {
              dispatch(monthRequest(storeId, token))
              dispatch(inputListRequest(storeId, token, 1, 35))
            }
          }}
        >
          <RefreshIcon />
        </Button>
      </div>
      <Notifications storeId={storeId} />
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title={`Vendas do dia ${currentDate[0]}/${currentDate[1]}`}
            value={`${
              monthReport
                ? monthReport.otherInputs
                  ? formatPrice(monthReport.otherInputs.store.daySold.sold)
                  : formatPrice(monthReport.store.daySold.sold)
                : 0
            }`}
            {...(monthReport?.store.daySold.updatedAt && {
              tooltip: monthReport.store.daySold.updatedAt
            })}
            subItemName='Meta'
            subItemValue={`${
              monthReport
                ? formatPrice(
                    monthReport.store.sell > 0 ||
                      (monthReport.otherInputs &&
                        monthReport.otherInputs.store.dayGoal > 0)
                      ? monthReport.otherInputs
                        ? monthReport.otherInputs?.store.dayGoal
                        : monthReport.store.sell
                      : 0
                  )
                : 0
            }`}
            color='#2A3ECB'
            inversed
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title={
              monthReport?.otherInputs ? 'Faturamento Loja' : 'Faturamento'
            }
            value={`${
              monthReport
                ? formatPrice(
                    monthReport.otherInputs
                      ? monthReport.otherInputs.store.total.sold
                      : monthReport.store.total.sold
                  )
                : 0
            }`}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title='Percentual da meta'
            value={formatToPercentage(
              monthReport
                ? ((monthReport.otherInputs
                    ? monthReport.otherInputs.store.total.sold
                    : monthReport.store.total.sold) /
                    monthReport.store.metric.acDayGoal) *
                    100
                : 0
            )}
            icon={<InsertChartIcon />}
            color='#2A3ECB'
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title='Projeção'
            value={`${
              monthReport
                ? formatPrice(
                    monthReport.otherInputs
                      ? monthReport.otherInputs.store.soldProjection
                      : monthReport.store.projection.sold
                  )
                : 0
            }`}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Performance data={performanceData} monthReport={monthReport} />
        </Grid>
        <Grid item lg={4} md={12} xl={3} xs={12}>
          <Ranking sellers={monthReport?.sellers || []} storeId={storeId} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <Card>
            <CardHeader title='Últimos lançamentos' />
            <InputsTable
              smallTable
              inputs={inputList}
              metadata={metadata}
              storeId={storeId}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
