import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography, Avatar } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { format } from 'date-fns'
import { BootstrapTooltip } from './BootstrapTooltip'
import { dateWithNoTimezone } from 'utils/dateFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '85px'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    height: 48,
    width: 48
  },
  subItem: {
    bottom: 4,
    left: 16,
    fontSize: '11px',
    fontWeight: 400
  }
}))

interface ValueCardProps {
  title: string
  value: string | number
  icon?: JSX.Element
  color?: string
  inversed?: boolean
  subItemName?: string
  subItemValue?: string
  valueDataCy?: string
  tooltip?: string
}

const ValueCard = ({
  value,
  title,
  icon = <AttachMoneyIcon />,
  color = '#27ae60',
  inversed = false,
  subItemName = '',
  subItemValue = '',
  valueDataCy = '',
  tooltip
}: ValueCardProps) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
      style={{
        paddingTop: !subItemName ? '16px' : '10px',
        paddingBottom: !subItemName ? '16px' : '4px',
        ...(inversed && {
          backgroundColor: color,
          color: '#fff'
        })
      }}
    >
      <div>
        <Typography
          component='h3'
          gutterBottom
          variant='overline'
          color={inversed ? 'inherit' : 'textSecondary'}
          style={{
            ...(tooltip && {
              display: 'flex',
              alignItems: 'center'
            })
          }}
        >
          {title}
          {tooltip && (
            <BootstrapTooltip
              title={`Ultima atualização: ${format(
                dateWithNoTimezone(tooltip),
                'pp'
              )}`}
            >
              <ErrorOutlineIcon
                style={{
                  color: '#C4C4C4',
                  fontSize: 20,
                  marginLeft: 5
                }}
              />
            </BootstrapTooltip>
          )}
        </Typography>
        <div className={classes.details}>
          <Typography
            variant='h3'
            color={inversed ? 'inherit' : 'textPrimary'}
            data-cy={valueDataCy}
          >
            {value}
          </Typography>
        </div>
        {subItemName && subItemValue && (
          <Typography
            variant='h6'
            className={classes.subItem}
            color={inversed ? 'inherit' : 'textPrimary'}
          >
            {`${subItemName}: ${subItemValue}`}
          </Typography>
        )}
      </div>
      <Avatar
        className={classes.avatar}
        style={{
          background: inversed ? '#fff' : color,
          marginBottom: subItemName ? '3px' : 0,
          ...(inversed && { color: color })
        }}
      >
        {icon}
      </Avatar>
    </Card>
  )
}

export default ValueCard
