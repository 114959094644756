import { ButtonHTMLAttributes } from 'react'

import { UploadFile as UploadFileIcon } from '@mui/icons-material'

import { Label, Media, Container, BadgeNumber, Video } from './styles'

interface StoriesListPreviewProps extends ButtonHTMLAttributes<HTMLDivElement> {
  media: string | undefined
  isSelected: boolean
  index: number
  mediaType: 'image/png' | 'image/jpg' | 'image/jpeg' | 'video/mp4'
}

export const StoriesListPreview = ({
  media,
  index,
  isSelected,
  mediaType,
  ...props
}: StoriesListPreviewProps) => {
  const isMediaTypeVideoMp4 = mediaType === 'video/mp4'

  return (
    <Container {...props} isSelected={isSelected}>
      <Label>
        <BadgeNumber>{index}</BadgeNumber>
        {isMediaTypeVideoMp4 ? (
          <Video src={media} disablePictureInPicture id='video-tag'>
            <source id='video-source' src={media} />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <Media src={media} alt='Imagem do story'>
            {!media && (
              <UploadFileIcon
                style={{
                  width: 29,
                  height: 38
                }}
              />
            )}
          </Media>
        )}
      </Label>
    </Container>
  )
}
