import styled from 'styled-components'

interface ContainerProps {
  isGoalOverview: boolean
}

export const Container = styled.li<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f6f8;
  width: 100%;
  padding: 5px;
  border-radius: 25px;
  cursor: ${({ isGoalOverview }) => (isGoalOverview ? 'pointer' : 'default')};
  transition: 0.3s ease-in;

  &:hover {
    background-color: ${({ isGoalOverview }) => isGoalOverview && '#E5E5E5'};
  }

  .sellers-list-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }

    strong {
      color: #37474f;
      max-width: 200px;
      margin-left: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
    }

    span {
      color: #37474f;
      max-width: 200px;
      margin-left: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 12px;
    }
  }

  .sellers-list-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .user-card-button {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      font-family: 'Roboto';
      font-weight: bold;
      flex-direction: row;
      color: #546e7a;
      background: #e2e4e9;
      border-radius: 3px;
      padding: 3px 4px;
      margin-right: 8px;
      cursor: pointer;

      svg {
        font-size: 16px;
        margin-right: 4px;
      }

      &:disabled {
        background: none;
      }

      &:not(:disabled):hover {
        background: #1e2fa9;
        color: #fff;

        svg,
        span {
          color: #fff;
        }
      }
    }

    button:not(.user-card-button) {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: #e2e4e9;
      color: #37474f;
      cursor: pointer;
      outline: none;

      svg {
        color: inherit;
        font-size: 18px;
      }
    }

    button.notworking:hover {
      background: #27ae60;
      color: #fff;
    }

    .ungoal-back-button:hover {
      background-color: #37474f !important;
      border-radius: 50%;
      color: #fff;
      width: 22px;
      height: 22px;
      padding: 2px;
    }
  }
`
