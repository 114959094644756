import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import getProvider from '../helpers/getProvider'

// Libs
import moment from 'moment'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import TodayIcon from '@material-ui/icons/Today'
import SyncIcon from '@material-ui/icons/Sync'
import PersonIcon from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import Tooltip from './Tooltip'

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  startDateButton: {
    marginRight: theme.spacing(1)
  },
  endDateButton: {
    marginLeft: theme.spacing(1)
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '16px'
  },
  historyBtn: {
    border: 'none',
    borderBottom: '1px dashed #546E7A',
    backgroundColor: 'transparent',
    margin: '2px 0px',
    cursor: 'pointer',
    color: '#263238',

    '&:focus': {
      outline: 'none'
    }
  }
}))

interface HeaderProps {
  data: any
  route: string
  title: string
  subtitle: string
  className?: string
  value?: string
  goal?: boolean
  dayEvent?: boolean
  actionText: string
  secondaryText?: string
  dataCy?: string
  secondaryDataCy?: string
  openModal?: () => void
  handleDelete: () => void
  handleSecondary?: () => void
  handleBack?: () => void
}

const Header = ({
  className,
  data,
  route,
  title,
  subtitle,
  value,
  goal,
  dayEvent,
  handleBack,
  handleDelete,
  actionText,
  secondaryText,
  handleSecondary,
  openModal,
  dataCy,
  secondaryDataCy,
  ...rest
}: HeaderProps) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.container}>
        <div>
          <Button
            startIcon={<ChevronLeftIcon />}
            onClick={() => (handleBack ? handleBack() : history.push(route))}
            data-cy='handleBackNavigationHeaderButton'
          >
            {title}
          </Button>
          <Typography component='h1' gutterBottom variant='h3'>
            {subtitle}
            {/* {name} de {data && moment(data.date).format('DD/MMMM')} */}
          </Typography>
          <div className={classes.itemContainer}>
            <div className={classes.item}>
              <TodayIcon
                style={{ color: '#63727A', marginRight: '6px' }}
                fontSize='small'
              />
              <Typography variant='subtitle2'>
                Última atualização:{' '}
                {(!data?.locked && data?.history?.length > 0) ||
                (data?.origin === 'user' && data?.history?.length > 0) ? (
                  <>
                    <Tooltip
                      id={`view-goal-modal`}
                      content={<p>Histórico de revisões</p>}
                      place='bottom'
                    />
                    <button
                      data-tip
                      data-for={`view-goal-modal`}
                      className={classes.historyBtn}
                      onClick={openModal}
                    >
                      {data && moment(data.updatedAt).format('DD/MM/YYYY')}
                    </button>
                  </>
                ) : (
                  data && moment(data.updatedAt).format('DD/MM/YYYY')
                )}
              </Typography>
              {!goal &&
                !dayEvent &&
                (data?.origin == 'user' ? (
                  <PersonIcon
                    style={{
                      color: '#63727A',
                      marginRight: '6px',
                      marginLeft: '12px'
                    }}
                    fontSize='small'
                  />
                ) : (
                  <SyncIcon
                    style={{
                      color: '#63727A',
                      marginRight: '6px',
                      marginLeft: '12px'
                    }}
                    fontSize='small'
                  />
                ))}
              {!goal && !dayEvent && (
                <Typography variant='subtitle2'>
                  Origem do lançamento: {getProvider(data?.origin)}
                </Typography>
              )}
            </div>
            {/* <div className={classes.item}>
              <TodayIcon
                style={{ color: '#63727A', marginRight: '6px' }}
                fontSize="small"
              />
              <Typography variant="subtitle2">
                Criado em: {data && moment(data.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </div> */}
            {goal && value && (
              <div className={classes.item}>
                <AttachMoneyIcon
                  style={{ color: '#63727A' }}
                  fontSize='small'
                />
                <Typography variant='subtitle2'>Meta: {value}</Typography>
              </div>
            )}
          </div>
        </div>
        <div>
          {secondaryText && (
            <Button data-cy={secondaryDataCy} onClick={handleSecondary}>
              {secondaryText}
            </Button>
          )}
          <Button
            data-cy={dataCy}
            onClick={() => handleDelete()}
            style={{ color: '#e74c3c' }}
          >
            {actionText}
          </Button>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  route: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  value: PropTypes.string,
  goal: PropTypes.bool,
  handleDelete: PropTypes.func,
  secondaryText: PropTypes.string,
  dayEvent: PropTypes.bool,
  handleBack: PropTypes.func,
  handleSecondary: PropTypes.func,
  handleInputLock: PropTypes.func
}

Header.defaultProps = {
  route: '/',
  title: 'Título',
  subtitle: 'Subtítulo',
  goal: false
}

export default Header
