import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Container = styled.div`
  > h1 {
    font-weight: 500;
    font-size: 24px;
    color: #263238;
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
  }
`

export const VisiblePerfectScrollbar = styled(PerfectScrollbar)`
  .ps__rail-x {
    opacity: 0.6;
  }

  .ps__thumb-x {
    height: 11px;
  }
`
export const StorylineNameAsAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #eeeef1;

  border-radius: 50%;

  color: #000;
  font-size: 0.75rem;
`
