import { CSSProperties, ReactNode, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Close from '@material-ui/icons/Close'

import { Container } from './styles'

interface PromptModalProps {
  icon: JSX.Element
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  onClose: () => void
  title?: string
  description?: string
  leftTitle?: string
  onLeft?: () => void
  leftButtonStyles?: CSSProperties
  rightButtonStyles?: CSSProperties
  buttonContainerStyles?: CSSProperties
  contentStyles?: CSSProperties
  rightTitle?: string | ReactNode
  onRight: () => void
  isAlert?: boolean
  children?: ReactNode
  disableLeft?: boolean
  disableRight?: boolean
  leftPrimary?: boolean
}

const PromptModal = ({
  icon,
  size = 'sm',
  onClose,
  title,
  description,
  onLeft,
  rightTitle = 'Avançar',
  onRight,
  isAlert = false,
  leftTitle = 'Cancelar',
  children,
  rightButtonStyles = {},
  leftButtonStyles = {},
  buttonContainerStyles = {},
  contentStyles = {},
  disableLeft = false,
  disableRight = false,
  className,
  leftPrimary = false
}: PromptModalProps) => {
  return (
    <Container className={className} size={size}>
      <div className='top-content'>
        {icon && <div className={`icon ${isAlert ? 'alert' : ''}`}>{icon}</div>}
        <Close onClick={onClose} className='close-modal-top-button' />
      </div>

      {(title || description) && (
        <div className='title-header'>
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
        </div>
      )}

      {children && (
        <div className='center-content' style={{ ...contentStyles }}>
          {children}
        </div>
      )}

      <div
        className='footer-buttons'
        style={{
          ...buttonContainerStyles
        }}
      >
        {leftTitle && (
          <button
            style={{
              ...leftButtonStyles
            }}
            disabled={disableLeft}
            onClick={onLeft ? onLeft : onClose}
            className={leftPrimary ? 'action-button-left' : ''}
          >
            {leftTitle}
          </button>
        )}
        <button
          style={{
            ...rightButtonStyles
          }}
          disabled={disableRight}
          onClick={onRight}
          className={`action-button ${isAlert ? 'alert' : ''}`}
        >
          {rightTitle}
        </button>
      </div>
    </Container>
  )
}

export default forwardRef(PromptModal)
