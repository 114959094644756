import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material'

import { browserHistory as history } from 'App'
import { StorylinesTable } from './StorylinesTable'
import { IStoryLineProps } from 'store/modules/storylines/types'
import { IUserProps } from 'store/modules/user/types'
import {
  Container,
  Content,
  SubHeader,
  StorylineSettingsButton,
  AddStorylineButton
} from './styles'
import { useEffect } from 'react'
import {
  getActiveStorylinesRequest,
  getFinishedStorylinesRequest
} from 'store/modules/storylines/actions'

interface SelectorStorylines {
  state: DefaultRootState
  storylines: IStoryLineProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

export const Storylines = () => {
  const dispatch = useDispatch()
  const {
    activeStorylines,
    finishedStorylines,
    hasRequested,
    metadata,
    activeStorylinesLoading,
    finishedStorylinesLoading,
    settings
  } = useSelector<SelectorStorylines, IStoryLineProps>(
    state => state.storylines
  )

  const { user } = useSelector<SelectorUser, IUserProps>(state => state.user)

  const token = window.localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (user._id && !user.company.hasNeoStory) {
      const userStores = user.stores.filter(s =>
        ['owner', 'manager', 'cashier', 'director'].includes(s.type)
      )
      if (userStores.length > 1) {
        history.push('/stores')
      } else {
        history.push(`/${user.stores[0].storeId._id}/dashboard`)
      }
    }
  }, [user])

  useEffect(() => {
    if (!hasRequested && user._id) {
      dispatch(getActiveStorylinesRequest(user._id, token))
      dispatch(getFinishedStorylinesRequest(user._id, token))
    }
  }, [hasRequested, user])

  return (
    <Container>
      <SubHeader>
        <StorylineSettingsButton
          onClick={() => history.push('/storylines/settings')}
        >
          <SettingsIcon />
          CONFIGURAÇÕES
        </StorylineSettingsButton>
        <AddStorylineButton onClick={() => history.push('/storylines/create')}>
          <AddIcon />
          COMUNICADO
        </AddStorylineButton>
      </SubHeader>

      <Content>
        <StorylinesTable
          storylines={activeStorylines}
          tableTitle='Comunicados Ativos'
          metadata={metadata}
          isLoading={activeStorylinesLoading}
          user={user}
          settings={settings}
        />
      </Content>
      <Content>
        <StorylinesTable
          storylines={finishedStorylines}
          tableTitle='Encerrados'
          metadata={metadata}
          isLoading={finishedStorylinesLoading}
          user={user}
          settings={settings}
        />
      </Content>
    </Container>
  )
}
