import { ImageDragAndDrop } from '../ImageDragAndDrop'
import { Container, Content } from './styles'

interface PreviewMediaProps {
  currentMediaUrlPreview: string
  previewMedias: string[]
  fileType: 'image' | 'video'
  hasLink: boolean
}

export const PreviewMedia = ({
  hasLink,
  fileType,
  previewMedias,
  currentMediaUrlPreview
}: PreviewMediaProps) => {
  const page =
    previewMedias.indexOf(currentMediaUrlPreview) > -1
      ? previewMedias.indexOf(currentMediaUrlPreview) + 1
      : previewMedias.length + 1

  return (
    <Container>
      <Content>
        <ImageDragAndDrop
          currentFileType={fileType}
          media={currentMediaUrlPreview}
          hasLink={hasLink}
          currentPage={page}
          mediasLength={previewMedias.length}
          handleSubmitMedia={() => {}}
          canPlay
          size='lg'
        />
        Página {page}
      </Content>
    </Container>
  )
}
