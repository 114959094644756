import React from 'react'

// Libs
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

// Styles
import { Container } from './styles'

interface TableCellProps {
  value: number
  isPercentage?: boolean
  reason: string
  backgroundColor?: string
  defaultCell?: boolean
  textColor?: string
}

function TableCell({
  value = 1000,
  isPercentage = false,
  reason,
  backgroundColor = '#f7f7f7',
  defaultCell = false,
  textColor,
  ...rest
}: TableCellProps) {
  return (
    <Tooltip title={reason ? `${reason}: ${value}` : ''} arrow>
      <Container
        defaultCell={defaultCell}
        backgroundColor={backgroundColor}
        value={value}
        textColor={textColor}
        {...rest}
      >
        <h1>
          {value}
          {isPercentage && '%'}
        </h1>
      </Container>
    </Tooltip>
  )
}

export default TableCell
