import { useEffect, useMemo, useState } from 'react'

// Libs
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import * as XLSX from 'xlsx'

// Utils
import { safeDivision } from '../../utils/format'

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { IconButton, Grid, Typography, useMediaQuery } from '@material-ui/core'

import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import { TableChartTwoTone } from '@material-ui/icons'

// Components
import StoresTable from './components/StoresTable'

// Actions
import { storesReportRequest } from '../../store/modules/report/actions'
import { listGroupRequest } from 'store/modules/group/actions'
import { clearRedux } from '../../store/modules/main/actions'
import { logout } from 'store/modules/user/actions'

import { SheetButton } from 'views/Reports/components/styles'
import { GroupsTable } from './components/GroupsTable'
import { GridStoresTable } from './components/GridStoresTable'
import { ExportSpreadSheetModal } from './components/ExportSpreadSheetModal'
import { HeaderCards } from './components/HeaderCards'
import { IStoreProps } from 'store/modules/store/types'
import { IGroupFromReport, IGroupProps } from 'store/modules/group/types'
import { IReportProps, IStoresGroup } from 'store/modules/report/types'
import { History } from 'history'
import { StoresDatePicker } from './components/StoresDatePicker'
import { getMonth } from 'date-fns'
import { IUserProps as IUserReduxProps } from 'store/modules/user/types'
import { StoresSearchBar } from './components/StoreSearchBar'
import { TableContainer } from './styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    minWidth: 300
  },

  card: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.08)'
    },
    cursor: 'pointer'
  },
  pos: {
    marginBottom: 12
  },
  avatar: {
    justifyContent: 'center',
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(1)
  },
  sellsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  select: {
    width: '150px'
  }
}))

interface StoreProps {
  history: History
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserReduxProps
}

interface SelectorGroup {
  state: DefaultRootState
  group: IGroupProps
}

export interface IReports extends IStoresGroup {
  _id: string
  sold: number
  sales: number
  items: number
  goalPercent: number
  acDayGoal: number
  mainGoal: number
  projectionSold: number
  mainGoalPercent: number
}

const Stores = ({ history }: StoreProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()

  // Redux
  const { storesReport, storesRange } = useSelector<
    SelectorReport,
    IReportProps
  >(state => state.report)
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { user } = useSelector<SelectorUser, IUserReduxProps>(
    state => state.user
  )
  const { groups, groupsFromReport } = useSelector<SelectorGroup, IGroupProps>(
    state => state.group
  )

  const [gridView, setGridView] = useState(false)
  const [reports, setReports] = useState<IReports[]>([])
  const [filteredStores, setFilteredStores] = useState<IReports[]>([])
  const [sheetColumnValue, setSheetColumnValue] = useState<
    (string | number)[][]
  >([])

  const [isSheetModalVisible, setIsSheetModalVisible] = useState(false)

  const token = window.localStorage.getItem('@NeoPro:token')

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  })

  const disabledValues = getMonth(storesRange.from) !== getMonth(storesRange.to)

  const groupsWithData = useMemo(() => {
    if (groups.length > 0 && groupsFromReport.length > 0) {
      const groupsFromReportArray: IGroupFromReport[] = []
      groups.forEach(({ _id }) => {
        const currGroup = groupsFromReport.find(
          ({ groupId }) => groupId === _id
        )

        if (currGroup) {
          groupsFromReportArray.push(currGroup)
        }
      })
      return groupsFromReportArray
    }
    return []
  }, [groups, groupsFromReport])

  useEffect(() => {
    if (user.stores.length === 1) {
      history.push(`/${user.stores[0].storeId._id}/dashboard`)
    }
  }, [user.stores.length, history])

  useEffect(() => {
    if (!(groups.length > 0)) {
      dispatch(listGroupRequest(token))
    }
  }, [groups, token, dispatch])

  useEffect(() => {
    let stores: IStoresGroup[] = []
    if (storesReport) {
      if (storesReport.stores.length) {
        stores = storesReport.stores
      }
      if (storesReport.groups.length) {
        const groupsStores = storesReport.groups
          // pega todas as lojas de cada regional
          .map(group => group.stores)
          // deixa em um único array
          .flat(1)
          // remove as lojas duplicadas
          .filter(
            (store, index, self) =>
              index === self.findIndex(att => att._id === store._id)
          )

        stores = [...stores, ...groupsStores]
      }

      setReports(
        stores.map(store => ({
          ...store,
          sold: store.total.sold,
          sales: store.total.sales,
          items: Number(store.total.items.toFixed()),
          goalPercent: Number(
            ((store.total.sold / store.metric.acDayGoal || 0) * 100).toFixed(2)
          ),
          acDayGoal: store.metric.acDayGoal,
          mainGoal: store.metric.mainGoal,
          projectionSold: store.projection.sold,
          mainGoalPercent: Number(
            ((store.total.sold / store.metric.mainGoal || 0) * 100).toFixed(2)
          )
        }))
      )
    }
  }, [storesReport])

  useEffect(() => {
    const tableHeaders = [
      'Nome',
      // 'Cidade',
      'Vendas do dia',
      'Faturamento',
      '% da Meta',
      'Meta ideal',
      '% da Meta do mês',
      'Meta do mês',
      'Projeção de Venda',
      'Nº Vendas',
      'Ticket Médio',
      'Peça/Venda',
      'Preço Médio',
      'Nº Peças'
    ]

    setSheetColumnValue([tableHeaders])

    reports?.forEach(store => {
      const tableData = [
        store.name,
        // store.city,
        store.daySold.sold ?? 0,
        store.total.sold ?? 0, // Faturamento do vendedor
        safeDivision(store.total.sold / store.metric.acDayGoal), // % da Meta
        store.metric.acDayGoal ?? 0, // Meta ideal
        safeDivision(store.total.sold / store.metric.mainGoal), // % da Meta do mês
        store.metric.mainGoal ?? 0, // Meta do mês
        store.projectionSold ?? 0, // Projeção de Venda
        store.total.sales ?? 0,
        store.average.tm ?? 0,
        store.average.pv ?? 0,
        store.average.pm ?? 0,
        store.total.items ?? 0
      ]

      setSheetColumnValue(state => [...state, tableData])
    })
  }, [reports])

  const handleOnExport = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(sheetColumnValue)

    new Array(reports.length + 1).fill(0).forEach((_, index) => {
      ws[`B${index + 1}`].z = '"R$ "#,##0.00' // Vendas do dia
      ws[`C${index + 1}`].z = '"R$ "#,##0.00' // Faturamento
      ws[`D${index + 1}`].z = '0.00%' // % da Meta
      ws[`E${index + 1}`].z = '"R$ "#,##0.00' // Meta ideal
      ws[`F${index + 1}`].z = '0.00%' // % da Meta do mês
      ws[`G${index + 1}`].z = '"R$ "#,##0.00' // Meta do mês
      ws[`H${index + 1}`].z = '"R$ "#,##0.00' // Projeção de venda
      ws[`I${index + 1}`].z = '0' // Nº de vendas
      ws[`J${index + 1}`].z = '"R$ "#,##0.00' // Ticket médio
      ws[`K${index + 1}`].z = '0.00' // Peça/Venda
      ws[`L${index + 1}`].z = '"R$ "#,##0.00' // Preço médio
      ws[`M${index + 1}`].z = '0' // Nº de Peças
    })

    XLSX.utils.book_append_sheet(wb, ws, `Relatório`)
    XLSX.writeFile(wb, `[NEOPRO] Relatorio-Lojas.xlsx`, {
      bookType: 'xlsx',
      type: 'binary'
    })
  }

  useEffect(() => {
    if (token) {
      if (!storesReport && user._id) {
        const isDirector = user.stores.every(
          store => store.type === 'communicator'
        )

        if (!isDirector) {
          dispatch(storesReportRequest(user._id, token))
        } else {
          history.push('/storylines')
        }
      }
    } else {
      window.localStorage.removeItem('@NeoPro:token')
      dispatch(logout())
      history.push(`/login`)
    }
  }, [token, user, storesReport, dispatch, history])

  const handleSelectStore = (storeId: string) => {
    history.push(`/${storeId}/dashboard`)
  }

  useEffect(() => {
    if (store) dispatch(clearRedux()) // se tiver store quer dizer que ele ja entrou numa store e voltou sem ser no botao de trocar de loja
  }, [store, dispatch])

  const hasGroups = groupsWithData.length > 0

  const hasFilteredStores = filteredStores.length > 0

  return (
    <div className={classes.root}>
      <Typography variant='h4' style={{ marginBottom: 8 }}>
        Todas as lojas
      </Typography>
      <Grid container spacing={4}>
        <HeaderCards storesReport={storesReport} />

        <Grid container style={{ paddingRight: 16 }}>
          <Grid item xs={6} style={{ marginBottom: 16 }}>
            {storesReport && !(storesReport.groups.length > 0) && (
              <IconButton
                onClick={() => setGridView(!gridView)}
                color='primary'
                aria-label='upload picture'
                component='span'
              >
                {gridView ? <ViewListIcon /> : <ViewModuleIcon />}
              </IconButton>
            )}
          </Grid>

          {!gridView && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: isDesktop ? 'row' : 'column'
              }}
            >
              <Grid
                item
                xs={isDesktop ? 6 : 10}
                style={{ display: 'flex', paddingLeft: 16 }}
              >
                <StoresSearchBar
                  stores={reports}
                  setFilteredStores={setFilteredStores}
                />
                <StoresDatePicker range={storesRange} />
              </Grid>

              <Grid item xs={isDesktop ? 6 : 10} style={{ display: 'flex' }}>
                <SheetButton onClick={() => setIsSheetModalVisible(true)}>
                  <TableChartTwoTone style={{ marginRight: 5 }} />
                  Exportar todas lojas
                </SheetButton>
                <SheetButton onClick={handleOnExport}>
                  <TableChartTwoTone style={{ marginRight: 5 }} />
                  Exportar tabela
                </SheetButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          {hasGroups ? (
            <>
              <TableContainer isVisible={hasFilteredStores}>
                <StoresTable
                  storesReport={filteredStores}
                  handleSelectStore={handleSelectStore}
                  disabledValues={disabledValues}
                />
              </TableContainer>
              <TableContainer isVisible={!hasFilteredStores}>
                {groupsWithData.map((group, index) => (
                  <Grid
                    key={group.groupId}
                    style={{
                      ...(groupsWithData.length > index + 1 && {
                        paddingBottom: '32px'
                      })
                    }}
                  >
                    <GroupsTable
                      group={group}
                      disabledValues={disabledValues}
                    />
                  </Grid>
                ))}

                {storesReport && storesReport?.stores.length > 0 && (
                  <>
                    <Typography
                      variant='h4'
                      style={{
                        marginBottom: 16,
                        marginTop: 32,
                        marginLeft: 8
                      }}
                    >
                      Lojas sem regional
                    </Typography>

                    <StoresTable
                      storesReport={storesReport}
                      handleSelectStore={handleSelectStore}
                      disabledValues={disabledValues}
                    />
                  </>
                )}
              </TableContainer>
            </>
          ) : gridView ? (
            <Grid container>
              {storesReport &&
                storesReport.stores.map(store => (
                  <GridStoresTable
                    key={store._id}
                    store={store}
                    handleSelectStore={handleSelectStore}
                  />
                ))}
            </Grid>
          ) : (
            storesReport && (
              <StoresTable
                storesReport={storesReport}
                handleSelectStore={handleSelectStore}
                disabledValues={disabledValues}
              />
            )
          )}
        </Grid>
      </Grid>

      {reports && (
        <ExportSpreadSheetModal
          isOpen={isSheetModalVisible}
          onClose={() => setIsSheetModalVisible(false)}
        />
      )}
    </div>
  )
}

export default Stores
