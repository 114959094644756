import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #f7f7f8;
  padding: 30px 24px;
  max-height: 92px;
`

export const LeftSide = styled.span`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 10px;
  }

  font-size: 1rem;
  font-weight: 400;
  color: #263238;
  font-family: 'Roboto', sans-serif;
`

export const RightSide = styled.span``

export const ExitButton = styled.button`
  background-color: #e3e3e8;
  border: 0;
  border-radius: 50px;
  padding: 7.5px 21px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Commissioner', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #5b5b76;
`
