import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import {
  TripOrigin as TripOriginIcon,
  Lens as LensIcon
} from '@mui/icons-material'

import { RowTable } from './components/RowTable'
// import Tooltip from '../../../../../../../../../../../components/Tooltip'
import {
  IDaysUsers,
  IDaysWithDayOff,
  UserWorkingWithDayOff
} from 'store/modules/goal/types'
import { UsersDaysWorkingAmount } from '../..'
import { TableCell } from './styles'
import { IWorkOptions } from '../CardDay'

interface UserModalProps {
  day: IDaysWithDayOff
  activeUsersLength: number
  availableUsersToPick: UserWorkingWithDayOff[]
  usersDaysWorkingAmount?: UsersDaysWorkingAmount
  handleChangeUserActive: (newUsersList: IDaysUsers[]) => void
}

export const UsersModal = ({
  day,
  activeUsersLength,
  availableUsersToPick,
  handleChangeUserActive,
  usersDaysWorkingAmount
}: UserModalProps) => {
  const { dayOffAmount, workingAmount, notWorkingAmount } = day.users.reduce(
    (acc, curr) => {
      if (curr.dayOff.enable && !curr.notWorking) {
        return {
          ...acc,
          dayOffAmount: (acc.dayOffAmount += 1)
        }
      } else if (!curr.notWorking) {
        return {
          ...acc,
          workingAmount: (acc.workingAmount += 1)
        }
      }
      return {
        ...acc,
        notWorkingAmount: (acc.notWorkingAmount += 1)
      }
    },
    {
      workingAmount: 0,
      dayOffAmount: 0,
      notWorkingAmount: 0
    }
  )

  const handleChangeUserWorkingStatus = (
    userId: string,
    option: 'dayOff' | 'willWork' | 'willNotWork'
  ) => {
    const newDayUsers: IDaysUsers[] = []

    day.users.forEach(usr => {
      if (usr.userId._id === userId) {
        if (option !== 'willNotWork') {
          const formattedUser = {
            ...usr,
            dayOff: {
              enable: option === 'dayOff'
            },
            userId: usr.userId._id
          }

          if (formattedUser.notWorking) {
            delete formattedUser.notWorking
          }

          newDayUsers.push(formattedUser)
        }
      } else {
        const userDayOff = {
          ...usr,
          dayOff: {
            enable: usr.dayOff.enable
          },
          userId: usr.userId._id
        }

        newDayUsers.push(userDayOff)
      }
    })

    handleChangeUserActive(newDayUsers)
  }
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
      <Table aria-label='Tabela de usuários' size='small' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Vendedores</TableCell>
            <Tooltip
              title={
                <Typography fontSize={14} style={{ color: '#fff' }}>
                  O vendedor terá meta para o dia
                </Typography>
              }
            >
              <TableCell>
                <span>
                  <LensIcon fontSize='small' style={{ color: '#93C5FD' }} />
                  Irá trabalhar
                </span>
              </TableCell>
            </Tooltip>

            <Tooltip
              title={
                <Typography fontSize={14} style={{ color: '#fff' }}>
                  A meta do vendedor desses dias é distribuída nos outros dias
                  em que for trabalhar
                </Typography>
              }
            >
              <TableCell>
                <span>
                  <TripOriginIcon
                    fontSize='small'
                    style={{ color: '#93C5FD' }}
                  />
                  Folga c/ meta
                </span>
              </TableCell>
            </Tooltip>
            <Tooltip
              title={
                <Typography fontSize={14} style={{ color: '#fff' }}>
                  A meta do vendedor desses dias é distribuída para os outros
                  vendedores
                </Typography>
              }
            >
              <TableCell>
                <span>
                  <LensIcon fontSize='small' style={{ color: '#EEEEF1' }} />
                  Folga s/ meta
                </span>
              </TableCell>
            </Tooltip>
          </TableRow>
        </TableHead>
        <TableBody>
          {availableUsersToPick.map(({ userId, dayOff, notWorking }) => {
            const option: IWorkOptions = dayOff?.enable
              ? 'dayOff'
              : notWorking
              ? 'willNotWork'
              : 'willWork'

            return (
              <RowTable
                key={userId._id}
                checked={option}
                name={userId.name.complete}
                userId={userId._id}
                photoUrl={userId.photo.url ?? undefined}
                handleChangeUserActive={handleChangeUserWorkingStatus}
                hasMoreThanOneDayWorking={
                  !!usersDaysWorkingAmount &&
                  usersDaysWorkingAmount[userId._id].daysWorkingAmount > 1
                }
              />
            )
          })}

          <TableRow style={{ backgroundColor: '#F7F7F8' }}>
            <TableCell component='th' scope='row' width='100%' align='left'>
              {activeUsersLength}
            </TableCell>
            <TableCell align='center'>{workingAmount}</TableCell>
            <TableCell align='center'>{dayOffAmount}</TableCell>
            <TableCell align='center'>{notWorkingAmount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
