import { useEffect } from 'react'
import { Router, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'

// Redux
import { useSelector } from 'react-redux'

// Reactotron
import './config/ReactotronConfig'
import 'react-toastify/dist/ReactToastify.css'

import * as Sentry from '@sentry/react'

// MaterialUi
import { ThemeProvider } from '@material-ui/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

// Utils
import validate from 'validate.js'
import MomentUtils from '@date-io/moment'
import { ToastContainer } from 'react-toastify'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import validators from './common/validators'
import Routes from './Routes'

//Analytics
import { logAnalytics } from 'services/firebase'
import GlobalStyle from 'styles/globalStyle'

export const browserHistory = createBrowserHistory()

validate.validators = {
  ...validate.validators,
  ...validators
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)

  useEffect(() => {
    const currentPathname = pathname.split('/')[2]

    if ((user, store, currentPathname)) {
      // Firebase analytics
      logAnalytics('navigate', {
        user_Id: user?._id,
        userName: user?.name.complete,
        storeId: store?._id,
        storeName: store?.name,
        userType: user?.stores?.find(
          userStore => userStore.storeId._id === store?._id
        )?.type,
        storeType: store?.config?.storeType,
        timeZone: store?.config?.timeZone,
        modalityStore: store?.config?.modalityStore,
        businessModel: store?.config?.businessModel,
        screen_name: currentPathname,
        screen_class: currentPathname
      })

      //Sentry analytics
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user?._id,
          username: user?.name.complete,
          storeId: store?._id,
          storeName: store?.name,
          userType: user?.stores?.find(
            userStore => userStore.storeId._id === store?._id
          )?.type,
          storeType: store?.config?.storeType,
          timeZone: store?.config?.timeZone,
          modalityStore: store?.config?.modalityStore,
          businessModel: store?.config?.businessModel,
          screen_name: currentPathname,
          screen_class: currentPathname
        })
      })
    }

    window.scrollTo(0, 0)
  }, [pathname, user])

  return null
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ConfirmProvider>
        <Router history={browserHistory}>
          <ScrollToTop />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Routes />
          </MuiPickersUtilsProvider>
        </Router>
        <ToastContainer
          autoClose={3000}
          toastStyle={{
            borderRadius: 6,
            padding: 12,
            fontWeight: '600',
            fontSize: 15
          }}
          limit={2}
        />
      </ConfirmProvider>
    </ThemeProvider>
  )
}
