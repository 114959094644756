import React from 'react'
import styled from 'styled-components'
import palette from '../../../../../../theme/palette'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
  padding: 4px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #f9f9fb;
`

export const ActualSeller = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${palette.text.primary};
  background-color: ${palette.background.default};
  padding: 6px 14px;
  border-radius: 50px;
`

export const HeaderDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
`

export const SellersFound = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
`

export const Counters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const CounterBase = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.background.paper};
  border-radius: 50%;
  min-width: 22px;
  height: 22px;
  font-size: 10px;
  font-weight: 700;
`
export const ActiveCounter = styled(CounterBase)`
  color: #2ecc71;
`
export const OtherCounter = styled(CounterBase)`
  color: #f39c12;
`
export const InactiveCounter = styled(CounterBase)`
  color: #e74c3c;
`

export const RadioContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0;
`

export const SellersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  gap: 8px;
  width: 100%;
  max-height: 390px;
  border-radius: 8px;
  background-color: ${palette.background.paper};
  overflow-y: scroll;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
`

export const Seller = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #f9f9fb;
  border-radius: 8px;
  width: calc(100% - 8px);
  height: 100%;
  padding: 8px 12px;
  color: ${color => color && '#E3E3E8'};

  &:hover {
    background-color: ${palette.background.default};
    color: ${color => color && '#5B5B76'};
    cursor: pointer;
  }
`
export const SellerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: center;
`

export const StatusConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`

export const SelectSeller = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 12px;
`
export const SelectForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const EditSellerIcon = () => (
  <div className='swap-icon'>
    <svg
      width='16'
      height='16'
      viewBox='0 0 13 13'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.60867 10.332L2.66667 10.342V7.38868L9.08933 1.02735C9.58933 0.528016 10.4687 0.52535 10.9727 1.02935L12.03 2.08668C12.282 2.33868 12.4207 2.67335 12.4207 3.02935C12.4207 3.38535 12.282 3.72002 12.03 3.97202L5.60867 10.332ZM11.0893 3.02735L10.03 1.97202L8.96733 3.02535L10.0247 4.08202L11.0893 3.02735ZM8.02 3.96268L4 7.94468V9.00402L5.058 9.00068L9.07733 5.02002L8.02 3.96268ZM10.6667 13H1.33333C0.598 13 0 12.402 0 11.6667V2.33335C0 1.59802 0.598 1.00002 1.33333 1.00002H7.23133L5.898 2.33335H1.33333V11.6667H3.31933C3.3301 11.667 3.34072 11.6685 3.35125 11.67C3.3629 11.6717 3.37445 11.6734 3.386 11.6734C3.39467 11.6734 3.4035 11.6717 3.41233 11.67C3.42117 11.6684 3.43 11.6667 3.43867 11.6667H10.6667V7.22135L12 5.88802V11.6667C12 12.402 11.402 13 10.6667 13Z'
        fill='currentColor'
      />
    </svg>
  </div>
)
