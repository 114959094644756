import styled, { css } from 'styled-components'
import { Avatar } from '@material-ui/core'

export const AsideContainer = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  > h2 {
    font-family: 'Commissioner', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #5b5b76;
  }
`

export const MediaPreviewContainer = styled.div`
  border: 2px solid #9a9ab1;
  border-radius: 8px;
  background-color: #eeeef1;
  position: relative;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 14.375rem;
  min-width: 7.1875rem;
  max-height: 14.375rem;
  max-width: 7.1875rem;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const Media = styled(Avatar)`
  height: 100%;
  width: 100%;

  img {
    max-height: 14.375rem;
    max-width: 7.1875rem;
  }

  color: #9a9ab1;
  border-radius: 0px;
  background-color: transparent;
`

export const BadgeNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 15px;
  height: 16px;
  border-radius: 0px 8px 0px 4px;
  background-color: #fff;

  position: absolute;
  right: 0;
  top: 0;

  color: #9a9ab1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 10px;
  z-index: 2;
`
export const Video = styled.video`
  ${({ src }) =>
    src
      ? css`
          height: 100%;
          width: 100%;
        `
      : css`
          height: 64px;
          width: 64px;
        `}

  max-height: 14.375rem;
  max-width: 7.1875rem;

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
`

export const MediasCarrousel = styled.div`
  display: flex;
  gap: 1rem;

  overflow: auto;
  max-width: 50%;
`
