import styled from 'styled-components'

interface ContainerProps {
  isDesktop: boolean
}

interface StoresCalendarProps {
  hasStores: boolean
}

interface CalendarProps {
  isDesktop: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 17rem;
  padding: 0 16px;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 0 : '10px')};
`

export const StoresCalendar = styled.div<StoresCalendarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  border-radius: 4px;
  gap: 5px;
  padding: 10px 18px;
  width: 100%;
  cursor: ${({ hasStores }) => (hasStores ? 'pointer' : 'not-allowed')};
`
export const Calendar = styled.div<CalendarProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  left: ${({ isDesktop }) => (isDesktop ? '347px' : '107px')};
  margin-top: -10px;
  padding: 6px 4px;
  border-radius: 4px;
`
