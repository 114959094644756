import { useState, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'

import api from '../../../../repositories'
import { goalUpdateUsers } from 'store/modules/goal/actions'

import UsersGoal from 'components/UsersGoal'
import { IDayOff, IDays, IGoal, IUserWorking } from 'store/modules/goal/types'
import { IStoreProps } from 'store/modules/store/types'

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface IUsersFromGoalInfo {
  working: IUserWorking[]
  notWorking: IUserWorking[]
  weights: 0
}

interface UserDays {
  date: string
  dayOff: IDayOff
}

interface IUsersWorkingWithDays extends IUserWorking {
  days?: IDays[]
}

interface SellersProps {
  goal: IGoal | undefined
  newUser: string | undefined
  loading: boolean
}

const Sellers = ({ goal, newUser, loading }: SellersProps) => {
  const dispatch = useDispatch()

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  const [sellersOutsideGoal, setSellersOutsideGoal] = useState<{
    list: IUserWorking[]
    requested: boolean
  }>({
    list: [],
    requested: false
  })

  const [userEdit, setUserEdit] = useState<IUserWorking | undefined>(undefined)
  const token = window.localStorage.getItem('@NeoPro:token')

  const [usersFromGoalInfo, setUsersFromGoalInfo] =
    useState<IUsersFromGoalInfo>({
      working: [],
      notWorking: [],
      weights: 0
    })
  const [isUsersWeightLocked, setIsUsersWeightLocked] = useState(false)

  const handleUpdateUserWeight = (
    days: IDays[],
    user: IUserWorking,
    type: 'auto' | 'fixed' = 'auto',
    weight = 1,
    goalId: string = '',
    isRemoving = false
  ) => {
    try {
      const seen = new Set()

      let newGoalSellersList: IUsersWorkingWithDays[] = [
        ...usersFromGoalInfo.notWorking,
        ...usersFromGoalInfo.working,
        user
      ].filter(el => {
        const duplicate = seen.has(el.userId?._id)
        seen.add(el.userId?._id)
        return !duplicate
      })

      //Todos os vendedores da meta (Ativos e inativos)
      const currentUserIndexInsideGoal = newGoalSellersList.findIndex(
        el => el.userId?._id === user.userId?._id
      )

      const newSellerEnteringGoal = {
        ...user,
        dsr: (goal?.dsr ?? 0) > 0,
        goalWeight: weight,
        days,
        weightLocked: type === 'fixed',
        active: days.length === 0 ? false : !isRemoving
      }

      if (currentUserIndexInsideGoal < 0) {
        newGoalSellersList.push(newSellerEnteringGoal) // Vendedor novo entrando
      } else {
        newGoalSellersList[currentUserIndexInsideGoal] = newSellerEnteringGoal // Vendedor já existente
      }

      newGoalSellersList = newGoalSellersList.map(userM => ({
        // Coloca todos os vendedores no padrão de ter days p/ auxiliar nos cálculos abaixo
        days: goal?.days.filter(day =>
          day.users.find(usr => usr.userId === userM.userId?._id)
        ),
        ...userM // Sobrescreve em seguida pq pode ter caso de o vendedor já vir com days montado
      }))

      const distributedUsers = distributeWeights(newGoalSellersList)

      if (!isUsersWeightLocked) setIsUsersWeightLocked(true) // se nenhum vendedor tiver sido alterado ou se ja clicou no cadeado uma vez, bloqueia para poder clicar no cadeado dnv

      if (isRemoving) {
        const newSellersOutSideGoal = {
          ...sellersOutsideGoal,
          list: sellersOutsideGoal.list.filter(
            userW => userW.userId?._id !== user.userId?._id
          )
        }
        setSellersOutsideGoal(newSellersOutSideGoal)
      }

      const users = distributedUsers.map(userM => {
        const userDays: UserDays[] = []
        userM.days?.forEach(day => {
          const formattedUser = day.users.find(
            usr => usr.userId === userM.userId._id
          )

          if (formattedUser && !!formattedUser.dayOff) {
            userDays.push({
              dayOff: {
                enable: formattedUser.dayOff.enable
              },
              date: day.date
            })
          }
        })

        return {
          dsr: userM.dsr,
          goalWeight: Number(userM.goalWeight) ?? 1,
          userId: userM.userId?._id,
          weightLocked: userM.weightLocked || false, // VERIFICAR SE PRECISA DISSO,
          days: userM.active ? userDays ?? [] : []
        }
      })

      dispatch(goalUpdateUsers(users, goalId, token))
    } catch (error) {
      console.log(error)
    }
  }

  const distributeWeights = (users: IUsersWorkingWithDays[]) => {
    const usersResults = users.reduce(
      (acc, user, i) => {
        if (user.active && user.goalWeight > 0) {
          // Passa por cada usuário somando o seu peso em 100%
          acc.accWeights += 1
          // Caso ele for fixado diminui o valor
          if (user.weightLocked) acc.accWeights -= user.goalWeight
          // Caso ele não for locado não vai entrar nos usuários a ser distribuidos
          if (!user.weightLocked) acc.usersUnlocked += 1
        }
        if (i === users.length - 1) {
          // No ultimo vai ver o acumulado que já subtraiu os fixados e dividir pelos usuários não fixados
          acc.accWeights = acc.accWeights / acc.usersUnlocked
        }
        return acc
      },
      { accWeights: 0, usersUnlocked: 0 }
    )

    users.forEach(user => {
      if (user.active && !user.weightLocked && goal?.distribute)
        user.goalWeight =
          usersResults.accWeights < 0 ? 0 : usersResults.accWeights
      return user
    })
    return users
  }

  const handleUnlockWeightUsers = () => {
    if (filteredUsersWorking) {
      const newUsers = filteredUsersWorking
        .filter(user => user.active)
        .map(user => {
          const days = goal?.days.filter(day =>
            day.users.find(usr => usr.userId === user.userId?._id)
          )

          const newDays = days
            ? days.map(day => {
                const currentUserDayOff = day.users.find(
                  usr => usr.userId === user.userId._id
                )!
                return {
                  dayOff: currentUserDayOff.dayOff,
                  date: day.date
                }
              })
            : []

          return {
            days: newDays ?? [],
            userId: user.userId?._id,
            goalWeight: 1,
            weightLocked: false,
            dsr: user.dsr
          }
        })

      goal && dispatch(goalUpdateUsers(newUsers, goal._id, token))
      setIsUsersWeightLocked(false)
    }
  }

  //Verifica se o usuário está vinculado a loja e se o tipo dele é vendedor, assim filtrando os usuários que estão trabalhando na meta.
  const filteredUsersWorking = goal?.usersWorking.filter(userWorking =>
    store?.users.find(
      user =>
        user._id === userWorking.userId?._id &&
        user.stores.find(store => store.type === 'seller')
    )
  )

  const outsideList = [
    ...sellersOutsideGoal.list,
    ...usersFromGoalInfo.notWorking
  ]

  // Caso seja um novo usuário e a userId esteja na URL, remove assim que ele entra na meta, pra caso a página atualize ele não abra o modal novamente
  useEffect(() => {
    if (goal) {
      // Novo usuário entrando na meta
      const isNewUserInsideGoal =
        goal.usersWorking.findIndex(user => user.userId._id === newUser) >= 0

      //Usuário já existente entrando na meta
      const isExistingUserInsideGoal =
        outsideList.findIndex(user => user.userId._id === newUser) >= 0

      // Objeto do novo usuário
      const newUserObject = goal.usersWorking.find(
        user => user.userId._id === newUser
      )

      // Objeto do usuário existente
      const existingUserObject = outsideList.find(
        user => user.userId._id === newUser
      )

      if (newUser && outsideList.length > 0 && !userEdit) {
        if (isNewUserInsideGoal) {
          setUserEdit(newUserObject)
        } else if (isExistingUserInsideGoal) {
          setUserEdit(existingUserObject)
        }
      }
    }
  }, [goal, newUser, outsideList])

  useEffect(() => {
    if (goal?.usersWorking) {
      if (!sellersOutsideGoal.requested) {
        //Request users not working from
        try {
          api.axios
            .get(`/goal/${goal._id}/usersNotWorking`)
            .then(({ data }) => {
              const usersTranslated = data.map((user: IUserWorking) => ({
                userId: user
              }))
              setSellersOutsideGoal({ list: usersTranslated, requested: true })
            })
        } catch (error) {
          setSellersOutsideGoal({ list: [], requested: true })
          console.log(error)
        }
      }

      if (filteredUsersWorking) {
        const usersResults = filteredUsersWorking.reduce(
          (acc: IUsersFromGoalInfo, user) => {
            if (user.active) {
              acc.working = [...acc.working, user]
              acc.weights += user.goalWeight
            } else acc.notWorking = [...acc.notWorking, user]

            if (user.weightLocked && !isUsersWeightLocked)
              setIsUsersWeightLocked(true)
            return acc
          },
          { weights: 0, working: [], notWorking: [] }
        )

        setUsersFromGoalInfo(usersResults)

        //Se tiver alguém que está dentro de working ou notWorking, e ainda está dentro de sellersOutsideGoal, tem que tirar
        if (
          usersResults.working.length > 0 ||
          usersResults.notWorking.length > 0
        ) {
          const newSellersOutsideGoal = {
            ...sellersOutsideGoal,
            list: sellersOutsideGoal.list.filter(
              userW =>
                !usersResults.working.find(
                  user => user.userId?._id === userW.userId?._id
                ) ||
                usersResults.notWorking.find(
                  user => user.userId?._id === userW.userId?._id
                )
            )
          }
          setSellersOutsideGoal(newSellersOutsideGoal)
        }
      }
    }
  }, [goal])

  return (
    <UsersGoal
      usersNotWorking={[
        ...sellersOutsideGoal.list,
        ...usersFromGoalInfo.notWorking
      ]}
      usersWorking={usersFromGoalInfo.working}
      totalWeight={usersFromGoalInfo.weights}
      loading={loading || !sellersOutsideGoal.requested}
      showUnlock={isUsersWeightLocked}
      onClickUnlock={handleUnlockWeightUsers}
      goal={goal}
      handleUpdateUserWeight={handleUpdateUserWeight}
      newUser={userEdit}
    />
  )
}

export default Sellers
