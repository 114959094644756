import { initializeApp } from 'firebase/app'
import { getAnalytics, setUserProperties, logEvent } from 'firebase/analytics'

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDZcVayL2eKjZ_FylP2kZr_qpSQU_KUcLE',
  authDomain: 'neopro-server.firebaseapp.com',
  projectId: 'neopro-server',
  storageBucket: 'neopro-server.appspot.com',
  messagingSenderId: '430450714113',
  appId: '1:430450714113:web:6fbe45b4af421fd10f8200',
  measurementId: 'G-ECRJPD2LW8'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Set user properties and send an event
const logAnalytics = (event, data) => {
  try {
    setUserProperties(analytics, {
      user_Id: data?.user_Id,
      userName: data?.userName,
      storeId: data?.storeId,
      storeName: data?.storeName,
      userType: data?.userType,
      storeType: data?.config?.storeType,
      timeZone: data?.config?.timeZone,
      modalityStore: data?.config?.modalityStore,
      businessModel: data?.config?.businessModel
    })
    logEvent(analytics, event, data)
  } catch (err) {
    console.error(err)
  }
}

export { logAnalytics }
