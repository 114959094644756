import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core'
import palette from 'theme/palette'

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const DayGoals = ({ data, ...rest }) => {
  const classes = useStyles()
  const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52)
  const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`

  const chartInstance = useRef()

  useEffect(() => {
    // if (chartInstance?.current?.data?.datasets) {
    //   chartInstance.current.data.datasets.forEach((ds, i) => {
    //     if (i >= 4) ds.hidden = true;
    //   });
    //   chartInstance.current.update();
    // }
  }, [chartInstance?.current?.data?.datasets])

  return (
    <Card {...rest}>
      <CardHeader title='Evolução do percentual da meta' />
      <Divider />
      <CardContent>
        {data ? (
          <div className={classes.chartContainer}>
            <Line
              ref={chartInstance}
              data={{
                labels: data.store.graphValues.dates,
                datasets: [
                  {
                    label: 'Loja',
                    data: data.store.graphValues.percentages.map(item =>
                      (item * 100).toFixed(2)
                    ),
                    fill: false,
                    tension: 0.3,
                    borderColor: palette.primary.main,
                    borderWidth: 3
                  },
                  ...data.sellers.map(s => ({
                    label: s.name.complete,
                    data: s.graph?.percentages.map(item =>
                      item === 0 ? 'N/A' : (item * 100).toFixed(2)
                    ),
                    fill: false,
                    tension: 0.3,
                    borderColor: randomRGB(),
                    borderWidth: 1
                  }))
                ]
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: 'right',
                    onHover: (e, item) => {
                      item.fontColor = palette.primary.main
                      chartInstance.current.data.datasets.forEach((ds, i) => {
                        if (i === item.datasetIndex) {
                          ds.borderWidth = 3
                        } else {
                          ds.backupColor = ds.borderColor
                          ds.borderColor = '#FFFFFF50'
                        }
                      })
                      // chartInstance.current.data.datasets[item.datasetIndex].borderWidth = 3
                      chartInstance.current.update()
                    },
                    onLeave: (e, item) => {
                      item.fontColor = '#000'
                      chartInstance.current.data.datasets.forEach((ds, i) => {
                        if (ds.backupColor) {
                          ds.borderColor = ds.backupColor
                        }
                        if (i > 0) {
                          ds.borderWidth = 1
                        }
                      })
                      chartInstance.current.update()
                    }
                  },
                  tooltip: {
                    enabled: true,
                    mode: 'nearest',
                    intersect: false,
                    borderWidth: 1,
                    borderColor: palette.divider,
                    backgroundColor: palette.white,
                    titleColor: palette.text.primary,
                    bodyColor: palette.text.secondary,
                    footerColor: palette.text.secondary,
                    filter: item => item.raw > 0,
                    itemSort: (a, b) => b.raw - a.raw,
                    callbacks: {
                      label: item => `${item.dataset.label}: ${item.raw}%`
                    }
                  },
                  annotation: {
                    annotations: {
                      line1: {
                        type: 'line',
                        yMin: 100,
                        yMax: 100,
                        borderColor: '#FF000030',
                        borderWidth: 2
                      }
                    }
                  }
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (value, index, ticks) => value + '%'
                    },
                    suggestedMin: 0
                  }
                },
                maintainAspectRatio: false,
                interaction: {
                  intersect: false
                },
                layout: { padding: 0 }
              }}
            />
          </div>
        ) : (
          <Typography variant='subtitle2'>
            Sem relatório para apresentar.
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

DayGoals.propTypes = {
  className: PropTypes.string
}

export default DayGoals
