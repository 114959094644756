import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../repositories/api'

import {
  storeSuccess,
  storeUpdateSuccess,
  storeAvatarUpdateSuccess,
  listGroupStoresSuccess,
  listGroupStoresFailed
} from './actions'
import { requestFailed } from '../error/action'

import { toast } from 'react-toastify'

export function* listGroupStores({ payload }) {
  try {
    const { token } = payload
    const response = yield call(api.axios.get, '/store/director_stores', {
      headers: { Authorization: `Bearer ${token}` }
    })

    yield put(listGroupStoresSuccess(response.data))
  } catch (e) {
    yield put(listGroupStoresFailed())
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getStore({ payload }) {
  try {
    const { storeId, token } = payload
    const response = yield call(api.axios.get, `/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(storeSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* storeUpdate({ payload }) {
  try {
    const { updatedStore, token, storeId } = payload
    // yield put(RepositoriesActions.send())
    const response = yield call(
      api.axios.put,
      `/store/${storeId}`,
      updatedStore,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    toast.success('Loja atualizada com sucesso.')
    yield put(storeUpdateSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* storeAvatarUpdate({ payload }) {
  try {
    const { newAvatar, token, storeId } = payload
    const response = yield call(
      api.axios.post,
      `/store/${storeId}/upload`,
      newAvatar,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    toast.success('Avatar atualizado com sucesso.')
    yield put(storeAvatarUpdateSuccess(response.data.url))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@store/LIST_GROUP_STORES_REQUEST', listGroupStores),
  takeLatest('@store/REQUEST', getStore),
  takeLatest('@store/UPDATE_REQUEST', storeUpdate),
  takeLatest('@store/AVATAR_UPDATE_REQUEST', storeAvatarUpdate)
])
