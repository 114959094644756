import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import { FooterButton } from '../FooterButton'
import { TextInput } from '../TextInput'
import { createStorylineTagRequest } from 'store/modules/storylines/actions'
import { FaCamera } from 'react-icons/fa'
import { BiFileFind, BiTrash } from 'react-icons/bi'

import { toast } from 'react-toastify'
import { Avatar } from '@material-ui/core'
import { convertFileSizeName } from 'utils/convertFileSizeName'

import {
  Button,
  Divider,
  IconButton,
  IconTagContainer,
  ImagePreviewContainer,
  MediaInfoCard,
  TagFooter,
  Text
} from '../../styles'

interface CreateTagModalProps {
  isOpen: boolean
  companyId: string | undefined
  onCancel: () => void
}

export const CreateTagModal = ({
  isOpen,
  companyId,
  onCancel
}: CreateTagModalProps) => {
  const [newTag, setNewTag] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [previewUrlImage, setPreviewUrlImage] = useState('')

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  const handleCreateNewTag = () => {
    if (newTag.length === 0) {
      setHasSubmitted(true)
      return
    }

    if (hasSubmitted) {
      setHasSubmitted(false)
    }

    if (!companyId) {
      return toast.error('Algo deu errado')
    }

    const formData = new FormData()

    if (!!file) {
      formData.append('photo', file)
    }

    dispatch(createStorylineTagRequest(newTag, formData, companyId, token))
    onCancel()
    setNewTag('')
  }

  const handleCloseModal = () => {
    if (hasSubmitted) {
      setHasSubmitted(false)
    }
    setFile(null)
    setPreviewUrlImage('')
    onCancel()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files && files.length > 0) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      const fileType = files[0].type

      if (
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg'
      )
        if (fileSize <= 2) {
          const previewURL = URL.createObjectURL(files[0])

          setFile(files[0])
          setPreviewUrlImage(previewURL)
        } else {
          toast.error(
            'Imagem muito grande! A imagem deve ser menor ou igual a 2MB.'
          )
        }
    }
  }

  const fileExtension =
    file?.name?.split('.')[file?.name?.split('.').length - 1] ?? ''
  const fileName = file?.name?.substring(0, 14) ?? ''

  const handleRemoveMedia = () => {
    setFile({} as File)
    setPreviewUrlImage('')
  }

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle fontSize={'0.875rem'} fontWeight={700}>
        Adicionar Tag
      </DialogTitle>
      <DialogContent
        style={{
          margin: '1.5rem 0',
          minWidth: 476,
          padding: '8px 3rem 0.75rem'
        }}
      >
        <TextInput
          autoFocus
          variant='outlined'
          value={newTag}
          error={hasSubmitted}
          id='tag'
          name='tag'
          label='Nome da tag'
          fullWidth
          onChange={event => setNewTag(event.target.value)}
        />
        <Divider />

        <IconTagContainer>
          <Text>Ícone da tag</Text>

          <TagFooter>
            <label htmlFor='icon-tag-image'>
              {previewUrlImage ? (
                <ImagePreviewContainer>
                  <img
                    src={previewUrlImage}
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                  />

                  <MediaInfoCard>
                    <div>
                      {fileName +
                        ((file?.name.length ?? 0) > 19
                          ? `..${fileExtension}`
                          : '')}
                      <span>{!!file && convertFileSizeName(file)}</span>
                    </div>
                    <div>
                      <IconButton htmlFor='icon-tag-image'>
                        <BiFileFind size={24} />
                      </IconButton>
                      <IconButton onClick={handleRemoveMedia}>
                        <BiTrash size={24} />
                      </IconButton>
                    </div>
                  </MediaInfoCard>
                </ImagePreviewContainer>
              ) : (
                <Avatar style={{ cursor: 'pointer' }}>
                  <FaCamera style={{ width: 18, height: 18 }} />
                </Avatar>
              )}

              <input
                style={{ display: 'none' }}
                type='file'
                id='icon-tag-image'
                accept='image/png, image/jpg, image/jpeg'
                onChange={handleChange}
              />
            </label>
            {!previewUrlImage && (
              <Button htmlFor='icon-tag-image'>Enviar</Button>
            )}
          </TagFooter>
        </IconTagContainer>
      </DialogContent>
      <DialogActions>
        <FooterButton buttonType='cancel' onClick={handleCloseModal}>
          CANCELAR
        </FooterButton>
        <FooterButton buttonType='submit' onClick={handleCreateNewTag}>
          CRIAR
        </FooterButton>
      </DialogActions>
    </Dialog>
  )
}
