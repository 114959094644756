import React, { useState, useEffect } from 'react'
import { Backdrop, Modal } from '@material-ui/core'
import Slider from '@mui/material/Slider'
import Close from '@material-ui/icons/Close'

import BoltImage from 'assets/bolt.png'
import { formatPrice } from 'utils/format'

// Styles
import SpeedIcon from '@material-ui/icons/Speed'
import { Lock } from '@material-ui/icons'
import Tooltip from 'components/Tooltip'
import {
  Container,
  InputContainer,
  SliderContainer,
  WeightSelection,
  WeightSelectionItem
} from './styles'
import { IGoal, IUserWorking } from 'store/modules/goal/types'
import { ICurrentGoalProps } from 'views/Goals/components/AddGoal/components/StepperContainer/GoalStep'

interface GoalSliderModalProps {
  data: ICurrentGoalProps | IGoal | undefined
  usersWorking: IUserWorking[] | undefined
  sellerName: string
  sellerWeight: number
  auxSellerWeight: string | number
  seller: IUserWorking | null
  setAuxSellerWeight: (sellerWeight: string | number) => void
  open: boolean
  onClose: () => void
  onSave: (type: 'auto' | 'fixed') => void
}

const GoalSliderModal = ({
  data,
  usersWorking,
  sellerName,
  sellerWeight,
  auxSellerWeight,
  seller,
  setAuxSellerWeight,
  open,
  onClose,
  onSave
}: GoalSliderModalProps) => {
  const [tab, setTab] = useState(seller?.weightLocked ? 1 : 0)
  const tabColorOptions = tab === 0 ? '#FFC700' : '#263238'

  useEffect(() => {
    setAuxSellerWeight(sellerWeight)
  }, [sellerWeight, setAuxSellerWeight])

  useEffect(() => {
    setTab(seller?.weightLocked ? 1 : 0)
  }, [seller?.weightLocked])

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
        setTab(seller?.weightLocked ? 1 : 0)
        setAuxSellerWeight(seller?.goalWeight ?? 0)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Container>
        <div
          className='close'
          onClick={() => {
            onClose()
            setTab(seller?.weightLocked ? 1 : 0)
            setAuxSellerWeight((seller?.goalWeight ?? 0) * 100)
          }}
        >
          <Close />
        </div>
        <div>
          <h1>Peso de {sellerName}</h1>
          <WeightSelection>
            <Tooltip
              fixed
              id='weight-selection-left'
              content={
                <p>
                  Peso ajustado automaticamente em referência aos outros
                  vendedores
                </p>
              }
            />
            <WeightSelectionItem
              data-tip
              data-for='weight-selection-left'
              onClick={() => {
                setTab(0)
                const distributedWeight = usersWorking?.reduce(
                  (acc, user, i) => {
                    if (user.active && user.goalWeight > 0) {
                      acc.weightAcc += 1
                      const currUser = user?.userId?._id === seller?.userId?._id
                      if (user.weightLocked && !currUser) {
                        acc.weightAcc -= user.goalWeight
                      }
                      if (!user.weightLocked || currUser) acc.usersUnlocked += 1
                    }
                    if (i === usersWorking.length - 1) {
                      acc.weightAcc /= acc.usersUnlocked
                    }
                    return acc
                  },
                  { weightAcc: 0, usersUnlocked: 0 }
                )
                setAuxSellerWeight(
                  data?.distribute ? distributedWeight?.weightAcc ?? 0 : 1
                )
              }}
              selected={tab === 0}
            >
              <SpeedIcon fontSize='small' />
              <span>Auto</span>
            </WeightSelectionItem>

            <Tooltip
              fixed
              id='weight-selection-right'
              content={
                <p>O peso do vendedor é fixado no valor inserido abaixo</p>
              }
            />
            <WeightSelectionItem
              data-tip
              data-for='weight-selection-right'
              onClick={() => setTab(1)}
              right
              selected={tab === 1}
            >
              <Lock fontSize='small' />
              <span>Fixado</span>
            </WeightSelectionItem>
          </WeightSelection>
          <InputContainer>
            <input
              maxLength={5}
              value={
                typeof auxSellerWeight === 'number'
                  ? (auxSellerWeight * 100 > 300
                      ? 300
                      : auxSellerWeight * 100
                    ).toFixed(2)
                  : +auxSellerWeight > 300
                  ? 300
                  : auxSellerWeight
              }
              style={{ color: tab === 0 ? '#FFC700' : '#000' }}
              onChange={e => {
                const amount = e.target.value
                setAuxSellerWeight(
                  Number(amount) > 300
                    ? String(300)
                    : String(amount)
                        .replace(',', '.')
                        .replace(/[^0-9.]/g, '')
                )
                setTab(1)
              }}
            />
            <label>%</label>
          </InputContainer>

          <SliderContainer>
            <span>0%</span>
            <Slider
              className='slider'
              defaultValue={100}
              step={5}
              min={0}
              max={300}
              onChange={(e, value) => {
                setAuxSellerWeight(String(value))
                setTab(1)
              }}
              value={Math.round(
                typeof auxSellerWeight === 'number'
                  ? auxSellerWeight * 100
                  : +auxSellerWeight
              )}
              sx={{
                '& .MuiSlider-track': {
                  color: tabColorOptions,
                  transition: '0.2s ease-in'
                },
                '& .MuiSlider-thumb': {
                  borderColor: tabColorOptions,
                  transition: '0.2s ease-in'
                },
                '& .MuiSlider-mark': {
                  backgroundColor: tabColorOptions,
                  transition: '0.2s ease-in'
                }
              }}
              marks={[
                { value: 0 },
                { value: 100 },
                { value: 200 },
                { value: 300 }
              ]}
            />
            <span>300%</span>
          </SliderContainer>

          <div className='example-card'>
            <h3>
              <strong>Exemplo</strong> <img src={BoltImage} />
            </h3>
            <p>
              Meta individual: <strong>{formatPrice(10000)}</strong>
              <br />
              Meta ajustada com o peso de{' '}
              {(typeof auxSellerWeight === 'number'
                ? auxSellerWeight * 100
                : +auxSellerWeight
              ).toFixed(2)}
              %:{' '}
              <strong>
                {formatPrice(
                  10000 *
                    (typeof auxSellerWeight === 'number'
                      ? auxSellerWeight
                      : Number(auxSellerWeight) / 100)
                )}
              </strong>
              <br />
              <br />
              <strong>* Todas as metas serão afetadas com este peso!</strong>
            </p>
          </div>
          <button onClick={() => onSave(tab === 0 ? 'auto' : 'fixed')}>
            Salvar
          </button>
        </div>
      </Container>
    </Modal>
  )
}

export default GoalSliderModal
