import { useEffect, useState } from 'react'

// Redux
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { monthRequest, reportRequest } from '../../store/modules/report/actions'
import { inputListRequest } from 'store/modules/input/actions'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Input,
  Checkbox,
  Select,
  MenuProps as MenuPropsType
} from '@material-ui/core'

import {
  CloseTwoTone,
  CloudDownloadTwoTone,
  TableChartTwoTone,
  DateRange
} from '@material-ui/icons'

// Compoments
import ReportsTable from './components/ReportsTable'
import Tooltip from '../../components/Tooltip'

// Libs
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import * as XLSX from 'xlsx'

// Styles
import {
  CalendarContainer,
  ReportCalendar,
  SheetButton,
  SheetModal
} from './components/styles'

// Utils
import { formatPrice, formatToPercentage, safeDivision } from 'utils/format'
import DayGoals from './components/DayGoals'
import { IInputProps } from 'store/modules/input/types'
import {
  IMonthReport,
  IReportProps,
  ISeller,
  IStore
} from 'store/modules/report/types'
import { IGoalProps } from 'store/modules/goal/types'
import { IStoreProps } from 'store/modules/store/types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  subContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  select: {
    width: '400px'
  }
}))

const MenuProps: Partial<MenuPropsType> = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
}

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface ReportRangeProps {
  from: string
  to: string
}
interface RangeProps {
  from: Date
  to: Date
}

interface ReportsProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
    }
    path: string
    url: string
  }
}

// Tabela para todas as lojas
const tableHeaders = [
  { id: 'name', label: 'Nome', minWidth: 150 },
  { id: 'sold', label: 'Faturamento', minWidth: 125 },
  { id: 'goalPercent', label: '%', minWidth: 75 },
  { id: 'goal', label: 'Meta ideal', minWidth: 125 },
  { id: 'mainGoalPercent', label: '%', minWidth: 75 },
  { id: 'mainGoal', label: 'Meta do mês ', minWidth: 125 },
  { id: 'currentGoal', label: 'Meta atual ', minWidth: 130 },
  { id: 'projection', label: 'Projeção de Venda', minWidth: 175 },
  { id: 'currentCommission', label: 'Remuneração Atual', minWidth: 175 },
  { id: 'commissionProj', label: 'Projeção de Remuneração', minWidth: 200 },
  { id: 'salesQuantity', label: 'N˚ Vendas', minWidth: 125 },
  { id: 'averageTicket', label: 'Ticket Médio', minWidth: 125 },
  { id: 'items/sales', label: 'Peça/Venda', minWidth: 100 },
  { id: 'averagePrice', label: 'Preço Médio', minWidth: 125 },
  { id: 'itemsQuantity', label: 'N˚ Peças', minWidth: 100 }
] as const

export type TableHeadersProps = readonly (typeof tableHeaders)[number][]

const headersSheet = {
  Nome: 'General',
  CPF: 'General',
  Faturamento: '"R$ "#,##0.00',
  '%': '0.00%',
  'Meta ideal': '"R$ "#,##0.00',
  '%.': '0.00%',
  'Meta do mês': '"R$ "#,##0.00',
  'Meta Atual': 'General',
  'Projeção de Venda': '"R$ "#,##0.00',
  'Remuneração Atual': '"R$ "#,##0.00',
  'Projeção de Remuneração': '"R$ "#,##0.00',
  'N˚ Vendas': '0',
  'Ticket Médio': '"R$ "#,##0.00',
  'Peça/Venda': '0.00',
  'Preço Médio': '"R$ "#,##0.00',
  'N˚ Peças': '0',
  'Piso Salarial': '"R$ "#,##0.00',
  DSR: '0',
  '% da Comissão': '0.00%',
  Bônus: ['"R$ "#,##0.00', '0.00%']
} as const

export type HeadersSheetKeys = keyof typeof headersSheet

export default function Reports({ match }: ReportsProps) {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { monthReport, reportsList, range } = useSelector<
    SelectorReport,
    IReportProps
  >(state => state.report)
  const { currentGoal } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { inputList, metadata } = useSelector<SelectorInput, IInputProps>(
    state => state.input
  )

  const { storeId } = match.params
  const token = window.localStorage.getItem('@NeoPro:token')

  const [reports, setReports] = useState<IMonthReport | null>(null)
  const [reportRange, setReportRange] = useState<ReportRangeProps>({
    from: moment().startOf('month').set({ hours: 12 }).toISOString(),
    to: ''
  })

  const [sheetModalVisible, setSheetModalVisible] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)

  const [sheetColumnItems, setSheetColumnItems] = useState<HeadersSheetKeys[]>([
    'Nome',
    'CPF',
    'Faturamento',
    'Meta do mês'
  ])

  // Ao inicializar, se não tiver exibindo nenhum report, solicita os do mês
  useEffect(() => {
    if (metadata.pagination.totalCount > 0) {
      if (!monthReport && !reportsList && !reports) {
        dispatch(monthRequest(storeId, token))
      }
    } else {
      if (metadata.pagination.totalCount < 0) {
        dispatch(inputListRequest(storeId, token))
      }
    }
  }, [])

  // Se chegar os reports do mês, e não tiver nenhum intervalo pré selecionado, atualiza
  useEffect(() => {
    // apresenta reports do intervalo selecionado
    if (reportsList) setReports(reportsList)
    else setReports(monthReport)
  }, [monthReport, reportsList])

  useEffect(() => {
    if (
      metadata.pagination.totalCount > 0 &&
      !monthReport &&
      !reportsList &&
      !reports
    ) {
      dispatch(monthRequest(storeId, token))
    }
  }, [metadata])

  // Tabela atualmente destinada apenas as lojas que possuem OtherInputs
  const tableOtherHeaders = [
    { id: 'name', label: 'Nome', minWidth: 150 },
    { id: 'sold', label: 'Faturamento', minWidth: 125 },
    { id: 'goalPercent', label: '%', minWidth: 75 },
    { id: 'goal', label: 'Meta ideal', minWidth: 100 },
    { id: 'mainGoalPercent', label: '%', minWidth: 75 },
    { id: 'mainGoal', label: 'Meta do mês', minWidth: 125 },
    { id: 'currentGoal', label: 'Meta atual', minWidth: 130 },
    { id: 'projection', label: 'Projeção de Venda', minWidth: 175 },
    { id: 'salesQuantity', label: 'N˚ Vendas', minWidth: 125 },
    { id: 'averageTicket', label: 'Ticket Médio', minWidth: 125 },
    { id: 'items/sales', label: 'Peça/Venda', minWidth: 100 },
    { id: 'averagePrice', label: 'Preço Médio', minWidth: 125 },
    { id: 'itemsQuantity', label: 'N˚ Peças', minWidth: 100 }
  ]

  useEffect(() => {
    if (!!reports) {
      setReportRange(state => ({
        ...state,
        to: reports.store.metric.lastInputDate
      }))
    }
  }, [reports])

  const handleOnExport = () => {
    if (reports && store) {
      const formatStoreDataTable = (storeToBeFormatted: IStore) => [
        'Loja',
        '', // CPF dos vendedores
        storeToBeFormatted.total.sold ?? 0, // Faturamento
        safeDivision(
          storeToBeFormatted.total.sold / storeToBeFormatted.metric.acDayGoal
        ), // Porcentagem da meta ideal
        storeToBeFormatted.metric.acDayGoal ?? 0, // Meta ideal
        safeDivision(
          storeToBeFormatted.total.sold / storeToBeFormatted.metric.mainGoal
        ), // Porcentagem da meta do mês
        storeToBeFormatted.metric.mainGoal ?? 0, // Meta do mês
        '-', // Meta Atual
        storeToBeFormatted.projection.sold ?? 0, // Projeção de Venda
        '-', // Remuneração Atual
        '-', // Projeção de Remuneração
        storeToBeFormatted.total.sales ?? 0, // N˚ Vendas
        safeDivision(
          storeToBeFormatted.total.sold / storeToBeFormatted.total.sales
        ), // Ticket Médio
        safeDivision(
          storeToBeFormatted.total.items / storeToBeFormatted.total.sales
        ), // Peça/Venda
        safeDivision(
          storeToBeFormatted.total.sold / storeToBeFormatted.total.items
        ), // Preço Médio
        storeToBeFormatted.total.items ?? 0, // N˚ Peças
        currentGoal?.salary.base ?? 0, // Piso Salarial
        currentGoal?.dsr, // DSR
        '-', // % da Comissão
        '-' // Bônus
      ]

      const sheetIndex = sheetColumnItems.map(item =>
        (Object.keys(headersSheet) as HeadersSheetKeys[]).findIndex(
          header => header === item
        )
      )

      // Informações gerais da Loja
      const storeOptions = formatStoreDataTable(reports?.store)

      const storeItems = sheetColumnItems.map(
        item => storeOptions[sheetIndex[sheetColumnItems.indexOf(item)]]
      )

      const formatSellersTableData = (
        sellerToBeFormatted: ISeller,
        isOscar = false
      ) => [
        sellerToBeFormatted.active
          ? sellerToBeFormatted.name.complete
          : `(INATIVO) ${sellerToBeFormatted.name.complete}`, // Nome do vendedor
        sellerToBeFormatted.cpf, // cpf do vendedor
        sellerToBeFormatted.total.sold ?? 0, // Faturamento do vendedor
        safeDivision(
          sellerToBeFormatted.total.sold / sellerToBeFormatted.metric.acDayGoal
        ), // Porcentagem da Meta ideal do vendedor
        sellerToBeFormatted.metric.acDayGoal ?? 0, // Meta ideal do vendedor
        safeDivision(
          sellerToBeFormatted.total.sold / sellerToBeFormatted.metric.acDayGoal
        ), // Porcentagem da Meta do mês do vendedor
        sellerToBeFormatted.metric.acDayGoal ?? 0, // Meta do mes do vendedor
        !isOscar ? sellerToBeFormatted.projection.goal.name : '-', // Meta Atual
        sellerToBeFormatted.projection.sold ?? 0, // Projeção de Venda
        sellerToBeFormatted.commission.sumCommission ?? 0, // Remuneração Atual aqui arrumar
        !isOscar
          ? sellerToBeFormatted.projection.commission.sumCommission ?? 0
          : '-', // Projeção de Remuneração qui arrumar
        sellerToBeFormatted.total.sales ?? 0, // N˚ Vendas
        safeDivision(
          sellerToBeFormatted.total.sold / sellerToBeFormatted.total.sales
        ), // Ticket Médio
        safeDivision(
          sellerToBeFormatted.total.items / sellerToBeFormatted.total.sales
        ), // Peça/Venda
        safeDivision(
          sellerToBeFormatted.total.sold / sellerToBeFormatted.total.items
        ), // Preço Médio
        sellerToBeFormatted.total.items ?? 0, // N˚ Peças
        '-', // Piso Salarial
        '-', // DSR

        currentGoal?.monthGoals.find(
          goal => goal.name === sellerToBeFormatted.projection.goal.name
        )?.commission ||
          (currentGoal?.monthGoals[0]?.commission ?? 0), // % da Comissão
        currentGoal?.monthGoals.find(
          goal => goal.name === sellerToBeFormatted.projection.goal.name
        )?.bonus ||
          (currentGoal?.monthGoals[0]?.bonus ?? 0) // Bônus
      ]

      let oscarSheetColumnValue: (string | number | undefined)[][] = []

      if (reports.otherInputs) {
        const total = {
          items: reports.otherInputs.other.items + reports.store.total.items,
          sales: reports.otherInputs.other.sales + reports.store.total.sales,
          sold: reports.otherInputs.other.sold + reports.store.total.sold
        }

        const oscarStoreOptions = formatStoreDataTable({
          ...reports.store,
          ...reports.otherInputs,
          total
        })

        const oscarStoreItems = sheetColumnItems.map(
          item => oscarStoreOptions[sheetIndex[sheetColumnItems.indexOf(item)]]
        )
        oscarSheetColumnValue = [sheetColumnItems, oscarStoreItems]
      }

      let sheetColumnValue = [sheetColumnItems, storeItems]

      let sellersOtherInputCount = 0
      const sellersIndex: number[] = []
      reports.sellers.forEach((seller, index) => {
        const options = formatSellersTableData(seller)

        const items = sheetIndex.map(itemIndex => options[itemIndex])

        sheetColumnValue = [...sheetColumnValue, items]

        if (reports.otherInputs && seller.otherInputs) {
          const oscarOptions = formatSellersTableData(
            {
              ...seller,
              total: { ...seller.otherInputs }
            },
            true
          )

          const oscarItems = sheetIndex.map(
            itemIndex => oscarOptions[itemIndex]
          )
          oscarSheetColumnValue = [...oscarSheetColumnValue, oscarItems]
          sellersOtherInputCount++
          sellersIndex.push(index)
        }
      })

      const wb = XLSX.utils.book_new()

      const defaultColumnsExcel = [
        [store.name],
        [
          `${format(new Date(reportRange.from), 'dd-MM-yyyy', {
            locale: ptBR
          })} até ${format(new Date(reportRange.to), 'dd-MM-yyyy', {
            locale: ptBR
          })}`
        ],
        ...sheetColumnValue
      ]

      if (reports.otherInputs) {
        defaultColumnsExcel.splice(2, 0, [
          'Desempenho vendedores - Todas as Lojas'
        ])
        defaultColumnsExcel.splice(0, 1),
          defaultColumnsExcel.push(
            [],
            [`Desempenho Loja - ${store.name}`],
            ...oscarSheetColumnValue
          )
      }

      const ws = XLSX.utils.aoa_to_sheet(defaultColumnsExcel)

      new Array(reports.sellers.length + 1).fill(0).forEach((_, index) => {
        new Array(sheetColumnItems.length).fill(0).forEach((_, idx) => {
          const isString =
            typeof headersSheet[sheetColumnItems[idx]] === 'string'
          ws[`${String.fromCharCode(idx + 97).toUpperCase()}${index + 4}`].z =
            isString
              ? headersSheet[sheetColumnItems[idx]]
              : headersSheet[sheetColumnItems[idx]][
                  Number(
                    currentGoal?.monthGoals.find(
                      goal =>
                        goal.name ===
                        reports.sellers[index]?.projection.goal.name
                    )?.bonusPercentage ||
                      (currentGoal?.monthGoals[0]?.bonusPercentage ?? false)
                  )
                ]
        })
      })

      if (reports.otherInputs) {
        new Array(sellersOtherInputCount + 1).fill(0).forEach((_, index) => {
          new Array(sheetColumnItems.length).fill(0).forEach((_, idx) => {
            const isString =
              typeof headersSheet[sheetColumnItems[idx]] === 'string'
            ws[
              `${String.fromCharCode(idx + 97).toUpperCase()}${
                index + 8 + reports.sellers.length
              }`
            ].z = isString
              ? headersSheet[sheetColumnItems[idx]]
              : headersSheet[sheetColumnItems[idx]][
                  Number(
                    currentGoal?.monthGoals.find(
                      goal =>
                        goal.name ===
                        reports.sellers[sellersIndex[index]]?.projection.goal
                          .name
                    )?.bonusPercentage ||
                      (currentGoal?.monthGoals[0]?.bonusPercentage ?? false)
                  )
                ]
          })
        })
      }

      XLSX.utils.book_append_sheet(wb, ws, `Relatório`)
      XLSX.writeFile(
        wb,
        `[NEOPRO_${format(new Date(reports.store.metric.today), 'dd-MM-yyyy', {
          locale: ptBR
        })}]Relatorio-${store?.name}-${format(
          new Date(reportRange.from),
          'dd-MM-yyyy',
          { locale: ptBR }
        )}-a-${format(new Date(reportRange.to), 'dd-MM-yyyy', {
          locale: ptBR
        })}.xlsx`,
        { bookType: 'xlsx', type: 'binary' }
      )
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.subContent}>
          {inputList?.length === 0 && (
            <Tooltip
              id={`storeDoesNotHaveReport`}
              content={
                <p>A loja ainda não possui metas ou lançamentos cadastrados.</p>
              }
              place='top'
            />
          )}
          <CalendarContainer data-tip data-for='storeDoesNotHaveReport'>
            <Typography style={{ whiteSpace: 'nowrap' }}>
              Relatório para:
            </Typography>
            <ReportCalendar
              onClick={() => {
                setOpenCalendar(true)
              }}
              hasReport={inputList?.length > 0}
            >
              <DateRange style={{ width: 16, marginRight: 5 }} />
              <Typography>
                {range.from ? moment(range.from).format('DD/MM/YYYY') : '-'}
              </Typography>
              <Typography>até</Typography>
              <Typography>
                {range.to ? moment(range.to).format('DD/MM/YYYY') : '-'}
              </Typography>
            </ReportCalendar>
          </CalendarContainer>
          <SheetButton onClick={() => setSheetModalVisible(true)}>
            <TableChartTwoTone style={{ marginRight: 5 }} />
            Exportar relatório para planilhas
          </SheetButton>
        </div>

        {store && (
          <ReportsTable
            reports={reports}
            store={store}
            tableHeaders={tableHeaders}
            tableOtherHeaders={tableOtherHeaders}
            range={range}
            requestReports={(range: RangeProps) => {
              setReportRange({
                from: String(range.from),
                to: String(range.to)
              })
              dispatch(reportRequest(storeId, token, range))
            }}
            openCalendar={openCalendar}
            setOpenCalendar={setOpenCalendar}
          />
        )}
        <DayGoals
          style={{
            marginTop: '24px'
          }}
          data={reports}
        />
      </div>

      {reports && (
        <SheetModal
          open={sheetModalVisible}
          onClose={() => setSheetModalVisible(false)}
        >
          <div className='content'>
            <h1>O que deseja exportar?</h1>
            <span>Selecione abaixo as informações que deseja exportar</span>

            <div
              className='close-button'
              onClick={() => setSheetModalVisible(false)}
            >
              <CloseTwoTone style={{ color: 'lightgrey' }} />
            </div>

            <div className='checkbox-container'>
              <FormControl>
                <InputLabel id='export-label'>Informações</InputLabel>
                <Select
                  multiple
                  labelId='export-label'
                  id='export-label-checkbox'
                  value={Object.values(sheetColumnItems)}
                  onChange={event =>
                    setSheetColumnItems(
                      event.target.value as HeadersSheetKeys[]
                    )
                  }
                  input={<Input />}
                  renderValue={selected => {
                    const typedSelected = selected as string[]
                    return typedSelected.join(', ')
                  }}
                  MenuProps={MenuProps}
                  className={classes.select}
                >
                  {(Object.keys(headersSheet) as HeadersSheetKeys[]).map(
                    header => {
                      const isInteractionDisabled = [
                        'Nome',
                        'Faturamento'
                      ].includes(header)

                      return (
                        <MenuItem
                          key={header}
                          value={header}
                          disabled={isInteractionDisabled}
                        >
                          <Checkbox
                            checked={
                              isInteractionDisabled ||
                              Object.values(sheetColumnItems).indexOf(header) >
                                -1
                            }
                            disabled={isInteractionDisabled}
                            style={{
                              color: '#1E2FA9'
                            }}
                          />
                          <ListItemText primary={header} />
                        </MenuItem>
                      )
                    }
                  )}
                </Select>
              </FormControl>
            </div>

            <SheetButton onClick={handleOnExport}>
              <CloudDownloadTwoTone style={{ marginRight: 5 }} />
              Fazer download da planilha
            </SheetButton>
          </div>
        </SheetModal>
      )}
    </div>
  )
}
