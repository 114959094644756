import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 15px;

  border: 2px solid;
  border-color: ${props =>
    props.isSelected ? palette.primary.main : '#C4C4C4'};
  border-radius: 8px;
  background-color: #fff;
  width: 390px;
  padding: 20px;
  transition: 0.3s ease-in-out;

  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: -10px;
    margin-top: -10px;
    text-align: left;

    h1 {
      font-size: 16px;
      margin-right: 8px;
      color: ${props => (props.isSelected ? palette.primary.main : '#37474F')};
    }
  }

  div {
    width: 100%;
  }

  span.disclaimer {
    color: #546e7a;
    font-size: 12px;
    text-align: left;
  }

  .card-check-children-container {
    margin-top: 15px;
  }
`
