import React from 'react'
// Libs
import PropTypes from 'prop-types'

// Styles
import './styles.css'

// Assets
import boltIcon from 'assets/bolt.png'
import Tooltip from 'components/Tooltip'
import palette from 'theme/palette'

const CardButton = ({
  selected,
  children,
  cardTitle,
  cardDescription,
  tooltipContent,
  buttonAction,
  buttonText,
  dataCy = null
}) => {
  return (
    <button
      style={{ borderColor: selected && palette.primary.main }}
      onClick={buttonAction}
      className='button-container'
      data-cy={dataCy}
    >
      {children}
      <div className='button-content-info'>
        <h1>{cardTitle}</h1>
        <p>{cardDescription}</p>
        {tooltipContent && (
          <>
            <strong data-tip data-for={cardTitle}>
              Exemplo <img src={boltIcon} alt='Icon' />
            </strong>
            <Tooltip id={cardTitle} content={tooltipContent} />
          </>
        )}
      </div>
      <p>{buttonText}</p>
    </button>
  )
}

CardButton.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.element,
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  tooltipText: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string
}

export default CardButton
