import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import EqualizerIcon from '@material-ui/icons/Equalizer'

// Styles
import Tooltip from 'components/Tooltip'
import { Container, MessageContainer } from './styles'

// Components
import TableCell from './components/TableCell'
import { IStore } from 'store/modules/store/types'

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: theme.spacing(4)
  },
  blue: {
    marginTop: theme.spacing(4),
    color: '#fff',
    backgroundColor: '#495BD4'
  },
  labelCell: {
    width: '250px'
  },
  customIcon: {
    fontSize: '128px',
    color: '#CCCCCC',
    backgroundColor: '#FAFAFA',
    borderRadius: '155px',
    padding: theme.spacing(4),
    marginBottom: '18px'
  }
}))

interface ISeller {
  activities: {
    _id: string
    date: string
    sellerId: string
    type: string
  }[]
  events: {
    _id: string
    entryType: string
    period: {
      start: string
      end: string
    }
    selected: {
      options: []
      title: string
    }
    sellerId: string
    success: boolean
  }[]
  fail: number
  success: number
  sellerId: {
    _id: string
    name: {
      first: string
      last: string
      complete: string
    }
    photo: {
      url: string | undefined
    }
  }
  time: {
    line: number
    working: number
  }
}

interface IContent {
  name: string
  total: number
  success: boolean
  sellers: {
    sellerId: string
    total: number
  }[]
}

interface HeatMapProps {
  sellers: ISeller[]
  totalSales: number
  total: number
  store: IStore
}

const HeatMap = ({ sellers, totalSales, total, store }: HeatMapProps) => {
  const classes = useStyles()

  const [header, setHeader] = useState<any[]>([[], [], []])
  const [content, setContent] = useState<IContent[]>([])

  const salesCases = ['Venda padrão', 'Venda por WhatsApp', 'Troca com venda']

  useEffect(() => {
    if (sellers) {
      setHeader(
        sellers.reduce(
          (acc, curr, index) => {
            acc[0].push(
              <th
                style={{ marginBottom: '28px' }}
                key={curr.sellerId.name.complete}
              >
                <Tooltip
                  id={`avatar-tooltip-${index}`}
                  content={<p>{curr.sellerId.name.complete}</p>}
                  place='top'
                />
                <p
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 150,
                    paddingLeft: 50
                  }}
                >
                  {curr.sellerId.name.first}{' '}
                  {curr.sellerId.name.last.substring(0, 1)}.
                </p>
                <Avatar
                  data-tip
                  data-for={`avatar-tooltip-${index}`}
                  className={classes.blue}
                  alt={curr.sellerId.name.complete}
                  src={curr.sellerId.photo.url}
                >
                  {!curr.sellerId.photo.url &&
                    `${curr.sellerId.name.first.substring(
                      0,
                      1
                    )}${curr.sellerId.name.last.substring(0, 1)}`}{' '}
                </Avatar>
              </th>
            )
            acc[1].push(
              <TableCell
                key={curr.success + curr.fail + curr.sellerId.name.complete}
                value={curr.success + curr.fail}
                reason='Total de atendimentos'
                defaultCell
              />
            )
            acc[2].push(
              <TableCell
                key={curr.success + curr.sellerId.name.complete}
                value={curr.success}
                textColor='#4B981F'
                reason='Vendas'
                backgroundColor='#F7F7F8'
              />
            )
            acc[3].push(
              <TableCell
                key={curr.success + curr.sellerId.name.complete}
                value={Number(
                  (
                    (curr.success /
                      (curr.success + curr.fail === 0
                        ? 1
                        : curr.success + curr.fail)) *
                    100
                  ).toFixed(2)
                )}
                isPercentage
                textColor='#4B981F'
                reason='Conversão'
                backgroundColor='#F7F7F8'
              />
            )
            return acc
          },
          [
            [
              <th
                key='Nome Vendedor'
                aria-label='Nome Vendedor'
                style={{
                  maxWidth: '240px',
                  justifyContent: 'flex-end',
                  paddingRight: '8px'
                }}
              />
            ],
            [
              <td
                key='Atendimentos'
                aria-label='Atendimentos'
                style={{
                  maxWidth: '240px',
                  width: '120px',
                  justifyContent: 'flex-end',
                  paddingRight: '8px'
                }}
              >
                <span>Atendimentos</span>
              </td>
            ],
            [
              <td
                key='Vendas'
                aria-label='Vendas'
                style={{
                  maxWidth: '240px',
                  width: '120px',
                  justifyContent: 'flex-end',
                  paddingRight: '8px'
                }}
              >
                <span>Vendas</span>
              </td>
            ],
            [
              <td
                key='Conversão'
                aria-label='Conversão'
                style={{
                  maxWidth: '240px',
                  width: '120px',
                  justifyContent: 'flex-end',
                  paddingRight: '8px'
                }}
              >
                <span>Conversão</span>
              </td>
            ]
          ]
        )
      )

      setContent(
        sellers
          .reduce<IContent[]>((acc, curr) => {
            curr.events.forEach(event => {
              if (event.selected?.title) {
                const failIndex = acc.findIndex(
                  failEvent => failEvent.name === event.selected.title
                )
                if (failIndex >= 0) {
                  const sellerIndex = acc[failIndex].sellers.findIndex(
                    seller => seller.sellerId === event.sellerId
                  )

                  if (sellerIndex >= 0) {
                    acc[failIndex].sellers[sellerIndex].total += 1
                    acc[failIndex].total += 1
                    acc[failIndex].success = salesCases.includes(
                      acc[failIndex].name
                    )
                  }
                } else {
                  acc.push({
                    name: event.selected.title,
                    sellers: sellers.map(seller => ({
                      sellerId: seller.sellerId._id,
                      total: Number(seller.sellerId._id === event.sellerId)
                    })),
                    total: 1,
                    success: salesCases.includes(event.selected.title)
                  })
                }
              }
            })
            return acc
          }, [])
          // Faz um sort pra deixar as vendas antes das "não-vendas"
          .sort((a, b) => (a.success === b.success ? 0 : a.success ? -1 : 1))
      )
    }
  }, [sellers])

  return (
    <Container>
      <thead>
        {header[0].length > 1 ? (
          header.map((line, index) => (
            <tr key={line[0].key}>
              {index === 0
                ? [
                    ...line,

                    <th
                      style={{ marginBottom: '28px' }}
                      key={`${line[0].key} ${index}`}
                    >
                      <p
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 150,
                          paddingLeft: 50
                        }}
                      >
                        {store ? store.name : '...'}
                      </p>
                      <Avatar
                        className={classes.blue}
                        alt={store ? store.name : '...'}
                        src={store?.photo?.url ?? undefined}
                      />
                    </th>
                  ]
                : index === 1
                ? [
                    ...line,
                    <TableCell
                      key={`${line[0].key} ${index}`}
                      value={total}
                      reason='Total de atendimentos'
                      defaultCell
                    />
                  ]
                : index === 2
                ? [
                    ...line,
                    <TableCell
                      key={`${line[0].key} ${index}`}
                      defaultCell
                      value={totalSales}
                      textColor='#4B981F'
                      reason='Total (Vendas)'
                    />
                  ]
                : [
                    ...line,
                    <TableCell
                      key={`${line[0].key} ${index}`}
                      defaultCell
                      value={Number(((totalSales / total) * 100).toFixed(2))}
                      isPercentage
                      textColor='#4B981F'
                      reason='Total (Vendas) %'
                    />
                  ]}
            </tr>
          ))
        ) : (
          <MessageContainer>
            <th>
              <EqualizerIcon className={classes.customIcon} />
              <h1>Sem dados registrados</h1>
            </th>
          </MessageContainer>
        )}
      </thead>
      <tbody>
        {content.map(reason => (
          <tr key={reason.name}>
            <td
              style={{
                maxWidth: '240px',
                width: '120px',
                justifyContent: 'flex-end',
                paddingRight: '8px'
              }}
            >
              <span>{reason.name}</span>
            </td>
            {reason.sellers.map(a => (
              <TableCell
                key={a.sellerId}
                value={a.total}
                reason={reason.name}
                backgroundColor={`rgba(${
                  salesCases.includes(reason.name)
                    ? '132, 205, 91'
                    : '73, 91, 212'
                }, ${
                  Number(
                    (Math.log((a.total * 100) / reason.total) / Math.log(100)) *
                      100
                  ) / 100
                })`}
              />
            ))}
            <TableCell
              value={reason.total}
              reason={`Total (${reason.name})`}
              defaultCell
            />
          </tr>
        ))}
      </tbody>
    </Container>
  )
}

HeatMap.propTypes = {
  sellers: PropTypes.array,
  totalSales: PropTypes.number
}

export default HeatMap
