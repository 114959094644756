import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  TextField,
  FormHelperText
} from '@material-ui/core'

// Material UI
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useConfirm } from 'material-ui-confirm'

// Redux
import { useDispatch } from 'react-redux'

// Libs
import { useParams } from 'react-router-dom'
import api from '../../../../../../repositories'
import {
  integrationDisconnectRequest,
  integrationRequest
} from '../../../../../../store/modules/integration/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  selectedBadge: {
    backgroundColor: '#FE6B8B',
    color: 'white'
  }
}))

const Kigi = ({ className, storeIntegrationData, store, loading, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'kigi'

  const DEFAULT_PROVIDER = {
    token: '',
    storeId: ''
  }

  const [providerData, setProviderData] = useState(DEFAULT_PROVIDER)
  const [storeLoading, setStoreLoading] = useState(false)
  const [error, setError] = useState(false)

  const [storeList, setStoreList] = useState([])

  // LocalStorage
  const token = localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com a Kigi?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(integrationDisconnectRequest(provider, storeId, token))
        setStoreList([])
        setProviderData(DEFAULT_PROVIDER)
      })
      .catch(() => {})
  }

  const handleSubmit = () => {
    setError(false)
    if (!providerData.token) {
      return setError('Todos os campos devem estar preenchidos.')
    }
    if (!providerData.storeId) {
      setStoreLoading(true)
      api.axios
        .put(`/integration/kigi/store/${storeId}`, providerData)
        .then(({ data }) => {
          setStoreList(data.storeList)
          setStoreLoading(false)
        })
        .catch(err => {
          setError('Erro ao conectar com a Kigi.')
          console.log(err)
          setStoreLoading(false)
        })
    } else {
      confirm({
        title: 'Atenção',
        description:
          'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na Kigi?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar'
      })
        .then(() => {
          dispatch(
            integrationRequest(
              { ...providerData, provider },
              provider,
              storeId,
              token
            )
          )
        })
        .catch(() => {})
    }
  }

  useEffect(() => {
    if (
      storeIntegrationData?.token &&
      storeIntegrationData?.provider === provider
    )
      setProviderData({
        token: storeIntegrationData.token || '',
        storeId: storeIntegrationData.storeId || ''
      })
  }, [storeIntegrationData])

  return (
    <>
      <Card {...rest}>
        <CardHeader title='Kigi' />
        <Divider />
        <CardContent>
          <Typography>
            Antes de iniciar a integração, certifique-se de que todos os
            vendedores estão cadastrados, assim como seus CPFs estão definidos e
            idênticos à Kigi. Caso haja qualquer diferença nos valores, o
            vendedor não será identificado e a venda entrará em "Outras vendas",
            prejudicando a validade do relatório.
            <br />
            Se acontecer de um vendedor não ter sido identificado e sua venda
            entrar em "Outras vendas", corrija os dados do vendedor e aguarde a
            próxima sincronização.
            <br />
          </Typography>
          <Divider style={{ marginTop: '1rem' }} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Token'
                fullWidth
                variant='outlined'
                margin='normal'
                id='token'
                name='token'
                onFocus={event => event.target.select()}
                onChange={e =>
                  setProviderData({ ...providerData, token: e.target.value })
                }
                value={providerData.token}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              {error && (
                <FormHelperText style={{ color: 'red' }}>
                  {error}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {storeIntegrationData?.provider !== provider && (
            <Button
              color='primary'
              variant='contained'
              disabled={loading || storeLoading}
              onClick={handleSubmit}
              className={classes.button}
              type='submit'
            >
              {loading || storeLoading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#fff', marginRight: '8px' }}
                  />{' '}
                  {' Conectando à Kigi...'}
                </>
              ) : providerData.storeId ? (
                'Salvar'
              ) : (
                'Conectar'
              )}
            </Button>
          )}
          {storeIntegrationData?.provider === provider && (
            <Button
              style={{ color: '#e74c3c' }}
              className={classes.button}
              disabled={loading || storeLoading}
              onClick={handleDisconnectIntegration}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#e74c3c', marginRight: '8px' }}
                  />{' '}
                  {' Desconectando...'}
                </>
              ) : (
                'Desconectar'
              )}
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  )
}

Kigi.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default Kigi
