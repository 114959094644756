import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: 'Roboto', sans-serif;
    color: #263238;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
  }
`

export const GoalGuide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  margin-top: 100px;

  h2 {
    font-size: 18px;
    color: #263238;
  }

  span {
    font-size: 14px;
    color: #546e7a;
    margin-top: 5px;
  }
`

export const GuideTable = styled.div`
  width: 55vw;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  align-items: flex-start;
  gap: 25px;
  margin-top: 20px;

  div {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-size: 14px;
      color: #263238;
      font-weight: 600;
      margin-bottom: 10px;
      width: 100%;
    }

    span {
      margin-bottom: 5px;
    }

    ul {
      text-align: left;
      color: #546e7a;
      font-size: 14px;
      padding-left: 20px;

      li {
        font-weight: 500;
      }
    }
  }
`
