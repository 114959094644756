import { useState } from 'react'

import { Backdrop, Modal } from '@material-ui/core'

import { formatPrice } from 'utils/format'
import PromptModal from '../../../../../../PromptModal'
import Tooltip from 'components/Tooltip'
import { IMonthGoal } from 'store/modules/goal/types'

import {
  ErrorOutline as ErrorOutlineIcon,
  DeleteOutline as DeleteOutlineIcon,
  Person as PersonIcon,
  Storefront as StorefrontIcon,
  Create as CreateIcon,
  Delete as DeleteIcon
} from '@material-ui/icons'

import { Container } from './styles'

interface TableItemProps {
  onDelete: (index: number) => void
  onEdit: () => void
  index: number
  goal: IMonthGoal
}

const TableItem = ({ onDelete, onEdit, index, goal }: TableItemProps) => {
  const [isShowModal, setIsShowModal] = useState(false)

  const {
    complement,
    dsr,
    sumCommission,
    salary,
    bonus,
    commission,
    fixedCommission
  } = goal.initialCommission

  const monthGoalTooltipInfos = {
    ...(fixedCommission?.value && {
      'Comissão base': formatPrice(fixedCommission.value)
    }),
    ...(commission?.value && {
      Comissão: formatPrice(commission.value)
    }),
    ...(dsr && { DSR: formatPrice(dsr) }),
    ...(salary && { Piso: formatPrice(salary) }),
    ...(complement && {
      Complemento: formatPrice(complement)
    }),
    ...(bonus?.value && { Bônus: formatPrice(bonus.value) })
  }

  return (
    <>
      <Container>
        <td>
          <div
            data-tip
            data-for={`goal-${index + 1}-tooltip`}
            style={{ ...(index === 0 && { border: '2px solid #FFC700' }) }}
            className='goal-icon'
          >
            <span>{index + 1}</span>
            <Tooltip id='goal-1-tooltip' content={<p>Meta Principal</p>} />
          </div>
        </td>
        <td>
          <strong>{goal.name}</strong>
        </td>
        <td>{formatPrice(goal.value)}</td>
        <td>
          <div className='goal-percent-comission'>
            <span>{(goal.commission * 100).toFixed(2)}%</span>
            {goal.type === 'user' ? (
              <PersonIcon data-tip data-for={`${index}-user-tooltip`} />
            ) : (
              <StorefrontIcon data-tip data-for={`${index}-store-tooltip`} />
            )}
            <Tooltip
              id={`${index}-${goal.type}-tooltip`}
              place='right'
              content={
                <p>
                  {`${(goal.commission * 100).toFixed(2)}% ${
                    goal.type === 'user'
                      ? 'de suas vendas'
                      : 'do faturamento da loja'
                  }`}
                </p>
              }
            />
          </div>
        </td>
        <td style={{ opacity: goal.bonus === 0 ? 0.5 : 1 }}>
          {goal.bonus > 0
            ? goal.bonusPercentage
              ? (goal.bonus * 100).toFixed(2) + '%'
              : formatPrice(goal.bonus)
            : 'Sem bônus'}
        </td>
        <td id='value' className='commission-cell'>
          <Tooltip
            id={`month-goal-total-${goal._id}`}
            content={
              <div style={{ fontWeight: 'normal' }}>
                <strong
                  style={{
                    display: 'block',
                    marginBottom: '10px',
                    fontSize: '14px'
                  }}
                >
                  Remuneração Total <br />
                </strong>
                {Object.entries(monthGoalTooltipInfos).map(([key, value]) => (
                  <p key={key} style={{ marginBottom: '2px' }}>
                    <strong>{key}: </strong> {value}
                    <br />
                  </p>
                ))}
                <p style={{ marginTop: '10px' }}>
                  <strong>Total: </strong>
                  {formatPrice(sumCommission ?? 0)}
                </p>
              </div>
            }
            place='bottom'
          />
          {formatPrice(sumCommission ?? 0)}

          <ErrorOutlineIcon
            data-tip
            data-for={`month-goal-total-${goal._id}`}
            style={{
              color: '#C4C4C4',
              fontSize: 20,
              marginLeft: 5,
              marginBottom: -5
            }}
          />
        </td>
        <td>
          <div className='table-row-button-container'>
            <button onClick={onEdit} type='button' className='edit'>
              <CreateIcon />
            </button>
            <button
              onClick={() => setIsShowModal(true)}
              type='button'
              className='delete'
            >
              <DeleteIcon />
            </button>
          </div>
        </td>

        {isShowModal && (
          <Modal
            open={isShowModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <PromptModal
              isAlert
              icon={<DeleteOutlineIcon fontSize='large' />}
              onClose={() => setIsShowModal(false)}
              leftTitle='Cancelar'
              onLeft={() => setIsShowModal(false)}
              rightTitle='Remover meta'
              onRight={() => {
                onDelete(index)
                setIsShowModal(false)
              }}
            >
              <span
                style={{ fontSize: 18, textAlign: 'center', marginTop: -10 }}
              >
                Deseja remover a meta <br />
                <strong style={{ textDecoration: 'underline' }}>
                  {goal.name}
                </strong>
                ?
              </span>
            </PromptModal>
          </Modal>
        )}
      </Container>
    </>
  )
}

export default TableItem
