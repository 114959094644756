import React, { useEffect, useState } from 'react'
import { getMonth, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { goalListRequest } from 'store/modules/goal/actions'
import { animateScroll as scroll } from 'react-scroll'

import { guideTable } from './guideTable'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import { Container, GoalGuide, GuideTable } from './styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const MonthStep = ({ data, handleNextStep, storeGoal, canCopyLastGoal }) => {
  const token = localStorage.getItem('@NeoPro:token')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { storeId } = useParams()

  const loading = useSelector(state => state.goal.loading)
  const goalList = useSelector(state => state.goal.goalList) // Usado p/ identificar os meses que já estão com meta

  const [months, setMonths] = useState([]) // Lista de meses que podem criar meta
  const [selectedMonth, setSelectedMonth] = useState(null)

  // HOOKS PARA MODAL DE COPIAR META
  // const [isCopyStoreGoal, setIsCopyStoreGoal] = useState(false);
  // const [storeToCopy, setStoreToCopy] = useState(null)

  // const [isShowModal, setIsShowModal] = useState({
  //   enable: false,
  // });

  // const [optionsToCopy, setOptionsToCopy] = useState({
  //   commission: true,
  //   remuneration: true,
  //   goalPeriod: true,
  //   goalDistribute: true,
  //   workDay: true,
  // })

  const generateMonths = () => {
    const currentMonth = getMonth(new Date())
    const year = new Date().getFullYear()

    const auxMonths = []
    for (let i = currentMonth; i <= currentMonth + 6; i++) {
      const date = new Date(year, i, 1)
      date.setUTCHours(15, 0, 0, 0)

      const isoDate = date.toISOString()

      if (goalList.length > 0) {
        const findMonth = goalList.find(goal => goal.month === isoDate)

        if (!findMonth) auxMonths.push(isoDate)
      } else auxMonths.push(isoDate)
    }
    return auxMonths
  }

  useEffect(() => {
    if (data.month) {
      if (months.indexOf(data.month) < 0) {
        const auxMonths = [...months]
        auxMonths.push(data.month)
        setMonths(auxMonths)
      }
      setSelectedMonth(data.month)
    }

    if (goalList.length === 0) dispatch(goalListRequest(storeId, token))
    scroll.scrollToTop()
  }, [data.month, storeId, token])

  useEffect(() => {
    // gera lista de meses que podem criar meta
    if (goalList) setMonths(generateMonths())
  }, [goalList])

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // AVISO: METADE DO CODIGO TA COMENTADO, MAS NAO EH INUTIL, NAO APAGUE ISSO AQUI PELO AMOR
  // O CODIGO COMENTADO EH DO MODAL DE CÓPIA DE META, QUE POR ENQUANTO TA INATIVA
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  return (
    <Container>
      <h1>A meta é para qual mês?</h1>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='month-select-label'>
          {loading ? (
            <CircularProgress size={20} />
          ) : data?.month || selectedMonth ? (
            format(new Date(data?.month || selectedMonth), 'MMMM - yyyy', {
              locale: ptBR
            })
          ) : (
            'Selecione um Mês'
          )}
        </InputLabel>

        <Select
          labelId='month-select-label'
          id='month-select'
          label='Selecione um Mês'
          disabled={loading || !!data.month}
          value={data.month || selectedMonth || ''}
          onChange={e => setSelectedMonth(e.target.value)}
        >
          {months.map(month => (
            <MenuItem key={month} value={month}>
              {format(new Date(month), 'MMMM - yyyy', { locale: ptBR })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* { (selectedMonth && !data.month) &&
        <button
          style={{margin: '50px auto'}}
          onClick={() => {
            updateGoal({month: selectedMonth})
          }}
          className='stepper-button-next'>
            Avançar
        </button>
      // : (data.month && !goalId) &&
      //   <button
      //     onClick={() => {
      //       setMonths(generateMonths())
      //       !goalId && setDeleteStep(["month"])
      //     }}
      //     className='stepper-button-edit'>
      //       Alterar
      //       <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
      //   </button>
      } */}
      {(selectedMonth || data.month) && (
        <button
          onClick={() => {
            if (!data.month) {
              storeGoal({ ...data, month: selectedMonth })
            } else {
              handleNextStep()
            }
            //handleNextStep(moment(goalList[0]?.month).unix() > moment('2021-08-01T15:00:00.000Z').unix() ? 2 : 1);
            // if (data?.month) {
            //   handleNextStep();
            // } else {
            //   setIsShowModal({enable: true})
            // }
          }}
          style={{ marginLeft: 0 }}
          className='stepper-button-next'
          data-cy='nextStepButton'
        >
          Próxima etapa
          <ArrowForwardIos className='next-button-icon' />
        </button>
      )}

      <GoalGuide>
        <div>
          <h2>O que iremos definir nas próximas etapas?</h2>
          <span>O preenchimento dura em média 4 minutos.</span>
        </div>

        <GuideTable>
          {guideTable.map(el => (
            <div key={el.title}>
              <h3>{el.title}</h3>
              <span>{el.listTitle}</span>
              <ul>
                {el.list.map(listEl => (
                  <li key={listEl}>{listEl}</li>
                ))}
              </ul>
            </div>
          ))}
        </GuideTable>
      </GoalGuide>

      {/*   MODAL PARA COPIAR META DE OUTRA LOJA (desativado por enquanto)  */}
      {/* { (isShowModal.enable && !isShowModal.isAlert) &&
          <Modal
            open={isShowModal.enable}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <PromptModal
              icon={<CopyIcon />}
              onClose={() => setIsShowModal({enable: false})}
              title={'Quer copiar a meta de outra loja?'}
              description={'Você pode copiar as informações da meta definida para outra loja da rede. Ou cadastrar uma meta novinha. Você que manda!'}
              leftPrimary={isCopyStoreGoal}
              leftTitle={isCopyStoreGoal && 'Começar sem copiar'}
              onLeft={() =>
                {
                  isCopyStoreGoal &&
                  updateGoal({month: selectedMonth})
                  handleNextStep();
                }
              }
              rightTitle={isCopyStoreGoal ? 'Copiar meta da loja' : 'Começar sem copiar'}
              onRight={
                () => {
                  updateGoal({month: selectedMonth})
                  handleNextStep();
                  // isCopyStoreGoal && -> enviar para o back o que copiar da loja
                }
              }
            >

            { !isCopyStoreGoal ?
              <FormControl style={{ width: '70%' }} variant='outlined'>
                <InputLabel>Selecione uma loja</InputLabel>
                <Select label='Selecione uma loja'>
                { storeList.map((store, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      setIsCopyStoreGoal(true);
                      setStoreToCopy(store.storeId.name)
                    }} value={store.storeId.name}
                  >
                    {store.storeId.name}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              :
              <div className='copy-store-container'>
                <span
                  onClick={() => setIsCopyStoreGoal(false)}
                  className='copy-store-name'
                >
                  {storeToCopy} <CreateIcon style={{ marginLeft: 5 }} fontSize='small' />
                </span>
                <div className='copy-store-form'>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOptionsToCopy({...optionsToCopy, commission: !optionsToCopy.commission})}
                        checked={optionsToCopy.commission && true}
                        checkedIcon={false}
                        uncheckedIcon={true}
                        handleDiameter={8}
                        height={12}
                        width={25}
                        offColor='#37474F'
                        onColor='#1E2FA9'
                      />
                    }
                    label="Políticas de comissão"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOptionsToCopy({...optionsToCopy, remuneration: !optionsToCopy.remuneration})}
                        checked={optionsToCopy.remuneration && true}
                        checkedIcon={false}
                        uncheckedIcon={true}
                        handleDiameter={8}
                        height={12}
                        width={25}
                        offColor='#37474F'
                        onColor='#1E2FA9'
                      />
                    }
                    label="Informações sobre remuneração"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOptionsToCopy({...optionsToCopy, goalPeriod: !optionsToCopy.goalPeriod})}
                        checked={optionsToCopy.goalPeriod && true}
                        checkedIcon={false}
                        uncheckedIcon={true}
                        handleDiameter={8}
                        height={12}
                        width={25}
                        offColor='#37474F'
                        onColor='#1E2FA9'
                      />
                    }
                    label="Período(s) da meta"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOptionsToCopy({...optionsToCopy, goalDistribute: !optionsToCopy.goalDistribute})}
                        checked={optionsToCopy.goalDistribute && true}
                        checkedIcon={false}
                        uncheckedIcon={true}
                        handleDiameter={8}
                        height={12}
                        width={25}
                        offColor='#37474F'
                        onColor='#1E2FA9'
                      />
                    }
                    label="Distribuição da meta"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOptionsToCopy({...optionsToCopy, workDay: !optionsToCopy.workDay})}
                        checked={optionsToCopy.workDay && true}
                        checkedIcon={false}
                        uncheckedIcon={true}
                        handleDiameter={8}
                        height={12}
                        width={25}
                        offColor='#37474F'
                        onColor='#1E2FA9'
                      />
                    }
                    label="Dias de funcionamento"
                  />
                </div>
              </div>
            }

          </PromptModal>
        </Modal>
      } */}
    </Container>
  )
}

export default MonthStep
