import { useState, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useConfirm } from 'material-ui-confirm'
import { Tabs, Divider, colors, Grid } from '@material-ui/core'
import moment from 'moment'

import CalendarIcon from '@material-ui/icons/CalendarToday'

import Header from 'components/Header'
import {
  goalRequest,
  goalDeleteRequest,
  goalEditMainGoalsRequest
} from 'store/modules/goal/actions'

import ValueCard from 'components/ValueCard'
import { formatPrice, formatPriceShort } from '../../utils/format'
import Goals from './components/Goals'
import EditGoalModal from './components/EditGoalModal'
import { Details } from './components/Details'
import Sellers from './components/Sellers'
import Days from './components/Days'
import Sections from './components/Sections'
import { AttachMoney, DateRange, Today } from '@material-ui/icons'
import { People, FactCheckOutlined } from '@mui/icons-material'
import { TabOption } from './styles'
import { IGoal, IGoalProps } from 'store/modules/goal/types'
import { History } from 'history'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'red'
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(1)
  }
}))

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface GoalProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
      userId: string | undefined
      id: string
    }
    path: string
    url: string
  }
  history: History
}

const Goal = ({ match, history }: GoalProps) => {
  const { id, userId, storeId } = match.params
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const {
    goalList,
    currentGoal,
    loading,
    goal: requestedGoal
  } = useSelector<SelectorGoal, IGoalProps>(state => state.goal)

  const token = window.localStorage.getItem('@NeoPro:token')

  const [goal, setGoal] = useState<IGoal>()
  const [newUser, setNewUser] = useState<string | undefined>(undefined)

  const [tab, setTab] = useState<string>('details')
  const [visibleEditGoal, setVisibleEditGoal] = useState(false)

  useEffect(() => {
    // const listFind = goalList.find(g => g._id === id)
    // if (listFind) {
    //   setGoal(listFind)
    // } else
    if (requestedGoal && requestedGoal._id === id) {
      setGoal(requestedGoal)
    } else if (currentGoal && currentGoal._id === id) {
      setGoal(currentGoal)
    } else if (id) {
      dispatch(goalRequest(id, token))
    }
  }, [goalList, requestedGoal])

  useEffect(() => {
    setVisibleEditGoal(false)
  }, [goal])

  useEffect(() => {
    dispatch(goalRequest(id, token))
  }, [newUser])

  useEffect(() => {
    setNewUser(userId)
    if (userId) {
      setTab('sellers')
    }
  }, [userId])

  const tabs = [
    {
      icon: <FactCheckOutlined />,
      value: 'details',
      label: 'Detalhes',
      dataCy: 'goalDetailsButton'
    },
    {
      icon: <AttachMoney />,
      value: 'goals',
      label: 'Metas',
      dataCy: 'goalTabLabelButton'
    },
    {
      icon: <People />,
      value: 'sellers',
      label: 'Vendedores',
      dataCy: 'sellersTabLabelButton'
    },
    {
      icon: <Today />,
      value: 'days',
      label: 'Dias',
      dataCy: 'daysTabLabelButton'
    },
    {
      icon: <DateRange />,
      value: 'sections',
      label: 'Períodos',
      dataCy: 'sectionTabLabelButton'
    }
  ]

  const handleDeleteGoal = () => {
    confirm({
      title: `Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`,
      description:
        'Tem certeza que deseja apagar a meta? Os lançamentos desse mês serão mantidos.',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        goal &&
          dispatch(
            goalDeleteRequest(
              goal._id,
              window.localStorage.getItem('@NeoPro:token')
            )
          )
        history.push(`/${storeId}/goals`)
      })
      .catch(() => {})
  }

  return (
    <div className={classes.root}>
      <Header
        data={goal}
        goal
        route={`/${storeId}/goals`}
        title='Metas'
        actionText='Apagar'
        subtitle={`Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`}
        value={`${goal ? formatPrice(goal.mainGoals.total) : 0}`}
        handleDelete={handleDeleteGoal}
        secondaryText='Editar'
        handleSecondary={() => history.push(`/${storeId}/goals/${id}/edit`)}
        dataCy='deleteGoalButton'
        secondaryDataCy='editGoalButton'
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Meta da loja'
            value={`${goal ? formatPrice(goal.mainGoals.total) : 0}`}
            color='#2A3ECB'
            valueDataCy='storeGoalValueH3'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Ticket médio'
            value={`${
              goal && goal.mainGoals.ticket >= 1000
                ? formatPriceShort(goal.mainGoals.ticket)
                : formatPrice(goal?.mainGoals.ticket ?? 0)
            }`}
            valueDataCy='averageTicketGoalH3'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Preço médio'
            value={`${
              goal && goal.mainGoals.price >= 1000
                ? formatPriceShort(goal.mainGoals.price)
                : formatPrice(goal?.mainGoals.price ?? 0)
            }`}
            valueDataCy='averagePriceGoalH3'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='DSR'
            value={goal ? goal.dsr : 0}
            icon={<CalendarIcon />}
            valueDataCy='dsrGoalH3'
          />
        </Grid>
      </Grid>
      <Tabs
        className={classes.tabs}
        onChange={(_, value) => setTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
        indicatorColor='primary'
      >
        {tabs.map(tab => (
          <TabOption
            icon={tab.icon}
            iconPosition='start'
            key={tab.value}
            label={tab.label}
            value={tab.value}
            data-cy={tab.dataCy}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        {tab === 'goals' && <Goals goal={goal} />}
        {tab === 'details' && <Details goal={goal} />}
        {tab === 'sellers' && (
          <Sellers goal={goal} newUser={newUser} loading={loading} />
        )}
        {tab === 'days' && <Days goal={goal} />}
        {tab === 'sections' && <Sections sections={goal?.sections} />}
      </div>

      {/* <EditGoalModal
        active={visibleEditGoal}
        onClose={() => setVisibleEditGoal(false)}
        goal={goal}
        onSave={(data) =>
          goal && dispatch(goalEditMainGoalsRequest(goal._id, data, token))
        }
      /> */}
    </div>
  )
}

export default Goal
