import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { Avatar } from '@material-ui/core'
import { Box, Grid } from '@mui/material'
import { toast } from 'react-toastify'
import { BiFileFind, BiTrash } from 'react-icons/bi'
import { FaCamera } from 'react-icons/fa'

import { convertFileSizeName } from 'utils/convertFileSizeName'

import {
  Button,
  Card,
  IconButton,
  IconContainer,
  IconLabel,
  ImagePreviewContainer,
  MediaInfoCard
} from './styles'

interface ThumbnailProps {
  firstStoryDataImage: string | null
  file: File | null
  setFile: Dispatch<SetStateAction<File | null>>
}

export const Thumbnail = ({
  file,
  firstStoryDataImage,
  setFile
}: ThumbnailProps) => {
  const [previewUrlImage, setPreviewUrlImage] = useState('')

  const fileExtension =
    file?.name?.split('.')[file?.name?.split('.').length - 1] ?? ''
  const fileName = file?.name?.substring(0, 14) ?? ''

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    const hasFiles = files && files.length > 0

    if (hasFiles) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      const fileType = files[0].type

      const isImage =
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg'

      if (isImage)
        if (fileSize <= 2) {
          const previewURL = URL.createObjectURL(files[0])

          setFile(files[0])
          setPreviewUrlImage(previewURL)
        } else {
          toast.error(
            'Imagem muito grande! A imagem deve ser menor ou igual a 2MB.'
          )
        }
    }
  }

  const handleRemoveMedia = () => {
    setFile(null)
    setPreviewUrlImage('')
  }

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }
  return (
    <Grid item xs={12} md={12}>
      <Card variant='outlined'>
        <IconLabel>Ícone</IconLabel>
        <Box>
          <IconContainer>
            {previewUrlImage || firstStoryDataImage ? (
              <ImagePreviewContainer>
                <img
                  src={
                    previewUrlImage
                      ? previewUrlImage
                      : firstStoryDataImage || undefined
                  }
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                />
                {!!file && (
                  <MediaInfoCard>
                    <div>
                      {fileName +
                        ((file?.name?.length ?? 0) > 19
                          ? `..${fileExtension}`
                          : '')}
                      <span>{convertFileSizeName(file)}</span>
                    </div>
                    <div>
                      <IconButton htmlFor='icon-tag-image'>
                        <BiFileFind size={24} />
                      </IconButton>
                      <IconButton onClick={handleRemoveMedia}>
                        <BiTrash size={24} />
                      </IconButton>
                    </div>
                  </MediaInfoCard>
                )}
              </ImagePreviewContainer>
            ) : (
              <Avatar style={{ cursor: 'pointer' }}>
                <FaCamera style={{ width: 18, height: 18 }} />
              </Avatar>
            )}
            {!previewUrlImage && (
              <Button htmlFor='icon-tag-image'>Enviar</Button>
            )}
            <label htmlFor='icon-tag-image'>
              <input
                style={{ display: 'none' }}
                type='file'
                id='icon-tag-image'
                accept='image/png, image/jpg, image/jpeg'
                onChange={handleChange}
                onClick={onInputClick}
              />
            </label>
          </IconContainer>
        </Box>
      </Card>
    </Grid>
  )
}
