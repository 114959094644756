import styled from 'styled-components'

export const Container = styled.aside`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2rem;

  font-size: 0.875rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #9a9ab1;
`
