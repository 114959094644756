import styled from 'styled-components'
import DayPicker from 'react-day-picker'
import CurrencyTextField from '../../../../components/CurrencyTextField'

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  margin-top: -10px;
  /* background-color: #fff; */
  padding: 6px 4px;
  border-radius: 4px;
  /* border: 1px solid #c4c4c4; */
`

export const Picker = styled(DayPicker)`
  font-family: 'Roboto';

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-Day--outside {
    color: #e1e1e1 !important;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #fff !important;
  }

  .DayPicker-Day--selected {
    background-color: #e7f3fd !important;
    color: #4a90e2 !important;
  }

  .DayPicker-Day--unUtilDay {
    color: #c1c1c1 !important;
  }

  .DayPicker-Day--start {
    background-color: #4a90e2 !important;
    color: white !important;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
    pointer-events: none;
  }

  .DayPicker-Day--end,
  .DayPicker-Day--hoverDay {
    background-color: #4a90e2 !important;
    color: white !important;
    border-radius: 0px 30px 30px 0px !important;
    font-weight: 600;
    cursor: pointer;
  }

  .DayPicker-Day--to {
    background-color: #4a90e2 !important;
    color: white;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
  }

  .DayPicker-Day--from {
    background-color: #4a90e2 !important;
    color: white;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
  }

  .DayPicker-Day--startEnd {
    border-radius: 50% !important;
  }
  .DayPicker-NavButton {
    margin-top: -14px;
  }

  .DayPicker-Weekdays {
    border-bottom: 1px solid #eeeeee;
  }

  .DayPicker-Weekday {
    color: #546e7a;
    font-weight: 500;
    font-size: 14px !important;
  }

  .DayPicker-Day {
    font-family: 'Roboto', sans-serif !important;
    border-radius: 0px;
    width: 25px;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    color: #e1e1e1 !important;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #fff !important;
  }
`

export const CustomCurrencyInput = styled(CurrencyTextField)``
