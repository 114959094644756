import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination
} from '@material-ui/core'
import { Container, VisiblePerfectScrollbar } from './styles'
import { Avatar } from '@mui/material'
import { differenceInDays, format } from 'date-fns'
import { SportsScore as SportsScoreIcon } from '@mui/icons-material'
import { ChangeEvent, useState } from 'react'
import { ISprint, ISprintMetadata } from 'store/modules/sprints/types'
import { useDispatch } from 'react-redux'
import { getFinishedSprintsRequest } from 'store/modules/sprints/actions'
import { formatPrice } from 'utils/format'

interface SprintsTableProps {
  sprints: ISprint[]
  tableTitle: string
  storeId?: string
  metadata: ISprintMetadata
  isLoading: boolean
  openEditModal?: (sprint: ISprint) => void
}

export const SprintsTable = ({
  sprints,
  tableTitle,
  metadata,
  storeId,
  isLoading,
  openEditModal
}: SprintsTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  const headCells = [
    { id: 'description', label: 'Item', minWidth: 275 },
    { id: 'goal', label: 'Meta', minWidth: 125 },
    { id: 'reward', label: 'Prêmio', minWidth: 125 },
    { id: 'startDate', label: 'Início', minWidth: 50 },
    { id: 'endDate', label: 'Encerramento (duração)', minWidth: 200 }
  ]

  const handleChangePage = (newPage: number) => {
    if (storeId) {
      dispatch(
        getFinishedSprintsRequest(storeId, token, newPage + 1, rowsPerPage)
      )
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (storeId) {
      const newRowsPerPage = Number(event.target.value)
      dispatch(getFinishedSprintsRequest(storeId, token, 1, newRowsPerPage))
      setRowsPerPage(newRowsPerPage)
      setPage(0)
    }
  }

  return (
    <Container>
      <h1>{tableTitle}</h1>
      <Paper style={{ marginBottom: '32px' }}>
        <TableContainer>
          <VisiblePerfectScrollbar>
            <Table aria-labelledby='tableTitle' aria-label='enhanced table'>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      style={{ minWidth: headCell.minWidth }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(metadata.pagination.totalCount > 0 || !storeId) &&
                sprints.length > 0 ? (
                  sprints.map(sprint => {
                    const diffInDaysBetweenStartAndEnd =
                      differenceInDays(
                        new Date(sprint.endDate),
                        new Date(sprint.startDate)
                      ) + 1
                    return (
                      <TableRow
                        hover
                        onClick={() => openEditModal && openEditModal(sprint)}
                        tabIndex={-1}
                        key={sprint._id}
                        style={{
                          cursor:
                            tableTitle !== 'Encerradas' ? 'pointer' : 'default'
                        }}
                        data-cy='store-row'
                      >
                        <TableCell
                          scope='row'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          <Avatar
                            src={sprint.photo?.url}
                            sx={{
                              width: '40px',
                              height: '40px'
                            }}
                          >
                            {!sprint.photo?.url && <SportsScoreIcon />}
                          </Avatar>
                          {sprint.title}
                        </TableCell>
                        <TableCell>
                          {sprint.goalType === 'financial'
                            ? formatPrice(Number(sprint.goal))
                            : sprint.goal}
                        </TableCell>
                        <TableCell>
                          {sprint.rewardType === 'money'
                            ? formatPrice(Number(sprint.reward))
                            : sprint.reward}
                          {sprint.rewardType === 'points' && ' pontos'}
                        </TableCell>
                        <TableCell>
                          {format(new Date(sprint.startDate), 'dd/MM/yyyy')}
                        </TableCell>
                        <TableCell>
                          {format(new Date(sprint.endDate), 'dd/MM/yyyy')}
                          <p
                            style={{
                              display: 'inline',
                              paddingLeft: '6px'
                            }}
                          >
                            ({diffInDaysBetweenStartAndEnd + ' '}
                            {diffInDaysBetweenStartAndEnd > 1 ? 'dias' : 'dia'})
                          </p>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                      {isLoading && metadata.pagination.totalCount < 0
                        ? 'Carregando...'
                        : storeId
                        ? 'Sem corridinhas encerradas cadastradas.'
                        : 'Sem corridinhas ativas cadastradas.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </VisiblePerfectScrollbar>
        </TableContainer>
        {tableTitle === 'Encerradas' && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={metadata?.pagination.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={event => handleChangeRowsPerPage(event)}
          />
        )}
      </Paper>
    </Container>
  )
}
