import { Grid, List } from '@mui/material'
import { Item } from './Item'

import { formatPrice } from '../../../../utils/format'
import { IGoal } from 'store/modules/goal/types'
interface DetailsProps {
  goal: IGoal | undefined
}

interface ItemProps {
  title: string
  text: string | number
}

export const Details = ({ goal }: DetailsProps) => {
  const firstColumnItems: ItemProps[] = [
    {
      title: 'Tipo de comissão',
      text: goal?.salary.directCommission
        ? 'Comissão direta'
        : 'Piso salarial + comissão'
    },
    {
      title: 'Período da meta',
      text:
        goal && goal.sections.length > 1
          ? `Vários períodos (${goal.sections.length})`
          : 'Período único'
    },
    {
      title: 'Base da comissão',
      text: goal?.salary.splitCommission
        ? 'Resultado por período'
        : 'Resultado por mês'
    },
    {
      title: 'Distribuição da meta',
      text: goal?.distribute ? 'Meta distribuída' : 'Meta fixa'
    },
    {
      title: 'Recebimento da comissão',
      text: goal?.config.commission.storeSold
        ? 'Total da loja'
        : 'Meta individual'
    }
  ]

  const secondColumnItems: ItemProps[] = [
    {
      title: 'Meta da loja',
      text: formatPrice(goal?.mainGoals.total ?? 0)
    },
    {
      title: 'Meta de ticket médio',
      text: formatPrice(goal?.mainGoals.ticket ?? 0)
    },
    {
      title: 'Meta de preço médio',
      text: formatPrice(goal?.mainGoals.price ?? 0)
    }
  ]

  const thirdColumnItems: ItemProps[] = [
    {
      title: 'Dias da loja aberta',
      text:
        goal?.days.reduce((acc, curr) => {
          return curr.working ? (acc += 1) : acc
        }, 0) ?? 0
    },
    {
      title: 'DSR',
      text: goal?.dsr ?? 0
    },
    {
      title: 'Vendedores ativos',
      text:
        goal?.usersWorking.reduce((acc, curr) => {
          return curr.active ? (acc += 1) : acc
        }, 0) ?? 0
    },
    {
      title: 'Metas individuais',
      text: goal?.monthGoals.length ?? 0
    }
  ]
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item width='100%'>
        <Grid
          container
          justifyContent='space-between'
          spacing={2}
          borderRadius={4}
        >
          <Grid item flex={1}>
            <List sx={{ bgcolor: 'background.paper', width: '330px' }}>
              {firstColumnItems.map(item => (
                <Item title={item.title} text={item.text} key={item.title} />
              ))}
            </List>
          </Grid>
          <Grid item flex={1}>
            <List sx={{ bgcolor: 'background.paper', width: '330px' }}>
              {secondColumnItems.map(item => (
                <Item title={item.title} text={item.text} key={item.title} />
              ))}
            </List>
          </Grid>
          <Grid item flex={1}>
            <List sx={{ bgcolor: 'background.paper', width: '330px' }}>
              {thirdColumnItems.map(item => (
                <Item title={item.title} text={item.text} key={item.title} />
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
