import styled from 'styled-components'

interface PickerProps {
  disabled: boolean
}

export const Picker = styled.div<PickerProps>`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  position: relative;

  .current-section-length {
    position: absolute;
    font-size: 13px;
    background: #faffbf;
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
    font-weight: 600;
  }

  .Range {
    .DayPicker-Month {
      margin: 0;
    }

    .DayPicker-Day--outside {
      color: #e3e3e3 !important;
      cursor: not-allowed;
      pointer-events: none;
      background-color: #fff !important;
    }

    .DayPicker-Day--selected {
      background-color: #e7f3fd;
      color: #4a90e2;
    }

    .DayPicker-Day--unUtilDay {
      color: #c1c1c1 !important;
    }

    .DayPicker-Day--start {
      background-color: #4a90e2 !important;
      color: white !important;
      border-radius: 30px 0px 0px 30px !important;
      font-weight: 600;
      pointer-events: none;
    }

    .DayPicker-Day--end,
    .DayPicker-Day--hoverDay {
      background-color: #4a90e2 !important;
      color: white !important;
      border-radius: 0px 30px 30px 0px !important;
      font-weight: 600;
      cursor: pointer;
    }

    .DayPicker-Day--to {
      background-color: #4a90e2 !important;
      color: white;
      border-radius: 30px 0px 0px 30px !important;
      font-weight: 600;
    }

    .DayPicker-Day--from {
      background-color: #4a90e2 !important;
      color: white;
      border-radius: 30px 0px 0px 30px !important;
      font-weight: 600;
    }

    .DayPicker-Caption,
    .DayPicker-NavButton {
      display: none !important;
    }

    .DayPicker-Weekdays {
      border-bottom: 1px solid #eeeeee;
    }

    .DayPicker-Weekday {
      color: #546e7a;
      font-weight: 500;
      font-size: 14px !important;
    }

    .DayPicker-Day {
      font-family: 'Roboto', sans-serif !important;
      border-radius: 0px;
      width: 25px;
    }

    .DayPicker-Day--today {
      font-weight: normal;
    }

    .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
      background-color: #e7f3fd !important;
      color: #4a90e2 !important;
    }
  }
`
