import React from 'react'

import { Container } from './styles'
import { formatPrice } from 'utils/format'

import Tooltip from 'components/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

function MainGoalInfo({ title, value, isGoalPrice, isPrice }) {
  return (
    <Container>
      <div className='maingoal-info-option-title'>
        <span>{title}</span>
        {!isPrice && (
          <>
            <InfoOutlinedIcon
              data-tip
              data-for='goal-indicator-tooltip'
              fontSize='small'
            />
            <Tooltip
              id='goal-indicator-tooltip'
              content={
                <p style={{ textTransform: 'none' }}>
                  Indicador estimado para a meta
                </p>
              }
            />
          </>
        )}
      </div>
      <span
        style={{
          opacity: value <= 0 ? '0.5' : '1',
          fontSize: isGoalPrice ? '24px' : '18px'
        }}
        className={isPrice ? 'option-price' : 'option-quantity'}
      >
        {isPrice
          ? value > 0
            ? formatPrice(value)
            : 'R$ 0.000,00'
          : value > 0
          ? value
          : '000'}
      </span>
    </Container>
  )
}

export default MainGoalInfo
