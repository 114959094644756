import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Box, Grid, Switch } from '@mui/material'

import { CustomICreateStoryline } from '../..'

import { Card } from './styles'

interface SwitchPermanentStorylineProps {
  type: 'fixed' | 'temporary'
  setStoryData: Dispatch<SetStateAction<CustomICreateStoryline>>
}

export const SwitchPermanentStory = ({
  setStoryData,
  type
}: SwitchPermanentStorylineProps) => {
  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) =>
    setStoryData(previousState => ({
      ...previousState,
      type: event.target.checked ? 'fixed' : 'temporary'
    }))

  return (
    <Grid item xs={12} md={12}>
      <Card variant='outlined'>
        <Switch
          value={type === 'fixed'}
          onChange={handleSwitch}
          style={{
            color: '#364AD3'
          }}
        />

        <Box marginLeft={2} fontFamily='Roboto, sans-serif'>
          <b style={{ fontSize: '1rem' }}>Fixar comunicado</b>
          <p style={{ fontSize: '0.875rem', color: '#ADADBD' }}>
            Este ficará visível indefinidamente
          </p>
        </Box>
      </Card>
    </Grid>
  )
}
