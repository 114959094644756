import { ChangeEvent } from 'react'

import { TextField } from '@material-ui/core'
import { addDays, addMonths, differenceInDays, format } from 'date-fns'

import { CustomICreateStoryline, SetStoryFieldValueFunctionProps } from '../..'

import { CalendarContainer } from './styles'

interface CalendarProps {
  storyData: CustomICreateStoryline
  setNewSprintFieldValue: ({
    data,
    field
  }: SetStoryFieldValueFunctionProps) => void
}

export const Calendar = ({
  setNewSprintFieldValue,
  storyData
}: CalendarProps) => {
  const formatDateWithNoTimezone = (value: string) => {
    // Por n ter o timezone ele zoa o dia jogando pra um dia a menos
    // dependendo da hora que o usuário ta mexendo
    // Esse workaround aqui evita esse tipo de problema

    const newDate = new Date(
      new Date(value).getTime() +
        Math.abs(new Date(value).getTimezoneOffset() * 60000)
    )

    return newDate.toString()
  }

  const handleChangeStorylineStartDate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newDate = formatDateWithNoTimezone(event.target.value)

    setNewSprintFieldValue({
      field: 'period.start',
      data: newDate
    })

    if (storyData.period.end) {
      const diffBetweenStartAndEndDates = differenceInDays(
        new Date(storyData.period.end),
        new Date(newDate)
      )

      const isStartDateOverOrEqualToEndDate = diffBetweenStartAndEndDates <= 0
      const isEndDateOverMaxDaysLimit = diffBetweenStartAndEndDates > 13

      // Previne o usuário de colocar um início depois do encerramento
      if (isStartDateOverOrEqualToEndDate) {
        setNewSprintFieldValue({
          field: 'period.end',
          data: addDays(new Date(newDate), 1).toString()
        })
      }
      // Previne o usuário de colocar um final maior que o permitido
      else if (isEndDateOverMaxDaysLimit) {
        setNewSprintFieldValue({
          field: 'period.end',
          data: addDays(new Date(newDate), 13).toString()
        })
      }
    }
  }

  const handleChangeStorylineEndDate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inTemporaryStory = storyData.type === 'temporary'

    if (inTemporaryStory && storyData.period.end) {
      const newDate = formatDateWithNoTimezone(event.target.value)

      setNewSprintFieldValue({
        field: 'period.end',
        data: newDate
      })
    }
  }

  return (
    <CalendarContainer>
      <TextField
        label='Início divulgação'
        type='date'
        fullWidth
        variant='outlined'
        margin='normal'
        id='start'
        onChange={handleChangeStorylineStartDate}
        value={format(new Date(storyData.period.start), 'yyyy-MM-dd')}
        onFocus={event => event.target.select()}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: format(new Date(), 'yyyy-MM-dd'),
          max: format(addMonths(new Date(), 1), 'yyyy-MM-dd')
        }}
      />

      {storyData.type === 'temporary' && storyData.period.end && (
        <TextField
          label='Último dia'
          type='date'
          fullWidth
          variant='outlined'
          margin='normal'
          id='end'
          onChange={handleChangeStorylineEndDate}
          value={format(new Date(storyData.period.end), 'yyyy-MM-dd')}
          onFocus={event => event.target.select()}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: format(new Date(storyData.period.start), 'yyyy-MM-dd'),
            max: format(
              addDays(new Date(storyData.period.start), 13),
              'yyyy-MM-dd'
            )
          }}
        />
      )}
    </CalendarContainer>
  )
}
