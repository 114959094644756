import { useState, useRef } from 'react'
import moment from 'moment'

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

// Components
import InputsTable from '../../components/InputsTable'
import { Toolbar, AddInput } from '../../components'
import { toast } from 'react-toastify'
import Tooltip from '@material-ui/core/Tooltip'

// Redux
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import { inputListRequest } from '../../store/modules/input/actions'
import { storeRequest } from '../../store/modules/store/actions'
import { Button } from '@material-ui/core'
import { Sync } from '@material-ui/icons'
import api from 'repositories/api'
import { IInputProps } from 'store/modules/input/types'
import { IStoreProps } from 'store/modules/store/types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface InputProps {
  match: {
    isExact: boolean
    params: {
      storeId: string
    }
    path: string
    url: string
  }
}

export default function Inputs({ match }: InputProps) {
  const dispatch = useDispatch()
  const classes = useStyles()

  // Input - Redux
  const { inputList, metadata } = useSelector<SelectorInput, IInputProps>(
    state => state.input
  )

  // Store - Redux
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  const { storeId } = match.params
  const token = window.localStorage.getItem('@NeoPro:token')

  const [loading, setLoading] = useState(false)

  const onRefresh = () => {
    dispatch(inputListRequest(storeId, token))
    dispatch(storeRequest(storeId, token))
  }

  const handleChangePage = (page: number) => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(
        inputListRequest(storeId, token, page + 1, metadata.pagination.limit)
      )
    }
  }

  const StyledButton = withStyles({
    root: {
      background: 'primary',
      borderRadius: 3,
      border: 5,
      color: 'white',
      height: 35,
      padding: '0 10px',
      marginRight: '8px',
      boxShadow: '0 3px 5px 2px #00000010'
    },
    label: {
      color: 'white'
    }
  })(Button)

  const lastRequest = useRef<NodeJS.Timeout | null>(null)
  const handleSync = () => {
    setLoading(true)
    if (lastRequest.current) clearTimeout(lastRequest.current)
    lastRequest.current = setTimeout(() => {
      setLoading(false)
    }, 10000)
    if (store?.integration) {
      const lastUpdateDate = new Date(store.integration.lastUpdate?.date)
      const now = new Date()
      if (!lastUpdateDate.getTime() || now.getTime() - lastUpdateDate.getTime() > 900_000) {
        // Mais de 15 minutos desde a última solicitação
        api.axios
          .get(`/integration/sync/store/${storeId}/`)
          .then(async res => {
            if (res.data.requested) {
              await api.axios.get(`/report/store/${storeId}/query?update=true`)
              toast.info(
                'Sincronização solicitada com sucesso. Aguarde alguns minutos para que os dados sejam atualizados.'
              )
            } else {
              throw new Error()
            }
          })
          .catch(err => {
            console.log(err)
            toast.error(
              'Erro ao solicitar sincronização. Tente novamente mais tarde.'
            )
          })
      } else {
        toast.info(
          'Sincronização já solicitada recentemente. Aguarde alguns minutos para que os dados sejam atualizados.'
        )
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Toolbar onRefresh={onRefresh}>
          {store?.integration?.provider && (
            <Tooltip
              title={
                <>
                  <h2>Última sincronização: </h2>
                  <h3>
                    <strong>
                      {moment(
                        store?.integration?.lastUpdate?.date ?? null
                      ).format('DD/MM - HH:mm:ss')}
                    </strong>
                  </h3>
                </>
              }
              placement='bottom'
            >
              <StyledButton
                color='secondary'
                variant='contained'
                onClick={handleSync}
                disabled={loading}
              >
                <Sync />
                Solicitar sincronização
              </StyledButton>
            </Tooltip>
          )}
          {store?.integration?.provider && <AddInput />}
        </Toolbar>
        <InputsTable
          inputs={inputList}
          storeId={storeId}
          onChangePage={handleChangePage}
          metadata={metadata}
        />
      </div>
    </div>
  )
}
