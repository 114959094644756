import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import moment from 'moment'
import api from '../../../repositories/api'

import {
  goalListSuccess,
  currentGoalSuccess,
  monthGoalSuccess,
  currentGoalFailed
} from './actions'
import { requestFailed } from '../error/action'

export function* goal({ payload }) {
  try {
    const { goalId, token } = payload
    const response = yield call(api.axios.get, `/goal/${goalId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const formattedDays = response.data.days
      .map(day => {
        const users = day.users.map(user => {
          const dayOffWithoutUpdatedAtProp = {
            enable: user.dayOff.enable
          }

          return {
            userId: user.userId,
            dayOff: dayOffWithoutUpdatedAtProp
          }
        })

        return { ...day, users }
      })
      .sort((a, b) => a.date.localeCompare(b.date))

    const newResponse = { ...response.data, days: formattedDays }

    yield put(monthGoalSuccess(newResponse))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* goalList({ payload }) {
  try {
    const { storeId, token, page, limit } = payload
    const response = yield call(api.axios.get, `/goal/store/${storeId}`, {
      params: {
        page,
        limit
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(goalListSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getMonthGoal({ payload }) {
  try {
    const { token, storeId, date } = payload
    const month = moment(date)
    month.set({
      date: 1,
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    })

    // Busca pelas metas no servidor
    const response = yield call(api.axios.get, `goal/store/${storeId}`, {
      params: {
        limit: 1,
        month: month.toISOString()
      },
      headers: { Authorization: `Bearer ${token}` }
    })

    if (response.data.metadata.pagination.totalCount > 0) {
      yield put(monthGoalSuccess(response.data.items[0]))
    } else {
      requestFailed({
        error: {
          friendlyMsg: 'Meta não encontrada.'
        }
      })
    }
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getCurrentGoal({ payload }) {
  try {
    const { token, storeId } = payload
    const todayDate = moment()
    todayDate.set({
      date: 1,
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    })

    // Busca pelas metas no servidor
    const response = yield call(api.axios.get, `goal/store/${storeId}`, {
      params: {
        limit: 1,
        month: todayDate.toISOString()
      },
      headers: { Authorization: `Bearer ${token}` }
    })

    if (
      response.data.metadata.pagination.totalCount > 0 &&
      response.data.items[0].config.active
    ) {
      yield put(currentGoalSuccess(response.data.items[0]))
    } else {
      yield put(currentGoalFailed())
    }
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* goalUpdateUsers({ payload }) {
  try {
    const { users, goalId, token } = payload
    const response = yield call(
      api.axios.put,
      `/goal/${goalId}/users`,
      { users },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    const updatedGoal = {
      ...response.data,
      storeId:
        typeof response.data.storeId === 'string'
          ? response.data.storeId
          : response.data.storeId._id
    }
    yield put(monthGoalSuccess(updatedGoal))
    toast.success('Meta alterada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* goalDelete({ payload }) {
  try {
    const { goalId, token } = payload
    yield call(api.axios.delete, `/goal/${goalId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    toast.success('A Meta foi deletada com sucesso!')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* goalEditMainGoals({ payload }) {
  try {
    const { goalId, data, token } = payload
    const response = yield call(api.axios.put, `/goal/${goalId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(monthGoalSuccess(response.data))
    toast.success('Meta alterada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@goal/GOAL_REQUEST', goal),
  takeLatest('@goal/LIST_REQUEST', goalList),
  takeLatest('@goal/CURRENT_REQUEST', getCurrentGoal),
  takeLatest('@goal/MONTH_REQUEST', getMonthGoal),
  takeLatest('@goal/UPDATE_USERS', goalUpdateUsers),
  takeLatest('@goal/DELETE_REQUEST', goalDelete),
  takeLatest('@goal/EDIT_MAINGOALS_REQUEST', goalEditMainGoals)
])
