import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Libs
import { toast } from 'react-toastify'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    height: 270,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  avatarImage: {
    height: 150,
    width: 150,
    borderRadius: 250,
    marginBottom: theme.spacing(2)
  }
}))

const AvatarInput = ({
  className,
  image,
  title,
  subTitle,
  handleSubmitImage,
  ...rest
}) => {
  const classes = useStyles()
  const [preview, setPreview] = useState(image && image)

  useEffect(() => setPreview(image), [image])

  const handleChange = e => {
    const data = new FormData()
    if (e.target.files.length > 0) {
      var fileSize = (e.target.files[0].size / 1024 / 1024).toFixed(4) // MB
      if (fileSize < 5) {
        data.append('photo', e.target.files[0])
        handleSubmitImage(data)
      } else {
        toast.error('Imagem muito grande! A imagem deve ser menor que 5MB.')
      }
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <label htmlFor='avatar' className={classes.container}>
        {/* <Button
          variant="contained"
          component="label"
          style={{ marginTop: '1rem' }}
        >
          Atualizar foto

        </Button> */}
        <Badge
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={<PhotoCamera fontSize='large' color='primary' />}
        >
          <Avatar
            className={classes.avatarImage}
            src={preview}
            alt='Imagem de perfil'
          />
        </Badge>
        <input
          style={{ display: 'none' }}
          type='file'
          id='avatar'
          accept='.png, .jpg, .jpeg'
          onChange={handleChange}
        />
      </label>
      <Typography variant='h3'>{title}</Typography>
      <Typography variant='body1'>{subTitle}</Typography>
    </Card>
  )
}

AvatarInput.propTypes = {
  image: PropTypes.string,
  handleSubmitImage: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export default AvatarInput
