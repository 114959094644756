import { useEffect } from 'react'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import jwt from 'jwt-decode'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { listGroupRequest } from 'store/modules/group/actions'
import { storesReportRequest } from 'store/modules/report/actions'

export const GroupsTable = ({ openEditModal }) => {
  const dispatch = useDispatch()

  const { groups, groupsFromReport } = useSelector(state => state.group)
  const { hasRequested } = useSelector(state => state.report)

  const token = localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (!hasRequested && token) {
      const user = jwt(token)
      dispatch(storesReportRequest(user.id, token))
    }
  }, [hasRequested, dispatch, token])

  useEffect(() => {
    if (!groups.length > 0) {
      dispatch(listGroupRequest(token))
    }
  }, [groups, token, dispatch])

  const groupsWithData = groups
    .map(({ _id }) => groupsFromReport.find(({ groupId }) => groupId === _id))
    .filter(el => el !== undefined)

  return (
    <Card style={{ marginTop: '32px' }}>
      <CardContent style={{ padding: 0 }}>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Regional</TableCell>
                  <TableCell style={{ minWidth: 250 }}>Responsável</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupsWithData.length > 0 ? (
                  groupsWithData.map(group => (
                    <TableRow
                      hover
                      key={group.groupId}
                      selected={false}
                      onClick={() => openEditModal(group)}
                      style={{ cursor: 'pointer' }}
                      data-cy='input-table-row'
                    >
                      <TableCell>
                        <b>{group.name}</b>

                        <TableCell
                          component='div'
                          style={{ borderBottom: 'none' }}
                        >
                          <b>Lojas: </b>
                          {group.stores.map(
                            (store, index) =>
                              `${store.name}${
                                index < group.stores.length - 1 ? ',' : ''
                              } `
                          )}
                        </TableCell>
                      </TableCell>

                      <TableCell>{group?.supervisor?.name}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan='5' style={{ textAlign: 'center' }}>
                      {groups ? 'Sem regionais cadastradas.' : 'Carregando...'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}
