import { Action } from 'redux'
import produce from 'immer'
import moment from 'moment'
import { IInputProps } from './types'

const INITIAL_STATE: IInputProps = {
  input: null,
  inputList: [],
  loading: false,
  modal: false,
  lastUpdate: -1,
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 35
    },
    sorting: '-date'
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function input(
  state = INITIAL_STATE,
  { payload, type }: ActionProps
) {
  return produce(state, draft => {
    switch (type) {
      case '@input/REQUEST': {
        draft.loading = true
        break
      }
      case '@input/SUCCESS': {
        draft.input = payload.input
        draft.loading = false
        break
      }
      case '@input/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/LIST_SUCCESS': {
        // arrumar aq e no actions
        draft.inputList = payload.input.items
        draft.metadata = payload.input.metadata
        // (moment().add(30, 'seconds').unix() - moment().unix()) * 1000
        draft.lastUpdate = moment().unix()
        draft.loading = false
        break
      }
      case '@input/ADD_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/ADD_SUCCESS': {
        if (!(draft.inputList.length > 0)) {
          draft.metadata.pagination.totalCount = 1
        }

        draft.inputList.push(payload.newInput)
        draft.inputList.sort((a, b) => {
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          return dateB.getTime() - dateA.getTime()
        })
        draft.loading = false
        draft.modal = false
        break
      }
      case '@input/UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/UPDATE_SUCCESS': {
        const responseId = payload.updatedInput._id
        const inputIndex = draft.inputList.findIndex(
          input => input._id === responseId
        )
        if (inputIndex >= 0) {
          draft.inputList[inputIndex] = payload.updatedInput
        }
        if (draft.input && draft.input._id === responseId) {
          draft.input = payload.updatedInput
        }
        draft.loading = false
        break
      }
      case '@input/DELETE_REQUEST': {
        const inputIndex = draft.inputList.findIndex(
          i => i._id === payload.inputId
        )
        draft.inputList.splice(inputIndex, 1)
        break
      }
      case '@modal/INPUT_OPEN': {
        draft.modal = true
        break
      }
      case '@modal/INPUT_CLOSE': {
        draft.modal = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
