import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories'

import {
  inputSuccess,
  inputListSuccess,
  inputAddSuccess,
  inputUpdateSuccess,
  inputDeleteSuccess
} from './actions'

import { monthRequest } from '../report/actions'

import { requestFailed } from '../error/action'

export function* input({ payload }) {
  try {
    const { inputId, token } = payload
    const response = yield call(api.axios.get, `/input/${inputId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    yield put(inputSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* inputList({ payload }) {
  try {
    const { storeId, token, page, rows } = payload
    const response = yield call(api.axios.get, `/input/store/${storeId}`, {
      params: {
        sort: '-date',
        limit: rows,
        page
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(inputListSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* inputAdd({ payload }) {
  try {
    const { newInput, token } = payload
    const response = yield call(api.axios.post, '/input', newInput, {
      headers: { Authorization: `Bearer ${token}` }
    })

    yield put(inputAddSuccess(response.data))
    yield put(monthRequest(newInput.storeId, token))
    toast.success('Lançamento realizado com sucesso!')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* inputUpdate({ payload }) {
  try {
    const { inputId, updatedInput, token, isStore, storeId } = payload

    const body = {}
    if (typeof updatedInput.locked === 'boolean') {
      body.locked = updatedInput.locked
    } else {
      if (isStore) {
        body.store = {
          sales: updatedInput.sales,
          items: updatedInput.items,
          sold: updatedInput.sold
        }
      } else if (!updatedInput.sellers) {
        body.seller = {
          _id: updatedInput.sellerId._id,
          sales: updatedInput.sales,
          items: updatedInput.items,
          sold: updatedInput.sold
        }
      }
    }
    if (updatedInput.store) {
      body.store = {
        sales: updatedInput.store.sales,
        items: updatedInput.store.items,
        sold: updatedInput.store.sold
      }
    }
    if (updatedInput.sellers) {
      body.sellers = updatedInput.sellers
    }

    const response = yield call(api.axios.put, `/input/${inputId}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(inputUpdateSuccess(response.data))
    if (typeof updatedInput.locked !== 'boolean')
      yield put(monthRequest(storeId, token))
    toast.success('Lançamento atualizado com sucesso!')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* inputDelete({ payload }) {
  try {
    const { inputId, token } = payload
    yield call(api.axios.delete, `/input/${inputId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    toast.success('O lançamento foi deletado com sucesso!')
    yield put(inputDeleteSuccess())
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@input/REQUEST', input),
  takeLatest('@input/LIST_REQUEST', inputList),
  takeLatest('@input/ADD_REQUEST', inputAdd),
  takeLatest('@input/UPDATE_REQUEST', inputUpdate),
  takeLatest('@input/DELETE_REQUEST', inputDelete)
])
