import styled from 'styled-components'

export const ImageContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  overflow-x: auto;

  & > :first-child {
    margin-left: auto;
  }
`
