import { Typography } from '@material-ui/core'

import jwt from 'jwt-decode'

import AddIcon from '@material-ui/icons/Add'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { listGroupStoresRequest } from 'store/modules/store/actions'
import { logout, usersGroupListRequest } from 'store/modules/user/actions'
import { useHistory } from 'react-router'
import {
  createGroupRequest,
  updateGroupRequest
} from 'store/modules/group/actions'
import { Button, Container, Content, SubHeader } from './styles'
import { GroupsTable } from './components/GroupsTable'
import { AddGroupModal } from './components/AddGroupModal'
import { EditGroupModal } from './components/EditGroupModal'
import { updateUserFromUsersGroupList } from '../../store/modules/user/actions'

export const Groups = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { groupStoresList } = useSelector(state => state.store)
  const { usersGroupList, user } = useSelector(state => state.user)

  const [selectedStores, setSelectedStores] = useState([])
  const [selectedResponsible, setSelectedResponsible] = useState({})
  const [groupName, setGroupName] = useState('')
  const [editingGroup, setEditingGroup] = useState({})

  const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false)
  const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false)

  const token = window.localStorage.getItem('@NeoPro:token')

  const isDirector = user.stores.find(store => store.type === 'director')

  useEffect(() => {
    if (token) {
      const decodedUser = jwt(token)
      if (!groupStoresList.length > 0 && decodedUser) {
        dispatch(listGroupStoresRequest(token))
      }
      if (!usersGroupList.length > 0 && decodedUser) {
        dispatch(usersGroupListRequest(token))
      }
    } else {
      window.localStorage.removeItem('@NeoPro:token')
      dispatch(logout())
      history.push('/login')
    }
  }, [token, dispatch, groupStoresList.length, history, usersGroupList.length])

  useEffect(() => {
    if (!isDirector) {
      history.push('/stores')
    }
  }, [history, isDirector])

  const handleChangeStoreList = event => {
    const {
      target: { value }
    } = event
    setSelectedStores(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSubmit = updatedNewResponsible => {
    dispatch(
      createGroupRequest(
        user._id,
        {
          name: groupName,
          supervisor: {
            id: selectedResponsible._id,
            name: selectedResponsible.name.complete
          },
          stores: selectedStores
        },
        token
      )
    )

    if (updatedNewResponsible) {
      dispatch(updateUserFromUsersGroupList(updatedNewResponsible))
    }

    setGroupName('')
    setSelectedResponsible({})
    setSelectedStores([])
    setIsAddGroupDialogOpen(false)
  }

  const handleSaveEditingModal = ({
    updatedOldResponsible,
    updatedNewResponsible,
    removeSupervisor = false
  } = {}) => {
    // A ideia de passar um id ou um false no supervisor é pra caso o usuário não altere o supervisor
    // Foi decidido com o back de mandar a prop supervisor apenas se o mesmo tiver sido alterado
    dispatch(
      updateGroupRequest(
        editingGroup.groupId,
        {
          name: groupName,
          supervisor: {
            id:
              selectedResponsible._id !== editingGroup.supervisor.id
                ? selectedResponsible._id
                : '',
            name: selectedResponsible.name.complete
          },
          stores: selectedStores
        },
        removeSupervisor,
        user._id,
        token
      )
    )

    if (updatedOldResponsible) {
      dispatch(updateUserFromUsersGroupList(updatedOldResponsible))
    }

    if (updatedNewResponsible) {
      dispatch(updateUserFromUsersGroupList(updatedNewResponsible))
    }

    setEditingGroup({})
    setGroupName('')
    setSelectedResponsible({})
    setSelectedStores([])
    setIsEditGroupDialogOpen(false)
  }

  const handleCloseModal = () => {
    setIsAddGroupDialogOpen(false)
    setIsEditGroupDialogOpen(false)
    setGroupName('')
    setSelectedResponsible({})
    setSelectedStores([])
  }

  const handleOpenEditModal = useCallback(
    group => {
      const groupStoreIds = new Set(group.stores.map(el => el._id))
      const stores = groupStoresList.filter(groupStore =>
        groupStoreIds.has(groupStore._id)
      )
      const populatedSupervisor = usersGroupList.find(
        user => user._id === group.supervisor.id
      )

      setIsEditGroupDialogOpen(true)
      setSelectedResponsible({
        ...populatedSupervisor,
        id: group.supervisor.id
      })
      setSelectedStores(stores)
      setGroupName(group.name)
      setEditingGroup(group)
    },
    [groupStoresList, usersGroupList]
  )

  return (
    <Container>
      <SubHeader>
        <Typography variant='h3' style={{ padding: '8px' }}>
          Regionais
        </Typography>

        <Button onClick={() => setIsAddGroupDialogOpen(true)}>
          <AddIcon />
          REGIONAL
        </Button>
      </SubHeader>

      <Content>
        <GroupsTable openEditModal={handleOpenEditModal} />
      </Content>

      <AddGroupModal
        closeModal={handleCloseModal}
        handleChangeStoreList={handleChangeStoreList}
        handleSubmit={handleSubmit}
        isOpen={isAddGroupDialogOpen}
        selectedStores={selectedStores}
        setGroupName={setGroupName}
        selectedResponsible={selectedResponsible}
        setSelectedResponsible={setSelectedResponsible}
      />

      <EditGroupModal
        closeModal={handleCloseModal}
        handleChangeStoreList={handleChangeStoreList}
        handleSave={handleSaveEditingModal}
        groupName={groupName}
        isOpen={isEditGroupDialogOpen}
        selectedStores={selectedStores}
        setGroupName={setGroupName}
        setSelectedResponsible={setSelectedResponsible}
        selectedResponsible={selectedResponsible}
        groupId={editingGroup.groupId}
      />
    </Container>
  )
}
