import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import {
  userSuccess,
  userListSuccess,
  userAddSuccess,
  userEditSuccess,
  userUnlinkSuccess,
  userChangePasswordSuccess,
  userAvatarUpdateSuccess,
  userExistsSuccess,
  userLinkSuccess,
  userExistsFailed,
  userListRequest,
  usersGroupListSuccess,
  getStorylineStoresAndGroupsSuccess
} from './actions'
import { requestFailed } from '../error/action'
import { currentGoalRequest } from '../goal/actions'
import { browserHistory as history } from 'App'
import { getStorylineSettingsSuccess } from '../storylines/actions'

export function* getUser({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(api.axios.get, `/user/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (response.data.stores.length === 0) {
      throw new Error('Você não está vinculado à uma loja.')
    }

    yield put(userSuccess(response.data))
    yield put(getStorylineSettingsSuccess(response.data.company))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getUserOnSignIn({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(api.axios.get, `/user/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (response.data.stores.length === 0) {
      throw new Error('Você não está vinculado à uma loja.')
    }

    yield put(userSuccess(response.data))
    yield put(getStorylineSettingsSuccess(response.data.company))

    const userStores = response.data.stores.filter(s =>
      ['owner', 'manager', 'cashier', 'director', 'supervisor'].includes(s.type)
    )

    const isCommunicator = response.data.stores.every(
      store => store.type === 'communicator'
    )

    if (isCommunicator) {
      history.push('/storylines')
    } else if (userStores.length > 1) {
      history.push('/stores')
    } else {
      history.push(`/${response.data.stores[0].storeId._id}/dashboard`)
    }
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userList({ payload }) {
  try {
    const { storeId, token, page, limit } = payload
    const response = yield call(api.axios.get, `/user/store/${storeId}`, {
      params: {
        page,
        limit: limit ? limit : ''
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(userListSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* usersGroupList({ payload }) {
  try {
    const { token } = payload
    const response = yield call(api.axios.get, '/user/heads', {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(usersGroupListSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userAdd({ payload }) {
  try {
    const { newUser, token } = payload
    const response = yield call(api.axios.post, '/user', newUser, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(userAddSuccess(response.data))
    toast.success('Usuário adicionado com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userEdit({ payload }) {
  try {
    const { updatedUser, token, userId } = payload
    const response = yield call(api.axios.put, `/user/${userId}`, updatedUser, {
      headers: { Authorization: `Bearer ${token}` }
    })
    toast.success('Usuário atualizado com sucesso.')
    yield put(userEditSuccess(response.data, userId))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userUnLink({ payload: { userId, storeId, token } }) {
  try {
    yield call(
      api.axios.post,
      `/user/${userId}/unlinkStore`,
      { storeId },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    yield put(userUnlinkSuccess(userId))

    toast.success('Usuário removido com sucesso.')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userLink({ payload }) {
  try {
    const { data, userId, token } = payload
    const response = yield call(
      api.axios.post,
      `/user/${userId}/linkStore`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    yield put(userLinkSuccess(response.data))
    toast.success('Usuário vinculado com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e?.response?.status == 401) {
      yield put(
        userExistsFailed({
          error: {
            friendlyMsg: 'O usuário já vinculado a loja'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userChangePassword({ payload }) {
  try {
    const { userPasswords, token, userId } = payload
    yield call(
      api.axios.post,
      `/user/${userId}/change_password`,
      userPasswords,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    toast.success('Senha alterada com sucesso.')
    yield put(userChangePasswordSuccess())
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userUpdateLink({ payload }) {
  try {
    const { data, token, userId, storeId } = payload
    const response = yield call(
      api.axios.put,
      `/user/${userId}/linkStore`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    toast.success('Usuário atualizado com sucesso.')
    yield put(userEditSuccess(response.data, userId))
    yield put(currentGoalRequest(storeId, token))
    yield put(userListRequest(storeId, token))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userAvatarUpdate({ payload }) {
  try {
    const { newAvatar, token, userId } = payload
    const response = yield call(
      api.axios.post,
      `/user/${userId}/upload`,
      newAvatar,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    toast.success('Avatar atualizado com sucesso.')
    yield put(userAvatarUpdateSuccess(response.data.url))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* userExists({ payload }) {
  try {
    const { cpf, token } = payload
    const response = yield call(api.axios.get, `/user/cpf/${cpf}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(userExistsSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.response.status == 400) {
      yield put(
        userExistsFailed({
          error: {
            friendlyMsg: 'O CPF informado é inválido'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getStorylineStoresAndGroups({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(
      api.axios.get,
      `/user/${userId}/user-stores-groups`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    yield put(getStorylineStoresAndGroupsSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export default all([
  /* Usuário */
  takeLatest('@user/REQUEST', getUser),
  takeLatest('@user/USER_REQUEST_ON_SIGN_IN', getUserOnSignIn),
  takeLatest('@user/LIST_REQUEST', userList),
  takeLatest('@user/USERS_GROUP_LIST_REQUEST', usersGroupList),
  takeLatest('@user/ADD_REQUEST', userAdd),
  takeLatest('@user/EDIT_REQUEST', userEdit),
  takeLatest('@user/CHANGE_PASSWORD_REQUEST', userChangePassword),
  takeLatest('@user/UNLINK', userUnLink),
  takeLatest('@user/LINK_REQUEST', userLink),
  takeLatest('@user/UPDATE_LINK', userUpdateLink),
  takeLatest('@user/AVATAR_UPDATE_REQUEST', userAvatarUpdate),
  takeLatest('@user/EXISTS_REQUEST', userExists),
  takeLatest(
    '@user/GET_STORYLINE_STORES_AND_GROUPS_REQUEST',
    getStorylineStoresAndGroups
  )
])
