import { BiFileFind, BiTrash } from 'react-icons/bi'

import { ImageDragAndDrop } from 'views/CreateStoryline/components/ImageDragAndDrop'
import { convertFileSizeName } from 'utils/convertFileSizeName'

import {
  Video,
  Subtitle,
  SubTitleContainer,
  MediaInfoCard,
  IconButton,
  ImagePreviewContainer
} from './styles'

interface MediaDataProps {
  currentMediaUrlPreview: string

  currentFile: File | undefined
  handleRemoveSelectedMedia: (imgSrc: string) => void
}

export const MediaData = ({
  currentMediaUrlPreview,
  currentFile,
  handleRemoveSelectedMedia
}: MediaDataProps) => {
  const mediaType = currentFile?.type as
    | 'image/png'
    | 'image/jpg'
    | 'image/jpeg'
    | 'video/mp4'

  const fileExtension =
    currentFile?.name.split('.')[currentFile?.name.split('.').length - 1] ?? ''
  const fileName = currentFile?.name.substring(0, 14) ?? ''

  const isMediaTypeVideoMp4 = mediaType === 'video/mp4'

  return (
    <>
      <ImagePreviewContainer>
        {isMediaTypeVideoMp4 ? (
          <Video
            src={currentMediaUrlPreview}
            disablePictureInPicture
            id='video-tag'
          >
            <source id='video-source' src={currentMediaUrlPreview} />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <ImageDragAndDrop
            media={currentMediaUrlPreview}
            handleSubmitMedia={() => {}}
            size='md'
          />
        )}

        <MediaInfoCard>
          {fileName}
          {(currentFile?.name.length ?? 0) > 19 ? `..${fileExtension}` : ''}
          <div>
            {convertFileSizeName(currentFile)}
            <span>
              <IconButton htmlFor='story'>
                <BiFileFind size={24} />
              </IconButton>
              <IconButton
                onClick={() =>
                  handleRemoveSelectedMedia(currentMediaUrlPreview)
                }
              >
                <BiTrash size={24} />
              </IconButton>
            </span>
          </div>
        </MediaInfoCard>
      </ImagePreviewContainer>
      <SubTitleContainer>
        <Subtitle>
          <b>Dimensão recom:</b> 1080px x 2160px
        </Subtitle>
        <Subtitle>
          <b>Imagem:</b> peso máx. 25mb
        </Subtitle>
        <Subtitle>
          <b>Vídeo:</b> peso máx. 25mb; duração máx. 120s
        </Subtitle>
      </SubTitleContainer>
    </>
  )
}
