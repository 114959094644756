import { Action } from 'redux'
import produce, { current } from 'immer'
import { IGroupProps } from './types'

const INITIAL_STATE: IGroupProps = {
  groups: [],
  groupsFromReport: [],
  loading: false,
  hasRequested: false,
  error: null
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function group(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@group/CREATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@group/CREATE_SUCCESS': {
        draft.groups = [...draft.groups, action.payload.groupData].sort(
          (a, b) => a.name - b.name
        )

        draft.groupsFromReport = [
          ...draft.groupsFromReport,
          action.payload.groupData
        ].sort((a, b) => a.name - b.name)
        draft.loading = false
        break
      }
      case '@group/CREATE_FAIL': {
        draft.loading = false
        draft.error = action.payload.data
        break
      }
      case '@group/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@group/LIST_SUCCESS': {
        draft.groups = action.payload.groups
        draft.loading = false
        draft.hasRequested = true
        break
      }
      case '@group/LIST_GROUPS_FROM_REPORT': {
        draft.groupsFromReport = action.payload.groups
        break
      }
      case '@group/LIST_FAIL': {
        draft.loading = false
        draft.error = action.payload.data
        break
      }
      case '@group/UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@group/UPDATE_SUCCESS': {
        const { groupId, groupData } = action.payload
        const indexFromGroups = state.groups.findIndex(
          item => item._id === groupId
        )
        const indexFromGroupsReport = state.groupsFromReport.findIndex(
          item => item.groupId === groupId
        )

        draft.groups[indexFromGroups] = groupData
        draft.groupsFromReport[indexFromGroupsReport] = groupData

        draft.loading = false
        break
      }
      case '@group/UPDATE_FAIL': {
        draft.loading = false
        draft.error = action.payload.data
        break
      }
      case '@group/DELETE_REQUEST': {
        draft.loading = true
        break
      }
      case '@group/DELETE_SUCCESS': {
        const { groupId } = action.payload
        const indexFromGroups = draft.groups.findIndex(
          item => item._id === groupId
        )
        const indexFromGroupsReport = draft.groupsFromReport.findIndex(
          item => item.groupId === groupId
        )

        if (indexFromGroups !== -1) {
          draft.groups.splice(indexFromGroups, 1)
        }
        if (indexFromGroupsReport !== -1) {
          draft.groupsFromReport.splice(indexFromGroupsReport, 1)
        }

        break
      }
      case '@report/STORES_REQUEST': {
        draft.groupsFromReport = []
        break
      }
      case '@group/DELETE_FAIL': {
        draft.loading = false
        draft.error = action.payload.data
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      default: {
        return draft
      }
    }
  })
}
