import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 600px;
  height: 563px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12),
    0px 6px 25px -9px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
`

export const ContentModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
  gap: 1.5rem;
`

export const FontBase = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #263238;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const HeaderTitle = styled(FontBase)`
  font-weight: 500;
`

export const HeaderSubtitle = styled(FontBase)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  height: 100%;
  padding: 0 1rem;
`

export const RevisionTime = styled(FontBase)`
  font-weight: 600;
  margin: 8px 0;
`

export const CurrentReview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 14px;
  background: #e2e4e9;
  border-radius: 4px;
`

export const DateHistoricOption = styled(FontBase)`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DateLastEdit = styled(FontBase)`
  font-weight: 500;
`
export const LastEditor = styled(FontBase)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding: 8px 16px;
`

export const CancelBtn = styled(FontBase)`
  font-weight: 700;
  text-transform: uppercase;
  color: #37474f;
  padding: 8px 16px;
  cursor: pointer;
`

export const RestoreBtn = styled(FontBase)`
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
`
