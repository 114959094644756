import styled from 'styled-components'
import { Radio } from '@mui/material'

export const CustomRadio = styled(Radio)`
  span {
    cursor: not-allowed;
    pointer-events: visible;
  }

  &.MuiRadio-root:hover {
    background-color: transparent !important;
  }
`
