import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;

  h1 {
    color: #263238;
    font-size: 22;
  }

  .information-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      h1 {
        margin: 0px 7px 0px 0px;
        color: #37474f;
        font-size: 22px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`
