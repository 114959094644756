import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Libs
import moment from 'moment'

// Icons

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { useConfirm } from 'material-ui-confirm'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Modal
} from '@material-ui/core'

// Utils
import { getTokenStoreId } from 'utils/getTokenStoreId'
import MaskedInput from 'react-text-mask'

import { phoneMask, cpfMask } from '../../../utils/inputMasks'
import PromptModal from '../../Goals/components/AddGoal/components/PromptModal'
import ManageIcon from '../../../assets/Icons/ManageIcon'
import { format, subYears } from 'date-fns'
import { BootstrapTooltip } from 'components/BootstrapTooltip'

// Styles
const useStyles = makeStyles(theme => ({
  root: {},
  textField: {
    marginTop: theme.spacing(2.5)
  },
  removeButton: { marginLeft: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}))

export default function EditUserModal({
  active,
  onClose,
  user,
  storeId,
  loading,
  isDifferentUser,
  handleSubmitData,
  handleUnLinkUser,
  handleUpdateUserLink,
  currentUserStoreType,
  availableRoles,
  ...rest
}) {
  const classes = useStyles()
  const confirm = useConfirm()

  const defaultValues = {
    firstName: '...',
    lastName: '...',
    birthDate: '',
    email: '...',
    gender: '...',
    phone: '...',
    cpf: '...',
    code: '...',
    type: '...',
    password: ''
  }

  const [initialData, setInitialData] = useState()
  const [data, setData] = useState(defaultValues)
  const [modalVerify, setModalVerify] = useState(false)
  const [hasRequested, setHasRequested] = useState(false)
  const textFieldDatePickerRef = useRef(null)
  const textFieldEmailInputRef = useRef(null)

  const handleChangeData = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const newUserLink = {
    code: data.code,
    type: data.type
  }

  const onSubmit = () => {
    if (!data.birthDate || (user.email && !data.email)) {
      setHasRequested(true)
      !data.birthDate
        ? textFieldDatePickerRef.current.focus()
        : textFieldEmailInputRef.current.focus()
    } else if (data) {
      handleSubmitData({
        name: {
          first: data.firstName,
          last: data.lastName
        },
        birthDate: new Date(data.birthDate).toISOString(),
        gender: data.gender,
        phone: data.phone.replace(/[^a-zA-Z0-9]/g, ''),
        cpf: data.cpf ? data.cpf.replace(/[^a-zA-Z0-9]/g, '') : undefined,
        email: data.email,
        password: data.password
      })
    }
  }

  const checkTypeSeller = () => {
    // Verifica se houve alguma alteração no tipo dele
    const initialUserType = initialData.userLink.type
    const hasChangeTypeSeller =
      JSON.stringify(newUserLink) !== JSON.stringify(initialData.userLink)

    if (hasChangeTypeSeller) {
      // Verifica se o usuário está ativo na meta atual, caso esteja abre o modal de confimação
      if (
        initialUserType === 'seller' &&
        initialUserType !== newUserLink.type &&
        user.isOnThisMonthGoal
      )
        setModalVerify(true)
      else {
        // Caso ele não esteja na meta, apenas faz a alteração do tipo
        handleUpdateUserLink({
          type: data.type,
          code: data.code,
          storeId
        })
      }
    } else {
      onSubmit() // Caso o tipo não haja alteração, apenas envia o submit do Data do usuário
    }
  }

  const handleCloseModal = () => {
    onClose()
    setTimeout(
      () => setData({ ...initialData.userData, ...initialData.userLink }),
      150
    )
  }

  useEffect(() => {
    if (user) {
      const currStore = user.stores.find(
        store => getTokenStoreId(store) === storeId
      )

      const initialDataObj = {
        userData: {
          firstName: user.name.first,
          lastName: user.name.last,
          birthDate: user.birthDate
            ? moment(user.birthDate).format('YYYY-MM-DD')
            : undefined,
          email: user.email,
          gender: user.gender,
          phone: user.phone,
          cpf: user.cpf,
          password: ''
        },
        userLink: {
          code: currStore?.code,
          type: currStore?.type
        }
      }

      setData({
        ...initialDataObj.userData,
        ...initialDataObj.userLink
      })
      setInitialData(initialDataObj)
    }
  }, [user])

  return (
    <div {...rest} className={classes.root}>
      <Dialog open={active} onClose={handleCloseModal}>
        <form className={classes.root} autoComplete='off'>
          <DialogTitle id='form-dialog-title'>Editar Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para editar o usuário.
            </DialogContentText>
            <Grid container spacing={1} data-clarity-unmask='true'>
              <Grid item xs>
                <TextField
                  label='Nome'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  // eslint-disable-next-line
                  id='firstName'
                  name='firstName'
                  onChange={handleChangeData}
                  value={data.firstName}
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  label='Sobrenome'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='lastName'
                  name='lastName'
                  onChange={handleChangeData}
                  value={data.lastName}
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                />
              </Grid>
            </Grid>
            <FormControl
              fullWidth
              variant='outlined'
              className={classes.textField}
              margin='normal'
            >
              <InputLabel htmlFor='gender'>Gênero</InputLabel>
              <Select
                id='gender'
                name='gender'
                value={data.gender}
                onChange={handleChangeData}
                labelWidth={30}
              >
                <MenuItem value='female'>Feminino</MenuItem>
                <MenuItem value='male'>Masculino</MenuItem>
                <MenuItem value='other'>Outro</MenuItem>
              </Select>
            </FormControl>
            <TextField
              inputRef={textFieldDatePickerRef}
              label='Data de nascimento'
              type='date'
              fullWidth
              variant='outlined'
              margin='normal'
              id='birthDate'
              error={hasRequested && !data.birthDate}
              name='birthDate'
              onChange={handleChangeData}
              value={data.birthDate}
              className={classes.textField}
              onFocus={() => textFieldDatePickerRef.current.showPicker()}
              InputLabelProps={{ shrink: true }}
              onClick={() => textFieldDatePickerRef.current.showPicker()}
              inputProps={{
                max: format(subYears(new Date(), 16), 'yyyy-MM-dd')
              }}
            />
            <TextField
              inputRef={textFieldEmailInputRef}
              label='Email'
              fullWidth
              variant='outlined'
              margin='normal'
              id='email'
              name='email'
              error={hasRequested && user.email && !data.email}
              onChange={handleChangeData}
              value={data.email}
              className={classes.textField}
              onFocus={event => event.target.select()}
            />
            <TextField
              label='Nova Senha'
              fullWidth
              variant='outlined'
              margin='normal'
              type='password'
              id='password'
              name='password'
              autoComplete='on'
              onChange={handleChangeData}
              value={data.password}
              className={classes.textField}
              onFocus={event => event.target.select()}
              inputProps={{
                'data-clarity-mask': 'True'
              }}
            />
            <TextField
              label='CPF'
              fullWidth
              variant='outlined'
              margin='normal'
              id='cpf'
              name='cpf'
              onChange={handleChangeData}
              value={data.cpf}
              className={classes.textField}
              onFocus={event => event.target.select()}
              InputProps={{
                inputComponent: cpfMask
              }}
            />
            <TextField
              label='Telefone'
              fullWidth
              variant='outlined'
              margin='normal'
              id='phone'
              name='phone'
              onChange={handleChangeData}
              value={data.phone}
              className={classes.textField}
              onFocus={event => event.target.select()}
              InputProps={{
                inputComponent: phoneMask
              }}
            />
            <FormControl
              fullWidth
              variant='outlined'
              margin='normal'
              className={classes.textField}
            >
              <InputLabel htmlFor='type'>Cargo</InputLabel>
              <Select
                id='type'
                name='type'
                value={data.type}
                onChange={handleChangeData}
                labelWidth={30}
              >
                {currentUserStoreType &&
                  Object.entries(availableRoles[currentUserStoreType]).map(
                    ([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
            {data.type === 'seller' && (
              <BootstrapTooltip
                disableHoverListener
                title='Defina um código de 4 números que o vendedor utilizará para acessar sua conta através do aplicativo da loja.'
                arrow
                placement='left'
              >
                <TextField
                  label='Código de segurança - 4 dígitos'
                  fullWidth
                  variant='outlined'
                  id='code'
                  name='code'
                  onChange={handleChangeData}
                  value={data.code}
                  placeholder='0000'
                  className={classes.textField}
                  InputProps={{
                    // eslint-disable-next-line no-use-before-define
                    inputComponent: CodeInputMask
                  }}
                  onFocus={event => event.target.select()}
                />
              </BootstrapTooltip>
            )}
          </DialogContent>
          <DialogActions>
            {isDifferentUser && (
              <>
                <Button
                  className={classes.removeButton}
                  onClick={() => {
                    confirm({
                      title: 'Remover usuário',
                      description: `Você tem certeza que deseja remover o usuário ${user.name.complete}?`,
                      confirmationText: 'Sim',
                      cancellationText: 'Não'
                    })
                      .then(() => {
                        handleUnLinkUser(user)
                        handleCloseModal()
                      })
                      .catch(() => {})
                  }}
                >
                  Desvincular da loja
                </Button>
                <div style={{ flex: 1 }} />
              </>
            )}
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button onClick={checkTypeSeller} color='primary'>
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                'Salvar'
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Modal open={modalVerify} closeAfterTransition>
        <PromptModal
          icon={<ManageIcon />}
          onClose={() => {
            setModalVerify(false)
          }}
          title='Remover usuário da meta'
          description='Para alterar o tipo do usuário ele deve ser removido da meta primeiro. Deseja continuar?'
          rightTitle='Remover e alterar'
          onRight={() => {
            handleUpdateUserLink({
              type: data.type,
              code: data.code,
              storeId
            })
            setModalVerify(false)
          }}
        />
      </Modal>
    </div>
  )
}

export function CodeInputMask({ inputRef, ...other }) {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      guide
    />
  )
}

EditUserModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  loading: PropTypes.bool,
  isDifferentUser: PropTypes.bool,
  handleSubmitData: PropTypes.func,
  handleUnLinkUser: PropTypes.func,
  handleUpdateUserLink: PropTypes.func
}
