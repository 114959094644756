import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { Search } from '@mui/icons-material'

import theme from 'theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2px 12px;

  border: 1px solid #c4c4c4;
  border-radius: 4px;
  position: relative;

  &:focus-within {
    border: 1px solid ${theme.palette.primary.light};
  }
`

export const Input = styled.input`
  border: none;
  margin-left: 10px;
  font-weight: 500;
  font-size: 1rem;
  padding-right: 20px;

  &:focus {
    outline: none;
  }
`

export const SearchIcon = styled(Search)``

export const Spinner = styled(CircularProgress).attrs({
  size: '1.25rem'
})`
  position: absolute;
  right: 6px;

  margin-left: 8px;
`
