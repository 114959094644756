import { all, call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment'

import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import {
  reportSuccess,
  monthSuccess,
  storesReportSuccess,
  addAxiosCancelToken
} from './actions'
import { requestFailed } from '../error/action'
import { listGroupsFromReport } from '../group/actions'
import {
  endDayFromCurrentMonth,
  startDayFromCurrentMonth
} from 'utils/getDateFromCurrentMonth'
import { toIsoStringLocalTz } from 'utils/dateFunctions'

export function* reportsList({ payload }) {
  try {
    const { storeId, token, range } = payload
    const response = yield call(api.axios.get, `report/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { ...range, graph: true }
    })
    yield put(reportSuccess(response.data, range))
  } catch (e) {
    const errorMessage = e.response.data?.error?.friendlyMsg

    if (errorMessage === 'A meta ainda não foi definida para esse mês.') {
      yield toast.warn(
        'Algum mês do período selecionado não tem meta cadastrada'
      )
    }

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: errorMessage || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export function* monthReport({ payload }) {
  try {
    const { storeId, token, range } = payload
    const response = yield call(api.axios.get, `report/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: range ? range.from : startDayFromCurrentMonth,
        to: range ? range.to : endDayFromCurrentMonth,
        graph: true
      }
    })
    yield put(monthSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export function* userStoresReport({ payload }) {
  try {
    const { userId, token, range } = payload
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    yield put(addAxiosCancelToken(source))

    const from = toIsoStringLocalTz(
      range ? range.from : startDayFromCurrentMonth
    )
    const to = toIsoStringLocalTz(range ? range.to : endDayFromCurrentMonth)

    const response = yield call(api.axios.get, `report/user/${userId}`, {
      cancelToken: source.token,
      headers: { Authorization: `Bearer ${token}` },
      params: { from, to },
      timeout: 40000
    })

    yield put(listGroupsFromReport(response.data.groups))
    yield put(storesReportSuccess(response.data, range))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export default all([
  takeLatest('@report/LIST_REQUEST', reportsList),
  takeLatest('@report/MONTH_REQUEST', monthReport),
  takeLatest('@report/STORES_REQUEST', userStoresReport)
])
