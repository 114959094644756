//React
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import clsx from 'clsx'

//MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  IconButton
} from '@material-ui/core'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

//Components
import Tooltip from './Tooltip'
import ReleaseHistory from 'views/Input/components/ReleaseHistory'
import { formatPrice } from '../utils/format'
import { IInput } from 'store/modules/input/types'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 850
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  historyBtn: {
    border: 'none',
    borderBottom: '1px dashed #546E7A',
    backgroundColor: 'transparent',
    margin: '2px 0px',
    cursor: 'pointer',
    color: '#263238',

    '&:focus': {
      outline: 'none'
    }
  },
  inputByUser: {
    border: 'none',
    borderBottom: '1px dashed #546E7A',
    backgroundColor: 'transparent',
    margin: '2px 0px',
    cursor: 'pointer',
    color: '#263238'
  }
}))

interface InputsTableProps {
  smallTable?: boolean
  inputs: IInput[]
  storeId: string
  metadata: {
    pagination: {
      currentPage: number
      limit: number
      pageCount: number
      totalCount: number
    }
    sorting: string
  }
  onChangePage?: (page: number) => void
}

export default function InputsTable({
  smallTable = false,
  storeId,
  inputs,
  onChangePage,
  metadata
}: InputsTableProps) {
  const classes = useStyles()
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  const [currentInput, setCurrentInput] = useState<IInput | null>(null)

  return (
    <Card className={clsx(classes.root)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Faturamento</TableCell>
                  <TableCell>N˚ de vendas</TableCell>
                  <TableCell>N˚ de peças</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inputs.length > 0 &&
                metadata &&
                metadata.pagination?.totalCount > 0 ? (
                  inputs
                    .slice(0, smallTable ? 5 : metadata.pagination.limit)
                    .map((input: IInput) => (
                      <TableRow
                        hover
                        key={input._id}
                        selected={false}
                        onClick={() =>
                          history.push(`/${storeId}/inputs/${input._id}`)
                        }
                        style={{ cursor: 'pointer' }}
                        data-cy='input-table-row'
                      >
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Typography variant='body1'>
                              {moment(input.date).format('DD/MMMM - dddd')}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          {input?.history?.length > 0 &&
                          input.locked &&
                          input.history[0].origin !== 'user' ? (
                            <>
                              <IconButton
                                aria-label='view'
                                color='secondary'
                                onClick={e => (
                                  e.preventDefault(), e.stopPropagation()
                                )}
                                data-tip
                                data-for={`view-goal-modal`}
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginLeft: '-23.5px'
                                }}
                              >
                                <RotateLeftIcon
                                  style={{
                                    color: '#546E7A',
                                    width: '16px',
                                    height: '16px'
                                  }}
                                  onClick={() => (
                                    setOpenModal(true), setCurrentInput(input)
                                  )}
                                />
                                <Tooltip
                                  id={`view-goal-modal`}
                                  content={
                                    <p>Restaurar lançamento para integração</p>
                                  }
                                  place='bottom'
                                />
                              </IconButton>
                              <button
                                data-tip
                                data-for={`view-goal-modal`}
                                className={classes.historyBtn}
                                onClick={e => (
                                  e.preventDefault(),
                                  e.stopPropagation(),
                                  setOpenModal(true),
                                  setCurrentInput(input)
                                )}
                              >
                                {formatPrice(input.total.sold)}
                              </button>
                            </>
                          ) : (input?.history?.length === 0 &&
                              input?.origin === 'user') ||
                            (input?.history?.length > 0 &&
                              input?.history[0].origin === 'user') ? (
                            <>
                              <Tooltip
                                id={`view-goal-user-modal`}
                                content={<p>Lançamento criado manualmente</p>}
                                place='bottom'
                              />
                              <button
                                data-tip
                                data-for={`view-goal-user-modal`}
                                className={classes.inputByUser}
                              >
                                {formatPrice(input.total.sold)}
                              </button>
                            </>
                          ) : (
                            formatPrice(input.total.sold)
                          )}
                        </TableCell>
                        <TableCell>{input.total.sales.toFixed(0)}</TableCell>
                        <TableCell>{input.total.items.toFixed(0)}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                      {inputs
                        ? 'Sem lançamentos cadastrados.'
                        : 'Carregando...'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {currentInput && (
                <ReleaseHistory
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  input={currentInput}
                />
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        {smallTable ? (
          <Button
            onClick={() => history.push(`/${storeId}/inputs`)}
            color='primary'
            size='small'
            variant='text'
          >
            Ver todos <ArrowRightIcon />
          </Button>
        ) : (
          <TablePagination
            component='div'
            count={metadata ? metadata.pagination.totalCount : 0}
            onPageChange={(_, page) => onChangePage && onChangePage(page)}
            page={metadata ? metadata.pagination.currentPage - 1 : 0}
            rowsPerPage={metadata ? metadata.pagination.limit : 0}
            rowsPerPageOptions={[35]}
          />
        )}
      </CardActions>
    </Card>
  )
}
