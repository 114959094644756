import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  width: 60vw;
  min-width: 700px;
  max-width: 800px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 25px 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  .table-header-add-goal-button {
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    background-color: #e8e5eb;
    color: ${palette.primary.main};
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }

  .table-header-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      color: #56676f;
      font-size: 16px;
      margin: 0;
    }

    p {
      margin: 4px 0 0 0;
      color: #546e7a;
      font-size: 12px;
    }
  }
`

export const Content = styled.main`
  font-family: 'Roboto', sans-serif;
  width: 100%;
  margin-top: 16px;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  .seller-without-goal-message {
    font-size: 14px;
    color: #546e7a;
    padding: 25px;
    background-color: #fafafa;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #cccccc;
  }

  th {
    color: #546e7a;
    font-size: 12px;
    text-align: center;
    padding: 10px 0 10px 10px;
  }

  th:nth-child(2) {
    text-align: left;
  }

  th:nth-child(2) {
    width: 180px;
  }

  thead {
    margin-bottom: 12px;

    tr {
      border-bottom: 1px solid #eee;
    }
  }
`
