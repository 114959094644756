import { Avatar } from '@material-ui/core'
import styled, { css } from 'styled-components'

interface ContainerProps {
  isSelected: boolean
}

export const Container = styled.div<ContainerProps>`
  border: 2px solid ${({ isSelected }) => (isSelected ? '#2A3ECB' : '#9a9ab1')};
  border-radius: 8px;
  background-color: #eeeef1;
  position: relative;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 6rem;
  min-width: 3rem;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const Media = styled(Avatar)`
  height: 100%;
  width: 100%;

  img {
    max-height: 6rem;
    max-width: 3rem;
  }

  color: #9a9ab1;
  border-radius: 0px;
  background-color: transparent;
`

export const BadgeNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 15px;
  height: 16px;
  border-radius: 0px 8px 0px 4px;
  background-color: #fff;

  position: absolute;
  right: 0;
  top: 0;

  color: #9a9ab1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 10px;
  z-index: 2;
`
export const Video = styled.video`
  ${({ src }) =>
    src
      ? css`
          height: 100%;
          width: 100%;
        `
      : css`
          height: 24px;
          width: 24px;
        `}

  max-height: 6rem;
  max-width: 3rem;

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
`
