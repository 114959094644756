import { TableRow, Tooltip, Typography } from '@mui/material'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material'
import UserDefaultPhoto from '../../../../../../../../UserDefaultPhoto'
import { common } from '@mui/material/colors'
import { TableCell } from '../../styles'
import { CustomRadio } from './styles'
import { IWorkOptions } from '../../../CardDay'

interface RowTableProps {
  name: string
  photoUrl?: string
  userId: string
  checked: IWorkOptions
  hasMoreThanOneDayWorking: boolean
  handleChangeUserActive: (
    userId: string,
    option: 'dayOff' | 'willWork' | 'willNotWork'
  ) => void
}

export const RowTable = ({
  name,
  checked,
  photoUrl,
  handleChangeUserActive,
  userId,
  hasMoreThanOneDayWorking
}: RowTableProps) => {
  const handleChangeUserWorkStatus = (option: IWorkOptions) => {
    if (option !== checked) {
      handleChangeUserActive(userId, option)
    }
  }

  return (
    <TableRow key={name}>
      <TableCell component='th' scope='row' width='100%'>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '6px'
          }}
        >
          {photoUrl ? (
            <img
              style={{ borderRadius: '50%', width: '25px', height: '25px' }}
              src={photoUrl}
            />
          ) : (
            <UserDefaultPhoto username={name} />
          )}
          <span>{name}</span>
        </span>
      </TableCell>

      <TableCell>
        <CustomRadio
          size='small'
          onChange={() => handleChangeUserWorkStatus('willWork')}
          value='willWork'
          checked={checked === 'willWork'}
          name='radio-buttons'
          inputProps={{ 'aria-label': 'Irá trabalhar' }}
          checkedIcon={<CheckCircleOutlineIcon />}
          sx={{
            color: common.black,
            '&.Mui-checked': {
              color: common.black
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            !hasMoreThanOneDayWorking && (
              <Typography fontSize={14} style={{ color: '#fff' }}>
                O vendedor precisa ter pelo menos 1 dia de trabalho na meta
              </Typography>
            )
          }
        >
          <CustomRadio
            size='small'
            onChange={() => handleChangeUserWorkStatus('dayOff')}
            value='dayOff'
            checked={checked === 'dayOff'}
            name='radio-buttons'
            inputProps={{ 'aria-label': 'Folga com meta' }}
            checkedIcon={<CheckCircleOutlineIcon />}
            sx={{
              color: common.black,
              '&.Mui-checked': {
                color: common.black
              }
            }}
            disabled={!hasMoreThanOneDayWorking}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            !hasMoreThanOneDayWorking && (
              <Typography fontSize={14} style={{ color: '#fff' }}>
                O vendedor precisa ter pelo menos 1 dia de trabalho na meta
              </Typography>
            )
          }
        >
          <CustomRadio
            size='small'
            onChange={() => handleChangeUserWorkStatus('willNotWork')}
            value='notWork'
            checked={checked === 'willNotWork'}
            name='radio-buttons'
            inputProps={{ 'aria-label': 'Folga sem meta' }}
            checkedIcon={<CheckCircleOutlineIcon />}
            sx={{
              color: common.black,
              '&.Mui-checked': {
                color: common.black
              }
            }}
            disabled={!hasMoreThanOneDayWorking}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
