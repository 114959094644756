import styled from 'styled-components'
import { TextField } from '@material-ui/core'

export const TextInput = styled(TextField)`
  font-family: 'Roboto', sans-serif;

  label {
    color: #949494;
    font-size: 0.875rem;
  }
  fieldset {
    border-color: #c4c4c4;
  }

  input {
    color: #263238;
  }
`
