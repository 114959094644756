import React from 'react'

import { useDispatch } from 'react-redux'

import { Modal } from '@material-ui/core'
import PromptModal from '../../../../../Goals/components/AddGoal/components/PromptModal'
import FixIcon from '../../../../../../assets/Icons/FixIcon'
import { makeStyles } from '@material-ui/core/styles'
import { inputUpdateRequest } from '../../../../../../store/modules/input/actions'

const ReleaseModal = ({ open, onClose, input }) => {
  const useStyles = makeStyles(theme => ({
    textModal: {
      textAlign: 'center'
    }
  }))
  const dispatch = useDispatch()
  const classes = useStyles()
  const token = window.localStorage.getItem('@NeoPro:token')

  const restoreIntegration = (input, token) => {
    const integrationInput = input?.history?.find(
      history => history.origin !== 'user'
    )
    if (integrationInput) {
      const dataInput = {}
      dataInput.sellers = integrationInput.sellers.map(seller => ({
        _id: seller.sellerId._id,
        sales: seller.sales,
        items: seller.items,
        sold: seller.sold
      }))
      dataInput.store = integrationInput.store
      dataInput.locked = false
      dispatch(
        inputUpdateRequest(input.storeId, input._id, dataInput, token, false)
      )
    }
  }

  return (
    <Modal open={true} closeAfterTransition>
      <PromptModal
        icon={<FixIcon />}
        onClose={onClose}
        title='Restaurar valores?'
        rightTitle='Restaurar'
        onRight={() => {
          restoreIntegration(input, token)
          onClose()
        }}
      >
        <div className={classes.textModal}>
          Os valores editados do lançamento serão substituídos pelos valores
          integrados do ERP. Deseja continuar?
        </div>
      </PromptModal>
    </Modal>
  )
}

export default ReleaseModal
