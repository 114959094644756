import { Action } from 'redux'
import { parseISO } from 'date-fns'
import produce from 'immer'
import {
  startDayFromCurrentMonth,
  endDayFromCurrentMonth
} from '../../../utils/getDateFromCurrentMonth'
import { IDayProps, IListDayReport } from './types'

const INITIAL_STATE: IDayProps = {
  loading: false,
  dayServices: 0,
  totalSales: 0,
  totalServices: 0,
  conversion: '',
  sellers: [],
  days: [],
  day: null,
  period: {
    from: startDayFromCurrentMonth.toISOString(),
    to: endDayFromCurrentMonth.toISOString()
  },
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 25
    },
    sorting: '-date'
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function store(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@day/REPORT_REQUEST': {
        draft.loading = true
        break
      }
      case '@day/REPORT_SUCCESS': {
        const { avg, fail, sellers, success }: IListDayReport =
          action.payload.dayReport
        draft.totalSales = success
        draft.dayServices = avg
        draft.totalServices = fail + success
        draft.conversion = (
          (success / (fail + success) > 0 ? success / (fail + success) : 0) *
          100
        ).toFixed(2)
        draft.sellers = sellers
        draft.period = {
          from: String(parseISO(action.payload.period.from)),
          to: String(parseISO(action.payload.period.to))
        }
        draft.loading = false
        break
      }
      case '@day/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@day/LIST_SUCCESS': {
        draft.days = action.payload.dayListResponse.items
        draft.metadata = action.payload.dayListResponse.metadata
        draft.loading = false
        break
      }
      case '@day/REQUEST': {
        draft.loading = true
        break
      }
      case '@day/SUCCESS': {
        draft.day = action.payload.day
        draft.loading = false
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
