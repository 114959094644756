import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import { useMemo, useState } from 'react'

import { useHistory } from 'react-router'
import { formatPrice, formatToPercentage } from 'utils/format'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import StoresTableHead from '../StoresTable/components/StoresTableHead'
import { VisiblePerfectScrollbar } from './styles'
import Tooltip from 'components/Tooltip'
import { format } from 'date-fns'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { dateWithNoTimezone } from 'utils/dateFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%'
  },
  table: {
    minWidth: 750
  },
  typographyPercent: {
    textAlign: 'right',
    fontSize: '11px',
    fontWeight: 'bold'
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white'
  }
}))

export const GroupsTable = ({ group, disabledValues }) => {
  const classes = useStyles()
  const history = useHistory()

  const [selectedRow, setSelectedRow] = useState(-1)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const { name, supervisor, total, metric, daySold, average, projection } =
    group

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const formattedStoresFromGroupsToStoresTable =
    group?.stores.length > 0
      ? group.stores.map(store => ({
          ...store,
          'average.tm': store.average.tm,
          'average.pv': store.average.pv,
          'average.pm': store.average.pm,
          'total.items': store.total.items,
          salesDay: store.daySold.sold,
          sold: store.total.sold,
          sales: store.total.sales,
          items: store.total.items.toFixed(),
          goalPercent: Number(
            ((store.total.sold / store.metric.acDayGoal || 0) * 100).toFixed(2)
          ),
          acDayGoal: store.metric.acDayGoal,
          mainGoal: store.metric.mainGoal,
          projection: store.projection.sold,
          mainGoalPercent: Number(
            ((store.total.sold / store.metric.mainGoal || 0) * 100).toFixed(2)
          )
        }))
      : []

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const getStoresForGroup = () => {
    const stores = stableSort(
      formattedStoresFromGroupsToStoresTable,
      getComparator(order, orderBy)
    )

    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return stores.slice(startIndex, endIndex)
  }

  return (
    <Card>
      <CardHeader title={name} subheader={`Responsável: ${supervisor.name}`} />
      <Divider />
      <Paper className={classes.paper}>
        <TableContainer>
          <VisiblePerfectScrollbar>
            {disabledValues && (
              <Tooltip
                id='view-goal-modal'
                content={<p>Informação disponível para datas do mesmo mês</p>}
                place='bottom'
              />
            )}
            <Table
              stickyHeader
              onMouseLeave={() => {
                if (selectedRow > -1) {
                  setSelectedRow(-1)
                }
              }}
            >
              <StoresTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                <TableRow
                  hover
                  key={1}
                  selected
                  onMouseEnter={() => {
                    if (selectedRow > -1) {
                      setSelectedRow(-1)
                    }
                  }}
                >
                  <TableCell
                    className={classes.sticky}
                    style={{ backgroundColor: '#fdf8ec' }}
                  >
                    <b>{name}</b>
                  </TableCell>
                  <TableCell>
                    <b>{formatPrice(daySold.sold)}</b>
                  </TableCell>
                  <TableCell>
                    <b>{formatPrice(total.sold)}</b>
                  </TableCell>
                  <TableCell data-tip data-for='view-goal-modal'>
                    <b>
                      {disabledValues
                        ? '-'
                        : formatToPercentage(
                            (total.sold / metric.acDayGoal) * 100
                          )}
                    </b>
                  </TableCell>
                  <TableCell data-tip data-for='view-goal-modal'>
                    <b>
                      {disabledValues ? '-' : formatPrice(metric.acDayGoal)}
                    </b>
                  </TableCell>
                  <TableCell data-tip data-for='view-goal-modal'>
                    <b>
                      {disabledValues
                        ? '-'
                        : formatToPercentage(
                            (total.sold / metric.mainGoal) * 100
                          )}
                    </b>
                  </TableCell>
                  <TableCell data-tip data-for='view-goal-modal'>
                    <b>{disabledValues ? '-' : formatPrice(metric.mainGoal)}</b>
                  </TableCell>
                  <TableCell data-tip data-for='view-goal-modal'>
                    <b>{disabledValues ? '-' : formatPrice(projection.sold)}</b>
                  </TableCell>
                  <TableCell>{total ? total.sales : 0}</TableCell>
                  <TableCell>
                    <b>{formatPrice(average.tm)}</b>
                  </TableCell>
                  <TableCell>
                    <b>{average.pv.toFixed(2)}</b>
                  </TableCell>
                  <TableCell>
                    <b>{formatPrice(average.pm)}</b>
                  </TableCell>
                  <TableCell>
                    <b>{total ? total.items : 0}</b>
                  </TableCell>
                </TableRow>

                {getStoresForGroup().map((store, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => history.push(`/${store._id}/dashboard`)}
                      tabIndex={-1}
                      key={store._id}
                      style={{ cursor: 'pointer' }}
                      data-cy='store-row'
                      onMouseEnter={() => setSelectedRow(index)}
                    >
                      <TableCell
                        scope='row'
                        className={classes.sticky}
                        style={{
                          ...(index === selectedRow && {
                            backgroundColor: '#f5f5f5'
                          })
                        }}
                      >
                        {store.name}
                      </TableCell>
                      <TableCell
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {formatPrice(store.daySold.sold)}
                        {store.daySold.updatedAt && (
                          <BootstrapTooltip
                            title={`Ultima atualização: ${format(
                              dateWithNoTimezone(store.daySold.updatedAt),
                              'pp'
                            )}`}
                          >
                            <ErrorOutlineIcon
                              style={{
                                color: '#C4C4C4',
                                fontSize: 20,
                                marginLeft: 'auto'
                              }}
                            />
                          </BootstrapTooltip>
                        )}
                      </TableCell>
                      <TableCell>{formatPrice(store.total.sold)}</TableCell>
                      <TableCell data-tip data-for='view-goal-modal'>
                        <Typography
                          style={{
                            color: (value =>
                              value >= 100
                                ? '#27ae60'
                                : value >= 80
                                ? '#f39c12'
                                : '#e74c3c')(
                              (store.total.sold / store.metric.acDayGoal) * 100
                            )
                          }}
                          className={classes.typographyPercent}
                        >
                          {disabledValues
                            ? '-'
                            : store.metric.acDayGoal !== 0
                            ? +(
                                (store.total.sold / store.metric.acDayGoal) *
                                100
                              ).toFixed(2) + '%'
                            : '0%'}
                        </Typography>
                      </TableCell>
                      <TableCell data-tip data-for='view-goal-modal'>
                        {disabledValues
                          ? '-'
                          : formatPrice(store.metric.acDayGoal)}
                      </TableCell>
                      <TableCell data-tip data-for='view-goal-modal'>
                        <Typography
                          style={{
                            color: (value =>
                              value >= 100
                                ? '#27ae60'
                                : value >= 80
                                ? '#f39c12'
                                : '#e74c3c')(
                              (store.total.sold / store.metric.mainGoal) * 100
                            )
                          }}
                          className={classes.typographyPercent}
                        >
                          {disabledValues
                            ? '-'
                            : store.metric.mainGoal !== 0
                            ? +(
                                (store.total.sold / store.metric.mainGoal) *
                                100
                              ).toFixed(2) + '%'
                            : '0%'}
                        </Typography>
                      </TableCell>
                      <TableCell data-tip data-for='view-goal-modal'>
                        {disabledValues
                          ? '-'
                          : formatPrice(store.metric.mainGoal)}
                      </TableCell>
                      <TableCell data-tip data-for='view-goal-modal'>
                        {disabledValues ? '-' : formatPrice(store.projection)}
                      </TableCell>
                      <TableCell>{store.total.sales}</TableCell>
                      <TableCell>{formatPrice(store.average.tm)}</TableCell>
                      <TableCell>{store.average.pv.toFixed(2)}</TableCell>
                      <TableCell>{formatPrice(store.average.pm)}</TableCell>
                      <TableCell>{store.total.items}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </VisiblePerfectScrollbar>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={formattedStoresFromGroupsToStoresTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={event => {
            handleChangeRowsPerPage(Number(event.target.value))
          }}
        />
      </Paper>
    </Card>
  )
}
