import { IGroupStoresList, IStore, IUpdateStore } from './types'

export function listGroupStoresRequest(token: string | null) {
  return {
    type: '@store/LIST_GROUP_STORES_REQUEST',
    payload: { token }
  }
}

export function listGroupStoresSuccess(groupStoresList: IGroupStoresList) {
  return {
    type: '@store/LIST_GROUP_STORES_SUCCESS',
    payload: { groupStoresList }
  }
}

export function listGroupStoresFailed() {
  return {
    type: '@store/LIST_GROUP_STORES_FAILED',
    payload: {}
  }
}

export function storeRequest(storeId: string, token: string | null) {
  return {
    type: '@store/REQUEST',
    payload: { storeId, token }
  }
}

export function storeSuccess(store: IStore) {
  return {
    type: '@store/SUCCESS',
    payload: { store }
  }
}

export function storeUpdateRequest(
  storeId: string,
  updatedStore: IUpdateStore,
  token: string | null
) {
  return {
    type: '@store/UPDATE_REQUEST',
    payload: { storeId, updatedStore, token }
  }
}

export function storeUpdateSuccess(updatedStore: IStore) {
  return {
    type: '@store/UPDATE_SUCCESS',
    payload: { updatedStore }
  }
}

export function storeAvatarUpdateRequest(
  newAvatar: FormData,
  storeId: string,
  token: string | null
) {
  return {
    type: '@store/AVATAR_UPDATE_REQUEST',
    payload: { newAvatar, storeId, token }
  }
}

export function storeAvatarUpdateSuccess(url: string | null) {
  return {
    type: '@store/AVATAR_UPDATE_SUCCESS',
    payload: { url }
  }
}
