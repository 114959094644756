import { ChangeEvent, FocusEvent, useState } from 'react'
import {
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Box
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  CircularProgress,
  DialogActions,
  Card,
  Switch
} from '@mui/material'

import palette from 'theme/palette'

import 'react-day-picker/lib/style.css'
import { CustomCurrencyInput } from './styles'
import { addMonths, differenceInDays, format } from 'date-fns'
import { Avatar } from '../Avatar'
import { IEditSprint, ISprint } from 'store/modules/sprints/types'
import { useDispatch } from 'react-redux'
import {
  deleteSprintRequest,
  updateSprintAvatarRequest,
  updateSprintRequest
} from 'store/modules/sprints/actions'

interface SetEditSprintFieldValueFuncProps {
  field:
    | 'title'
    | 'description'
    | 'isRecurring'
    | 'goalType'
    | 'goal'
    | 'rewardType'
    | 'reward'
    | 'startDate'
    | 'endDate'
    | 'photo'

  data: string | FormData | boolean
}

interface EditSprintModalProps {
  isOpen: boolean
  closeModal: () => void
  initialSprintState: ISprint
}
export const EditSprintModal = ({
  isOpen,
  closeModal,
  initialSprintState
}: EditSprintModalProps) => {
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false)
  const [isConfirmDeleteSprintModalOpen, setIsConfirmDeleteSprintModalOpen] =
    useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [updatedSprint, setUpdatedSprint] =
    useState<IEditSprint>(initialSprintState)
  const [previewURLImage, setPreviewURLImage] = useState<string | undefined>(
    initialSprintState.photo.url
  )

  const token = window.localStorage.getItem('@NeoPro:token')

  const dispatch = useDispatch()

  const setUpdatedSprintFieldValue = ({
    field,
    data
  }: SetEditSprintFieldValueFuncProps) => {
    setUpdatedSprint(previousState => ({ ...previousState, [field]: data }))
  }

  const handleSaveEditNewSprint = async () => {
    // checa se todos os campos estão preenchidos e caso nao ativa um estado
    // "hasSubmitted" pra mostrar os erros no modal pro ser humano
    const itemsLength = Object.values(updatedSprint).filter(
      item => item !== ''
    ).length
    if (itemsLength < Object.keys(updatedSprint).length) {
      if (!hasSubmitted) {
        setHasSubmitted(true)
      }
      return
    }

    setIsSaveButtonLoading(true)
    // verifica se alterou algum campo do form, que nao seja o avatar
    // pra caso tenha alterado apenas o avatar, faz so a request de update do avatar
    if (
      JSON.stringify({ ...updatedSprint, photo: null }) ===
      JSON.stringify({ ...initialSprintState, photo: null })
    ) {
      if (updatedSprint.photo instanceof FormData) {
        dispatch(
          updateSprintAvatarRequest(
            updatedSprint._id,
            updatedSprint.photo,
            token
          )
        )
      }
    } else {
      const formattedUpdatedSprint: IEditSprint = {
        ...updatedSprint,
        goal: Number(
          String(updatedSprint.goal).replaceAll('.', '').replace(',', '.')
        ),
        ...(['money', 'points'].includes(updatedSprint.rewardType) && {
          reward: Number(
            String(updatedSprint.reward).replaceAll('.', '').replace(',', '.')
          )
        })
      }

      if (formattedUpdatedSprint.campaignRef) {
        delete formattedUpdatedSprint.campaignRef
      }

      dispatch(updateSprintRequest(formattedUpdatedSprint, token))
    }
    closeModal()
    setIsSaveButtonLoading(false)
  }

  const confirmDeleteSprint = () => {
    setIsDeleteButtonLoading(true)
    dispatch(deleteSprintRequest(initialSprintState._id, token))
    closeModal()
    setIsDeleteButtonLoading(false)
  }

  const checkIfInputHasError = (field: keyof IEditSprint) => {
    if (
      typeof updatedSprint[field] === 'string' &&
      ((field === 'goal' && updatedSprint.goalType === 'financial') ||
        (field === 'reward' && updatedSprint.rewardType === 'money'))
    ) {
      const inputNumber = Number(String(updatedSprint[field]).replace(',', '.'))
      return hasSubmitted && (updatedSprint[field] === '' || inputNumber === 0)
    }
    return hasSubmitted && updatedSprint[field] === ''
  }

  const sprintDaysAmount =
    differenceInDays(
      new Date(updatedSprint.endDate),
      new Date(updatedSprint.startDate)
    ) + 1

  return (
    <Dialog
      open={isOpen}
      onClose={
        !isDeleteButtonLoading && !isSaveButtonLoading ? closeModal : undefined
      }
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle style={{ paddingTop: 22 }}>
        <Typography style={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Editar Corridinha
        </Typography>
        <IconButton
          aria-label='close'
          onClick={
            !isDeleteButtonLoading && !isSaveButtonLoading
              ? closeModal
              : undefined
          }
          style={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item container>
            <Grid item xs={4} md={4}>
              <Avatar
                image={previewURLImage}
                subTitle='Imagem para corridinha'
                handleSubmitImage={(formData: FormData, previewURL: string) => {
                  setUpdatedSprintFieldValue({
                    field: 'photo',
                    data: formData
                  })
                  setPreviewURLImage(previewURL)
                }}
              />
            </Grid>

            <Grid
              item
              xs={8}
              md={8}
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column'
              }}
            >
              <Grid>
                <TextField
                  fullWidth
                  error={checkIfInputHasError('title')}
                  label='Título'
                  variant='outlined'
                  placeholder='Título'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={updatedSprint.title}
                  onChange={event =>
                    event.target.value.length < 36 &&
                    setUpdatedSprintFieldValue({
                      field: 'title',
                      data: event.target.value
                    })
                  }
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  multiline
                  maxRows={5}
                  error={checkIfInputHasError('description')}
                  label='Descrição'
                  variant='outlined'
                  placeholder='Item/Descrição'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={updatedSprint.description}
                  onChange={event =>
                    event.target.value.length < 140 &&
                    setUpdatedSprintFieldValue({
                      field: 'description',
                      data: event.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 1
              }}
            >
              <Switch
                style={{
                  color: '#364AD3'
                }}
                checked={updatedSprint.isRecurring}
                onChange={event =>
                  setUpdatedSprintFieldValue({
                    field: 'isRecurring',
                    data: event.target.checked
                  })
                }
              />

              <Box marginLeft={2} fontFamily='Roboto, sans-serif'>
                <b style={{ fontSize: '1rem' }}>Meta Recorrente</b>
                <p style={{ fontSize: '0.875rem', color: '#ADADBD' }}>
                  A premiação acumula a cada vez que a meta for atingida durante
                  a corridinha.
                </p>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='goalType'>Tipo da Meta</InputLabel>
              <Select
                labelId='goalType'
                label='Tipo da Meta'
                error={checkIfInputHasError('goalType')}
                autoComplete='off'
                value={updatedSprint.goalType}
                onChange={event => {
                  setUpdatedSprintFieldValue({
                    field: 'goalType',
                    data: String(event.target.value)
                  })
                  setUpdatedSprintFieldValue({
                    field: 'goal',
                    data: ''
                  })
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value='financial'>Financeira</MenuItem>
                <MenuItem value='unity'>Unidade</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            {updatedSprint.goalType === 'financial' ? (
              <CustomCurrencyInput
                fullWidth
                onFocus={(event: FocusEvent<HTMLInputElement>) =>
                  event.target.select()
                }
                label='Meta'
                error={checkIfInputHasError('goal')}
                variant='outlined'
                currencySymbol='R$'
                decimalCharacter=','
                digitGroupSeparator='.'
                minimumValue='0'
                placeholder='0,00'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { textAlign: 'left' }
                }}
                value={updatedSprint.goal}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUpdatedSprintFieldValue({
                    field: 'goal',
                    data: event.target.value
                  })
                }
              />
            ) : (
              <TextField
                fullWidth
                type='number'
                label='Meta'
                error={checkIfInputHasError('goal')}
                variant='outlined'
                placeholder={'0'}
                InputLabelProps={{
                  shrink: true
                }}
                onKeyDown={event =>
                  ['.', ','].includes(event.key) && event.preventDefault()
                }
                value={updatedSprint.goal}
                onChange={event =>
                  setUpdatedSprintFieldValue({
                    field: 'goal',
                    data:
                      Number(event.target.value) > 0
                        ? String(parseInt(event.target.value))
                        : '0'
                  })
                }
              />
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='rewardType'>Tipo do Prêmio</InputLabel>
              <Select
                labelId='rewardType'
                label='Tipo do Prêmio'
                error={checkIfInputHasError('rewardType')}
                autoComplete='off'
                value={updatedSprint.rewardType}
                onChange={event => {
                  setUpdatedSprintFieldValue({
                    field: 'rewardType',
                    data: String(event.target.value)
                  })
                  setUpdatedSprintFieldValue({
                    field: 'reward',
                    data: ''
                  })
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value='money'>Dinheiro</MenuItem>
                <MenuItem value='points'>Pontos</MenuItem>
                <MenuItem value='general'>Geral</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            {updatedSprint.rewardType === 'money' ? (
              <CustomCurrencyInput
                fullWidth
                onFocus={(event: FocusEvent<HTMLInputElement>) =>
                  event.target.select()
                }
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                currencySymbol='R$'
                decimalCharacter=','
                digitGroupSeparator='.'
                minimumValue='0'
                placeholder='0,00'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { textAlign: 'left' }
                }}
                value={updatedSprint.reward}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUpdatedSprintFieldValue({
                    field: 'reward',
                    data: event.target.value
                  })
                }
              />
            ) : updatedSprint.rewardType === 'points' ? (
              <TextField
                fullWidth
                type='number'
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                placeholder={'0'}
                InputLabelProps={{
                  shrink: true
                }}
                onKeyDown={event =>
                  ['.', ','].includes(event.key) && event.preventDefault()
                }
                value={updatedSprint.reward}
                onChange={event => {
                  setUpdatedSprintFieldValue({
                    field: 'reward',
                    data:
                      Number(event.target.value) > 0
                        ? String(parseInt(event.target.value))
                        : '0'
                  })
                }}
              />
            ) : (
              <TextField
                fullWidth
                label='Prêmio'
                error={checkIfInputHasError('reward')}
                variant='outlined'
                value={updatedSprint.reward}
                onChange={event =>
                  event.target.value.length < 36 &&
                  setUpdatedSprintFieldValue({
                    field: 'reward',
                    data: event.target.value
                  })
                }
              />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label='Data de Início'
              type='date'
              fullWidth
              variant='outlined'
              margin='normal'
              id='startDate'
              value={format(new Date(updatedSprint.startDate), 'yyyy-MM-dd')}
              onChange={event => {
                // Por n ter o timezone ele zoa o dia jogando pra um dia a menos
                // dependendo da hora que o usuário ta mexendo
                // Esse workaround aqui evita esse tipo de problema
                const newDate = new Date(
                  new Date(event.target.value).getTime() +
                    Math.abs(
                      new Date(event.target.value).getTimezoneOffset() * 60000
                    )
                ).toString()

                setUpdatedSprintFieldValue({
                  field: 'startDate',
                  data: newDate
                })

                const diffBetweenStartAndEndDates = differenceInDays(
                  new Date(updatedSprint.endDate),
                  new Date(newDate)
                )

                // Previne o usuário de colocar um início depois do encerramento
                if (diffBetweenStartAndEndDates < 0) {
                  setUpdatedSprintFieldValue({
                    field: 'endDate',
                    data: newDate
                  })
                }
              }}
              onFocus={event => event.target.select()}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: format(new Date().setDate(1), 'yyyy-MM-dd'),
                max: format(new Date(), 'yyyy-MM-dd')
                // max: format(addMonths(new Date().setDate(0), 3), 'yyyy-MM-dd')
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography>até</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label='Data de Encerramento'
              type='date'
              fullWidth
              variant='outlined'
              margin='normal'
              id='endDate'
              value={format(new Date(updatedSprint.endDate), 'yyyy-MM-dd')}
              onChange={event => {
                // Por n ter o timezone ele zoa o dia jogando pra um dia a menos
                // dependendo da hora que o usuário ta mexendo
                // Esse workaround aqui evita esse tipo de problema
                const newDate = new Date(
                  new Date(event.target.value).getTime() +
                    Math.abs(
                      new Date(event.target.value).getTimezoneOffset() * 60000
                    )
                ).toString()

                setUpdatedSprintFieldValue({
                  field: 'endDate',
                  data: newDate
                })
              }}
              onFocus={event => event.target.select()}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: format(new Date(updatedSprint.startDate), 'yyyy-MM-dd'),
                max: format(addMonths(new Date().setDate(0), 3), 'yyyy-MM-dd')
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'end', paddingTop: 0, paddingBottom: 0 }}
          >
            <Typography>
              {sprintDaysAmount} {sprintDaysAmount > 1 ? 'dias' : 'dia'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{
          paddingLeft: '24px',
          paddingRight: '24px',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant='text'
          onClick={() => setIsConfirmDeleteSprintModalOpen(true)}
          disabled={isDeleteButtonLoading || isSaveButtonLoading}
          style={{ color: 'red' }}
        >
          EXCLUIR
        </Button>
        <Box>
          <Button
            variant='text'
            onClick={() => {
              closeModal()
              setUpdatedSprint(initialSprintState)
              setPreviewURLImage(undefined)
              setHasSubmitted(false)
            }}
            disabled={isSaveButtonLoading}
            color='inherit'
            style={{ marginRight: '12px' }}
          >
            CANCELAR
          </Button>
          <Button
            variant='text'
            type='submit'
            onClick={handleSaveEditNewSprint}
            disabled={isSaveButtonLoading}
            style={{ background: palette.primary.main, color: palette.white }}
          >
            {isSaveButtonLoading && (
              <CircularProgress size={14} color='inherit' />
            )}
            {!isSaveButtonLoading && 'SALVAR'}
          </Button>
        </Box>
      </DialogActions>

      <Dialog
        open={isConfirmDeleteSprintModalOpen}
        onClose={() =>
          !isDeleteButtonLoading && setIsConfirmDeleteSprintModalOpen(false)
        }
      >
        <DialogTitle style={{ paddingTop: 22 }} disableTypography>
          <Typography variant='h4'>Excluir Corridinha</Typography>
          <IconButton
            aria-label='close'
            onClick={() =>
              !isDeleteButtonLoading && setIsConfirmDeleteSprintModalOpen(false)
            }
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Você tem certeza que você deseja excluir a corridinha
            <b> {updatedSprint.title}</b>?
          </Typography>

          <Typography style={{ marginTop: '12px' }}>
            Essa ação não poderá ser desfeita
          </Typography>
        </DialogContent>

        <DialogActions style={{ paddingLeft: '24px', paddingRight: '24px' }}>
          <Button
            variant='text'
            onClick={() => setIsConfirmDeleteSprintModalOpen(false)}
            disabled={isDeleteButtonLoading}
            color='inherit'
          >
            Cancelar
          </Button>
          <Button
            variant='text'
            onClick={confirmDeleteSprint}
            disabled={isDeleteButtonLoading}
            style={{ color: 'red' }}
          >
            {isDeleteButtonLoading && (
              <CircularProgress size={14} color='inherit' />
            )}
            {!isDeleteButtonLoading && 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
