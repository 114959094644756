import {
  IStory,
  IStoryLine,
  ICreateStoryline,
  IStorylineMetadata,
  IStorylineTable,
  IStorylineSettings,
  ISettings,
  ITag
} from './types'

interface IFile {
  [key: string]: File
}

interface ILink {
  [key: string]: string
}

export function getActiveStorylinesRequest(
  userId: string,
  token: string | null
) {
  return {
    type: '@storylines/GET_ACTIVE_STORYLINES_REQUEST',
    payload: { userId, token }
  }
}

export function getActiveStorylinesSuccess(
  activeStorylines: IStorylineTable[]
) {
  return {
    type: '@storylines/GET_ACTIVE_STORYLINES_SUCCESS',
    payload: { activeStorylines }
  }
}

export function getDraftStorylinesRequest(
  userId: string,
  token: string | null
) {
  return {
    type: '@storylines/GET_DRAFT_STORYLINES_REQUEST',
    payload: { userId, token }
  }
}

export function getDraftStorylinesSuccess(draftStorylines: IStorylineTable[]) {
  return {
    type: '@storylines/GET_DRAFT_STORYLINES_SUCCESS',
    payload: { draftStorylines }
  }
}

export function getFinishedStorylinesRequest(
  userId: string,
  token: string | null,
  page = 1,
  limit = 5
) {
  return {
    type: '@storylines/GET_FINISHED_STORYLINES_REQUEST',
    payload: { userId, token, page, limit }
  }
}

export function getFinishedStorylinesSuccess(
  finishedStorylines: IStorylineTable[],
  metadata: IStorylineMetadata
) {
  return {
    type: '@storylines/GET_FINISHED_STORYLINES_SUCCESS',
    payload: { finishedStorylines, metadata }
  }
}

export function createNewStorylineRequest(
  newStoryline: ICreateStoryline,
  files: IFile,
  links: ILink,
  token: string | null
) {
  return {
    type: '@storylines/CREATE_NEW_STORYLINE_REQUEST',
    payload: { newStoryline, files, links, token }
  }
}

export function createNewStorylineSuccess(newStoryline: IStoryLine) {
  return {
    type: '@storylines/CREATE_NEW_STORYLINE_SUCCESS',
    payload: { newStoryline }
  }
}

export function linkStoriesToStorylineRequest(
  files: IFile,
  links: ILink,
  storylineId: string,
  token: string | null
) {
  return {
    type: '@storylines/LINK_STORIES_TO_STORYLINE_REQUEST',
    payload: { files, links, storylineId, token }
  }
}

export function linkStoriesToStorylineSuccess(
  url: string,
  storylineId: string
) {
  return {
    type: '@storylines/LINK_STORIES_TO_STORYLINE_SUCCESS',
    payload: { url, storylineId }
  }
}

export function activeStorylineRequest(
  storylineId: string,
  token: string | null
) {
  return {
    type: '@storylines/ACTIVE_STORYLINE_REQUEST',
    payload: { storylineId, token }
  }
}

export function activeStorylineSuccess() {
  return {
    type: '@storylines/ACTIVE_STORYLINE_SUCCESS'
  }
}

// export function updateStorylineRequest(
//   updatedStoryline: IEditStoryline,
//   token: string | null
// ) {
//   return {
//     type: '@storylines/UPDATE_STORYLINE_REQUEST',
//     payload: { updatedStoryline, token }
//   }
// }

// export function updateStorylineSuccess(updatedStoryline: IStoryLine) {
//   return {
//     type: '@storylines/UPDATE_STORYLINE_SUCCESS',
//     payload: { updatedStoryline }
//   }
// }

export function deleteStorylineRequest(
  storylineId: string,
  token: string | null
) {
  return {
    type: '@storylines/DELETE_STORYLINE_REQUEST',
    payload: { storylineId, token }
  }
}

export function deleteStorylineSuccess(storylineId: string) {
  return {
    type: '@storylines/DELETE_STORYLINE_SUCCESS',
    payload: { storylineId }
  }
}

export function getStoriesFromStorylineRequest(
  storylineId: string,
  token: string | null
) {
  return {
    type: '@storylines/GET_STORIES_FROM_STORYLINE_REQUEST',
    payload: { storylineId, token }
  }
}

export function getStoriesFromStorylineSuccess(
  storylineId: string,
  stories: IStory[]
) {
  return {
    type: '@storylines/GET_STORIES_FROM_STORYLINE_SUCCESS',
    payload: { storylineId, stories }
  }
}

export function getStorylineSettingsSuccess(settings: ISettings) {
  return {
    type: '@storylines/GET_STORYLINE_SETTINGS_SUCCESS',
    payload: { settings }
  }
}

export function createStorylineTagRequest(
  tagName: string,
  tagIcon: FormData,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/CREATE_STORYLINE_TAG_REQUEST',
    payload: { tagName, tagIcon, companyId, token }
  }
}

export function createStorylineTagSuccess(newTag: ITag) {
  return {
    type: '@storylines/CREATE_STORYLINE_TAG_SUCCESS',
    payload: { newTag }
  }
}

export function updateStorylineTagRequest(
  tagId: string,
  newTagName: string,
  tagIcon: FormData,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/UPDATE_STORYLINE_TAG_REQUEST',
    payload: { tagId, tagIcon, newTagName, companyId, token }
  }
}

export function updateStorylineTagAvatarRequest(
  tagId: string,
  tagIcon: FormData,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/UPDATE_STORYLINE_TAG_AVATAR_REQUEST',
    payload: { tagId, tagIcon, companyId, token }
  }
}

export function updateStorylineTagAvatarSuccess(updatedTag: ITag) {
  return {
    type: '@storylines/UPDATE_STORYLINE_TAG_AVATAR_SUCCESS',
    payload: { updatedTag }
  }
}

export function updateStorylineTagSuccess(updatedTag: ITag) {
  return {
    type: '@storylines/UPDATE_STORYLINE_TAG_SUCCESS',
    payload: { updatedTag }
  }
}

export function updateStorylineNameRequest(
  newName: string,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/UPDATE_STORYLINE_NAME_REQUEST',
    payload: { newName, companyId, token }
  }
}

export function updateStorylineNameSuccess(newName: string) {
  return {
    type: '@storylines/UPDATE_STORYLINE_NAME_SUCCESS',
    payload: { newName }
  }
}

export function updateStorylineSettingsAvatarRequest(
  newStorylineSettingsAvatar: FormData,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/UPDATE_STORYLINE_SETTINGS_AVATAR_REQUEST',
    payload: { newStorylineSettingsAvatar, companyId, token }
  }
}

export function updateStorylineSettingsAvatarSuccess(
  newStorylineSettingsAvatar: string
) {
  return {
    type: '@storylines/UPDATE_STORYLINE_SETTINGS_AVATAR_SUCCESS',
    payload: { newStorylineSettingsAvatar }
  }
}

export function deleteStorylineTagRequest(
  tagId: string,
  companyId: string,
  token: string | null
) {
  return {
    type: '@storylines/DELETE_STORYLINE_TAG_REQUEST',
    payload: { tagId, companyId, token }
  }
}

export function deleteStorylineTagSuccess(tagId: string) {
  return {
    type: '@storylines/DELETE_STORYLINE_TAG_SUCCESS',
    payload: { tagId }
  }
}
