import { IFile, SelectedMediaProps } from '../..'
import { ImageDragAndDrop } from '../../../ImageDragAndDrop'
import { StoriesListPreview } from '../../components/StoriesListPreview'
import { Video } from '../../components/StoriesListPreview/styles'

import { ImageContainer } from './styles'

interface StoriesFormProps {
  previewUrls: string[]
  files: IFile
  selectedMedia: SelectedMediaProps
  isAddingNewMedia: boolean
  isEditingMedia: boolean
  currentMediaUrlPreview: string
  currentFile: File | undefined
  hasError: boolean | undefined
  handleAdd: () => void
  handleEditMedia: (srcImg: string) => void
  handleChangeMedia: (image: File, srcImg: string) => void
}

export const StoriesForm = ({
  previewUrls,
  files,
  selectedMedia,
  currentFile,
  currentMediaUrlPreview,
  isAddingNewMedia,
  isEditingMedia,
  handleAdd,
  handleChangeMedia,
  handleEditMedia,
  hasError
}: StoriesFormProps) => {
  const hasPreviewUrls = previewUrls.length > 0

  return (
    <ImageContainer>
      {hasPreviewUrls &&
        previewUrls.map((url, index) => {
          const currentFileType = files[url]?.type as
            | 'image/png'
            | 'image/jpg'
            | 'image/jpeg'
            | 'video/mp4'

          return (
            <StoriesListPreview
              mediaType={currentFileType}
              isSelected={index === selectedMedia.index}
              index={index + 1}
              key={url}
              media={url}
              onClick={() => {
                const hasSelectedADifferentMedia =
                  url !== selectedMedia.url && index !== selectedMedia.index

                if (hasSelectedADifferentMedia) {
                  handleEditMedia(url)
                }
              }}
            />
          )
        })}
      {isAddingNewMedia && !isEditingMedia && !currentMediaUrlPreview && (
        <ImageDragAndDrop
          handleSubmitMedia={handleChangeMedia}
          size='sm'
          icon='uploadFile'
        />
      )}
      {currentMediaUrlPreview &&
        !isEditingMedia &&
        !previewUrls.includes(currentMediaUrlPreview) &&
        (currentFile?.type === 'video/mp4' ? (
          <Video
            src={currentMediaUrlPreview}
            disablePictureInPicture
            id='video-tag'
          >
            <source id='video-source' src={currentMediaUrlPreview} />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <ImageDragAndDrop
            media={currentMediaUrlPreview}
            handleSubmitMedia={handleChangeMedia}
            size='sm'
          />
        ))}
      {Object.keys(files).length < 10 && (
        <ImageDragAndDrop
          error={hasError}
          handleSubmitMedia={handleChangeMedia}
          size='sm'
          icon='addPhoto'
          onClick={handleAdd}
        />
      )}
    </ImageContainer>
  )
}
