let sellers = []

sellers[0] = [
  {
    sellerId: '1',
    total: 0
  },
  {
    sellerId: '2',
    total: 0
  },
  {
    sellerId: '3',
    total: [5322.4, -0.7542]
  },
  {
    sellerId: '4',
    total: 0
  },
  {
    sellerId: '5',
    total: 0
  }
]

sellers[1] = [
  {
    sellerId: '1',
    total: 0
  },

  {
    sellerId: '2',
    total: [-100.0, -0.0221]
  },
  {
    sellerId: '3',
    total: [-200.0, -0.0647]
  },
  {
    sellerId: '4',
    total: [-300.0, -0.0895]
  },
  {
    sellerId: '5',
    total: [-400.0, -0.1248]
  }
]

sellers[2] = [
  {
    sellerId: '1',
    total: 0
  },

  {
    sellerId: '2',
    total: [-100.0, -0.0104]
  },
  {
    sellerId: '3',
    total: [-40.0, -0.87]
  },
  {
    sellerId: '4',
    total: [-30.0, -0.0237]
  },
  {
    sellerId: '5',
    total: [-70.0, -0.0184]
  }
]
sellers[3] = [
  {
    sellerId: '1',
    total: [5322.4, 1.0214]
  },
  {
    sellerId: '2',
    total: [4902.22, 0.9756]
  },
  {
    sellerId: '3',
    total: [4176.33, 0.8819]
  },
  {
    sellerId: '4',
    total: [3881.82, 0.6188]
  },
  {
    sellerId: '5',
    total: [2179.59, 0.5922]
  }
]

sellers[4] = [
  {
    sellerId: '1',
    total: [1500.0, 0.0572]
  },
  {
    sellerId: '2',
    total: [500.0, 0.0154]
  },
  {
    sellerId: '3',
    total: 0
  },
  {
    sellerId: '4',
    total: [-500.0, -0.0154]
  },
  {
    sellerId: '5',
    total: [-1500.0, -0.0572]
  }
]

export { sellers }
