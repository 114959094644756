import { Avatar } from '@material-ui/core'
import styled, { css } from 'styled-components'

interface ContainerProps {
  size: 'sm' | 'md' | 'lg'
  error: boolean
}

interface CustomImageProps {
  size: 'sm' | 'md' | 'lg'
  icon: 'add' | 'addPhoto' | 'uploadFile'
}

interface VideoProps {
  size: 'sm' | 'md' | 'lg'
}

interface StoryBarProps {
  storyPosition: 'isCurrentStory' | 'isPreviousStory' | 'isNextStory'
}

export const Container = styled.div<ContainerProps>`
  border: 2px dashed ${({ error }) => (error ? '#F34456' : '#9a9ab1')};
  border-radius: ${({ size }) => (size === 'lg' ? 24 : 8)}px;
  background-color: #eeeef1;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  position: relative;

  ${({ size }) =>
    size === 'sm'
      ? css`
          height: 6rem;
          width: 3rem;
          min-height: 6rem;
          min-width: 3rem;
        `
      : size === 'md'
      ? css`
          height: 10rem;
          width: 5rem;
        `
      : size === 'lg' &&
        css`
          height: 37.5rem;
          width: 18.75rem;
        `}
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const Image = styled(Avatar)<CustomImageProps>`
  ${({ src, size }) =>
    src
      ? css`
          height: 100%;
          width: 100%;
        `
      : css`
          height: ${size === 'sm' ? 24 : 64}px;
          width: ${size === 'sm' ? 24 : 64}px;
        `}

  img {
    ${({ size }) =>
      size === 'sm'
        ? css`
            max-height: 6rem;
            max-width: 3rem;
          `
        : size === 'md'
        ? css`
            max-height: 10rem;
            max-width: 5rem;
          `
        : size === 'lg' &&
          css`
            max-height: 37.5rem;
            max-width: 18.75rem;
          `}
  }

  border-radius: ${({ src, size }) => (src || size === 'sm' ? 0 : 32)}px;
  background-color: ${({ size, icon }) =>
    size === 'sm' || icon === 'uploadFile' ? 'transparent' : '#cbcbd2'};

  color: ${({ size, icon }) =>
    size === 'sm' || icon === 'uploadFile' ? '#9a9ab1' : '#fff'};
`

export const Video = styled.video<VideoProps>`
  ${({ src, size }) =>
    src
      ? css`
          height: 100%;
          width: 100%;
        `
      : css`
          height: ${size === 'sm' ? 24 : 64}px;
          width: ${size === 'sm' ? 24 : 64}px;
        `}

  source {
    ${({ size }) =>
      size === 'sm'
        ? css`
            max-height: 6rem;
            max-width: 3rem;
          `
        : size === 'md'
        ? css`
            max-height: 10rem;
            max-width: 5rem;
          `
        : size === 'lg' &&
          css`
            max-height: 37.5rem;
            max-width: 18.75rem;
          `}
  }

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
`
export const LinkButtonFooter = styled.footer`
  button {
    border: 1px solid #cbcbd2;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;

    background-color: #fff;
    color: #2a3ecb;

    padding: 9.5px 12px 9.5px 15px;
    border-radius: 8px;

    svg {
      margin-right: 5px;
    }
  }

  p {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif;

    margin-top: 1.5rem;
    letter-spacing: 0.8px;

    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
`
export const StoryBarContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 8px;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 12;
`

export const StoryBar = styled.div<StoryBarProps>`
  height: 2px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);

  ${({ storyPosition }) =>
    storyPosition === 'isPreviousStory'
      ? css`
          background-color: #fff;
        `
      : storyPosition === 'isCurrentStory'
      ? css`
          background: -webkit-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: -moz-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: -ms-linear-gradient(left, #fff 30%, #ffffff99 70%);
          background: linear-gradient(left, #fff 30%, #ffffff99 70%);
        `
      : storyPosition === 'isNextStory' &&
        css`
          background-color: #ffffff99;
        `}
`
