// React
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactSwitch from 'react-switch'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Tooltip,
  TextField
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

// Redux
import { useDispatch } from 'react-redux'

// Actions
import {
  integrationDisconnectRequest,
  integrationRequest
} from '../../../../../../store/modules/integration/actions'

// Assets
import MicrovixExample from '../../../../../../assets/exemploMicrovix.png'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DCEDC8',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    padding: '0 8px'
  },
  switchDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E2E4E9',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    padding: '0 8px'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  iconCardHeader: {
    color: '#C4C4C4',
    width: '15px'
  }
}))

const Microvix = ({
  className,
  storeIntegrationData,
  store,
  loading,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'microvix'

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
      storeId: '',
      portalId: ''
    }
  })

  const [useOtherSales, setUseOtherSales] = useState(false)

  // LocalStorage
  const token = localStorage.getItem('@NeoPro:token')
  const { storeId } = useParams()

  const onSubmit = data => {
    confirm({
      title: 'Atenção',
      description:
        'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na Microvix?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(
          integrationRequest(
            { ...data, useOtherSales, provider },
            provider,
            storeId,
            token
          )
        )
      })
      .catch(() => {})
  }

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com a Linx Microvix??',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() =>
        dispatch(integrationDisconnectRequest(provider, storeId, token))
      )
      .catch(() => {})
  }

  useEffect(() => {
    if (
      storeIntegrationData?.username &&
      storeIntegrationData?.provider === provider
    ) {
      reset({
        username: storeIntegrationData.username,
        password: storeIntegrationData.password,
        storeId: storeIntegrationData.storeId,
        portalId: storeIntegrationData.portalId
      })
      setUseOtherSales(storeIntegrationData.useOtherSales)
    }
  }, [storeIntegrationData])

  const renderError = error =>
    errors[error] && (
      <FormHelperText id='component-error-text'>
        Este campo é obrigatório
      </FormHelperText>
    )
  // useEffect(() => {
  //   console.log(document.getElementById("tagplus_iframe").contentWindow.location.href)
  // }, [document.getElementById("tagplus_iframe").contentWindow.location.href])

  return (
    <>
      <Card {...rest}>
        <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='Linx Microvix' />
          <Divider />
          <CardContent>
            <Typography>
              Antes de iniciar a integração, certifique-se de que todos os
              vendedores estão cadastrados, assim como seus CPFs estão definidos
              e idênticos à Microvix. Caso haja qualquer diferença nos valores,
              o vendedor não será identificado e a venda entrará em "Outras
              vendas", prejudicando a validade do relatório.
              <br />
              Se acontecer de um vendedor não ter sido identificado e sua venda
              entrar em "Outras vendas", corrija os dados do vendedor e aguarde
              a próxima sincronização.
              <br />
              Recomendamos que crie um acesso apenas para vincular a NeoPro{' '}
              <a href='https://linx.microvix.com.br:8370/gestor_web/usuarios/cadastro_usuario_simples.asp?operacao=A'>
                Criar acesso
              </a>
              , com as seguintes permissões:
              <br />
              - Faturamento - Relatórios de Faturamento - Faturamento Vendedor
              <br />
              - Faturamento - Cadastros auxiliares - Vendedores/Compradores
              <br />
              ID do portal e ID da loja podem ser encontrados na barra superior
              do sistema conforme o exemplo:
              <br />
            </Typography>
            <img src={MicrovixExample} />
            <Typography>
              Seguindo o exemplo acima, 1 é o ID da loja e 12345 é o ID do
              portal.
            </Typography>
            <Divider style={{ marginTop: '1rem' }} />
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='ID do portal'
                  placeholder='(Ex: 12345)'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='portalId'
                  name='portalId'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.portalId}
                />
                {renderError('portalId')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='ID da loja'
                  placeholder='(Ex: 1)'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='storeId'
                  name='storeId'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.storeId}
                />
                {renderError('storeId')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Login'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='username'
                  name='username'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.username}
                />
                {renderError('username')}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label='Senha'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  id='password'
                  name='password'
                  type='password'
                  className={classes.textField}
                  onFocus={event => event.target.select()}
                  inputRef={register({ required: true })}
                  error={!!errors.password}
                  inputProps={{
                    'data-clarity-mask': 'True'
                  }}
                />
                {renderError('password')}
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '1rem' }} />
            <Grid
              container
              alignItems='center'
              className={classes.sectionsContainer}
            >
              <Grid item>
                <div
                  className={
                    useOtherSales ? classes.switch : classes.switchDisabled
                  }
                >
                  <ReactSwitch
                    onColor='#09177d'
                    height={15}
                    width={40}
                    onHandleColor='#364AD3'
                    offHandleColor='#364AD3'
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={20}
                    checked={useOtherSales}
                    onChange={() => setUseOtherSales(!useOtherSales)}
                  />
                </div>
              </Grid>
              <Grid item xs={10}>
                <CardHeader
                  title={
                    <div className={classes.cardHeader}>
                      Outras vendas
                      <Tooltip
                        title='Importação de outras vendas'
                        placement='right'
                        arrow
                      >
                        <ErrorOutlineIcon className={classes.iconCardHeader} />
                      </Tooltip>
                    </div>
                  }
                  subheader='Importação de outras vendas para sua loja'
                  titleTypographyProps={{ variant: 'h6' }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              disabled={loading}
              className={classes.button}
              type='submit'
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#fff', marginRight: '8px' }}
                  />{' '}
                  {' Conectando à Linx Microvix...'}
                </>
              ) : (
                'Salvar'
              )}
            </Button>
            {storeIntegrationData?.provider === provider && (
              <Button
                style={{ color: '#e74c3c' }}
                className={classes.button}
                disabled={loading}
                onClick={() => handleDisconnectIntegration()}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={24}
                      style={{ color: '#e74c3c', marginRight: '8px' }}
                    />{' '}
                    {' Desconectando...'}
                  </>
                ) : (
                  'Desconectar'
                )}
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
    </>
  )
}

Microvix.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default Microvix
