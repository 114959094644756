import styled from 'styled-components'

export const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin: 16px 0;
  width: 100%;
  gap: 16px;
`

export const Video = styled.video`
  height: 100%;
  width: 100%;

  border: 2px dashed #9a9ab1;
  border-radius: 8px;
  background-color: #eeeef1;
  height: 10rem;
  width: 5rem;

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
`

export const MediaInfoCard = styled.div`
  max-width: 10rem;
  width: 100%;

  text-align: left;

  div {
    display: flex;
    justify-content: space-between;
    color: #9a9ab1;
    vertical-align: top;
    text-align: left;

    margin-top: 12px;
    width: 100%;

    span {
      display: flex;
      flex-direction: row;
    }
  }
`
export const IconButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  background-color: #e3e3e8;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:last-child {
    margin-left: 6px;
  }

  & > svg {
    color: #5b5b76;
  }
`

export const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const Subtitle = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  text-align: left;
  color: #9a9ab1;
`
