import { useEffect, useState } from 'react'
import {
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon
} from '@mui/icons-material'
import { MdInsertLink, MdSchedule } from 'react-icons/md'

import {
  Container,
  Content,
  HeaderSection,
  Title,
  Footer,
  FooterSaveButton,
  FooterCancelButton,
  Divider,
  UploadLabelButton,
  TextInput,
  Subtitle,
  Section
} from './styles'
import { MediaData } from '../ImageData'

interface MediaSettingsProps {
  fileName: string
  fileType: 'image' | 'video'
  currentMediaUrlPreview: string
  previewMedias: string[]
  link: string
  handleCloseMediaSettings: () => void
}

export const MediaSettings = ({
  fileName,
  fileType,
  previewMedias,
  currentMediaUrlPreview,
  handleCloseMediaSettings,
  link
}: MediaSettingsProps) => {
  const [videoDuration, setVideoDuration] = useState(0)

  const page =
    previewMedias.indexOf(currentMediaUrlPreview) > -1
      ? previewMedias.indexOf(currentMediaUrlPreview) + 1
      : previewMedias.length + 1

  useEffect(() => {
    const video = document?.getElementById('video-tag') as HTMLVideoElement
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        setVideoDuration(video.duration)
      })
    }

    return () => video.removeEventListener('onloadedmetadata', () => {})
  }, [fileName])

  const formatVideoDuration = (videoDuration: number) => {
    const minutes = Math.floor(videoDuration / 60)
    const seconds = Math.floor(videoDuration % 60)
    if (minutes > 0) {
      return `${minutes} ${minutes > 1 ? 'minutos' : 'minuto'} ${seconds} ${
        seconds > 1 ? 'segundos' : 'segundo'
      }`
    }
    return `${seconds} ${seconds > 1 ? 'segundos' : 'segundo'}`
  }

  return (
    <Container>
      <Content>
        <HeaderSection>
          <Title>Página {page}</Title>
          <Section>
            <InsertDriveFileOutlinedIcon />
            MÍDIA
          </Section>
          {currentMediaUrlPreview ? (
            <MediaData
              currentMediaUrlPreview={currentMediaUrlPreview}
              fileName={fileName}
              fileType={fileType}
            />
          ) : (
            <UploadLabelButton htmlFor='story'>
              Enviar mídia <CloudUploadOutlinedIcon />
            </UploadLabelButton>
          )}
          <Divider />
          <Section>
            <MdInsertLink size={16} />
            LINK
          </Section>
          <TextInput
            label='URL do link'
            variant='outlined'
            placeholder='Link para página'
            value={link}
            disabled
          />
          <Subtitle>
            Adicione link na página para os usuários poderem clicar no botão ou
            arrastar para cima para acessá-lo.
          </Subtitle>
          <Divider />
          <Section style={{ marginBottom: '1rem' }}>
            <MdSchedule size={16} />
            DURAÇÃO
          </Section>
          <span>
            {'\u2022'}{' '}
            {fileType === 'video'
              ? formatVideoDuration(videoDuration)
              : '15 segundos'}
          </span>
        </HeaderSection>

        <Footer>
          <FooterCancelButton onClick={handleCloseMediaSettings}>
            Cancelar
          </FooterCancelButton>
          <FooterSaveButton disabled>Salvar</FooterSaveButton>
        </Footer>
      </Content>
    </Container>
  )
}
