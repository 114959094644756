import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'

import { IReports } from 'views/Stores/Stores'
import { normalizeString } from 'utils/normalizeString'

import { Container, Input, SearchIcon, Spinner } from './styles'

interface StoreSearchBarProps {
  stores: IReports[]
  setFilteredStores: Dispatch<SetStateAction<IReports[]>>
}

export const StoresSearchBar = ({
  stores,
  setFilteredStores
}: StoreSearchBarProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [debouncedInputValue, setDebouncedInputValue] = useState('')
  const searchValueRef = useRef(debouncedInputValue)
  const currentIndexRef = useRef(0)

  const maxNumberOfFilteredStores = 100
  const maxLength = stores.length

  const filterStores = (
    storesToBeFiltered: IReports[],
    filter: string,
    limit = 30,
    currentStoresCount = 0
  ) => {
    const hasChangedTheFilter = filter !== searchValueRef.current
    const hasReachedMaxNumberOfFilteredStores =
      currentIndexRef.current >= maxNumberOfFilteredStores
    const hasFilteredAllStores = currentStoresCount > maxLength
    const hasNoFilter = filter === ''

    if (hasFilteredAllStores) {
      setIsLoading(false)

      if (currentIndexRef.current === 0) {
        setFilteredStores([])
      }
    }

    if (hasNoFilter) {
      setIsLoading(false)
      setFilteredStores([])
    }

    if (
      hasChangedTheFilter ||
      hasReachedMaxNumberOfFilteredStores ||
      hasFilteredAllStores ||
      hasNoFilter
    ) {
      return
    }

    const filteredStoresChunk: IReports[] = []
    let chunkCount = 0
    let interactionsCount = 1

    for (
      let i = currentStoresCount;
      i < maxLength && interactionsCount < limit;
      i++
    ) {
      interactionsCount++

      const currentStoreNameMatchesWithFilter = normalizeString(
        storesToBeFiltered[i].name
      ).includes(normalizeString(filter))

      if (currentStoreNameMatchesWithFilter) {
        filteredStoresChunk.push(storesToBeFiltered[i])
        chunkCount++
      }
    }
    const hasFindStores = filteredStoresChunk.length > 0

    if (hasFindStores) {
      const isFirstTimeFillingStoresArray = currentIndexRef.current === 0

      if (isFirstTimeFillingStoresArray) {
        setFilteredStores(filteredStoresChunk)
        setIsLoading(false)
      } else {
        setFilteredStores(prev => [...prev, ...filteredStoresChunk])
      }
      currentIndexRef.current += chunkCount
    }

    setTimeout(() => {
      filterStores(
        storesToBeFiltered,
        filter,
        limit,
        currentStoresCount + interactionsCount
      )
    }, 100)
  }

  useEffect(() => {
    if (searchInputValue.length > 0) {
      setIsLoading(true)
    }
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchInputValue)
    }, 500)
    return () => clearTimeout(delayInputTimeoutId)
  }, [searchInputValue, 600])

  useEffect(() => {
    searchValueRef.current = debouncedInputValue
    currentIndexRef.current = 0
    filterStores(stores, debouncedInputValue)
  }, [debouncedInputValue, stores])

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const filterValue = event.target.value

    setSearchInputValue(filterValue)
  }

  return (
    <Container>
      <SearchIcon />
      <Input
        type='text'
        value={searchInputValue}
        placeholder='Digite o nome da loja'
        onChange={handleChangeInputValue}
      />
      {isLoading && <Spinner />}
    </Container>
  )
}
