import { ICreateSprint, IEditSprint, ISprint, ISprintMetadata } from './types'

export function getActiveSprintsRequest(storeId: string, token: string | null) {
  return {
    type: '@sprints/GET_ACTIVE_SPRINTS_REQUEST',
    payload: { storeId, token }
  }
}

export function getActiveSprintsSuccess(activeSprints: ISprint[]) {
  return {
    type: '@sprints/GET_ACTIVE_SPRINTS_SUCCESS',
    payload: { activeSprints }
  }
}

export function getFinishedSprintsRequest(
  storeId: string,
  token: string | null,
  page = 1,
  limit = 5
) {
  return {
    type: '@sprints/GET_FINISHED_SPRINTS_REQUEST',
    payload: { storeId, token, page, limit }
  }
}

export function getFinishedSprintsSuccess(
  finishedSprints: ISprint[],
  metadata: ISprintMetadata
) {
  return {
    type: '@sprints/GET_FINISHED_SPRINTS_SUCCESS',
    payload: { finishedSprints, metadata }
  }
}

export function createNewSprintRequest(
  newSprint: ICreateSprint,
  currentStoreId: string,
  storesIds: string[],
  token: string | null
) {
  return {
    type: '@sprints/CREATE_NEW_SPRINT_REQUEST',
    payload: { newSprint, currentStoreId, storesIds, token }
  }
}

export function createNewSprintSuccess(newSprint: ISprint) {
  return {
    type: '@sprints/CREATE_NEW_SPRINT_SUCCESS',
    payload: { newSprint }
  }
}

export function updateSprintRequest(
  updatedSprint: IEditSprint,
  token: string | null
) {
  return {
    type: '@sprints/UPDATE_SPRINT_REQUEST',
    payload: { updatedSprint, token }
  }
}

export function updateSprintSuccess(updatedSprint: ISprint) {
  return {
    type: '@sprints/UPDATE_SPRINT_SUCCESS',
    payload: { updatedSprint }
  }
}

export function updateSprintAvatarRequest(
  sprintId: string,
  newSprintAvatar: FormData,
  token: string | null
) {
  return {
    type: '@sprints/UPDATE_SPRINT_AVATAR_REQUEST',
    payload: { sprintId, newSprintAvatar, token }
  }
}

export function updateAllSprintAvatarRequest(
  sprintsIds: string[],
  newSprintAvatar: FormData,
  token: string | null
) {
  return {
    type: '@sprints/UPDATE_ALL_SPRINT_AVATAR_REQUEST',
    payload: { sprintsIds, newSprintAvatar, token }
  }
}

export function updateSprintAvatarSuccess(
  updatedSprintAvatarUrl: string,
  sprintsIds: string[]
) {
  return {
    type: '@sprints/UPDATE_SPRINT_AVATAR_SUCCESS',
    payload: { updatedSprintAvatarUrl, sprintsIds }
  }
}

export function deleteSprintRequest(sprintId: string, token: string | null) {
  return {
    type: '@sprints/DELETE_SPRINT_REQUEST',
    payload: { sprintId, token }
  }
}

export function deleteSprintSuccess(sprintId: string) {
  return {
    type: '@sprints/DELETE_SPRINT_SUCCESS',
    payload: { sprintId }
  }
}
