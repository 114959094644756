import React from 'react'

const ExitIcon = ({ color }) => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.4983 23.5817L16.0833 26.1667L25.25 17L16.0833 7.83333L13.4983 10.4183L18.2283 15.1667H0.5V18.8333H18.2283L13.4983 23.5817ZM29.8333 0.5H4.16667C2.13167 0.5 0.5 2.15 0.5 4.16667V11.5H4.16667V4.16667H29.8333V29.8333H4.16667V22.5H0.5V29.8333C0.5 31.85 2.13167 33.5 4.16667 33.5H29.8333C31.85 33.5 33.5 31.85 33.5 29.8333V4.16667C33.5 2.15 31.85 0.5 29.8333 0.5Z'
        fill={color ? color : '#1E2FA9'}
      />
    </svg>
  )
}

export default ExitIcon
