import styled, { css } from 'styled-components'
import palette from 'theme/palette'

export const StepperOptionContainer = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.active
      ? css`
          background-color: #1e5fa9;
          cursor: pointer;
          color: white;
          padding: 5px;
          border-radius: 5px;
        `
      : props.completed &&
        css`
          cursor: pointer;
          color: ${palette.primary.main};
        `}

  span {
    font-weight: 500;
  }

  .edit-icon {
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
    color: ${props => (props.active ? 'white' : palette.primary.main)};
  }
`
