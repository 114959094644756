import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  background-color: #f5f6f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stepper-bar {
    height: 5px;
    width: 50%;
    margin: auto;
    border-radius: 50px;
    background-color: #ddd;
    position: relative;

    .progress-stepper-circle-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: 0.5s ease-in-out;
      padding-left: 10px;

      .progress-stepper-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1e5fa9;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .progress-stepper-bar {
      height: 5px;
      width: 0px;
      background: linear-gradient(
        to right,
        ${palette.primary.main} 20%,
        #1e5fa9 100%
      );
      border-radius: 50px;
      transition: 0.5s ease-in-out;
    }
  }

  .stepper-options {
    width: 62%;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #c4c4c4;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5%;

      span {
        transition: 0.3s ease-in-out;
      }
    }
  }
`
