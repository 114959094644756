import { Avatar } from '@material-ui/core'
import styled, { css } from 'styled-components'

interface ContentProps {
  hasSomeMedia: boolean
}

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
`

export const Content = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: ${({ hasSomeMedia }) =>
    hasSomeMedia ? '5.5fr 2.1fr 2.4fr' : '7.6fr 2.4fr'};

  height: calc(100% - 92px);
`
