import React from 'react';

const CreateGoalIcon = ({color = "#37474F"}) => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.834 0.5C6.69232 0.5 3.33398 3.85833 3.33398 8H0.833984L4.16732 11.3333L7.50065 8H5.00065C5.00065 4.78333 7.61732 2.16667 10.834 2.16667C14.0507 2.16667 16.6673 4.78333 16.6673 8C16.6673 11.2167 14.0507 13.8333 10.834 13.8333C9.25065 13.8333 7.81732 13.2 6.76732 12.175L5.58398 13.35C6.93398 14.675 8.79232 15.5 10.834 15.5C14.9757 15.5 18.334 12.1417 18.334 8C18.334 3.85833 14.9757 0.5 10.834 0.5ZM12.5007 7.16667V6.33333C12.5007 5.41667 11.7507 4.66667 10.834 4.66667C9.91732 4.66667 9.16732 5.41667 9.16732 6.33333V7.16667C8.70898 7.16667 8.33398 7.54167 8.33398 8V10.5C8.33398 10.9583 8.70898 11.3333 9.16732 11.3333H12.5007C12.959 11.3333 13.334 10.9583 13.334 10.5V8C13.334 7.54167 12.959 7.16667 12.5007 7.16667ZM11.6673 7.16667H10.0007V6.33333C10.0007 5.875 10.3757 5.5 10.834 5.5C11.2923 5.5 11.6673 5.875 11.6673 6.33333V7.16667Z" 
    fill={color}/>
    </svg>
  );
}

export default CreateGoalIcon;