import { Switch, Redirect } from 'react-router-dom'
import AddGoal from 'views/Goals/components/AddGoal'
import { Groups } from 'views/Groups'

import { RouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Owner as OwnerLayout
} from './layouts'

import {
  Dashboard as DashboardView,
  Inputs as InputsView,
  UserList as UserListView,
  Settings as SettingsView,
  // Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Goals as GoalsView,
  Goal as GoalView,
  Input as InputView,
  Reports as ReportsView,
  Stores as StoresView,
  Sellers as SellersView,
  Services as ServicesView,
  ServiceDay as ServiceDayView
  // StoreSettings as StoreSettingsView
} from './views'

import { Sprints } from 'views/Sprints'
import { Storylines } from 'views/Storylines'
import { CreateStoryline } from 'views/CreateStoryline'
import { EditStoryline } from 'views/EditStoryline'
import { StorylineSettings } from 'views/StorylineSettings'

const Routes = () => {
  const token = window.localStorage.getItem('@NeoPro:token')

  return (
    <Switch>
      <Redirect exact from='/' to={token ? '/stores' : '/login'} />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path='/:storeId/dashboard'
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path='/:storeId/users'
      />
      <RouteWithLayout
        component={InputsView}
        exact
        layout={MainLayout}
        path='/:storeId/inputs'
      />
      <RouteWithLayout
        component={InputView}
        exact
        layout={MainLayout}
        path='/:storeId/inputs/:id'
      />
      <RouteWithLayout
        component={StoresView}
        exact
        layout={OwnerLayout}
        path='/stores'
      />
      <RouteWithLayout
        component={Groups}
        exact
        layout={OwnerLayout}
        path='/groups'
      />
      <RouteWithLayout
        component={Storylines}
        exact
        layout={OwnerLayout}
        path='/storylines'
      />

      <RouteWithLayout
        component={CreateStoryline}
        exact
        layout={MinimalLayout}
        path='/storylines/create'
      />
      <RouteWithLayout
        component={EditStoryline}
        exact
        layout={MinimalLayout}
        path='/storylines/:storylineId/edit'
      />
      <RouteWithLayout
        component={StorylineSettings}
        exact
        layout={OwnerLayout}
        path='/storylines/settings'
      />
      <RouteWithLayout
        component={Sprints}
        exact
        layout={MainLayout}
        path='/:storeId/sprints'
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path='/store/:id'
      />
      <RouteWithLayout
        component={SellersView}
        exact
        layout={OwnerLayout}
        path='/:storeId/sellers'
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path='/:storeId/settings'
      />
      <RouteWithLayout
        component={GoalsView}
        exact
        layout={MainLayout}
        path='/:storeId/goals'
      />
      <RouteWithLayout
        component={AddGoal}
        exact
        layout={MinimalLayout}
        path='/:storeId/goals/create'
      />
      <RouteWithLayout
        component={AddGoal}
        exact
        layout={MinimalLayout}
        path='/:storeId/goals/:goalId/edit'
      />
      <RouteWithLayout
        component={ServicesView}
        exact
        layout={MainLayout}
        path='/:storeId/events'
      />
      <RouteWithLayout
        component={ServiceDayView}
        exact
        layout={MainLayout}
        path='/:storeId/events/:dayId'
      />
      <RouteWithLayout
        component={GoalView}
        exact
        layout={MainLayout}
        path='/:storeId/goals/:id/:userId?'
      />
      <RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        path='/:storeId/report'
      />
      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/register"
      /> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path='/login'
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path='/not-found'
      />
      <Redirect to='/not-found' />
    </Switch>
  )
}

export default Routes
