import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import App from './App'
import store from './store/index'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn: 'https://e686c6463f6042cb8db42df6a333ec6e@o4504797799448576.ingest.sentry.io/4504797803577344',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  })

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
