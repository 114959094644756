import { Action } from 'redux'
import produce from 'immer'
import { IStoryAPIResponse, IStoryLine, IStoryLineProps } from './types'

const INITIAL_STATE: IStoryLineProps = {
  activeStorylines: [],
  draftStorylines: [],
  finishedStorylines: [],
  settings: {
    _id: '',
    hasNeoStory: false,
    name: '',
    tags: [],
    photo: {
      url: null
    }
  },
  loading: false,
  activeStorylinesLoading: true,
  finishedStorylinesLoading: true,
  hasRequested: false,
  metadata: {
    pagination: {
      currentPage: 1,
      limit: 5,
      pageCount: 1,
      totalCount: -1
    }
  }
}

interface ActionProps extends Action<string> {
  payload: any
}

export default function storylines(state = INITIAL_STATE, action: ActionProps) {
  return produce(state, draft => {
    switch (action.type) {
      case '@storylines/GET_ACTIVE_STORYLINES_REQUEST': {
        draft.loading = true
        draft.activeStorylinesLoading = true
        break
      }
      case '@storylines/GET_FINISHED_STORYLINES_REQUEST': {
        draft.loading = true
        draft.finishedStorylinesLoading = true
        break
      }
      case '@storylines/GET_DRAFT_STORYLINES_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/GET_ACTIVE_STORYLINES_SUCCESS': {
        draft.activeStorylines = action.payload.activeStorylines
        draft.loading = false
        draft.activeStorylinesLoading = false
        draft.hasRequested = true
        break
      }
      case '@storylines/GET_DRAFT_STORYLINES_SUCCESS': {
        draft.draftStorylines = action.payload.draftStorylines.sort(
          (a: IStoryLine, b: IStoryLine) =>
            new Date(a.period.start).getTime() -
            new Date(b.period.end).getTime()
        )
        draft.loading = false
        draft.hasRequested = true
        break
      }
      case '@storylines/GET_FINISHED_STORYLINES_SUCCESS': {
        draft.finishedStorylines = action.payload.finishedStorylines
        draft.metadata = action.payload.metadata
        draft.loading = false
        draft.finishedStorylinesLoading = false
        draft.hasRequested = true
        break
      }
      case '@storylines/CREATE_NEW_STORYLINE_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/CREATE_NEW_STORYLINE_SUCCESS': {
        if (action.payload.newStoryline) {
          draft.activeStorylines = [
            ...draft.activeStorylines,
            action.payload.newStoryline
          ].sort(
            (a: IStoryLine, b: IStoryLine) =>
              new Date(a.period.start).getTime() -
              new Date(b.period.end).getTime()
          )
        }
        break
      }
      case '@storylines/CREATE_STORYLINE_TAG_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/CREATE_STORYLINE_TAG_SUCCESS': {
        draft.settings.tags = [...draft.settings.tags, action.payload.newTag]
        draft.loading = false
        break
      }
      case '@storylines/UPDATE_STORYLINE_TAG_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/UPDATE_STORYLINE_TAG_SUCCESS': {
        draft.settings.tags = draft.settings.tags.map(tag => {
          if (tag._id === action.payload.updatedTag._id) {
            return {
              ...tag,
              ...action.payload.updatedTag
            }
          }
          return tag
        })

        draft.loading = false
        break
      }
      case '@storylines/UPDATE_STORYLINE_TAG_AVATAR_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/UPDATE_STORYLINE_TAG_AVATAR_SUCCESS': {
        draft.settings.tags = draft.settings.tags.map(tag => {
          if (tag._id === action.payload.updatedTag._id) {
            return action.payload.updatedTag
          }
          return tag
        })

        draft.loading = false
        break
      }
      case '@storylines/UPDATE_STORYLINE_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/LINK_STORIES_TO_STORYLINE_SUCCESS': {
        draft.activeStorylines = draft.activeStorylines.map(storyline => {
          if (storyline._id === action.payload.storylineId) {
            return {
              ...storyline,
              photo: {
                url: action.payload.url
              }
            }
          }
          return storyline
        })
        break
      }
      case '@storylines/ACTIVE_STORYLINE_SUCCESS': {
        draft.loading = false
        break
      }
      case '@storylines/UPDATE_STORYLINE_SUCCESS': {
        const updatedStorylineIndex = draft.activeStorylines.findIndex(
          storylines => storylines._id === action.payload.updatedStoryline._id
        )

        if (updatedStorylineIndex >= 0) {
          draft.activeStorylines[updatedStorylineIndex] =
            action.payload.updatedStoryline
        }
        draft.loading = false
        break
      }
      // case '@storylines/UPDATE_STORYLINES_AVATAR_SUCCESS': {
      //   const storyline = draft.activeStorylines.find(storyline =>
      //     action.payload.storylinesIds.includes(storyline._id)
      //   )
      //   const updatedStorylineIndex = draft.activeStorylines.findIndex(
      //     storylines => storylines._id === storyline?._id
      //   )

      //   if (updatedStorylineIndex >= 0) {
      //     draft.activeStorylines[updatedStorylineIndex].photo.url =
      //       action.payload.updatedStorylineAvatarUrl
      //   }
      //   break
      // }
      case '@storylines/DELETE_STORYLINE_TAG_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/DELETE_STORYLINE_TAG_SUCCESS': {
        draft.settings.tags = draft.settings.tags.filter(
          tag => tag._id !== action.payload.tagId
        )

        draft.loading = false
        break
      }
      case '@storylines/DELETE_STORYLINE_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/DELETE_STORYLINE_SUCCESS': {
        const deletedActiveStorylineIndex = draft.activeStorylines.findIndex(
          storylines => storylines._id === action.payload.storylineId
        )
        if (deletedActiveStorylineIndex >= 0) {
          draft.activeStorylines.splice(deletedActiveStorylineIndex, 1)
        }

        const deletedDraftStorylineIndex = draft.draftStorylines.findIndex(
          storylines => storylines._id === action.payload.storylineId
        )
        if (deletedDraftStorylineIndex >= 0) {
          draft.activeStorylines.splice(deletedDraftStorylineIndex, 1)
        }

        draft.loading = false
        break
      }
      case '@storylines/GET_STORIES_FROM_STORYLINE_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/GET_STORIES_FROM_STORYLINE_SUCCESS': {
        const isActiveStoryline = draft.activeStorylines.find(
          storyline => storyline._id === action.payload.storylineId
        )

        if (isActiveStoryline) {
          draft.activeStorylines = draft.activeStorylines.map(storyline => {
            if (storyline._id === action.payload.storylineId) {
              return {
                ...storyline,
                stories: action.payload.stories.map(
                  (story: IStoryAPIResponse) => ({
                    url: story.content.url,
                    type: story.content.type,
                    link: story.link ?? ''
                  })
                )
              }
            }
            return storyline
          })
        } else {
          draft.draftStorylines = draft.draftStorylines.map(storyline => {
            if (storyline._id === action.payload.storylineId) {
              return {
                ...storyline,
                stories: action.payload.stories.map(
                  (story: IStoryAPIResponse) => ({
                    url: story.content.url,
                    type: story.content.type,
                    link: story.link ?? ''
                  })
                )
              }
            }
            return storyline
          })
        }

        draft.loading = false
        break
      }
      case '@storylines/UPDATE_STORYLINE_SETTINGS_AVATAR_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/UPDATE_STORYLINE_SETTINGS_AVATAR_SUCCESS': {
        draft.settings.photo.url = action.payload.newStorylineSettingsAvatar
        draft.loading = false
        break
      }
      case '@storylines/GET_STORYLINE_SETTINGS_SUCCESS': {
        draft.settings = action.payload.settings
        break
      }
      case '@storylines/UPDATE_STORYLINE_SETTINGS_REQUEST': {
        draft.loading = true
        break
      }
      case '@storylines/UPDATE_STORYLINE_NAME_SUCCESS': {
        draft.settings.name = action.payload.newName
        draft.loading = false
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      case '@error/REQUEST_FAILED': {
        return INITIAL_STATE
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
