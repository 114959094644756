import { useState } from 'react'
import { DateRange } from '@material-ui/icons'
import { Typography, Grid, useMediaQuery } from '@material-ui/core'
import { Calendar, StoresCalendar, Container } from './styles'
import moment from 'moment'
import jwt from 'jwt-decode'

import CalendarWithRangeDates from 'components/CalendarWithRangeDates'
import { useTheme } from '@material-ui/core/styles'
import { storesReportRequest } from 'store/modules/report/actions'
import { useDispatch } from 'react-redux'

interface RangeProps {
  from: Date
  to: Date
}

interface CalendarRangeProps {
  from: Date
  to: Date
}
interface StoresDatePickerProps {
  range: RangeProps
}

export const StoresDatePicker = ({ range }: StoresDatePickerProps) => {
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false)

  const theme = useTheme()
  const dispatch = useDispatch()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const handleDayClick = (calendarRange: CalendarRangeProps) => {
    const token = window.localStorage.getItem('@NeoPro:token')
    if (token) {
      const user = jwt<{ id: string }>(token)

      dispatch(storesReportRequest(user.id, token, calendarRange))
    }
  }

  const today = new Date()
  const maxDateAllowed = new Date(today.getFullYear(), today.getMonth() + 1, 1)
  maxDateAllowed.setDate(maxDateAllowed.getDate() - 1)

  return (
    <Container isDesktop={isDesktop}>
      <StoresCalendar
        onClick={() => {
          setIsCalendarPickerOpen(true)
        }}
        hasStores
      >
        <DateRange style={{ width: 16, marginRight: 5 }} />
        <Typography>
          {range.from ? moment(range.from).format('DD/MM/YYYY') : '-'}
        </Typography>
        <Typography>até</Typography>
        <Typography>
          {range.to ? moment(range.to).format('DD/MM/YYYY') : '-'}
        </Typography>
      </StoresCalendar>
      <Grid item>
        {isCalendarPickerOpen && (
          <Calendar isDesktop={isDesktop}>
            <CalendarWithRangeDates
              openCalendar={isCalendarPickerOpen}
              setOpenCalendar={setIsCalendarPickerOpen}
              handleDayClick={handleDayClick}
              range={range}
              maxDate={maxDateAllowed}
            />
          </Calendar>
        )}
      </Grid>
    </Container>
  )
}
