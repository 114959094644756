import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import CurrencyTextField from '../../../../../../components/CurrencyTextField'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: { margin: theme.spacing(2) },
  margin: { margin: theme.spacing(1) }
}))

const EditDayModal = ({
  active,
  onClose,
  onSubmit,
  data,
  userList,
  ...rest
}) => {
  const classes = useStyles()
  const [day, setDay] = useState({
    working: false,
    users: [],
    goal: 0
  })

  useEffect(() => {
    if (data)
      setDay({
        working: data.working,
        users: data.users,
        goal: data.goal,
        _id: data._id,
        date: data.date
      })
  }, [data])

  return (
    <div {...rest} className={classes.root}>
      <Dialog open={active} onClose={onClose} maxWidth='sm' fullWidth>
        <DialogTitle id='alert-dialog-title'>
          {data && moment(data.date).format('DD - dddd')}{' '}
          {process.env.NODE_ENV === 'development' && `(${day._id})`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} direction='column'>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={day.working}
                        onChange={() =>
                          setDay({ ...day, working: !day.working })
                        }
                        name='working'
                      />
                    }
                    label='Dia Utíl'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <CurrencyTextField
                fullWidth
                className={classes.margin}
                onFocus={event => event.target.select()}
                label='Meta'
                variant='outlined'
                value={day.goal}
                currencySymbol='R$'
                decimalCharacter=','
                digitGroupSeparator='.'
                minimumValue='0'
                inputProps={{ style: { textAlign: 'left' } }}
                onChange={(e, value) =>
                  setDay({
                    ...day,
                    goal: value || 0,
                    working:
                      !day.working && Number(value) > 0 ? true : day.working
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancelar
          </Button>
          <Button onClick={() => onSubmit(day)} color='primary' autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditDayModal
