import styled from 'styled-components'
import { Modal } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'
import DayPicker from 'react-day-picker'

interface ReportCalendarProps {
  hasReport: boolean
}

interface CalendarProps {
  isDesktop: boolean
}

interface SheetButtonProps {
  isModalButton?: boolean
}

export const SheetButton = styled.button<SheetButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #188038;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  color: white;
  /* margin-left: auto; */
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-family: 'Roboto';
  font-weight: bold;
  width: ${({ isModalButton }) => (isModalButton ? '55%' : 'auto')};

  &:hover {
    background: #126d2d;
  }
  &:first-child {
    margin-left: auto;
    margin-right: 12px;
  }

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #188038;
  }
`

export const SheetModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';

  div.content {
    background: white;
    border-radius: 12px;
    padding: 30px;
    width: 500px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none !important;
    position: relative;
  }

  h1 {
    font-size: 24px;
    max-width: 80%;
    text-align: center;
    margin-bottom: 20px;
  }

  span {
    text-align: center;
  }

  div.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    z-index: 66;
    cursor: pointer;
  }

  div.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px auto;
  }
`

export const HeaderStore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  height: 48px;
  background: #e5e9eb;

  //Font
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #263238;
`

export const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ReportCalendar = styled.div<ReportCalendarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  border-radius: 4px;
  gap: 5px;
  padding: 10px 18px;
  width: 100%;
  cursor: ${({ hasReport }) => (hasReport ? 'pointer' : 'not-allowed')};
`

export const Calendar = styled.div<CalendarProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  left: ${props => (props.isDesktop ? '347px' : '107px')};
  margin-top: -10px;
  /* background-color: #fff; */
  padding: 6px 4px;
  border-radius: 4px;
  /* border: 1px solid #c4c4c4; */
`

export const Picker = styled(DayPicker)`
  font-family: 'Roboto';

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-Day--outside {
    color: #e1e1e1 !important;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #fff !important;
  }

  .DayPicker-Day--selected {
    background-color: #e7f3fd !important;
    color: #4a90e2 !important;
  }

  .DayPicker-Day--unUtilDay {
    color: #c1c1c1 !important;
  }

  .DayPicker-Day--start {
    background-color: #4a90e2 !important;
    color: white !important;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
    pointer-events: none;
  }

  .DayPicker-Day--end,
  .DayPicker-Day--hoverDay {
    background-color: #4a90e2 !important;
    color: white !important;
    border-radius: 0px 30px 30px 0px !important;
    font-weight: 600;
    cursor: pointer;
  }

  .DayPicker-Day--to {
    background-color: #4a90e2 !important;
    color: white;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
  }

  .DayPicker-Day--from {
    background-color: #4a90e2 !important;
    color: white;
    border-radius: 30px 0px 0px 30px !important;
    font-weight: 600;
  }

  .DayPicker-Day--startEnd {
    border-radius: 50% !important;
  }
  .DayPicker-NavButton {
    margin-top: -14px;
  }

  .DayPicker-Weekdays {
    border-bottom: 1px solid #eeeeee;
  }

  .DayPicker-Weekday {
    color: #546e7a;
    font-weight: 500;
    font-size: 14px !important;
  }

  .DayPicker-Day {
    font-family: 'Roboto', sans-serif !important;
    border-radius: 0px;
    width: 25px;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    color: #e1e1e1 !important;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #fff !important;
  }
`

export const VisiblePerfectScrollbar = styled(PerfectScrollbar)`
  .ps__rail-x {
    opacity: 0.6;
  }

  .ps__thumb-x {
    height: 11px;
  }
`
