import styled from 'styled-components'

export const WrapperBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 34px;
  background-color: ${props => (props.different ? '#fffde7' : '#fafafa')};
  padding: 4px;
  color: ${props => (props.different ? '#3e2723' : '#263238')};
`

export const ListSeller = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ReviewWrapper = styled(WrapperBase)`
  height: 46px;
  margin: 16px 0px;
`

export const HistoryWrapper = styled(WrapperBase)`
  height: 34px;
  margin: 6px 0px;
`

export const SellerBase = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 220px;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: 4px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 50px;
  }
`

export const FontBase = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`

export const FontBold = styled(FontBase)`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SellerSold = styled(FontBase)`
  font-weight: 600;
`

export const FontNormal = styled(FontBase)`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`

export const TotalSoldInfo = styled(SellerBase)``

export const SellerHeader = styled(SellerBase)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  gap: 10px;
`

export const Header = styled(SellerBase)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`
export const SoldInfo = styled(SellerBase)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`
