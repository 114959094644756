import { CancelTokenSource } from 'axios'
import {
  IGroup,
  IMonthReport,
  IRange,
  IStoresGroup,
  IStoresReport
} from './types'

export function reportRequest(
  storeId: string,
  token: string | null,
  range: IRange
) {
  return {
    type: '@report/LIST_REQUEST',
    payload: { storeId, token, range }
  }
}

export function reportSuccess(reportsList: IMonthReport, range: IRange) {
  return {
    type: '@report/LIST_SUCCESS',
    payload: { reportsList, range }
  }
}

export function monthRequest(
  storeId: string,
  token: string | null,
  range?: IRange
) {
  return {
    type: '@report/MONTH_REQUEST',
    payload: { storeId, token, range }
  }
}

export function monthSuccess(monthReport: IMonthReport) {
  return {
    type: '@report/MONTH_SUCCESS',
    payload: { monthReport }
  }
}

export function storesReportRequest(
  userId: string,
  token: string | null,
  range?: IRange
) {
  return {
    type: '@report/STORES_REQUEST',
    payload: { userId, token, range }
  }
}

export function storesReportSuccess(
  storesReport: IStoresReport,
  range?: IRange
) {
  return {
    type: '@report/STORES_SUCCESS',
    payload: { storesReport, range }
  }
}

export function updateStoresFromStoresReport(
  newStoresFromStoresReport: IStoresGroup[]
) {
  return {
    type: '@report/UPDATE_STORES_FROM_STORES_REPORT',
    payload: { newStoresFromStoresReport }
  }
}

export function updateGroupsFromStoresReport(
  newGroupsFromStoresReport: IGroup[]
) {
  return {
    type: '@report/UPDATE_GROUPS_FROM_STORES_REPORT',
    payload: { newGroupsFromStoresReport }
  }
}

export function addGroupToStoresReport(newGroup: IGroup) {
  return {
    type: '@report/ADD_GROUP_TO_STORES_REPORT',
    payload: { newGroup }
  }
}

export function updateGroupFromStoresReport(updatedGroup: IGroup) {
  return {
    type: '@report/UPDATE_GROUP_FROM_STORES_REPORT',
    payload: { updatedGroup }
  }
}

export function addAxiosCancelToken(axiosCancelToken: CancelTokenSource) {
  return {
    type: '@report/ADD_AXIOS_CANCEL_TOKEN',
    payload: { axiosCancelToken }
  }
}
