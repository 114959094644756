import { Avatar, Card as CardMUI } from '@material-ui/core'
import styled from 'styled-components'

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const Card = styled(CardMUI)`
  height: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
  margin: 26px 0 18px;
`
export const CustomAvatar = styled(Avatar)`
  height: 88px;
  width: 88px;
  border-radius: 50%;
`
