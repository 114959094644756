import { Dispatch, SetStateAction, useState } from 'react'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { Modal } from '@material-ui/core'
import { differenceInDays, isSameDay } from 'date-fns'
import { MdMailOutline } from 'react-icons/md'
import { BiSend } from 'react-icons/bi'
import {
  Draw as DrawIcon,
  CollectionsOutlined as CollectionsOutlinedIcon
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

import { PromptModal } from 'views/CreateStoryline/styles'
import { Select } from './components/Select/Select'
import { StoresSelect } from './components/StoresSelect'
import { Thumbnail } from './components/Thumbnail'
import { SwitchPermanentStory } from './components/SwitchPermanentStory'
import { Calendar } from './components/Calendar'
import { StoriesForm } from './components/StoriesForm'
import { createNewStorylineRequest } from 'store/modules/storylines/actions'
import {
  ICreateStoryline,
  IStoryLineProps,
  ITag
} from 'store/modules/storylines/types'
import api from 'repositories/api'

import {
  Container,
  Content,
  Divider,
  Footer,
  FooterSaveButton,
  FormContainer,
  Label,
  StoryPagesNumber,
  TextInput
} from './styles'

export interface SetStoryFieldValueFunctionProps {
  field:
    | 'name'
    | 'period.start'
    | 'period.end'
    | 'tags'
    | 'visibilitySettings.roles'
    | 'visibilitySettings.storeIds'

  data: string | string[] | ITag[]
}

export interface IFile {
  [key: string]: File
}

interface ILink {
  [key: string]: string
}

interface ErrorProps {
  name?: boolean
  tags?: boolean
  'visibilitySettings.roles'?: boolean
  hasSelectedStores?: boolean
  hasMedia?: boolean
}

export interface SelectedMediaProps {
  file: File | null
  url: string | null
  link: string
  index: number
}

export interface CustomICreateStoryline
  extends Omit<ICreateStoryline, 'visibilitySettings' | 'tagIcon' | 'tags'> {
  tags: ITag[]
  visibilitySettings: {
    storeIds: string[]
    roles: (
      | 'Vendedor'
      | 'Gerente'
      | 'Caixa'
      | 'Dono'
      | 'Diretor'
      | 'Comunicador'
    )[]
  }
}

interface SelectorStorylines {
  state: DefaultRootState
  storylines: IStoryLineProps
}

interface FormProps {
  files: IFile
  links: ILink
  previewUrls: string[]
  isAddingNewMedia: boolean
  isEditingMedia: boolean
  currentFile: File | undefined
  currentLink: string
  selectedMedia: SelectedMediaProps
  currentMediaUrlPreview: string
  storyData: CustomICreateStoryline
  setStoryData: Dispatch<SetStateAction<CustomICreateStoryline>>
  handleAdd: () => void
  handleEditMedia: (srcImg: string) => void
  handleChangeMedia: (image: File, srcImg: string) => void
}

export const Form = ({
  files,
  links,
  handleAdd,
  setStoryData,
  storyData,
  currentLink,
  currentFile,
  previewUrls,
  currentMediaUrlPreview,
  isAddingNewMedia,
  isEditingMedia,
  selectedMedia,
  handleChangeMedia,
  handleEditMedia
}: FormProps) => {
  const roles = {
    Vendedor: 'seller',
    Gerente: 'manager',
    Caixa: 'cashier',
    Dono: 'owner',
    Diretor: 'director',
    Comunicador: 'communicator',
    Supervisor: 'supervisor'
  } as const

  type RolesKeys = keyof typeof roles

  const [isStoreSelected, setIsStoreSelected] = useState<{
    [key: string]: boolean
  }>({})

  const [inputErrors, setInputErrors] = useState<ErrorProps>({
    name: false,
    tags: false,
    'visibilitySettings.roles': false,
    hasSelectedStores: false,
    hasMedia: false
  })
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const [isCreateStorylineModalOpen, setIsCreateStorylineModalOpen] =
    useState(false)

  const [file, setFile] = useState<File | null>(null)
  const [reqLoading, setReqLoading] = useState(false)

  const { loading, settings } = useSelector<
    SelectorStorylines,
    IStoryLineProps
  >(state => state.storylines)

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  const setNewSprintFieldValue = ({
    field,
    data
  }: SetStoryFieldValueFunctionProps) => {
    const splitField = field.split('.')
    const hasTwoProperties = splitField.length > 1

    if (hasTwoProperties) {
      const key = splitField[0] as 'period'
      setStoryData(previousState => ({
        ...previousState,
        [splitField[0]]: {
          ...previousState[key],
          [splitField[1]]:
            splitField[1] === 'roles' ? ['Dono', 'Comunicador', ...data] : data
        }
      }))
    } else {
      setStoryData(previousState => ({ ...previousState, [field]: data }))
    }
  }

  const checkInputErrors = (fromSubmitButton = false) => {
    const { name, visibilitySettings } = storyData

    const errors = {
      name: name.length < 1 || name.length > 64,
      'visibilitySettings.roles': visibilitySettings.roles.length === 0,
      hasSelectedStore:
        Object.values(isStoreSelected).filter(bool => bool).length === 0,
      hasMedia: Object.keys(files).length === 0
    } as ErrorProps

    if (!fromSubmitButton) {
      setInputErrors(errors)
    }

    if (Object.values(errors).filter(err => err).length > 0) {
      return true
    }

    return false
  }

  const handleCreateStoryline = async () => {
    const hasInputErrors = checkInputErrors()
    if (hasInputErrors) {
      setHasSubmitted(true)
      return
    }

    const storeIds: string[] = []

    Object.entries(isStoreSelected).forEach(([key, value]) => {
      if (value) {
        storeIds.push(key)
      }
    })

    const formData = new FormData()

    if (!!file) {
      formData.append('thumbnail', file)
    } else if (storyData.tags[0] && storyData.tags[0].photo.url) {
      setReqLoading(true)
      const response = await api.axios.get(
        `/company/download-tag-photo/tag/${storyData.tags[0]._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          responseType: 'arraybuffer'
        }
      )

      const blobFile = new Blob([response.data])
      const file = new File([blobFile], 'thumbnail')

      formData.append('thumbnail', file)
    }

    const formattedStoryData = {
      ...storyData,
      tagIcon: formData,
      period: {
        start: storyData.period.start,
        end: storyData.type === 'temporary' ? storyData.period.end : null
      },
      tags: storyData.tags.map(tag => tag._id),
      visibilitySettings: {
        roles: storyData.visibilitySettings.roles.map(
          (role: RolesKeys) => roles[role]
        ),
        storeIds: storeIds
      }
    }

    dispatch(createNewStorylineRequest(formattedStoryData, files, links, token))

    setReqLoading(false)
  }

  const handleChangeTagsSelect = (tags: string[]) => {
    const selectedTags = tags.map(tag => {
      const settingsTag = settings.tags.find(el => el.name === tag)

      return settingsTag!
    })

    setNewSprintFieldValue({
      field: 'tags',
      data: selectedTags
    })
  }

  const storylineDurationInDays = storyData.period.end
    ? differenceInDays(
        new Date(storyData.period.end),
        new Date(storyData.period.start)
      ) + 1
    : 0

  const isEditing =
    (selectedMedia.url &&
      (selectedMedia.url !== currentMediaUrlPreview ||
        selectedMedia.link !== currentLink)) ||
    (isAddingNewMedia && !isEditingMedia) ||
    (isAddingNewMedia && !currentMediaUrlPreview)

  return (
    <Container>
      <Content>
        <FormContainer>
          <Label>
            <DrawIcon />
            COMUNICADO
          </Label>
          <TextInput
            label='Título'
            variant='outlined'
            value={storyData.name}
            error={hasSubmitted && inputErrors.name}
            onChange={event =>
              setNewSprintFieldValue({
                field: 'name',
                data: event.target.value
              })
            }
          />

          <Select
            label='Tags'
            options={settings.tags.map(tag => tag.name)}
            error={hasSubmitted && inputErrors.tags}
            handleChange={handleChangeTagsSelect}
            value={storyData.tags.map(tag => tag.name)}
          />

          <Thumbnail
            file={file}
            firstStoryDataImage={storyData.tags[0]?.photo?.url}
            setFile={setFile}
          />

          <SwitchPermanentStory
            type={storyData.type}
            setStoryData={setStoryData}
          />

          <Calendar
            storyData={storyData}
            setNewSprintFieldValue={setNewSprintFieldValue}
          />

          <Label style={{ fontWeight: 400 }}>
            Divulgação:
            <b style={{ marginLeft: 2 }}>
              {storyData.type === 'fixed'
                ? 'Permanente'
                : `${storylineDurationInDays} dia${
                    storylineDurationInDays > 1 ? 's' : ''
                  }`}
            </b>
          </Label>
          <Divider />
          <Label>
            <BiSend size={24} />
            DESTINATÁRIOS
          </Label>

          <Select
            label='Tipos de usuários'
            error={hasSubmitted && inputErrors['visibilitySettings.roles']}
            options={Object.keys(roles).filter(
              key => key !== 'Dono' && key !== 'Comunicador'
            )}
            handleChange={(userRoles: string[]) =>
              setNewSprintFieldValue({
                field: 'visibilitySettings.roles',
                data: userRoles
              })
            }
            value={storyData.visibilitySettings.roles.filter(
              role => role !== 'Dono' && role !== 'Comunicador'
            )}
          />

          <StoresSelect
            error={hasSubmitted && inputErrors.hasSelectedStores}
            isStoreSelected={isStoreSelected}
            setIsStoreSelected={setIsStoreSelected}
          />
          <Divider />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label>
              <CollectionsOutlinedIcon />
              PÁGINAS
            </Label>

            <StoryPagesNumber storiesLength={Object.keys(files).length}>
              {Object.keys(files).length}/10
            </StoryPagesNumber>
          </div>

          <StoriesForm
            currentFile={currentFile}
            currentMediaUrlPreview={currentMediaUrlPreview}
            files={files}
            previewUrls={previewUrls}
            hasError={inputErrors.hasMedia}
            isAddingNewMedia={isAddingNewMedia}
            isEditingMedia={isEditingMedia}
            selectedMedia={selectedMedia}
            handleAdd={handleAdd}
            handleChangeMedia={handleChangeMedia}
            handleEditMedia={handleEditMedia}
          />
        </FormContainer>

        <Footer>
          <FooterSaveButton
            disabled={checkInputErrors(true) || isEditing}
            variant='contained'
            onClick={() => setIsCreateStorylineModalOpen(true)}
          >
            {isSameDay(new Date(storyData.period.start), new Date())
              ? 'Enviar comunicado'
              : 'Agendar comunicado'}
          </FooterSaveButton>
        </Footer>
      </Content>

      <Modal open={isCreateStorylineModalOpen}>
        <PromptModal
          icon={<MdMailOutline color='#2a3ecb' />}
          title={
            isSameDay(new Date(storyData.period.start), new Date())
              ? 'Enviar comunicado?'
              : 'Agendar comunicado?'
          }
          onClose={() =>
            !loading && !reqLoading && setIsCreateStorylineModalOpen(false)
          }
          leftTitle='Continuar edição'
          onRight={!loading && !reqLoading ? handleCreateStoryline : () => {}}
          leftButtonStyles={{
            ...((loading || reqLoading) && {
              cursor: 'not-allowed'
            })
          }}
          rightButtonStyles={{
            backgroundColor: '#27AE60'
          }}
          rightTitle={
            loading || reqLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress
                  size={16}
                  color='inherit'
                  style={{ marginRight: 12 }}
                />
                Enviando
              </div>
            ) : (
              'Enviar'
            )
          }
        />
      </Modal>
    </Container>
  )
}
