import { ChangeEvent, useEffect, useState } from 'react'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Avatar, Badge } from '@material-ui/core'
import { FaCamera } from 'react-icons/fa'
import { BiPlus, BiTrash } from 'react-icons/bi'
import { MdOutlineEdit } from 'react-icons/md'
import { PiLinkSimpleBold } from 'react-icons/pi'

import { CreateTagModal } from './components/CreateTagModal'
import { EditTagModal } from './components/EditTagModal'
import { DeleteTagModal } from './components/DeleteTagModal'
import { IStoryLineProps, ITag } from 'store/modules/storylines/types'

import {
  updateStorylineSettingsAvatarRequest,
  updateStorylineNameRequest
} from 'store/modules/storylines/actions'

import {
  AddTagButton,
  BadgeContent,
  Card,
  CardContainer,
  CardTitle,
  Container,
  Content,
  Divider,
  IconsContainer,
  Main,
  NameTagContainer,
  SaveButton,
  StoreAvatar,
  StoryBar,
  StoryBarContainer,
  StoryButtonFooter,
  StoryHeader,
  StoryPreview,
  Tag,
  TagsContainer,
  TextInput
} from './styles'
import { IUserProps } from 'store/modules/user/types'
import { browserHistory } from 'App'

interface SelectorStorylines {
  state: DefaultRootState
  storylines: IStoryLineProps
}

interface SelectorUser {
  state: DefaultRootState
  user: IUserProps
}

export const StorylineSettings = () => {
  const [photo, setPhoto] = useState<FormData>()
  const [storyName, setStoryName] = useState('')
  const [previewUrlImage, setPreviewUrlImage] = useState('')

  const [selectedTag, setSelectedTag] = useState({} as ITag)
  const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState(false)
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false)
  const [isDeleteTagModalOpen, setIsDeleteTagModalOpen] = useState(false)

  const { settings } = useSelector<SelectorStorylines, IStoryLineProps>(
    state => state.storylines
  )

  const {
    user,
    user: { company }
  } = useSelector<SelectorUser, IUserProps>(state => state.user)

  const dispatch = useDispatch()

  const token = window.localStorage.getItem('@NeoPro:token')

  useEffect(() => {
    if (user._id && !user.company.hasNeoStory) {
      const userStores = user.stores.filter(s =>
        ['owner', 'manager', 'cashier', 'director'].includes(s.type)
      )
      if (userStores.length > 1) {
        browserHistory.push('/stores')
      } else {
        browserHistory.push(`/${user.stores[0].storeId._id}/dashboard`)
      }
    }
  }, [user])

  useEffect(() => {
    setStoryName(settings.name)
    setPreviewUrlImage(settings.photo.url ?? '')
  }, [settings])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const data = new FormData()
    const { files } = e.target

    if (files && files.length > 0) {
      var fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)) // MB
      if (fileSize < 5) {
        const previewURL = URL.createObjectURL(files[0])
        data.append('photo', files[0])

        setPreviewUrlImage(previewURL)
        setPhoto(data)
      } else {
        toast.error('Imagem muito grande! A imagem deve ser menor que 5MB.')
      }
    }
  }

  const handleSaveStorylineSettings = () => {
    if (!company._id) {
      return toast.error('Algo deu errado')
    }
    if (settings.name !== storyName) {
      dispatch(updateStorylineNameRequest(storyName, company._id, token))
    }
    if (!!photo) {
      dispatch(updateStorylineSettingsAvatarRequest(photo, company._id, token))
      setPhoto(undefined)
    }
  }

  return (
    <Container>
      <h1>Configuração de Comunicados</h1>

      <Content>
        <Main>
          <CardContainer>
            <Card>
              <label htmlFor='storyImage' style={{ cursor: 'pointer' }}>
                <Badge
                  overlap='circular'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  badgeContent={<BadgeContent />}
                >
                  <StoreAvatar src={previewUrlImage}>
                    {!previewUrlImage && (
                      <FaCamera style={{ width: 50, height: 50 }} />
                    )}
                  </StoreAvatar>
                </Badge>
                <input
                  style={{ display: 'none' }}
                  type='file'
                  id='storyImage'
                  accept='.png, .jpg, .jpeg'
                  onChange={handleChange}
                />
              </label>

              <Divider />

              <CardTitle>Identificação para NeoStory</CardTitle>

              <TextInput
                label='Nome'
                variant='outlined'
                value={storyName}
                onChange={event => setStoryName(event.target.value)}
              />
            </Card>
          </CardContainer>

          <SaveButton
            disabled={settings.name === storyName && !photo}
            onClick={handleSaveStorylineSettings}
          >
            SALVAR
          </SaveButton>

          <CardContainer>
            <Card>
              <TagsContainer>
                <CardTitle>Identificação para NeoStory</CardTitle>
                <AddTagButton onClick={() => setIsCreateTagModalOpen(true)}>
                  <BiPlus size={20} color='#fff' />
                  TAG
                </AddTagButton>
              </TagsContainer>

              {settings.tags.length > 0 ? (
                settings.tags.map(tag => (
                  <Tag key={tag._id}>
                    <NameTagContainer>
                      {tag.photo.url ? (
                        <img
                          src={tag.photo.url}
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                        />
                      ) : (
                        <Avatar style={{ width: 24, height: 24 }}>
                          <FaCamera style={{ width: 14, height: 14 }} />
                        </Avatar>
                      )}
                      {tag.name}
                    </NameTagContainer>
                    <IconsContainer>
                      <MdOutlineEdit
                        onClick={() => {
                          setIsEditTagModalOpen(true)
                          setSelectedTag(tag)
                        }}
                        size={20}
                        color='#5B5B76'
                      />
                      <BiTrash
                        onClick={() => {
                          setIsDeleteTagModalOpen(true)
                          setSelectedTag(tag)
                        }}
                        size={20}
                        color='#5B5B76'
                      />
                    </IconsContainer>
                  </Tag>
                ))
              ) : (
                <p>Sem tags cadastradas</p>
              )}
            </Card>
          </CardContainer>
        </Main>

        <StoryPreview>
          <div>
            <StoryBarContainer>
              {new Array(6).fill(0).map((_, index) => (
                <StoryBar
                  key={index}
                  storyPosition={
                    index < 2
                      ? 'isPreviousStory'
                      : index === 2
                      ? 'isCurrentStory'
                      : 'isNextStory'
                  }
                />
              ))}
            </StoryBarContainer>

            <StoryHeader>
              {previewUrlImage ? (
                <img
                  src={previewUrlImage}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <Avatar>
                  <FaCamera style={{ width: 18, height: 18 }} />
                </Avatar>
              )}

              <span>
                {storyName ? storyName : 'Nome'}
                <p>Título do NeoStory</p>
              </span>
            </StoryHeader>

            <img
              src='/images/story-preview.png'
              alt='Exemplo de pré-visualização do story'
            />

            <StoryButtonFooter>
              <button>
                <PiLinkSimpleBold size={18} />
                ABRIR LINK
              </button>
              <p>Arraste pra cima</p>
            </StoryButtonFooter>
          </div>
          Exemplo pré-visualização
        </StoryPreview>
      </Content>

      <CreateTagModal
        isOpen={isCreateTagModalOpen}
        companyId={company._id}
        onCancel={() => setIsCreateTagModalOpen(false)}
      />

      <EditTagModal
        isOpen={isEditTagModalOpen}
        companyId={company._id}
        onCancel={() => {
          setIsEditTagModalOpen(false)
          setSelectedTag({} as ITag)
        }}
        tag={selectedTag}
      />

      <DeleteTagModal
        isOpen={isDeleteTagModalOpen}
        companyId={company._id}
        onCancel={() => {
          setIsDeleteTagModalOpen(false)
          setSelectedTag({} as ITag)
        }}
        tag={selectedTag}
      />
    </Container>
  )
}
