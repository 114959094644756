export const guideTable = [
  {
    title: 'Comissão',
    listTitle: 'Escolha os parâmetros de:',
    list: [
      'Modelo de remuneração',
      'Meta por períodos',
      'Redistribuição da meta',
      'Referência para comissão'
    ]
  },
  {
    title: 'Valores',
    listTitle: 'Preencha os valores de:',
    list: [
      'Piso Salarial',
      'DSR',
      'Meta principal da loja',
      'Meta de Ticket médio',
      'Meta de Preço médio'
    ]
  },
  {
    title: 'Meta',
    listTitle: 'Defina informações como:',
    list: [
      'Vendedores da meta',
      'Meta dos vendedores',
      'Metas diárias da loja',
      'Dias que a loja irá abrir',
      'Dias de folga dos vendedores',
      'Períodos da meta'
    ]
  }
]
